.colorSolidWrapper {
  width: inherit;
  height: fit-content;
}

.colorPickerBottomBar {
  width: inherit;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 14px;
}

.colorPickerCanvasBarContainer,
.colorPickerCanvasContainer,
.colorPickerTransparencyBarContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorPickerCanvasBarContainer,
.colorPickerTransparencyBarContainer,
.colorPickerHexSelector {
  margin-top: 10px;
}

.colorBarMarker,
.canvasMarker,
.transparencyBarMarker {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  background-color: white;
  border: 2px solid #e4e4e4;
  box-shadow: 0px 1px 2px 0px #00000080;
}

.transparencyBar {
  height: 16px;
  border-radius: 16px;
  position: relative;
}

.colorPickerHexSelector {
  display: flex;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
}

.hexSelectorHash {
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
  color: #888;
  font-size: 20px;
}

.hexSelectorHashContainer {
  margin: 0 2px 0 6px;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.hexSelectorInputContainer {
  height: inherit;
  width: 100%;
}

.hexSelectorInput {
  height: inherit;
  width: inherit;
  border-color: transparent !important;
  color: #333;
  font-weight: 700;
}

.hexSelectorSeparator {
  border-left: 1px solid #e4e4e4;
  margin-left: auto;
}

.hexSelectorTransparencyContainer {
  width: 70px;
  margin-left: auto;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hexSelectorTransparencyText {
  color: #333 !important;
  font-weight: 700;
}

.hexSelectorTransparencyText:focus {
  color: #333 !important;
}

.barColorPreview {
  display: flex;
  align-items: center;
  margin-left: 4px;
  margin-right: 2px;
}