.LabsCensus :global(.wrapperAdaOpen img) {
  margin-right: 50px;
  z-index: 10001;
}

.LabsCensus :global(.contentContainer) {
  margin-right: 200px;
  position: fixed;
  z-index: 10004;
}

.LabsCensus :global(.disableInfo) {
  background: rgba(0, 0, 0, 0.45);
}

.LabsCensus :global(.contentWrapper.BngAdaBackgroundSteps) {
  padding: 35px 0;
}

.LabsCensus :global(.contentWrapper.BngAdaBackgroundSteps h1) {
  margin-left: 35px;
}

.LabsCensus :global(.contentContainer h1) {
  margin-bottom: 5px;
}

.LabFeatureVote {
  display: inline-flex;
  gap: 5px;
  height: fit-content;
  width: 100%;
}

.LabFeatureVote img {
  border-radius: 14px;
  width: 200px;
}

.labFeatTitle {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}

.labFeatDescription {
  color: #fff;
  display: -webkit-box;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.labFeatInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5px;
}

.labFeatButtons {
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 15px;
}

.labFeatButton {
  color: #fff !important;
  height: 40px !important;
  width: 40px !important;
}

.labFeatButton:hover {
  background: inherit !important;
}

.labFeatButton i {
  border-radius: 100%;
}

.labFeatTag {
  background: #f98900;
  color: #fff;
  margin-bottom: 20px;
  margin-left: 35px;
  width: fit-content;
}

.labsLink {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.animatedButtonWrapper {
  width: 50%;
}

.navigationButton {
  align-self: center;
  color: #fff;
  height: 40px !important;
}

.voteUpBtn {
  background: #00a355;
}

.voteDownBtn {
  background: #e23d3d;
}

.AnimatedVoteButton {
  border-radius: 24px;
  overflow: hidden;
}

.leftAlign {
  float: left;
}

.rightAlign {
  float: right;
}

.AnimatedVoteButton.leftAlign {
  padding-right: 10px;
}

.AnimatedVoteButton.rightAlign {
  padding-left: 10px;
}

.AnimatedVoteButton:hover {
  cursor: pointer;
}

.animatedButtonInnerDiv {
  align-items: center;
  display: inline-flex;
  width: fit-content;
  white-space: nowrap;
}
