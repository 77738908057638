.mainWrapper {
  height: 100%;
}

.bimShieldContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40%;
  max-height: 180px;
}

.bimShield {
  height: 100%;
  max-height: 180px;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2%;
  height: 60%;
}

.headerDesc .Description {
  text-align: center;
  margin-top: 14px;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
  overflow: scroll;
  max-height: 225px;
}

.cardStripContainer {
  width: calc(100% - 24px);
  background-color: #f6f6f6;
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
}

.cardIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.cardStripContainer .cardIcon :global(i) {
  font-size: 24px;
  color: var(--blue-default);
}

.cardText {
  display: flex;
  justify-content: center;
  align-items: center;
}
