.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 30px;
}

.itemWrapper {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.firstColumn {
  width: 330px;
  text-align: end;
}

.centerColumn {
  width: 20px;
  margin-left: 10px;
}

.lastColumn {
  width: 110px;
  margin-left: 10px;
}

.logFirstColumn {
  display: flex;
  width: 330px;
  text-align: end;
  margin-top: 30px;
  align-items: center;
}

.logLastColumn {
  width: 85px;
  margin: 30px 0 0 15px;
}

.errorFullUrl {
  font-size: 14px;
  display: flex;
  width: 435px;
  text-align: justify;
  align-items: center;
}

.done {
  color: #00a355;
}

.sync {
  color: #005dff;
  animation: rotate 1s linear 0s infinite normal none;
}

.close {
  color: #e23d3d;
}

.visualizeLog {
  border: 1px solid;
  cursor: pointer;
  font-weight: 500;
  line-height: 25px;
  width: 135px;
  padding: 5px 0 5px 0;
  text-align: center;
}

.visualizeLog:hover {
  transform: scale(1.02);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

.textError {
  text-align: start;
  padding-left: 10px;
}
