.personal-page > .icon-home {
    margin-left: 2px;
    margin-right: 7px;
}

.personal-page > .menu-text {
    width: 388px;
}

.personal-page .menu_option {
    width: 132px;
}

.menu-icon-centralized {
    float: right;
    width: 22px;
    text-align: center;
}