.checkboxSpan :global(.lbl) {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.radioSpan :global(.lbl) {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 92%;
}

.itensContainer {
  overflow: auto;
  width: 100%;
}
