.DomainCustomizationWrapper {
  margin: 35px;
}

.TitleAndDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.TitleAndDescription > h6 {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0;
}

.DomainCustomizationWrapper > p,
.TitleAndDescription > p {
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.DomainCustomizationWrapper > label {
  margin-bottom: 16px;
}

.DomainCustomizationWrapper > p {
  margin-bottom: 0;
}



