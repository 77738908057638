.main {
}

:global(.DarkMode) p {
  color: #EFEFEF;
}

.tfaOpt {
  padding: 8px 0 8px 0;
  border-bottom: 1px solid #5f5f5f;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
}

.tfaOpt:hover {
  opacity: 0.8;
}

.tfaOpt:global(.disabled) {
  cursor: not-allowed;
  opacity: 0.7;
}

.tfaOpt:last-child {
  border-bottom: none;
}

.tfaOpt :global(.Icon) {
  color: #005dff;
  font-size: 24px;
}

.whatsappOpt :global(.Icon) {
  color: #34bc67;
  padding-left: 3px;
}

:global(.btn.BngCancelButton).backToLogin {
  color: #5F5F5F
}

:global(.DarkMode) :global(.btn.BngCancelButton).backToLogin {
  color: #999999 !important;
}