.item-container-dashboard-options {
    display: flex;
    align-items: center;
}

.items-option-dashboard {
    text-align: center;
    width: 50%;
    padding: 10px;
    cursor: pointer;
}

.items-option-dashboard .Icon {
    color: #2876fd;
}

.items-option-dashboard:hover {
    background: #e6e6e6;
}

.item-container-dashboard-label {
    font-size: 12px;
    white-space: nowrap;
    margin-top: 5px;
    font-weight: 500;
}
