.AccFinanceTabBlocker {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.AccFinanceTab {
  background: #fafafa;
  box-sizing: border-box;
  color: #333333;
  height: 100%;
  max-width: 1240px;
  padding: 32px;
  width: 100%;
}

.AccFinanceTabBlocker :global(.block-ui-container) {
  position: fixed;
}

.planSection {
  box-sizing: border-box;
  display: inline-flex;
  gap: 24px;
  width: 100%;
}

.PlanCard {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 305px;
  height: 100%;
}

.planCardWrapper,
.newsWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;
}

.newsWrapper {
  height: 342px;
}

.planCardWrapper span,
.newsWrapper span,
.sectionTitle {
  font-size: 16px;
  font-weight: 500;
}

.planTitleWrapper {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  justify-content: space-between;
}

.priceWrapper {
  color: #005dff;
  display: inline-flex;
  font-weight: 500;
  height: 34px;
}

.planHeader {
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  gap: 20px;
  padding: 24px;
  width: 100%;
}

.priceWrapper .currency {
  font-size: 20px;
}

.priceWrapper .pricing {
  align-items: center;
  display: flex;
  font-size: 34px;
}

.planFooter {
  border-top: 1px solid #e4e4e4;
  box-sizing: border-box;
  padding: 14px 24px;
  display: inline-flex;
  justify-content: end;
  width: 100%;
}

.changePlanBtn {
  background: #ffffff !important;
  border: 1px solid #555555;
  border-radius: 6px;
  color: #333333 !important;
  padding: 5px 19px;
  transition: transform 0.2s;
}

.changePlanBtn:hover {
  background: #eee !important;
  color: #333333 !important;
  transform: scale(1.02);
}

.changePlanBtn:disabled {
  background: #eee !important;
}

.planFeatures {
  padding: 0 24px 12px 24px;
  overflow: scroll;
}

.featureItem {
  box-sizing: border-box;
  display: inline-flex;
  font-size: 12px;
  gap: 4px;
  padding-right: 8px;
  width: 50%;
}

.featureItem i {
  font-size: 20px;
}

.featureItem :global(.Icon) {
  color: #005dff;
}

.salesWrapper {
  color: #d1d1d1;
  font-size: 18px;
  text-decoration-line: line-through;
  white-space: nowrap;
}

.newsCard {
  align-items: center;
  background: #e6efff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  color: rgba(51, 51, 51, 0.36);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 33px;
  justify-content: center;
  overflow: hidden;
}

.ctaPlaceholder {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.contractSection,
.controlSection,
.consumptionSection,
.invoicesSection {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.invoicesTableWrapper {
  max-height: 600px;
  overflow: scroll;
}

.ContractsTable,
.InvoicesTable {
  background: #f6f6f6;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  position: relative;
}

.ContractsTable {
  height: 250px;
  overflow: scroll;
}

.ContractsTable tr,
.InvoicesTable tr {
  color: #333333 !important;
  font-size: 12px;
}

.ContractsTable :global(.BngEmpty) {
  position: relative;
  transform: translateY(0px);
}

.proposalId {
  align-items: center;
  color: #005dff;
  display: inline-flex;
  gap: 4px;
}

.proposalId i {
  font-size: 18px;
}

.infoCard {
  align-items: center;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  padding: 12px 0;
  width: 100%;
}

.infoCard i {
  font-size: 12px;
}

.infoValue {
  color: #005dff;
  font-weight: 700;
  line-break: anywhere;
  margin: 0 10px;
  text-align: center;
}

.AccFinanceTab section {
  margin-top: 18px;
}

.AccFinanceTab section:last-child {
  margin-bottom: 18px;
}

.infoCardSection {
  display: inline-flex;
  gap: 24px;
  width: 100%;
}

.consumptionItemWrapper,
.addonsWrapper {
  display: inline-flex;
}

.ConsumptionContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  gap: 1vw;
}

.consumptionTitle,
.controlTitle {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.consumptionControlSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.ConsumptionItems {
  display: flex;
  width: 42vw;
  justify-content: space-between;
  flex-wrap: wrap;
}

.consumptionItemsContainer {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  flex: 1 1;
}

.ControlItems {
  background-color: white;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  max-width: 100%;
  flex: 1 1;
}

.ConsumptionKpi {
  display: inline-flex;
  gap: 8px;
}

.kpiLabel {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 70px;
}

.kpiLabel span {
  font-size: 12px;
  text-align: left;
  line-height: 98%;
}

.kpiIcon {
  align-items: center;
  border-radius: 100%;
  display: flex !important;
  font-size: 38px !important;
  color: #ffffff;
  height: 62px;
  justify-content: center;
  width: 62px;
}

.valueWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.consumptionValue {
  color: #333333;
  display: flex;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 6px;
}

.valuePercent {
  color: #888888;
  font-weight: 500;
  font-size: 12px;
}

.clickable {
  cursor: pointer;
}

.valueRedirectIcon {
  color: #555555;
  font-weight: 400 !important;
  font-size: 12px !important;
  cursor: pointer;
  margin-left: 3px;
}

.link {
  font-weight: 500;
  font-size: 12px;
  color: #337dff;
}

.projected {
  color: #f98900;
}

.open {
  color: #ff4d4f;
}

.paid,
.upfront {
  color: #49aa19;
}

.financeActionDropdown {
  background: #ffffff;
  border: 1px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.195822);
  display: block;
  font-weight: 500 !important;
  margin: 5px 0;
  max-width: 260px;
  text-align: left;
  width: 300px;
}

.actionsButton {
  padding: 2px !important;
}

.actionsButton i {
  font-size: 20px !important;
}

.partialIcon {
  font-size: 18px !important;
}

.dueTimeWrapper {
  align-items: center;
  display: inline-flex;
  gap: 5px;
}

.confirmationDiv {
  position: absolute;
  top: -130%;
  left: 50%;
  transform: translateX(-65%);
  width: 240px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 2;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirmationDiv p {
  margin-bottom: 12px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}

.confirmationDiv button {
  margin: 0 8px;
}

.confirmationDiv div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.confirmationDiv button {
  width: 100px;
  padding: 3px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
}

.confirmationDiv button:first-child {
  background-color: #fff;
  color: #000;
}

.confirmationDiv button:first-child:hover {
  background-color: #f4f4f4;
}

.confirmationDiv button:last-child {
  background-color: #005dffdf;
  color: #fff;
}

.confirmationDiv button:last-child:hover {
  background-color: #0058f4;
}

.addonsItems {
  display: flex;
  gap: 15px;
  width: 100%;
  flex-wrap: wrap;
}

.addonsIcon {
  align-items: center;
  border-radius: 100%;
  display: flex !important;
  font-size: 38px !important;
  font-weight: bold;
  height: 62px;
  justify-content: center;
  width: 62px;
}

.actionIcons {
  background-color: #fff;
  color: #005dff;
  border: 1px solid #005dff;
}

.seeMoreIcon {
  font-size: 12px !important;
}

.moreIcon {
  font-size: 20px;
}

.countIcon {
  font-size: 35px;
}

.missingButtonPadding {
  padding-top: 20px;
}

.sectionTitleWrapper {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
}

.upfrontButton {
  color: #005DFF;
  padding: 5px 18px;
}