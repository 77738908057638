.main {
  word-break: keep-all;
  text-align: left;
  padding: 30px 40px 0;
}

.main h1 {
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  text-align: left;
}

.main h3 {
  font-size: 20px;
  line-height: 24px;
  text-align: left;
}