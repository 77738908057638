/*================ Dropdown ==============*/

.FloatActionButtonDropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.FloatActionButtonDropdown > div > button {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}

.FloatActionButtonDropdown > div > button > i {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.FloatActionButtonDropdown > div {
  position: relative;
  margin-top: 10px;
}

.FloatActionButtonDropdown > div > label {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  top: 10px;
  left: 60px;
  white-space: nowrap;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer !important;
}

.FloatActionButtonDropdown > div > label:after {
  position: absolute;
  top: 8px;
  left: -6px;
  content: '';
  width: 0;
  height: 0;
  border-right: solid 6px rgba(0, 0, 0, 0.8);
  border-bottom: solid 6px transparent;
  border-top: solid 6px transparent;
}

/*================ RenderActions ==============*/

.actionsDropdownPopper {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.195822);
  width: 300px;
  display: block;
  margin: 5px 0;
  border: 1px;
  border-radius: 4px;
  text-align: left;
  font-weight: 500 !important;
  max-width: 260px;
}

.actionsDropdownPopper :global(i) {
  font-size: 16px;
}

.actionsDropdownPopper :global(span) {
  font-size: 14px;
}

.actionButtonWrapper :global(div:not(#userLevelWrapper)) {
  display: flex;
  align-items: center;
  padding: 11px 15px;
  font-weight: 500;
  color: #6e6e6e;
  cursor: pointer;
  line-height: 30px;
}

.actionButtonWrapper :global(.BngDropdown) {
  padding: 0 !important;
}

.actionButtonWrapper :global(hr) {
  color: #e2e2e2;
  margin: 0;
  width: 100%;
}

.actionItem {
  max-height: 25px;
}

.actionButtonWrapper :global(div:hover:not(#userLevelWrapper)) {
  background: #efefef;
}

.actionButtonWrapper :global(i) {
  padding: 0 8px 0 5px;
}
