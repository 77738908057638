.InviteUsersDialog {
  border-radius: 8px;
  left: 45%;
  width: 725px !important;
}

.InviteUsersDialog :global(.dialog-body) {
  overflow: hidden;
}

.dialogDivisionTop {
  margin-top: 10px;
  border-top: #e2e2e2 1px solid;
}

.img {
  width: 19vw;
  max-width: 368px;
  min-width: 180px;
}

.dialogBody {
  height: 100%;
}

.emailConfirmation {
  height: 100%;
}

.fieldWrapper {
  align-items: center;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  height: 201px;
  justify-content: flex-start;
  margin-bottom: -9px;
  position: relative;
  top: -2%;
  width: 100%;
}

.userForm.multiProject .fieldWrapper {
  height: 469px;
}

.NewUserDialog :global(.DialogBody) {
  display: flex;
  justify-content: center;
}

.NewUserDialog :global(.widget-header) {
  border-radius: 8px 8px 0 0;
}

.NewUserDialog :global(.widget-body) {
  border-radius: 0 0 8px 8px;
}

.NewUserDialog :global(.DialogFooter) {
  border-top: 1px solid #e2e2e2;
}

.userForm {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.userForm:not(.multiProject) {
  padding-top: 3%;
}

.fieldWrapper :global(.BngField) {
  margin-top: 3%;
  width: 70%;
}

.emailField {
  min-height: 40px;
}

.iconWrapper {
  background: #9e9e9e;
  border-radius: 100%;
  height: 24px;
  position: absolute;
  right: 17%;
  top: 27.5%;
  width: 24px;
}

.userForm.multiProject .iconWrapper {
  top: 56px;
}

.addIcon {
  color: white;
  font-size: 18px !important;
  margin-top: 3px;
  margin-left: 3px;
}

.iconValidated {
  background: #005dff;
  cursor: pointer;
}

.emailsWrapper {
  display: inline-flex;
  font-size: 14px !important;
  flex-wrap: wrap;
  max-height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
  text-shadow: none !important;
}

.selectedEmail {
  align-items: center;
  background: #005dff;
  border-radius: 20px;
  color: white;
  display: flex;
  gap: 5px;
  height: 7px;
  margin: 6px;
  max-width: 200px;
  padding: 10px;
}

.selectedEmail :global(span) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fieldWrapper :global(.controls) {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
}

.fieldWrapper :global(.BngInput) {
  border: none;
  padding-right: 45px !important;
}

.userRoleWrapper {
  align-items: center;
  display: inline-flex;
  width: 70%;
}

.roleWrapper {
  align-items: center;
  display: flex;
  cursor: pointer;
  gap: 8px;
  margin-left: 10px;
  padding: 9px 15px;
  position: relative;
  width: fit-content;
}

.roleWrapper:hover {
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.projectListWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 70%;
  height: 320px;
}

.projectTableWrapper {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  overflow: auto;
}

.InviteUsersDialog span,
.InviteUsersDialog label {
  font-weight: 500;
}

.changeRoleOverlay {
  z-index: 321050 !important;
}
