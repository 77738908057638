.tableAppInfoLogo {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

.tableSep {
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.progressIcon {
  line-height: 40px;
}
