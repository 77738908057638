.successAnimationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.successAnimation {
  width: 255px;
  height: 255px;
}

.stepsContainer {
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 136px;
  overflow-y: scroll;
}

.cardStrip {
  background-color: var(--light-gray-default);
  padding: 8px 12px;
  align-items: center;
  border-radius: 4px;
}

.contentContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.cardStrip .contentContainer :global(i) {
  color: var(--blue-default);
}
