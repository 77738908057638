.new-ticket-support-image-parent {
    background-color: #f0f1f2;
}

.new-ticket-support-image {
    width: 215px;
    height: 215px;
}

.support-file-info {
    margin: 3px;
}

.files-container {
    max-height: 150px;
    overflow-y: auto;
}

.alert.min-padding {
    padding: 8px !important;
}

.btn-upload-container {
    cursor: pointer;
    text-align: center;
    margin-top: 5px;
    background-color: #ffffff !important;
}

.btn-upload-container:hover {
    cursor: pointer;
    text-align: center;
    margin-top: 5px;
    background-color: #eeeeee !important;
}

.TicketForm .progress.progress-info {
    margin-top: 5px;
    margin-bottom: 5px;
}

.TicketForm .progress.progress-info span {
    color: white;
}

.TicketForm .files-container .alert {
    margin-left: 0;
    margin-right: 0;
}

.TicketFormBody .icon-trash {
    margin: 0;
}

.TicketFormBody.Mobile {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    min-height: calc(100vh - 40px);
    padding-left: 10px;
    padding-right: 10px;
}

.TicketFormBody.Mobile .DefaultDialogActions button {
    margin-top: 0;
}

.TicketFormBody .RemoveFileButton {
    width: 20px;
    height: 20px;
}

.TicketForm .widget-body  {
    overflow-y: scroll;
    max-height: 75vh;
}

.TicketForm .widget-header {
    box-shadow: inset 0 -1px 0 0 #E2E2E2;
}

.MobileSupportForm {
    overflow: scroll;
    height: 100vh;
}

.MobileSupportForm::-webkit-scrollbar {
    width: 0;
}