.pillWrapper {
  width: 100%;
}

.pillContainer {
  position: relative;
  width: 100%;
  height: 10px;
  border-radius: 22px;
  background: #dbdbdb;
}

.pillFilledBar {
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  border-radius: 22px;
  background-color: #015d00;
  background-image: linear-gradient(314deg, #015d00 0%, #04bf00 74%);
}
