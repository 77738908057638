.main-content .BngRightMenu,
#right-menu-container .BngRightMenu,
.main-content #ObjectRightMenuAccordionWrapper,
#right-menu-container #ObjectRightMenuAccordionWrapper {
    top: 84px;
    height: calc(100% - 84px);
}

.RightMenuWrapper.Close #ObjectRightMenuAccordionWrapper {
    width: 0;
}

#ObjectRightMenuAccordionWrapper {
    position: absolute;
    top: 0;
    right: 53px;
    width: 0;
    height: 100%;
    overflow-y: auto;
}

#ObjectRightMenuAccordionWrapper::-webkit-scrollbar {
    background-color: #EFEFEF;
}

.BngRightMenu {
    position: absolute;
    top: 0;
    right: 0;
    width: 52px;
    height: 100%;
    border-left: 1px solid #CCC;
    background-color: #EFEFEF;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 99;
    overflow-y: auto;
    overflow-x: hidden;
}

.BngRightMenu.Close {
    width: 0;
    border: 0;
}

.BngRightMenu ul {
    margin: 0;
}

.BngRightMenu ul > div {
    display: flex;
}

.BngRightMenu li {
    width: 100%;
    height: 52px;
    border: 0;
    color: #6e6e6e;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.BngRightMenu li > div.disabled {
    opacity: 0.5;
    cursor: not-allowed
}

.BngRightMenu li.open,
.BngRightMenu li:hover {
    margin: 0;
    background-color: #FAFAFA;
    box-shadow: inset 0 1px 0 0 #E2E2E2, inset 0 -1px 0 0 #E2E2E2;
}

.BngRightMenu ul li .Icon {
    font-size: 20px;
    display: flex;
    align-items: center;
}

.BngRightMenu ul li .Icon[class*=" icon-bim-"] {
    font-size: 22px;
}

.BngRightMenu li > * {
    margin: auto;
}

.BngRightMenu li a {
    border: 0;
    color: unset;
}

.BngRightMenu #object-right-menu-fixed-options {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #efefef;
}

.BngRightMenu #object-right-menu-fixed-options li {
    box-shadow: inset 0 1px 0 0 #E2E2E2;
}

/*submenus*/

.RightMenuOptionPopper {
    z-index: 150;
    background: #fff;
    width: 250px;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.BngClickOutsideOverlay.RightMenuOptionPopperOverlay {
    z-index: 149;
}

.RightMenuOptionPopper .submenu {
    padding: 0 !important;
    background-color: #f7f8fa;
    margin: 0;
    border-radius: 4px;
}

.RightMenuOptionPopper ul.submenu li {
    display: block;
}

.RightMenuOptionPopper .submenu .menu-text {
    text-align: center;
    padding: 14px;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    font-weight: 500;
}

.RightMenuOptionPopper .submenu .menu-text,
.submenu-analysis-visualizations,
.item-container-dashboard-options {
    color: #585858;
}

.AnalysisVisualizationHelp {
    float: right;
}

.AnalysisVisualizationHelp .icon-info-sign {
    line-height: 20px;
}

li.submenu-analysis-visualizations-action {
    padding: 10px 0;
}

li.submenu-analysis-visualizations-action .BngCheckbox span.lbl {
    padding-left: 60px;
}

.SortRankDropdown .SortRankQuantityWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}

.SortRankDropdown .SortRankQuantityWrapper .Icon {
    padding: 4px;
    background-color: #e6e6e6;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.SortRankDropdown .SortRankQuantityWrapper > .BngField {
    margin: 0;
    width: 100%;
}

.SortRankDropdown .BngForm {
    padding: 10px;
}

/*end submenus*/