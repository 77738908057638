.ActivityDialog {
    height: 550px;
    width: 80%;
    max-width: 1050px;
    left: 36%;
    overflow: hidden;
}

.ActivityDialog :global(.modal-dialog),
.ActivityDialog :global(.modal-content),
.ActivityDialog :global(.modal-body) {
    height: 100%;
    overflow: hidden;
}

.headerDivider {
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: #E2E2E2 1px solid;
}

.fieldsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
}

.buttonOpenDropdown {
    cursor: pointer;
    color: #373737;
    font-weight: 500;
    opacity: 0.8;
    display: inline-flex;
    padding: 3px;
    margin: 7px 12px;
    align-items: center;
    border-radius: 6px;
}

.buttonOpenDropdown:hover {
    background-color: #eee;
}

.buttonOpenDropdown :global(i){
    color: #373737;
}

.ActivityTable :global(.BngTableTh:first-child),
.ActivityTable :global(.BngTableTd:first-child) {
    padding-left: 38px;
}

.ActivityTable :global(.BngTableTh  ) {
    color: #333;
}

.ActivityTable :global(tr) {
    height: 65px !important;
    max-height: 65px;
}

.buttonDropdownText {
    cursor: pointer !important;
    font-weight: 500;
    padding: 3px;
    margin: 0;
}

.activityMessage {
    max-height: 42px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 42px;
}

.activityMessage :global(strong) {
    color: #005dff;
}

.ActivityFilterDropdownPopper {
    z-index: 321051 !important;
}

.ActivityFilterDropdownPopper:global(.ActivityFilterDropdownPopperOverlay) {
    z-index: 321050 !important;
}

.activityDropdownBody {
    width: 240px;
    padding: 28px;
    display: block;
    margin: 5px 0;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    background: #fff;
    border: 1px;
    border-radius: 4px;
}

.ActivityTable :global(.BngEmpty) {
    top: 65%
}

.ActivityTable :global(tr:first-child) {
    padding-left: 15px;
}

.ActivityTable :global(tr:last-child) {
    padding-right: 15px;
}

.filterDropdownField :global(li) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dateField {
    height: 38px !important;
    box-shadow: inset 0 0 0 1px #ccc !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    color: #555 !important;
    position: relative !important;
    background-color: white !important;
}

.filterDropdownPopper {
    z-index: 999999  !important;
}

.selectOption {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.activitiesScroll {
    overflow-y: scroll;
    height: 408px;
}