.presentationlist-table-tr {
    height: 46px;
}

.presentationlist-table-tr:nth-child(even) {
    background-color: #f6f6f6 !important;
}

.presentationlist-table-tr:nth-child(odd) {
    background-color: #ffffff !important;
}

.presentationlist-table-td {
    line-height: 37px;
    max-width: 260px;
    max-height: 40px;
    vertical-align: middle !important;
}

.presentationlist-table-td .name {
    width: 490px;
    max-width: 490px;
}

.presentationlist-table-td .date {
    width: 260px;
    max-width: 260px;
}

.div-information-presentation {
    display: flex;
    line-height: 37px;
    padding-left: 10px;
    font-size: 12px;
    color: #373737;
    font-weight: 500;
    opacity: 0.8;
    align-items: center;
    gap: 5px;
}

.div-information-presentation span {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    overflow: hidden;
}

.button-menu-presentation {
    color: black;
    padding-top: 7px;
    font-size: 28px;
}

.label-add-link {
    cursor: pointer !important;
    font-weight: 500;
    padding: 3px;
    margin: 0;
}

.presentationPagination {
    width: 100%;
}

.PresentationTabSet .tab-content {
    height: 500px;
}