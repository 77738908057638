.sendTabContainer {
  height: 300px;
}

.sendTabContainer :global(.control-group.error) .bngFieldLabel,
.sendTabContainer .bngFieldLabel {
  font-size: 14px;
  color: #333;
  text-shadow: none;
  font-weight: 500;
}

.titleDescContainer {
  border-radius: 8px;
}

.titleDescContainer .titleField {
  border: 1px solid #d1d1d1;
  height: 38px;
  border-radius: 8px 8px 0 0;
  position: relative;
}

.titleDescContainer .titleFieldRoot,
.titleDescContainer .descriptionFieldRoot {
  margin-bottom: 0;
}

.titleDescContainer .descriptionField {
  height: fit-content;
  min-height: 100px;
  border: 1px solid #d1d1d1;
  border-radius: 0 0 8px 8px;
  margin-top: -1px;
  position: relative;
}

.titleDescLabel {
  display: flex;
  margin-bottom: 8px;
}

.memberCheckboxOption {
  position: relative;
}

.optionWrapper {
  user-select: none;
}
