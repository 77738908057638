.IpRestrictionDetailsDialog :global(.dialog-body) {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.updateInfoSection {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.updateMember {
  border-left: 3px solid #d1d1d1;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 20px;
}

.extraInfoSection {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 10px 10px 10px;
  width: 100%;
}

.usersAndGroupsSection {
  display: inline-flex;
  gap: 20px;
}

.userList {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.userListHeader {
  align-items: center;
  box-shadow: 0 1px 0 0 #e4e4e4;
  display: inline-flex;
  padding: 5px 0;
  gap: 5px;
}

.userListHeader span:not(:global(.BngTag)) {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
}

.userListItem {
  align-items: center;
  box-shadow: 0 1px 0 0 #e4e4e4;
  display: inline-flex;
  gap: 5px;
  padding: 5px;
}

.userListTag {
  width: fit-content;
}

.listItem::before {
  border-radius: 100%;
  background: #669eff;
  content: '';
  display: inline-block;
  height: 10px;
  margin-right: 2px;
  width: 10px;
}
