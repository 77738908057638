.BngMultiStep {
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    width: 550px;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.BngMultiStep .Body {
    flex: 1;
}

.BngMultiStep .Step {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.BngMultiStep .Step .Children {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.BngMultiStep .Step .Image {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    height: 309px;
    width: 100%;
}

.BngMultiStep .Step .Title {
    color: #292C31;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: normal;
    margin: 20px 20px;
}

.BngMultiStep .Step .Subtitle {
    color: #6C6C6C;
    font-size: 15px;
    letter-spacing: 0.09px;
    line-height: 19px;
    margin: 0 20px;
    max-height: 70px;
}

.BngMultiStep .Step .Footer button {
    margin: 0;
    border: unset;
}

.BngMultiStep .Footer {
    display: flex;
    flex: 0 1 60px;
    margin: 0 20px;
    align-items: center;
}

.BngMultiStep .Footer .Dots {
    display: flex;
    flex: 1;
}

.BngMultiStep .Footer .Buttons {
    display: flex;
    justify-content: flex-end;
}

.BngMultiStep.BngAdaBackground .cancel.enable,
.BngMultiStep.BngAdaBackground .save.enable {
    color: white !important;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
}

.BngMultiStep.BngAdaBackground h4.Title {
    color: white !important;
}

.BngMultiStep.BngAdaBackground p.Subtitle {
    color: white !important;
}