.appsToSelectContainer {
  height: 260px;
  overflow: auto;
}

.appInfoTime {
  color: #373737;
  font-weight: 500;
}

.logoContainer {
  height: 30px;
  width: 100%;
}
