.TicketViewDialog .ScrollContainer.details {
    max-height: 350px;
}

.TicketViewDialog .comment-form-container {
    margin-top: 10px;
}

.TicketViewDialog {
    top: 36px;
}

.details {
    height: 190px;
}

.TicketViewDialog.mobile {
    height: 95%;
    width: 95%;
    margin-left: -48%;
}

.TicketViewDialog.mobile .scroll-wrapper.ScrollContainer.scrollbar-macosx.historic {
    height: 68% !important;
}

.TicketViewDialog.mobile .modal-dialog,
.TicketViewDialog.mobile .modal-dialog .modal-content,
.TicketViewDialog.mobile .modal-dialog .modal-content .modal-body,
.TicketViewDialog.mobile .modal-dialog .modal-content .modal-body .UiBlocker,
.TicketViewDialog.mobile .modal-dialog .modal-content .modal-body .UiBlocker .widget-box,
.TicketViewDialog.mobile .modal-dialog .modal-content .modal-body .UiBlocker .widget-box .widget-body .widget-main,
.TicketViewDialog.mobile .modal-dialog .modal-content .modal-body .UiBlocker .widget-box .widget-body .widget-main .dialog-body,
.TicketViewDialog.mobile .modal-dialog .modal-content .modal-body .UiBlocker .widget-box .widget-body .widget-main .dialog-body:last-child {
    height: 100%;
}

.TicketViewDialog.mobile .modal-dialog .modal-content .modal-body .UiBlocker .widget-box .widget-body {
    height: 91%;
}
