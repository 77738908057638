.BngInputIconSelector .Label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer !important;
}

.BngInputIconSelector .Label .lbl {
  margin-right: 10px;
}

.BngField .BngInputIconSelector {
  cursor: pointer;
  border: 1px solid #efefef;
  border-radius: 4px;
  font-size: 12px;
  padding: 3px 8px;
  text-align: center;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3558ef;
}

.BngInputIconSelector.round {
  background: #ffffff;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  height: 42px;
  width: 42px;
  border-radius: 100%;
}
