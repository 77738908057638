.btnContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
}

.activateButton,
.notNowButton {
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 8px;
}

.activateButton {
  font-weight: 700;
  background-color: var(--blue-default);
  color: white;
}

.notNowButton {
  background: transparent;
}
