.labsSuggestionWrapper {
  padding: 10px 18px 10px 18px;
  margin-top: 8px;
}

.labsSuggestionContainer {
  padding: 2vh 8vw;
}

.infoLabel {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 34px;
  border-radius: 4px;
  background-color: #e6efff;
  border: 1px solid #ccdfff;
}

.infoLabel :global(i) {
  margin-left: 8px;
  font-size: 18px;
  color: var(--blue-default);
}

.infoLabel :global(span) {
  color: #555555;
}

.labsSuggestionWrapper .formWrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 18px !important;
}

.labsSuggestionWrapper .formWrapper :global(.BngInput) {
  padding: 14px;
}

.labsSuggestionWrapper .formWrapper :global(.control-label.flex-center-items) {
  margin-bottom: 12px;
  color: #333;
  font-weight: 500;
}

.labsSuggestionWrapper .formWrapper :global(.BngTextArea) {
  height: 112px;
}

.labsSuggestionWrapper .formWrapper :global(.BngSelect) {
  height: fit-content;
  padding: 14px;
}

.uploadWrapper {
  width: 100%;
  position: relative;
}

.pill {
  width: 30%;
  position: absolute;
  right: 0;
  top: 12px;
}

.filesTag {
  display: inline-flex;
  gap: 8px;
  flex-flow: row wrap;
}

.formFooter {
  width: 100%;
  display: flex;
}

.submitBtn {
  margin-left: auto;
}
