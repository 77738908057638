.AddonDisabledDialog {
  background: #fff;
  border-radius: 8px;
  max-width: 500px;
  width: 500px;
}

.AddonDisabledDialog :global(.widget-main) {
  padding: 0;
}

.AddonDisabledDialog :global(.widget-header) {
  border-radius: 8px;
  height: 45%;
  padding: 0;
}

.AddonDisabledDialog :global(.widget-body) {
  border-radius: 8px;
}

.AddonDisabledDialogHeader {
  width: 100%;
  border-radius: 8px;
}

.titleIcon {
  color: #337dff;
  font-size: 82px !important;
  left: 42%;
  position: absolute;
  top: 35%;
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

.addonTitle {
  font-weight: 500;
  color: #333333;
  font-size: 24px;
  text-align: center;
  margin: 10px;
}

.addonDescription {
  color: #555555;
  font-weight: 500;
  font-size: 14px;
  margin: 14px 30px;
}

.buttonsWrapper {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 35px;
  margin-bottom: 25px;
  margin-top: 10px;
}

.addonButtons {
  border-radius: 2px;
  padding: 8px 18px;
  font-weight: 700;
  transition: transform 0.2s;
}

.addonButtons:hover {
  transform: scale(1.02);
}

.knowMoreButton {
  background: #fff !important;
  color: #333 !important;
  border: 1px solid #6c6c6c;
}

.knowMoreButton:hover {
  background-color: #eee !important;
}

.knowMoreButton[disabled] {
  background-color: #eee !important;
}

.submitButton {
  background: #005dff !important;
  color: #fff;
}

.submitButton:hover {
  background-color: #506dea !important;
  transform: scale(1.02);
}
