.BngForm label {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
}

.BngForm .BngField {
    margin-bottom: 18px;
}

.BngForm input,
.BngForm select,
.BngForm textarea {
    border: 1px solid #EFEFEF;
    border-radius: 4px;
    font-size: 12px;
    padding: 8px 12px;
}

.BngForm input,
.BngForm select {
    height: 34px;
}

.BngForm textarea {
    min-height: 40px;
}

.BngForm .BngSwitch .BngSwitchLabel {
    font-size: 12px;
}

.BngForm .BngSwitch .switch-label {
    padding: 0;
    height: 14px;
}

.BngForm .BngSwitch.vertical-title {
    margin-top: 0;
}

.BngForm .help-inline {
    padding-left: 0;
}