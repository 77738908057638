.DashAddObjectMenu {
  overflow-x: hidden;
}

.DashAddObjectMenu .BngNewKpiPageWrapper .AssistedObjectPageContainer,
.DashAddObjectMenu .BngNewAnalysisWrapper .AssistedObjectPageContainer {
  padding: 0px;
}

.DashAddObjectMenu .AssistedObjectPage .UiBlocker.Content {
  padding: 15px;
}

.DashAddObjectMenu .AssistedObjectPage .TypeSelectionContainer > .Content .SearchContainer {
  margin-bottom: 15px;
  width: 100%;
}

.DashAddObjectMenu .AssistedObjectPage .TypeSelectionContainer > .Content .BngHorizontalCardBorder {
  margin: 0;
  width: calc(100% - 46px);
}

.DashAddObjectMenu .AssistedObjectPage .TypeSelectionContainer > .Content .CardsContainer {
  gap: 15px;
}

.DashAddObjectMenu .BngAnalysisTypeSelected .ParamsBox {
  background-color: transparent;
  padding: 15px;
}

.DashAddObjectMenu .RightPanel,
.DashAddObjectMenu .BngAnalysisTypeSelected .ParamsBox {
  width: 100%;
}

.DashAddObjectMenu .DashAddObjectMenuContent {
  height: calc(100vh - 135px);
  overflow-y: auto;
  overflow-x: hidden;
}

.DashAddObjectMenu .AssistedObjectPage.onTypeSelection {
  background-color: transparent;
  min-height: calc(100% - 3px);
}

.DashAddObjectMenu .bng-search.open {
  background-color: transparent;
}

.DashAddObjectMenu .AssistedObjectPage .Content .SearchContainer .bng-search-input .Icon {
  font-size: 24px;
}
