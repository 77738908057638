.upfrontPaymentDialogBody {
  height: 384px;
  overflow-y: auto;
  padding: 0 !important;
}

.monthsSection,
.proposalsSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.totalSection {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
}

.totalSection span {
  color: #555555;
  font-size: 16px;
  font-weight: 500;
}

.pricingWrapper {
  display: inline-flex;
  gap: 5px;
}

.totalPricing {
  font-size: 18px;
  font-weight: bold;
}

.fullPricing {
  text-decoration: line-through;
}

.titleSpan {
  color: #555555;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.upfrontPaymentMonth {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
}

.upfrontPaymentMonth,
.upfrontPaymentDialogBody :global(.BngRadio label) {
  cursor: pointer !important;
}

.upfrontPaymentMonth.selectedRadio span {
  font-weight: 500;
}

.selectedRadio .monthDiscount {
  color: #005dff;
}

.monthsWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upfrontPaymentDialogFooter {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.proposalsTable {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
}

.proposalsTable :global(.BngTableTr:first-child .BngTableTd) {
  border-top: none;
}

.proposalsTable :global(.BngTableTr .BngTableTd) {
  padding: 6px;
}

.actionsRow {
  align-items: center;
  display: inline-flex;
  height: 30px;
}

.actionsRow.childRow {
  margin-left: 15px;
}
