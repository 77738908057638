/*background dos warnings*/
.Toastify__toast-theme--colored.Toastify__toast--warning {
    background: #ff9f09;
}

/*container do alerta*/
.Toastify__toast-container {
    z-index: 1000000;
    width: 295px;
}

/*a notificação/alerta*/
.Toastify__toast {
    border-radius: 6px;
    padding: 0 9px;
    margin-bottom: 0.7rem;
    min-height: auto;
}

/*corpo/texto da notificação*/
.Toastify__toast-body {
    padding: 0;
    margin: 3px 0;
    align-items: flex-start;
}

/*botão de abrir log completo*/
i.Icon.notranslate.material-icons.zoom-out-toast-button,
i.Icon.notranslate.material-icons.close-toast-button {
    margin-top: 1px;
    font-size: 21px;
    transition: all 0.5s ease-in-out;
}

i.Icon.notranslate.material-icons.zoom-out-toast-button:hover,
i.Icon.notranslate.material-icons.close-toast-button:hover {
    border-radius: 100%;
    transform: scale(1.1);
    background: #00000021;
    transition: 0.5s all linear;
}

.toast-buttons {
    display: flex;
    align-items: flex-start;
}

.toast-buttons.hasTrace {
    display: flex;
    flex: 1 0 17%;
    justify-content: space-between;
}

/*container título/botões etc*/
.toast-flex-up-column.info,
.toast-flex-up-column.warning,
.toast-flex-up-column.error {
    display: flex;
    flex-grow: 1;
    color: #fff;
    margin: 1px 0 2px 0;
    word-break: break-word;
}

/*títulos de notificações*/
h5.toast-title,
h5.toast-title.error {
    display: flex;
    order: -1;
    flex: 1 0 83%;
    text-align: justify;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-break: auto;
    margin: 4px 4px 6px 0;
}

h5.toast-title.warning,
h5.toast-title.info {
    font-size: 14px;
}

h5.toast-title.warning.hasTrace,
h5.toast-title.info.hasTrace,
h5.toast-title.error.hasTrace {
    flex: 1 0 81%;
}

/*descrições/detalhes*/
p.toast-details.error,
p.toast-details.warning,
p.toast-details.info {
    overflow: hidden;
    line-break: auto;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    width: 267px;
    -webkit-box-orient: vertical;
    margin-top: 1px;
    margin-bottom: 8px;
    color: #fff;
    line-height: 16px;
    text-align: left;
    word-break: break-word;
}