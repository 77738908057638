.BngRestrictedColor {
    display: inline-flex;
    align-items: center;
    position: relative;
}

.BngRestrictedColor label {
    margin-bottom: 0;
    margin-left: 4px;
}

.BngRestrictedColor .SelectedColor {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: inline-block;
}

.BngRestrictedColor .ColorsDropdown {
    overflow: hidden;
    list-style-type: none;
    width: 120px;
    background-color: white;
    position: absolute;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 50000 !important;
    border-radius: 8px;
    left: -30px;
    top: 20px;
    padding: 5px;
}

.BngRestrictedColor .GridOptions {
    display: grid;
    grid: repeat(2, 25px) / auto-flow 25px;
    grid-column-gap: 6px;
    grid-row-gap: 4px;
}

.BngRestrictedColor .OptionColors {
    border-radius: 100%;
    display: inline-block;
    border: 0;
}