.pageWrapper {
  display: flex;
  padding-top: 5vh;
  width: 100%;
  height: 90vh;
  flex-direction: column;
  align-items: center;
  overflow: hidden scroll;
}

.blueThing {
  align-items: center;
  background-color: #0f82f4;
  background-position: center;
  background-size: contain;
  border-radius: 12px;
  display: flex;
  height: 220px;
  justify-content: center;
  position: relative;
  width: 90%;
}

.logoFilters {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 220px;
  justify-content: space-evenly;
}

.logoFilters > div > img {
  height: 60px;
  margin-left: 5px;
}

.logoFilters > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 48px;
  line-height: 45px;
  font-weight: 700;
  color: white;
}

.filters {
  height: 50px;
  max-width: 50vw;
  overflow-x: auto;
}

.languageSelector {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}

.filter {
  border-radius: 4px;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  margin-right: 10px;
  padding: 5px 10px 5px 10px;
}

.filter > i {
  margin-right: 5px;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
}

.bimNewsBody {
  display: flex;
  height: 1555px;
  width: 90%;
}

.newsList {
  width: 85%;
  height: 1555px;
  display: flex;
  justify-items: center;
  flex-direction: column;
}

.newsCard {
  height: 235px;
  width: 75%;
  margin: 25px;
  display: flex;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  padding: 12px;
  gap: 12px;
  transition: all 0.2s ease-in-out;
}

.newsCard:hover,
.helpCenter:hover,
.pinnedNews:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.newsCard:global(.reverse) {
  flex-direction: row-reverse;
}

.titleDescription {
  display: flex;
  height: 100%;
  width: 50%;
  gap: 8px;
  flex-direction: column;
}

.image {
  align-content: center;
  aspect-ratio: 16/9;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.newsCard:global(:not(.vertical)) .image > img,
.newsCard:global(:not(.vertical)) .image {
  height: 100%;
}

.image > img,
.newsCard:global(.vertical) .image > img {
  aspect-ratio: 16/9;
  border-radius: 12px;
}

.socials {
  width: 30%;
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.socialsTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  padding: 20px 0 0 10px;
}

.socialIcons {
  display: flex;
  flex-direction: row;
}

.socialIcons > img {
  height: 30px;
  border-radius: 15px;
  padding: 10px;
  filter: invert(0.3);
  transition: all 0.2s ease-in-out;
}

.socialIcons > img:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.newsType {
  border-radius: 15px;
  color: white;
  display: inline-block;
  padding: 0 10px 0 10px;
  width: fit-content;
}

.newsTitle {
  color: #333333;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.versionDate {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #888888;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.versionDate > div {
  height: 3px;
  width: 3px;
  background-color: #888888;
  border-radius: 100%;
  margin-right: 5px;
  margin-left: 5px;
}

.newsBrief {
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #555555;
}

.helpCenter {
  background-color: #333333;
  color: white;
  font-size: 25px;
  padding: 12px 24px 12px 24px;
  border-radius: 6px;
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 10px;
  transition: all 0.2s ease-in-out;
}

.helpCenter > img {
  height: 40px;
  margin-right: 10px;
}

.pinnedNewsWrapper {
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  height: 580px;
}

.pinnedNewsListTitle {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  padding: 10px;
}

.pinnedNewsListWrapper {
  border-top: 1px solid #e4e4e3;
  padding-left: 10px;
  padding-right: 10px;
  overflow: auto;
  height: 552px;
}

.pinnedNews {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #555555;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 10px;
  transition: all 0.2s ease-in-out;
}

.pinnedNews > div {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 10px;
  line-height: 18px;
  color: #333333;
}

.otherNews {
  display: grid;
  justify-items: center;
  width: 90%;
  margin-top: 15px;
  position: relative;
}

.otherNews :global(.title) {
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
  margin-bottom: 5px;
}

.otherNews :global(.title.hide) {
  display: none;
}

.moreNews {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  justify-items: center;
  align-items: end;
}

.newsCard:global(.vertical) {
  flex-direction: column;
  height: 325px;
  justify-content: center;
  width: 80%;
}

.newsCard:global(.vertical) .titleDescription {
  display: flex;
  height: unset;
  width: 100%;
  gap: 8px;
  flex-direction: column;
}

.newsCard:global(.vertical) .image {
  align-content: center;
  aspect-ratio: 16/9;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: auto;
  width: 100%;
}

.seeMoreWrapper {
  height: 100px;
  width: 99vw;
  background-color: rgb(255 255 255);
  position: absolute;
  bottom: 0;
  box-shadow: 0 0 70px 80px rgba(255, 255, 255, 1);
  display: grid;
  justify-items: center;
  align-items: end;
}

.seeMoreWrapper:global(.hide) {
  display: none;
}

.seeMore {
  height: 35px;
  width: fit-content;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row-reverse;
}
