.RenameDialog {

}

.RenameDialog .modal-input {
    width: 500px;
}

.RenameDialog .LoadingSvg {
    width: 18px;
    height: 18px;
}

.RenameDialog .ChildrenView {
    overflow: auto;
    max-height: 350px;
}

.RenameDialog .ChildrenView .BngTable {
    font-size: 14px;
}

.RenameDialog .ChildrenView .DoneIcon {
    color: #2876fd;
}

.RenameDialog .ChildrenView .ErrorIcon {
    cursor: pointer;
    color: #d15b47;
    opacity: 0.85;
    transition: opacity ease-in-out 200ms;
}

.RenameDialog .ChildrenView .ErrorIcon:hover {
    opacity: 1;
}

.ErrorContainer {
    max-height: 300px;
    overflow: auto;
    text-align: left;
}

.RenameDialog .DetailsBtn {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.RenameDialog .FolderWarning {
    word-break: keep-all;
}

.RenameDialog .loadingIcon {
    width: 24px;
    height: 24px;
}