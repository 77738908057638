.labsPageWrapper {
  width: 80%;
  background-color: white;
  min-width: 800px;
  margin: 20px 0 20px 0;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
}

.labsHeader {
  width: 60%;
  gap: 18px;
  display: grid;
  height: 170px;
  user-select: none;
  padding: 50px 0 0 30px;
}

.labsHeaderTitle {
  color: #333333;
  font-size: 24px;
  font-weight: 700;
}

.labsHeaderDesc {
  color: #555;
  font-weight: 400;
  font-size: 14px;
}

.labsHeaderDesc :global(a) {
  cursor: pointer;
}

.labsHeaderScientist {
  background-image: url('Ada_scientisst_left.svg');
  height: 260px;
  width: 260px;
  position: absolute;
  left: 65vw;
  top: 6%;
}

.tabSetContainer :global(li.link) {
  background-color: #f6f6f6;
}

.tabSetContainer :global(.tab-content) {
  border-radius: 0 0 1% 1%;
  overflow: hidden;
}

.firstTab {
  margin-left: 24px;
}

.labsContainer {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #f6f6f6;
  height: calc(100vh - 48px);
  overflow: auto;
}
