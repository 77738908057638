.cockpit-header-mobile {
    height: 52px;
    background-color: #292C31;
    text-align: center;
}

.label-cockpit-mobile {
    height: 25px;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    margin: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: calc(100vw - 150px);
    text-align: left;
}

.icon-back-mobile {
    margin-left: 15px;
    margin-right: 0px;
}

.width-100 {
    width: 100%;
}

.cockpit-nav-item {
    height: 40px;
}

a.cockpit-item-label {
    text-align: left;
}

.cockpit-header-panel-ul-container.on-publisher {
    width: 100%;
    margin-right: 0;
}

.cpt-btn-item span {
    font-size: 16px;
    position: absolute;
    margin-top: 10px;
    font-weight: normal;
}

.cpt-btn-item a {
    text-align: left;
}

.cpt-btn-item a nobr {
    padding-left: 24px;
    width: 100%;
    max-width: calc(33ch);
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.cockpit-toolbar-dropdown-option a span.icon-star,
.cockpit-toolbar-dropdown-option a span.icon-star-empty {
    font-size: 12px;
    line-height: 44px;
}

.cockpit-toolbar-dropdown-option a.cockpit-dropdown-access-link.active:hover {
    color: #0389cc !important;
}

.cockpit-dropdown-access-link.active,
.cockpit-toolbar-dropdown-option a span.icon-star,
.cockpit-dropdown-access-link.active + .cockpit-dropdown-fav.cptfavbt {
    background: #fff;
    color: #0389cc;
}

.cockpit-header-panel-ul {
    list-style: none;
    margin: 0;
    white-space: nowrap;
}

.sidebar-collapse i {
    cursor: pointer;
}

.cockpit-nav-menu-form {
    position: relative;
    background: #efefef;
    height: 36px;
    display: inline-flex;
    width: 100%;
}

#cockpit-header,
#cockpit-header-tabs {
    box-shadow: 0 2px 2px 0 #00000022;
    position: relative;
    z-index: 3;
}

#cockpit-header #cockpit-header-tabs {
    box-shadow: none;
}

.cockpit-toolbar-container {
    color: rgba(0, 0, 0, .75);
    height: 36px;
    border-right: 1px solid #E7E8EB;
    background-color: #fff;
}

.cockpit-object-iframe {
    border: none;
    width: 100%;
}

.cockpit-object-iframe.withHeader {
    height: calc(100vh - 36px);
}

.cockpit-object-iframe.withoutHeader {
    height: 100vh;
}

.dashboard-filter-box.on-publisher.withoutHeader.filter-bottom-fixed.on-mobile {
    height: 39px;
}

.cockpit-item-label-publisher {
    color: #ffffff !important;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 13px;
    padding: 2px 25px 0px 2px !important;
}

.cpt-btn-item.cpt-btn-item-publisher {
    height: 100%;
    border-radius: 0;
    margin-top: 0;
}

.cpt-btn-item {
    text-align: left;
    padding: 0 12px;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: .6px;
    height: 34px;
    line-height: 38px;
}

.cockpit-item-panel {
    width: 100%;
    overflow: auto;
    z-index: 0;
}

.Cockpit .cockpit-item-panel {
    height: calc(100vh - 36px);
}

.cockpit-item-panel.o-visible {
    overflow: visible;
}

.cockpit-header-panel-ul > li > .cpt-btn-item > a.btn.btn-link {
    color: rgba(0, 0, 0, .6) !important;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 12px !important;
    padding: 0px 5px 0px 20px !important;
    margin-top: 3px;
    text-shadow: none !important;
    letter-spacing: .3px;
    background: #f7f8fa;
}

.cockpit-header-panel-ul > li.active {
    background: #ffffff;
}

.cockpit-header-panel-ul > li > .cpt-btn-item > a.btn.btn-link > span,
.cockpit-header-panel-ul > li > .cpt-btn-item > a.btn.btn-link > i {
    padding: 2px 0 0 0px !important;
    color: rgba(0, 0, 0, .6) !important;
    font-size: 16px;
    position: absolute;
    margin-left: -22px;
}

button.cockpit-toolbar-selector i {
    font-size: 20px;
    margin: 0 0 0 14px;
}

button.cockpit-toolbar-selector {
    background-color: transparent;
    color: rgba(0, 0, 0, .75);
    border: 0;
    font-weight: 600 !important;
}

.cockpit-toolbar-btn-group > button:disabled {
    text-align: left;
    font-size: 13px;
    height: 25px;
    letter-spacing: .3px;
    padding: 1px 0;
}

ul.pull-left.dropdown-navbar.dropdown-menu.dropdown-close.cockpit-toolbar-dropdown {
    margin-top: 10px;
    width: 300px;
    border: 0;
    padding: 0;
    margin-left: 14px;
}

.cockpit-header-panel-ul > li.cpt-empty:hover {
    background: transparent;
}

button.cockpit-toolbar-selector.dropdown-toggle {
    box-shadow: none !important;
    min-width: 20px;
    padding: 1px 0;
}

.cockpit-nav-btn,
.cockpit-nav-btn .cockpit-comment-btn {
    color: rgba(0, 0, 0, .75);
}

.cockpit-nav-btn [class^="icon-bim-"]:before, [class*=" icon-bim-"]:before {
    line-height: initial;
}

.cockpit-nav-btn .filtered {
    color: #FF4136 !important;
}

.cockpit-header-controls-container span {
    margin: 0 8px;
}

.cockpit-nav-btn i {
    font-size: 18px;
}

.cockpit-header-controls-container {
    position: absolute;
    top: 0px;
    right: 0px;
    background: #ffffff;
    padding: 10px 0px 2px 0px;
    height: 25px;
}

.cockpit-header-controls-container .dropdown-menu-item span.material-icons {
    font-size: 16px;
    vertical-align: sub;
}

.cockpit-header-controls-container.hide-controls {
    display: flex;
}

.cockpit-header-panel-ul > li:hover {
    color: rgba(0, 0, 0, .75);
    background: #FFFFFF;
}

.cpt-btn-item-bar-marker {
    width: 100%;
    height: 2px;
    visibility: hidden;
}

.cockpit-header-panel-ul > li.active .cpt-btn-item-bar-marker {
    visibility: visible;
}

.cockpit-header-panel-ul > li:not(.active):hover .cpt-btn-item-bar-marker {
    visibility: visible;
    background: #6c6c6c;
}

.cockpit-header-panel-ul > li:not(.active) .cpt-btn-item a {
    color: #6C6C6C;
}

.cockpit-header-panel-ul > li:not(.active):hover .cpt-btn-item a {
    color: #292c31 !important;
}

.cockpit-toolbar-selector-icon {
    float: left;
}

.cockpit-toolbar-selector-text {
    float: left;
    font-size: 13px;
    letter-spacing: .5px;
    font-weight: 500;
    padding: 0 0 0 14px;
}

.cockpit-toolbar-selector-arrow_down {
    float: left;
    padding: 0 10px;
    width: 20px;
}

.cockpit-toolbar-selector-arrow_down i.material-icons {
    padding: 0 !important;
    margin: 0 !important;
}

.book-title-cockpit-list {
    padding: 14px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .5px;
    color: #656565;
    text-transform: uppercase;
}

.cockpit-book-btn.refresh a:hover,
.cockpit-book-btn.refresh a:focus,
.cockpit-book-btn.refresh a:active {
    color: #2876fd;
    background: transparent;
}

.cockpit-book-btn.refresh {
    position: absolute;
    top: 15px;
    right: 14px;
}

.book-cockpit-list {
    background: #fafafa;
    padding: 10px 14px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

.book-btn-cockpit-generate {
    padding: 14px;
    text-align: center;
}

.book-btn-cockpit-generate a {
    color: #0088cc;
    font-size: 12px;
    text-shadow: none;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .3px;
}

.book-btn-cockpit-generate a:hover,
.book-btn-cockpit-generate a:active,
.book-btn-cockpit-generate a:focus {
    background: #ffffff !important;
    color: #0088cc !important;
}

ul.dropdown-menu.dropdown-menu-filters.pull-right {
    padding: 0;
}

.Cockpit .scroll-wrapper.filter-container.scrollbar-outer {
    position: relative;
    padding: 0 !important;
    margin: 0 !important;
}

.btn-group.cockpit-toolbar-btn-group .btn-cpt-arrow-down {
    display: none;
}

.btn-group.cockpit-toolbar-btn-group.open .btn-cpt-arrow-down {
    display: block;
}

.btn-group.cockpit-toolbar-btn-group .btn-cpt-arrow-right {
    display: block;
}

.btn-group.cockpit-toolbar-btn-group.open .btn-cpt-arrow-right {
    display: none;
}

.Cockpit .filter-container.on-publisher .scrollbar-inner,
.Cockpit .filter-container.on-publisher .scrollbar-outer {
    white-space: normal;
    float: left;
}

.on-publisher .filter-block {
    margin-right: 3px;
}


.cpt-item-view-more-icon-publisher {
    color: #ff711b88;
    font-size: 15px;
}

.cpt-item-view-more-icon-publisher .material-icons {
    font-size: 18px;
}

.cpt-item-view-more-name-publisher {
    text-align: left;
    height: 20px;
    color: #373737;
}

.cpt-item-view-more-name.cpt-item-view-more-name-title.text-overflow-ellipsis {
    height: 20px;
}

td.cpt-item-view-more-icon-width {
    width: 15px;
    text-align: center;
}

.cpt-item-view-more-option {
    margin: 10px 0px 10px 0px;
}

.cpt-item-view-more-name-width {
    width: 170px;
}

.cpt-item-view-more-name-title {
    height: 20px !important;
    font-size: 13px !important;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial !important;
    margin-left: 3px !important;
}

.cpt-item-view-more-name-publisher.cpt-item-view-more-name-title {
    font-size: 13px;
    margin-left: 5px;
}

ul.dropdown-menu.cpt-item-view-more-menu, ul.dropdown-menu.dropdown-menu-filters.pull-right {
    padding: 0 !important;
}

#cockpit-menu .mdl-layout__header {
    min-height: 35px;
}

#cockpit-menu .mdl-layout__drawer-button {
    height: 24px;
    background: unset;
    left: unset;
    right: 0px;
    padding: 10px;
    outline-color: #292C31;
}

#cockpit-menu .mdl-layout__title, #cockpit-menu .mdl-layout-title {
    font-size: 15px;
    padding-left: 0px;
}

#cockpit-menu .mdl-layout__header-row {
    height: 35px;
    padding: 0 16px 0 52px;
}

#cockpit-menu .mdl-layout__header {
    background-color: #fafafa;
    color: #222;
}

#cockpit-menu .mdl-layout__drawer .mdl-navigation {
    padding-top: 0px;
}

/*firefox hack*/
@-moz-document url-prefix() {
    .cockpit-item-panel .dashboard-wrapper-with-filters.filter-top-fixed {
        padding-top: 50px !important;
    }
}

.cockpit-item-panel .dashboard-wrapper-with-filters.filter-top-fixed {
    padding-top: 45px;
}

.cpt-item-current {
    padding: 10px 0px 10px 18px;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    font-weight: 500;
}

#cockpit-menu .page-content {
    margin-left: 0px;
    margin-right: 0px;
}


.indexSearchNavSearchSpan {
    margin-left: 26px;
    margin-top: 20px;
}

.indexSearchNavSearchInput {
    margin-bottom: 0 !important;
    border: 1px solid #dddddd;
    width: 300px !important;
}

.cockpit-toolbar-dropdown .menu-bottom-btn {
    border-top: 1px solid #ededed;
}

input.search.fill-w.input-small.nav-search-input.indexSearchNavSearchInput {
    border: 0;
    background: transparent;
    border-bottom: 2px solid #b5b5b5;
    height: 34px;
    padding-left: 32px;
}

.cockpit-index-page-summary {
    padding: 20px;
}

input.search.fill-w.input-small.nav-search-input.indexSearchNavSearchInput:focus {
    outline: 0;
}

.cockpit-index-page-ol li a.iceCmdLnk i {
    font-size: 16px;
    font-weight: normal;
    margin: 0px 4px 4px 4px;
}

.cockpit-index-page-summary-content-html {
    padding: 14px;
}

.cockpit-index-page-summary .nav-search-icon {
    color: #656565 !important;
    font-size: 20px !important;
    left: 0px;
    top: 3px;
}

.cockpit-index-page-summary .widget-box .widget-header {
    border-bottom: 1px solid #e4e4e8;
}

.cockpit-index-page-summary .widget-box .widget-header span.icon {
    padding: 8px 8px 0 6px;
}

.cockpit-index-page-summary .widget-body {
    background: #ffffff;
    min-height: 450px;
    height: Calc(100vh - 180px);
    overflow: auto;
}

.cockpit-index-page-summary .widget-box {
    background: #ffffff;
}

ol.list.cockpit-index-page-ol li {
    padding: 6px 40px 6px 0px;
}

/* Small Devices, Tablets */
@media only screen and (max-device-width : 829px), screen and (max-device-height : 560px) {

    .mobileIos input[type=radio]:after {
        margin: -1px;
    }

    .mobileIos input[type=radio]:checked:before {
        margin: 1px;
    }

    .modal.fade.in {
        top: 90px;
    }

    .modal-dialog .widget-main {
        padding: 0;
    }

    .RANGE .row-fluid.select-items-one .select-items-desc-data {
        display: none;
    }

    .RANGE .row-fluid.select-items-one .select-items-pagination {
        float: right;
        width: 100%;
    }

    .filter-selection-dialog.modal .widget-header {
        display: none;
    }

    .filter-selection-dialog.modal legend {
        width: 94%;
        margin-top: -3px;
    }

    .filter-selection-dialog.modal .TimeFilterView legend {
        width: 60%;
    }

    /*Não congelar coluna em mobile*/
    .fixed-columns {
        display: none;
    }

    .filter-container {
        border-bottom: none;
    }

    .filter-container {
        border-bottom: none;
    }

    .modal {
        top: 10px !important;
    }

    .modal .widget-body {
        border-radius: 4px;
    }

    .filter-selection-dialog.FilterType-TIME .DialogContent {
        border-radius: 4px 4px 0 0;
    }

    .filter-selection-dialog .Footer * {
        line-height: 25px;
    }

    .modal,
    .bng-dropdown-parent {
        zoom: 0.7 !important;
    }

    .mobileIos,
    .mobileIos .bng-dropdown-parent {
        transform: scale3d(0.75, 0.75, 1) !important;
        margin-top: -4%;
        zoom: unset !important;
    }

    #filter-bar-container .row-fluid {
        margin: 0 !important;
        padding: 0;
    }

    .modal.filter-selection-dialog {
        width: 555px !important;
        margin-left: -278px !important;
    }

    .RANGE .select-items-component-items-container {
        width: 200px;
    }

    .select-items-component-items-container {
        width: 426px;
        min-height: 150px !important;
        height: 210px;
    }

    legend {
        line-height: 42px !important;
    }

    .select-items-many > .span6 {
        width: calc(48.93617021276595% - 56px) !important;
    }

    .select-items-search-group {
        width: calc(100% - 72px) !important;
    }

    .lbl {
        box-shadow: none !important;
        font-size: 13px !important;
        color: #222 !important;
    }

    .mdl-layout__content {
        overflow: hidden !important;
    }

    .modal-title {
        font-size: 20px !important;
        line-height: 20px !important;
        margin: 4px 0 4px !important;
    }

    /*::-webkit-scrollbar-thumb {*/
    /*background-color: rgba(108, 110, 113, .4);*/
    /*border-radius: 3px;*/
    /*transition: 1s all ease;*/
    /*}*/
    /*::-webkit-scrollbar {*/
    /*width: 3px;*/
    /*height: 3px;*/
    /*}*/
    ul.dropdown-navbar.dropdown-menu.pull-right.CockpitNavFilterDrop.on-publisher {
        transform: scale3d(.7, .7, 1);
        position: absolute;
        right: -60px !important;
        top: -6px !important;
    }

}

@media only screen and (max-device-height: 369px) {
    .modal.fade.in {
        top: 80px !important;
    }

    .select-items-component-items-container {
        min-height: 100px !important;
    }
}

@media only screen and (max-device-height: 349px) {
    .modal.fade.in {
        top: 65px !important;
    }
}

/* start adjusts to filter on publisher */

.dashboard-filter-box.on-publisher {
    position: fixed;
}

.dashboard-filter-box.filter-bottom-fixed.on-publisher {
    left: 0;
    width: 100%;
}

.dashboard-filter-box.filter-top-right.on-publisher {
    top: 10px;
}

.dashboard-filter-box.filter-top-right.on-publisher .filter-container.filter-expand {
    padding-right: 36px;
}

.on-publisher .filter-container {
    margin: 0;
    box-shadow: none;
}

.dashboard-filter-box.on-publisher .filter-container.filter-expand {
    box-shadow: inset 0 1px 0 0 #EFEFEF, 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.on-publisher .filter-container.filter-contracted {
    box-shadow: inset 0 1px 0 0 #EFEFEF, 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.dashboard-filter-box.filter-top-fixed.on-publisher {
    width: 100%;
    left: 0;
    top: 0px;
}

.dashboard-filter-box.filter-top-fixed.on-publisher .filter-container {
    border-bottom: 0;
}

.dashboard-filter-box.filter-top-left.on-publisher {
    top: 10px;
    left: 10px;
}

.dashboard-filter-box.filter-top-left.on-publisher #filter-bar-container {
    padding-left: 34px;
}

.dashboard-filter-box.filter-bottom-left.on-publisher {
    left: 10px;
}

.dashboard-filter-box.filter-top-fixed.on-publisher + script + input + div#dashboard-wrapper {
    padding-top: 46px;
}

.on-publisher .filter-container {
    border: 0;
}

.dashboard-filter-box.on-publisher.filter-bottom-fixed.on-mobile {
    top: 0;
    height: 40px;
}

.dashboard-filter-box.on-publisher.on-mobile .scroll-bar {
    display: none !important;
}

.dashboard-filter-box.on-publisher.on-mobile .filter-container {
    padding: 3px 2px 1px 2px;
}

.dashboard-filter-box.on-publisher.filter-bottom-fixed.on-mobile.on-ios {
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
}

.dashboard-filter-box.on-publisher.on-mobile.on-ios .filter-container.filter-expand {
    -webkit-overflow-scrolling: touch !important;
}

.dashboard-filter-box.on-publisher.filter-bottom-fixed.on-mobile.on-ios .filter-container.filter-expand::-webkit-scrollbar-track {
    background-color: transparent;
}

.dashboard-filter-box.on-publisher.filter-bottom-fixed.on-mobile.on-ios .filter-container.filter-expand::-webkit-scrollbar {
    background-color: transparent;
}

.dashboard-filter-box.on-publisher.filter-bottom-fixed.on-mobile.on-ios .filter-container.filter-expand::-webkit-scrollbar-thumb {
    background-color: transparent;
}

/*hack ios*/

.dashboard-filter-box.on-publisher.on-mobile.on-ios {
    position: -ms-device-fixed !important;
    display: contents;
}

.dashboard-filter-box.on-publisher.on-mobile.on-ios .filter-container.filter-expand {
    -webkit-overflow-scrolling: touch !important;
}

.dashboard-filter-box.on-publisher.filter-bottom-fixed.on-mobile.on-ios .filter-container.filter-expand::-webkit-scrollbar-track {
    background-color: transparent;
}

.dashboard-filter-box.on-publisher.filter-bottom-fixed.on-mobile.on-ios .filter-container.filter-expand::-webkit-scrollbar {
    background-color: transparent;
}

.dashboard-filter-box.on-publisher.filter-bottom-fixed.on-mobile.on-ios .filter-container.filter-expand::-webkit-scrollbar-thumb {
    background-color: transparent;
}

/*end hack ios*/

.on-mobile .dashboard-filter-container-persistence.dashboard-filter-container-persistence-save-disabled {
    padding-right: 0;
}

/* end adjusts to filter on publisher */

.dashboard-filter-box.on-publisher.filter-top-right.contracted.embedded {
    position: absolute;
    top: 24px;
}

.dashboard-filter-box.on-publisher.filter-top-right.embedded {
    position: absolute;
    top: 24px;
    max-width: Calc(100% - 30px);
}

.dashboard-filter-box.filter-bottom-left.on-publisher.embedded {
    position: absolute;
    max-width: Calc(100% - 30px);
}

.dashboard-filter-box.filter-bottom-right.on-publisher.embedded {
    position: absolute;
    max-width: Calc(100% - 30px);
}

.dashboard-filter-box.filter-top-left.on-publisher.embedded {
    position: absolute;
    top: 24px;
    max-width: Calc(100% - 30px);
}

.dashboard-filter-box.filter-top-fixed.on-publisher.embedded {
    position: absolute;
    top: 0;
}

.dashboard-filter-box.on-publisher.filter-top-right.embedded .filter-container.filter-contracted {
    height: 36px;
}

.on-publisher .dashboard-filter-container-persistence.dashboard-filter-container-persistence-save-disabled {
    padding-right: 0;
}

.CockpitNavFilterDrop .filter-container,
.CockpitNavFilterDrop .filter-bar-container-parent-li {
    padding: 0;
}

.CockpitNavFilterDrop.on-publisher {
    right: 0 !important;
}

.desktop-filter-container .filter-container {
    margin-bottom: 0;
}

.desktop-filter-container .filter-block {
    margin-top: 0;
}

.cockpit-nav-btn.cockpit-more-panels-btn i.material-icons {
    height: 28px;
    padding: 0px 10px 0px 3px;
}

.cockpit-nav-btn.cockpit-more-panels-btn:after {
    content: "";
    border-right: 1px solid #e6e6e6;
    height: 36px;
    position: absolute;
    top: 0;
}


.cockpit-navigation:hover {
    opacity: 1;
}

.cockpit-navigation {
    float: left;
    width: 44px;
    position: absolute;
    left: -44px;
    background: rgba(247, 248, 250, .7);
    height: 36px;
    top: 0px;
    opacity: 0;
    display: none;
}

.cockpit-header-scroll-left i.material-icons,
.cockpit-header-scroll-right i.material-icons {
    margin-top: 8px;
    color: #575bd8;
    font-size: 20px;
}

/*theme skin*/

.cockpit-header-panel-ul > li.active .cpt-btn-item a {
    color: #575bd8 !important;
}

.cpt-btn-item-bar-marker {
    background: #575bd8;
}

.skin-7 .cockpit-header-panel-ul > li.active .cpt-btn-item a,
.skin-7 .cockpit-header-scroll-left i.material-icons,
.skin-7 .cockpit-header-scroll-right i.material-icons {
    color: #2C6AA0 !important;
}

.skin-7 .cpt-btn-item-bar-marker {
    background: #2C6AA0;
}

.skin-6 .cockpit-header-panel-ul > li.active .cpt-btn-item a,
.skin-6 .cockpit-header-scroll-left i.material-icons,
.skin-6 .cockpit-header-scroll-right i.material-icons {
    color: #4272FE !important;
}

.skin-6 .cpt-btn-item-bar-marker {
    background: #4272FE;
}

.skin-5 .cockpit-header-panel-ul > li.active .cpt-btn-item a,
.skin-5 .cockpit-header-scroll-left i.material-icons,
.skin-5 .cockpit-header-scroll-right i.material-icons {
    color: #269c59 !important;
}

.skin-5 .cpt-btn-item-bar-marker {
    background: #269c59;
}

.skin-4 .cockpit-header-panel-ul > li.active .cpt-btn-item a,
.skin-4 .cockpit-header-scroll-left i.material-icons,
.skin-4 .cockpit-header-scroll-right i.material-icons {
    color: #d23d3d !important;
}

.skin-4 .cpt-btn-item-bar-marker {
    background: #d23d3d;
}

.skin-3 .cockpit-header-panel-ul > li.active .cpt-btn-item a,
.skin-3 .cockpit-header-scroll-left i.material-icons,
.skin-3 .cockpit-header-scroll-right i.material-icons {
    color: #4272FE !important;
}

.skin-3 .cpt-btn-item-bar-marker {
    background: #4272FE;
}

.skin-2 .cockpit-header-panel-ul > li.active .cpt-btn-item a,
.skin-2 .cockpit-header-scroll-left i.material-icons,
.skin-2 .cockpit-header-scroll-right i.material-icons {
    color: #e89702 !important;
}

.skin-2 .cpt-btn-item-bar-marker {
    background: #e89702;
}

.skin-1 .cockpit-header-panel-ul > li.active .cpt-btn-item a,
.skin-1 .cockpit-header-scroll-left i.material-icons,
.skin-1 .cockpit-header-scroll-right i.material-icons {
    color: #4272FE !important;
}

.skin-1 .cpt-btn-item-bar-marker {
    background: #4272FE;
}

/*end theme skin*/

.disabled-display-header {
    display: none !important;
}

.CockpitNavFilterDrop .filter-container {
    overflow-x: hidden;
}