.buttonBorder {
    border-top: 1px solid var(--ag-secondary-border-color, #dde2eb);
    width: 179px;
    display: flex;
    flex-direction: row-reverse;
    padding: 12px;
}

.resetButton {
    background-color: #3558EF !important;
    border-color: #3558EF !important;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: normal !important;
    padding: 4px 8px !important;
}

.optionSelector {
    padding: 0;
    outline: none !important;
}

.filterInput, .optionSelector {
    width: 177px;
    height: 24px;
}

.filterInput:focus, .optionSelector:focus, .buttonBorder > input:active {
    border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4)) !important;
    box-shadow: 0 0 2px 0.1rem rgb(33 150 243 / 40%) !important;
}

.filterOptionsWrapper {
    padding: 12px 12px 3px 12px;
    width: 179px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}