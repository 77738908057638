.BngAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border: 1px solid #EFEFEF;
    border-radius:  100%;
    background-color: #F6F6F6;
    overflow: hidden;
}

