.censusVote {
  width: 30.8rem;
  margin: 20px 35px 20px 0;
}

.voteButtonsWrapper {
  display: inline-flex;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 4px;
  width: 100%;
}

.voteButton {
  align-items: center;
  background: rgba(255, 255, 255, 0.24);
  border-radius: 100%;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  height: 5px;
  justify-content: center;
  text-align: center;
  transition: background-color 300ms ease-out;
  padding: 12px;
  width: 5px;
  white-space: nowrap;
}

.voteButton.selected {
  background: rgba(255, 255, 255, 0.24) !important;
  border: 1px solid #ffffff;
}

.voteButton:hover {
  background: rgba(255, 255, 255, 0.52);
}

.censusCaption {
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.censusCaption :global(span) {
  font-size: 12px;
}

.censusWrapper {
  display: inline-flex;
  transition: all 400ms ease-in-out;
}

.censusDescribe {
  margin: 0 35px;
}

.captionField {
  width: 430px;
  height: 77px;
  background: rgba(255, 255, 255, 0.24);
  border: 1px solid #ffffff;
  border-radius: 8px;
  resize: none;
  color: #ffffff;
}

.captionField:focus {
  background: rgba(255, 255, 255, 0.24);
  color: #ffffff;
}

.AdaNPSCensus :global(.wrapperAdaOpen img) {
  margin-right: 50px;
  z-index: 10001;
}

.AdaNPSCensus :global(.contentContainer) {
  margin-right: 200px;
  z-index: 10004;
}

.AdaNPSCensus :global(.contentContainer) {
  position: fixed;
  z-index: 10004;
}

.AdaNPSCensus :global(.disableInfo) {
  background: rgba(0, 0, 0, 0.45);
}
