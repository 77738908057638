.BngBigTable__ag-container .block-ui-container {
    position: static;
}

.BngBigTable__ag-container .block-ui-message-container {
    z-index: 47;
}

.BngBigTable__ag-container .ag-body-horizontal-scroll-viewport::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
}

.BngBigTable__ag-container .ag-body-horizontal-scroll-viewport:not(.focus):not(:hover)::-webkit-scrollbar-thumb {
    transition: unset;
    background: rgba(108, 110, 113, 0.7);
}

.BngBigTable__ag-container .ag-header i {
    cursor: pointer;
}

.BngBigTable .ag-filter-apply-panel button[ref=resetFilterButton] {
    background-color: #3558EF;
    border-color: #3558EF;
    color: #fff;
    font-size: 13px;
    font-weight: normal;
    padding: 4px 8px;
}