.installButtonsContainer {
  display: flex;
  gap: 24px;
}

.installButtonsContainer :global(img) {
  /* This filter was achieved using https://isotropic.co/tool/hex-color-to-css-filter/ */
  filter: invert(30%) sepia(84%) saturate(5689%) hue-rotate(215deg) brightness(100%) contrast(109%);
}

.installButtonsContainer :global(button:disabled) {
  color: #b9b9b9;
  cursor: not-allowed;
}

.installButtonsContainer :global(button:disabled):hover {
  transform: unset;
}

.installButtonsWrapper :global(button:disabled) :global(img) {
  filter: invert(90%) sepia(0%) saturate(34%) hue-rotate(136deg) brightness(87%) contrast(78%);
}

.installButtonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 36px;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.skipTutorialBtn {
  color: var(--gray-description);
  text-align: center;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  transition: transform 0.1s;
}

.skipTutorialBtn:hover {
  transform: scale(1.01);
}

.termsFooterContainer {
  width: 65%;
  display: flex;
  text-align: center;
  height: 32px;
}

.termsFooterContainer :global(span) {
  color: var(--gray-description);
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.25px;
}

.installedCheckboxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.installedCheckboxWrapper :global(span) {
  color: var(--gray-description);
  font-weight: 500;
}

.supportFooter {
  color: var(--blue-default);
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: 10px;
}

.thirdPageWrapper :global(li) {
  text-align: initial;
}

.fourthPageWrapper :global(.BimGatewayLayoutDescription) :global(i) {
  position: relative;
  bottom: -2px;
  font-size: 16px;
}

.connectionTagWrapper {
  display: inline-flex;
  padding: 4px 18px;
  align-items: center;
  gap: 4px;
  border-radius: 19px;
  margin-top: 29px;
  font-weight: 500;
  color: white;
}

.connectionTagWrapper :global(i) {
  font-size: 18px;
}

.connectionTagWrapper.Loading {
  background-color: var(--blue-default);
}

.connectionTagWrapper.Error {
  cursor: pointer;
  background-color: #f98900;
}

.connectionTagWrapper.Success {
  background-color: #21a55f;
}

.pulsatingLoadingContainer {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centeredCircle,
.pulsatingLoading {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: white;
}

.pulsatingLoading {
  position: absolute;
}
