.DimensionListWithFilters {
}

.DimensionListWithFiltersContainer .FilterButtonContainer {
  display: inline-block;
  position: relative;
}

.DimensionListWithFiltersButtonWrapper .badge {
  position: absolute;
  top: 10px;
  left: 25px;
  padding: 2px 5px;
  font-size: 11px;
  line-height: 11px;
}

.DimensionListWithFiltersPopper {
  z-index: 1000001 !important;
}

.DimensionListWithFiltersPopper .FiltersContainer {
  border: 1px solid #efefef;
  border-radius: 8px;
  padding: 8px;
  height: 125px;
  overflow-y: auto;
  position: relative;
}

.DimensionListWithFiltersPopper .AlertIcon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: rgba(0, 93, 255, 0.1);
  color: #005dff;
  font-size: 26px;
}

.BngDropdownCheckboxPopper {
  z-index: 1000002 !important;
}

.DimensionListDeleteButtonWrapper,
.DimensionListWithFiltersButtonWrapper {
  display: flex;
  padding: 11px 15px;
}

.DimensionListDeleteButtonWrapper.disabled,
.DimensionListWithFiltersButtonWrapper.disabled {
  opacity: 0.8;
  cursor: not-allowed !important;
}

.DimensionListDeleteButton.Icon,
.DimensionListWithFiltersButton.Icon {
  align-self: center;
  font-size: 20px;
}

.DimensionListWithFiltersButton.Icon.Selected {
  color: #005dff;
}

.DimensionListWithFiltersButton.Icon.Filter {
  margin-right: 7px;
}

.DimensionListWithFiltersButton.Icon.Filter.Selected {
  color: #005dff;
}

.filterIconGlow > .filterBlink {
  pointer-events: none;
  position: absolute;
  right: 8px;
  bottom: 28px;
  background-color: #E24C38;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  text-decoration: blink;
  animation: blinkerFilter 0.8s ease-in-out infinite alternate;
}

@-webkit-keyframes blinkerFilter {
  from {
    box-shadow: 0 0 2px 1px red;
  }
  to {
    box-shadow: 0 0 6px 0 red;
  }
}

.DimensionListDeleteButtonWrapper > .DimensionListDeleteButton.Label,
.DimensionListWithFiltersButtonWrapper > .DimensionListWithFiltersButton.Label {
  margin-bottom: 0px;
  font-weight: 500;
  color: #6e6e6e;
  line-height: 30px;
  cursor: pointer !important;
}

.DimensionListDeleteButtonWrapper.disabled > .DimensionListDeleteButton.Label,
.DimensionListWithFiltersButtonWrapper.disabled > .DimensionListWithFiltersButton.Label {
  cursor: not-allowed !important;
}

.DimensionListWithFiltersButtonDropdown {
  padding: 0 !important;
}