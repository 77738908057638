.activities-nav-tab.active{
    display: block;
}

.activities-nav-tab{
    display: none;
}

.RecentActivities .widget-body-inner {
    height: 270px;
}