.bng-search {
  display: flex;
  align-items: center;
  height: 36px;
  transition: box-shadow ease-in-out 200ms;
}

.bng-search.absolute {
  position: absolute;
}

.bng-search.open:not(.InlineBngSearch),
.bng-search.open.InlineBngSearch:focus-within {
  background-color: #fff;
  box-shadow: 0 3px 0 -1px #005dff;
}

.bng-search.open.InlineBngSearch {
  box-shadow: 0 3px 0 -1px #efefef;
}

.bng-search.open:not(.InlineBngSearch) .SearchIcon,
.bng-search.open.InlineBngSearch:focus-within .SearchIcon {
  color: #005dff;
}

.bng-search.open.InlineBngSearch .SearchIcon {
  color: #757575;
}

.bng-search.open.SimpleBngSearch {
  background-color: transparent;
  box-shadow: none;
}

.bng-search.closed .bng-search-input {
  opacity: 0;
  pointer-events: none;
  top: 28px;
}

.bng-search.open .bng-search-input {
  display: flex;
  align-items: center;
}

.bng-search .bng-search-input {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  top: 0;
  opacity: 1;
}

.bng-search-input.open {
  transition: opacity 0.2s ease-in-out, top 0.3s ease-out;
}

.bng-search .bng-search-input .material-icons {
  margin-right: 5px;
}

.bng-search .bng-search-input .search-field {
  font-size: 14px;
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
  width: 100%;
  background-color: transparent;
  font-weight: 500;
}

.bng-search .search-button {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.bng-search .search-button:not(.simple-button) {
  margin-right: 0px !important;
  border-radius: 100%;
  cursor: pointer;
  height: 24px;
  padding: 8px;
}

.bng-search .search-button:not(.simple-button):hover {
  background-color: #eee;
}

.search-icon {
    display: flex !important;
    margin-right: 40px;
    margin-left: 40px;
}

.search-label {
    width: 50px;
    align-self: center;
}

.bng-search:not(.SimpleBngSearch) .search-button.with-label.open {
    border-radius: 4px;
}

.bng-search .search-right-icon {
  margin-right: 4px;
}

.bng-search.disabled {
  cursor: not-allowed;
}

.bng-search.disabled input {
  background-color: transparent !important;
}