.GeneralWrapper {
  margin: 35px;
}

.TitleWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 18px;
}

.TitleWrapper > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TitleWrapper :first-child > div {
  margin-right: 10px;
}

.logoWrapper {
  background: #fff;
  border: 1px solid #E4E4E4;
  border-radius: 4px;
  height: 50px;
  padding: 5px;
}

.logoWrapper img {
  height: 50px;
}

.Title {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ProjectTypeButton {
  display: flex;
  padding: 2px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid rgba(248, 148, 6, 0.2);
  background: rgba(248, 148, 6, 0.1);
}

.ProjectTypeButton > span {
  color: #f89406;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ProjectTypeButton > div {
  display: flex;
  width: 18px;
  height: 18px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: #f89406;
  border-radius: 2px;
}

.ProjectTypeButton > i {
  color: #f89406;
}

.ProjectTypePopper {
  display: flex;
  flex-direction: row-reverse;
}

.ProjectTypeUiBlocker {
  display: inline-flex;
  padding: 8px 0;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07);
  width: 160px;
}

.ProjectType {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ProjectType > span {
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 24px;
  flex-shrink: 0;
  margin-left: 23px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.ProjectType > div {
  width: 75px;
  height: 1px;
  flex-shrink: 0;
  background: #d1d1d1;
}

.ProjectTypeOptionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 34px;
  flex-shrink: 0;
  margin-left: 24px;
}

.ProjectTypeOptionWrapper:hover {
  cursor: pointer;
}

.ProjectTypeOptionWrapper > div {
  display: flex;
  width: 18.22px;
  height: 18px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 2px;
  margin-right: 5px;
}

.ProjectTypeOptionWrapper > span {
  width: 60px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ProjectIdWrapper {
  display: flex;
  flex-direction: row;
}

.ProjectIdWrapper :first-child {
  color: #888;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 5px;
}

.ProjectIdWrapper :last-child {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ProjectInfos {
}

.Form1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Form1 :first-child {
  margin-right: 20px;
}

.Form1 > div {
  width: 100%;
}

.Form2 {
  display: flex;
  flex-direction: row;
}

.Form2 > div {
  width: 100%;
}

.ProjectDescription {
  margin-right: 20px;
}

.ProjectDescription > div {
  height: 80%;
}

.ProjectDescription > div > textarea {
  height: 100%;
}

.MasterAndAccount {
  display: flex;
  flex-direction: row;
}

.CardWrapper {
  width: 100%;
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.CardWrapper:global(.disabled) {
  color: rgb(51 51 51 / 50%);
}

.Card {
  height: 104px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  background: #fff;
  margin-top: 10px;
}

.MasterWrapper {
  margin-right: 20px;
}

.Master {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Account {
  display: flex;
  flex-direction: column;
  align-content: flex-start !important;
  flex-wrap: wrap;
  justify-content: center;
}

.Account:global(.disabled) {
  background-color: rgb(228 228 228 / 50%);
}

.Master :last-child :global(.master-name),
.Account :global(.account-name) {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Master :last-child :global(.master-email),
.Account :global(.account-id) {
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Master:global(.disabled) :last-child :global(.master-name),
.Account:global(.disabled) :global(.account-name) {
  color: rgb(51 51 51 / 50%);
}

.Master:global(.disabled) :last-child :global(.master-email),
.Account:global(.disabled) :global(.account-id) {
  color: rgb(85 85 85 / 50%);
}

.Account > div {
  margin-left: 15px;
}

.Avatar {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin-left: 15px;
  margin-right: 20px;
  flex-shrink: 0;
  background-color: lightgrey;
  background-repeat: inherit !important;
  background-position: center !important;
  background-size: contain !important;
}

.Change {
  color: #337dff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Change:global(.disabled) {
  color: rgb(51 125 255 / 50%);
}

.Change:hover {
  cursor: pointer;
}

.Change:global(.disabled):hover {
  cursor: not-allowed;
}

.ProjectOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ProjectOptions > div {
  flex-grow: 1;
  width: 33%;
}

.ProjectOptions > div :global(.BngSwitch) {
  margin: 20px 0 20px 20px;
}

.ProjectOptions :first-child :global(.BngSwitch) {
  margin-left: 0;
}

.ProjectOptions > div :global(.BngSwitch) :global(.BngSwitchLabel) {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 84%;
}
