.BngBlockSelect {
    background-color: #FFF;
    font-weight: bold;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BngBlockSelect__list {
    background-color: #FFF;
    padding: 4px 0;
}

.BngBlockSelect__option {
    padding: 8px;
    cursor: pointer;
}

.BngBlockSelect__option.selected {
    border: 1px solid #005DFF;
}
