.mainDialog,
.bs-dialog.mainDialog.ui-draggable {
  width: 600px;
}

.titleWrapper {
  display: flex;
  margin: 18px 0 0 13px !important;
  max-width: 85%;
}

.icon {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 24px !important;
  color: #555555 !important;
  margin-right: 8px;
}

.identifier {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 19px !important;
  color: #333333 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.creationInfos {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.modifier,
.creator,
.sendingMethod,
.type,
.weekDays,
.frequency,
.timeInterval,
.monthDay {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.sendingMethod,
.modifier,
.creator,
.atTime {
  width: auto !important;
}

.modifierName,
.creatorName,
.sendingMethod > div,
.type > div,
.weekDays > div,
.frequency > div,
.timeInterval > div,
.monthDay > div {
  font-weight: 500;
  white-space: nowrap;
  width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}

.creatorName {
  width: 139px;
}

.modifierName,
.monthDay > div {
  width: 123px;
}

.sendingMethod > div {
  width: 100px;
}

.modifiedDate,
.creationDate,
.modifier,
.creator,
.sendingMethod,
.type,
.weekDays,
.frequency,
.timeInterval,
.monthDay {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #555555;
}

.modificationWrapper,
.creationWrapper {
  margin: 0 0 0 20px;
  border-left: 3px solid #d1d1d1;
  padding: 0 0 0 12px;
}

.modificationWrapper {
  width: 42%;
}

.creationWrapper {
  width: 40%;
}

.sendingInfos {
  display: flex;
  margin: 20px 0 10px 13px;
}

.sendingInfosLeftWrapper,
.sendingInfosRightWrapper {
  width: 49.5%;
}

.weekDays > div {
  width: 190px;
}

.blueBall {
  background: #669eff;
  width: 8px !important;
  height: 8px !important;
  border-radius: 4px;
  margin: 3px 6px 0 0;
}

.objectListItem,
.receiverListItem {
  display: flex;
  margin: 4px 0 6px 0;
  box-shadow: 0 1.5px 0 #e4e4e4;
  align-items: center;
  padding: 0 0 2px 0;
  height: 30px;
}

.listWrapper,
.receiverWrapper,
.receiverWrapper {
  overflow: scroll;
  height: 150px;
}

.objectListCount,
.receiverListCount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #005dff;
  border-radius: 15px;
  color: white;
  width: auto;
  padding: 0 10px 0 10px;
  align-items: center;
  margin: 0 10px 0 0;
}

.objectListTitle,
.receiverListTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.objectListIcon {
  font-size: 24px !important;
  line-height: 18px !important;

  display: flex !important;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #555555;
  height: 34px;
  width: 34px;
}

.objectListName,
.receiverListName,
.receiverListName {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 15px !important;

  color: #555555 !important;

  white-space: nowrap;
  width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}

.objectReceiverListWrapper {
  display: flex;
  margin: 0 0 0 20px;
}

.receiverList,
.objectList {
  width: 50%;
}

.receiverListTitleWrapper,
.objectListTitleWrapper {
  display: flex;
  padding: 15px 0 15px 0;
  box-shadow: 0 1.5px 0 #e4e4e4;
  margin: 0 8px 0 0;
}

.receiverListEmpty,
.objectListEmpty {
  position: unset;
  transform: unset;
  margin: unset;
}

.receiverListEmpty > div,
.objectListEmpty > div {
  width: 183px !important;
}

.receiverListEmpty > div > img,
.objectListEmpty > div > img {
  height: 90px !important;
}

.avatar {
  height: 27px;
  width: 27px;
}

.footerMsg1 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #888888;
  width: 92%;
  margin: 20px 0 0 20px;
}

:global(.ExportSchedulingDetailsDialog) :global(.UpdatedBy) {
  width: 280px;
  margin: 0 0 0 20px;
}

:global(.ExportSchedulingDetailsDialog) :global(.CreatedBy) {
  width: 280px;
}
