.btn-upload-container {
    cursor: pointer;
    text-align: center;
    margin-top: 5px;
    background-color: #ffffff !important;
}

.btn-upload-container:hover {
    cursor: pointer;
    text-align: center;
    margin-top: 5px;
    background-color: #eeeeee !important;
}