.colorNodeWrapper {
  width: 42px;
  height: 42px;
  border-radius: 100%;
}

.transparentNode {
  background-image: url('../form/BngInputColorTransparent.png');
  width: 38px;
  height: 38px;
  border-radius: 100%;
  transition: transform 0.2s;
  border: solid 2px rgba(0, 0, 0, 0.15);
}

.transparentNode:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.semiTransparentNode {
  position: relative;
  bottom: 2px;
  right: 2px;
  width: 38px;
  height: 38px;
  border-radius: inherit;
  border: solid 2px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s;
}

.colorNode {
  width: 38px;
  height: 38px;
  border-radius: inherit;
  border: solid 2px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s;
}

.colorNode:hover {
  transform: scale(1.1);
  cursor: pointer;
}
