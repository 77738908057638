.marketplaceFilter {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.activeFilter {
  font-weight: bold;
}

.marketplaceFilterOptions {
  display: flex;
  flex-direction: row;
}

.marketplaceFilterOptions div {
  margin-left: 6px;
}

.marketplaceFilterOptions span {
  margin-left: 6px;
  cursor: pointer;
}
