.BngAdvancedModeAlert {
    width: 385px;
}

.BngAdvancedModeAlert h4 {
    font-weight: 500;
}

.BngAdvancedModeAlert .Action {
    margin-top: 24px;
}

.BngAdvancedModeAlert .Message {
    font-size: 16px;
    word-break: normal;
}


/* pages/analysis/properties/mdx-edit-properties.xhtml */
#mdx-edit-properties-advanced-alert {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
