.ul-dropdown-presentation {
    list-style-type: none;
    padding: 0;
    width: 180px;
    height: 200px;
    margin-top: -14px;
    margin-left: -181px;
    background-color: white;
    font-weight: bold;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 50000 !important;
    border-radius: 8px;
}

.button-menu-publisher {
    color: black;
    padding-top: 7px;
    font-size: 28px;
    cursor: pointer;
}

.dropdown-presentation-table-list.table-presentation {
    font-weight: 500;
    text-align: right;
    margin-right: 6px;
    font-size: 28px;
    opacity: 0.8;
    cursor: pointer;
    color: #373737 !important;
}

.presentation-dropdown-icon-button {
    padding: 6px !important;
}

.li-dropdown:hover {
    background-color: #eee;
}

.button-dropdown-action {
    margin: 0 2px !important;
    background: transparent !important;
    color: #555555 !important;
    padding-top: 5px !important;
    font-size: 14px;
}

.btn-only-icon.table-presentation {
    width: 202px;
    text-align: left;
    display: flex;
    align-items: center;
    margin-left: 0 !important;
}

.bng-dropdown .table-presentation {
    margin-top: -13px;
    margin-right: 43px;
}

.bng-dropdown-parent.presentationDropdown {
    z-index: 99999 !important;
    width: 240px !important;
    left: -41px !important;
}

.bng-dropdown.table-presentation.presentationDropdown {
    margin: 0 0 !important;
}

.PresentationMenuClickOutsideOverlay {
    z-index: 50000 !important;
}

.button-menu-publisher .table-presentation {
    font-weight: 500;
    opacity: 0.8;
    margin-top: -7px;
    color: #373737;
}