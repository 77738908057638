.title-type-object {
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
}

.description-type-object {
    font-size: 12px;
    white-space: normal;
    margin: 10px 0px 0px 2px;
    line-height: 16px;
    text-align: justify;
}


.linkhelp-type-object {
    font-size: 11px;
    margin-top: 46px;
    float: right;
}

.menu_divider {
    border-bottom: 0px solid #f3e4ec !important;
}

.description-type-object .label .label-important .label-beta .pull-right {
    margin-top: -10px;
}

.CreateObjectMenuButton a.menu_sttings {
    padding-top: 5px !important;
}

.create-object-item-container:hover .title-type-object,
.create-object-item-container:hover span {
    color: #005dff;
}

.MenuAlertContainer.error {
    position: absolute;
    display: inline-block;
    right: 16px;
    background-color: transparent;
    box-shadow: none;
    text-shadow: none;
    color: red;
    font-size: 18px;
}
