.favorites-nav-tab.active {
    display: block;
}

.favorites-nav-tab {
    display: none;
}

div.favorite-itens a:hover .favorite-caption {
    color: #2e76fd !important;
}

.favorite-empty-message {
    text-align: center;
}

img.favorite-empty-image {
    height: 140px;
}

.favorite-empty-title {
    padding: 16px 0 8px 0;
    font-size: 16px;
    color: #292C31;
    font-weight: 500;
    letter-spacing: .5px;
}

.favorite-empty-message {
    margin-top: 10px;
}

.favorite-empty-description {
    line-height: 16px;
}

.favorite-item {
    width: Calc(50% - 12px);
    color: #9f9f9f;
    background: #FFFFFF;
    margin: 5px;
    border-radius: 4px;
    box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.07);
    border: 1px solid #E4E4E8;
    height: 72px;
    float: left;
}

.favorite-itens {
    padding: 10px;
}

.favorite-description {
    font-size: 11px;
    letter-spacing: .1px;
    line-height: 12px;
    max-height: 24px;
    overflow: hidden;
}

.favorite-caption {
    color: #202020;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 14px;
    margin-bottom: 5px;
    max-height: 28px;
    overflow: hidden;
    font-size: 13px;
}

i.favorite-icon {
    font-size: 36px;
    color: #9f9f9f;
}

td.favorite-text-td, td.favorite-icon-td {
    height: 74px;
}

td.favorite-icon-td {
    padding: 0 14px;
    border-right: 1px solid #E4E4E8;
    width: 44px;
    text-align: center;
}

td.favorite-text-td {
    padding: 0 10px 0 14px;
    color: #656565;
}

.home-favorites .widget-body, .home-last-activities .widget-body {
    height: 270px;
}

.home-favorites {
    margin-right: 2px;
}

.home-last-activities {
    margin-left: 3px;
}
