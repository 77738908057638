.headerButtons :global(.bng-search) {
  height: 40px;
}

.logsSearch {
  width: 100%;
  margin-left: 6px;
}

.logsTableWrapper :global(.BngTable) .tableLeftPadding {
  padding-left: 20px;
}
