.trialText {
  font-size: 14px;
  color: #3A4045;
  font-weight: 400;
}

:global(.DarkMode) .trialText {
  color: var(--default-text-color)
}

:global(.DarkMode) .termsText,
:global(.DarkMode) .termsText :global(a) {
  color: #999999;
}

.trialText :global(a) {
  color: var(--link-blue) !important;
  font-weight: 500;
  transition: filter 200ms ease-in-out;
}

.trialText :global(a:hover) {
  filter: brightness(90%);
}

.termsText {
  color: #787878;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
}

.termsText :global(a) {
  color: #787878;
  font-weight: 500;
  transition: filter 200ms ease-in-out;
}

.termsText :global(a:hover) {
  filter: brightness(90%);
}

.capsLockAlert {
  position: absolute;
  right: 13px;
  top: 6px;
  font-size: 12px;
  font-weight: 400;
  color: #787878;
}

:global(.DarkMode) .capsLockAlert {
  color: #999999;
}

.loginBoxWidgetBodyOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0);
  z-index: 2147483647;
}

.rememberMeBtn span {
  color: #3A4045;
  font-size: 14px;
}
