.ApiForm .row-fluid.painel {
    width: 383px;
    margin-top: 95px;
    border-right: 3px solid #f1f2f2;
    height: 189px;
}

.api-button-container .row-fluid {
    width: 100%;
}

.ApiForm .BngField .control-label {
    display: none;
}

.ApiForm .field-container {
    height: 50px;
    width: 80%;
}

.ApiForm .validate-params {
    float: right;
    display: inline-grid;
    margin-top: -195px;
    margin-right: 50px;
}

.ApiForm .dialog-body {
    height: 390px;
}

.ApiForm .bar-style {
    background: #fff !important;
    color: #000 !important;
}

.ApiForm #titlehelp {
    color: #000 !important;
}