.AccountsPage {
  min-height: calc(100vh - 84px);
  width: 100%;
}

.AccountsPage .accountsBlocker {
  min-height: calc(100vh - 84px);
  height: 100%;
}

.navPanelWrapper {
  display: inline-flex;
  height: calc(100vh - 290px);
  width: 100%;
}

.NavPanel {
  background: #ffffff;
  border-right: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 270px;
}

.AccountSelect {
  align-items: center;
  background: #f6f6f6;
  border-top: 1px solid #e4e4e4;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  height: 60px;
  padding: 6px;
}

.accountName {
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: center;
}

.accountTabs {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  width: 100%;
}

.tabItem,
.tabChildren {
  align-items: center;
  color: #555555;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 4px 0;
  width: 100%;
}

.tabTitle {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  padding: 10px 10px 10px 32px;
}

.tabItem .tabIcon {
  color: var(--blue-default);
}

.tabItem :global(.BngIconButton) {
  margin-right: 20px;
}

.tabContent {
  height: 100%;
  overflow: scroll;
  width: 100%;
  overflow-x: hidden;
}

.tabChildren.lastChild {
  border-bottom: 1px solid #e4e4e4;
}

.tabChildren span {
  border-left: 1px dashed #d1d1d1;
  margin-left: 42px;
  padding: 10px 0 10px 16px;
}

.tabChildren.notVisible {
  display: none;
}

.ProjectSelector {
  display: flex;
  align-items: center;
  position: absolute;
  width: 250px;
  height: 75px;
  top: 35px;
  right: 80px;
}

.ProjectSelectorDropDown {
  box-shadow: none;
}

.ProjectSelectorDropDown > div {
  width: 250px !important;
}

.ProjectSelectorDropDown > div :first-child {
  text-align: right;
  font-style: normal;
  line-height: normal;
  color: #005dff;
  font-size: 14px;
  font-weight: 400;
}

.ProjectSelectorDropDown > div > label {
  text-align: left;
  font-style: normal;
  line-height: normal;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.FloatActionButtonDropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.FloatActionButtonDropdown > div > button {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}

.FloatActionButtonDropdown > div > button > i {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.FloatActionButtonDropdown > div {
  position: relative;
  margin-top: 10px;
}

.FloatActionButtonDropdown > div > label {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  top: 10px;
  left: 60px;
  white-space: nowrap;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer !important;
}

.FloatActionButtonDropdown > div > label:after {
  position: absolute;
  top: 8px;
  left: -6px;
  content: '';
  width: 0;
  height: 0;

  border-right: solid 6px rgba(0, 0, 0, 0.8);
  border-bottom: solid 6px transparent;
  border-top: solid 6px transparent;
}
