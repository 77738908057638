input[type=checkbox].ace-switch.edit-switch + .lbl .edit-object-label {
    position: absolute;
    color: #FFFFFF;
    text-align: right;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    transition: left 0.2s linear;
}

input[type=checkbox].ace-switch.edit-switch + .lbl::before {
    content: "";
    width: 100%;
    text-indent: 30px;
}

input[type=checkbox].ace-switch.edit-switch:checked + .lbl::before {
    content: "";
    text-indent: 10px;
}

input[type=checkbox].ace-switch.edit-switch:checked + .lbl::after {
    left: calc(100% - 18px);
}

.floating-edit-object {
    width: 70px;
}

.floating-edit-object:not(.floating-edit-object-dashboard) {
    padding: 8px;
}

.floating-edit-object > label {
    margin-bottom: 0;
}

.floating-edit-object.floating-edit-object-newmap {
    right: 205px;
    height: 20px;
}

.floating-edit-object label .ace-switch + span.lbl {
    min-height: unset;
    margin: 0;
    display: block;
}

.sidebar.menu-min.right-side-sidebar {
    width: 0;
}

.floating-edit-object.WaitingLoading {
    cursor: wait;
}

.floating-edit-object.Disabled {
    opacity: 0.5;
}

.EditObject .spanContainer {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
}