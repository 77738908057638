.button-order-presentation {
    height: 24px;
    width: 30px;
    border-radius: 4px;
    border: 1px solid #EFEFEF;
    background-color: #FFFFFF;
    margin-top: 6px;
    margin-left: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    color: #000000;
}

.input-select-table {
    width: 208px;
    background-color: #fff;
    border: 1px solid #ccc;
    margin-top: 4px;
}

.input-time-presentation,
.input-link-presentation {
    margin-top: 6px;
    border: 1px solid #EFEFEF;
    border-radius: 4px;
    background-color: #FFFFFF;
}

.input-time-presentation {
    width: 80px;
}

.button-edit-page-presentation {
    width: 206px;
    height: 30px;
    border: 1px solid #EFEFEF;
    border-radius: 4px;
    background-color: #FFFFFF;
    cursor: pointer;
    color: #000000;
    line-height: 30px;
    display: inline-flex;
    position: relative;
    align-items: center;
}

.button-edit-page-presentation .material-icons {
    font-size: 18px;
    position: absolute;
    right: 5px;
}

.button-edit-page-presentation .label-edit-button {
    margin-left: 7px;
}

.CreatePresentationDialog .scroll-bar-presentation-table {
    max-height: 331px;
    min-height: 331px;
}

.scroll-bar-presentation-table {
    overflow: auto;
    max-height: 300px;
    min-height: 300px;
    background-color: #f6f6f6;
}

.scroll-bar-presentation-table .table {
    margin-bottom: 0;
    border: 1px solid #E4E4E4;
}

.presentation-table-title {
    padding-left: 14px !important;
    height: 37px;
    line-height: 37px !important;
}

.presentationlist-table-td-drag,
.presentationlist-table-td-slide,
.presentationlist-table-td-component,
.presentationlist-table-td-time,
.presentationlist-table-td-remove {
    line-height: 37px !important;
    vertical-align: middle !important;
    max-height: 40px;
    max-width: 260px;
}

.presentationlist-table-td-drag {
    width: 60px;
    min-width: 60px;
}

.presentationlist-table-td-slide {
    width: 200px;
    min-width: 200px;
}

.presentationlist-table-td-time {
    width: 150px;
    min-width: 150px;
}

.div-information-presentation .empty-html-page-presentation.button-edit-page-presentation {
    border-color: #e9322d;
    -webkit-box-shadow: 0 0 6px #f8b9b7;
    -moz-box-shadow: 0 0 6px #f8b9b7;
    box-shadow: 0 0 6px #f8b9b7;
}

.div-information-presentation .Icon {
    font-size: 20px;
}

.presentation-item-slide-name {
    margin-left: 5px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    display: -webkit-box;
    overflow: hidden;
    line-height: 37px;
    padding-left: 10px;
    font-size: 12px;
    color: #373737;
    font-weight: 500;
    opacity: 0.8;
    align-items: center;
}

.presentationlist-table-td-remove .button-remove-div {
    margin-left: 58px;
}

.btn-only-icon.remove-slide {
    width: 20px;
    padding-top: 5px;
}

.button-remove-div {
    margin-left: 58px;
}

.btn-only-icon.remove-slide .material-icons {
    font-size: 22px;
}