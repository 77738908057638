:root {
    --bng-connection-blue: 0, 93, 255;
    --bng-connection-red: 253, 0, 61;
    --bng-connection-green: 0, 163, 85;
    --bng-connection-orange: 249, 137, 0;
    --bng-connection-card-color-opacity: 0.5;
}

.DialogResponsive {
    width: 90%;
    max-width: 1520px;
    border-radius: 12px;
    max-height: calc(90% - 90px);
    margin: unset !important;
    transform: translateX(-50%);
    left: 50%;
    border: unset;
}

.DialogResponsive.Dragged {
    transform: unset !important;
}

.DialogResponsive .modal-body {
    border-radius: 12px;
}

.DialogResponsive .widget-header {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.DataOriginsContainer {
    display: flex;
    flex-direction: column;
    background: white;
    border: 1px solid #e6e6e6;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.DataOriginsHeader {
    display: flex;
    padding: 10px 20px;
}

.DataOriginsBody {
    display: flex;
    height: 80vh;
}

.DataOriginsCards {
    display: flex;
    padding: 16px 24px 16px 24px;
    flex: 1;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
    position: relative;
}

.DataOriginsMenu {
    display: flex;
    flex-direction: column;
    background: white;
    flex: 0 0 276px;
    margin-left: 16px;
    margin-top: 24px;
}

.DataOriginsMenu .TitleMenu {
    font-size: 14px;
    color: rgba(0,0,0,0.75);
    font-weight: bold;
    padding: 15px 10px 10px 10px;
    letter-spacing: 0;
    margin: 0;
    background: white;
}

.DataOriginsSectionTitleWrapper > .DataOriginsTitle {
    font-size: 13px !important;
}

.DataOriginsTitle {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    align-self: center;
    margin: 0;
    line-height: unset;
    text-transform: uppercase;
}

.DataOriginsSearch {
    border-radius: 24px;
    background: white !important;
    border: 1px solid #e0e0e0;
    margin: 0 0 24px 15px;
    height: 46px;
}

.DataOriginsFilters {
    overflow-y: auto;
}

.DataOriginsSearch input {
    color: #ACACAC !important;
    margin-left: 10px;
}

.DataOriginsSearch .search-button i {
    color: #525252;
}

.DataOriginsPadding__Section {
    padding: 5px 20px
}

.DataOriginsSection {
    border-top: 1px solid #e6e6e6;
}

.DataOriginsSectionBodyWrapper {
    padding: 0px 20px !important;
    display: flex;
    flex-wrap: wrap;
}

.DataOriginsContainer .BngHorizontalCardBorder {
    height: calc(100% - 20px);
}
.DataOriginsContainer .BngHorizontalCard {
    height: 100%;
    min-height: 100px;
    display: flex;
    transition: all .1s ease-in-out;
    box-shadow: none !important;
    border-radius: 5px !important;
    width: auto;
}

.DataOriginsContainer .BngHorizontalCard:hover {
    border-top: 1px solid #4285f4;
    border-right: 1px solid #4285f4;
    border-bottom: 1px solid #4285f4;
    background-color: rgba(23,78,166,.04);
    box-shadow: 0 1px 3px 1px #f1f3f4 !important;
}

.DataOriginsContainer .BngHorizontalCard .Content {
    padding: 10px 10px !important;
}

.DataOriginsContainer .BngHorizontalCard .Content .Text {
    color: #7c7c7c;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-break: break-word;
    margin-top: 8px;
}

.DataOriginsContainer .BngHorizontalCard .IconContainer {
    padding: 10px 7px 10px 15px !important;
    min-height: 40px;
    border: none !important;
    align-items: flex-start !important;
}

.DataOriginsContainer .BngHorizontalCard img {
    width: 45px;
    height: 45px;
}

.DataOriginsContainer .TagContainer {
    display: flex;
    flex-wrap: wrap;
}

.DataOriginsContainer .DataOriginsFilterTag {
    margin-left: 19px;
}

.DataOriginsContainer input.DataOriginsFilterTag {
    padding: 2px !important;
}

.DataOriginsContainer .Icon ~ .DataOriginsFilterTag {
    margin-left: 5px;
}

.DataOriginsContainer .DataOriginsFilterTag span {
    font-size: 12px !important;
    color: #191919;
    font-weight: 400;
    letter-spacing: 0;
}

.DataOriginsContainer .DataOriginsFilterTag span::before {
    vertical-align: initial !important;
    height: 14px;
    width: 14px;
    min-width: 10px;
    border-radius: 6px !important;
    border: 1px solid #cacaca;
    padding: 1px;
    margin-right: 10px;
}

.DataOriginsContainer .DataOriginsFilterTag input[type=checkbox]:checked,
.DataOriginsContainer .DataOriginsFilterTag input[type=checkbox]:checked + .lbl::before{
    padding: 3px;
    border-radius: 6px !important;
    margin-right: 10px;
}

.DataOriginsContainer .DataOriginsFilterTag.BngConnexionRed input[type=checkbox]:checked,
.DataOriginsContainer .DataOriginsFilterTag.BngConnexionRed input[type=checkbox]:checked + .lbl::before {
    background: rgba(var(--bng-connection-red), 1) !important;
}

.DataOriginsContainer .DataOriginsFilterTag.BngConnexionBlue input[type=checkbox]:checked,
.DataOriginsContainer .DataOriginsFilterTag.BngConnexionBlue input[type=checkbox]:checked + .lbl::before{
    background: rgba(var(--bng-connection-blue), 1) !important;
}

.DataOriginsContainer .DataOriginsFilterTag.BngConnexionGreen input[type=checkbox]:checked,
.DataOriginsContainer .DataOriginsFilterTag.BngConnexionGreen input[type=checkbox]:checked + .lbl::before {
    background: rgba(var(--bng-connection-green), 1) !important;
}

.DataOriginsContainer .DataOriginsFilterTag.BngConnexionOrange input[type=checkbox]:checked,
.DataOriginsContainer .DataOriginsFilterTag.BngConnexionOrange input[type=checkbox]:checked + .lbl::before {
    background: rgba(var(--bng-connection-orange), 1) !important;
}

.DataOriginsContainer .DotsPaginationBody {
    flex: 1 1 552px;
    align-content: flex-start;
    position: relative;
}

.DataOriginsContainer .AccordionSideMenu {
    height: auto !important;
    background-color: white !important;
    border: none !important;
    box-shadow: unset !important;
    position: initial !important;
    margin-bottom: 16px !important;
}

.DataOriginsContainer .AccordionSideMenu .AccordionContent {
    padding: 2px 11px 11px 11px;
}

.DataOriginsContainer .AccordionSideMenu.AccordionClose .AccordionContent {
    padding-top: 0;
    padding-bottom: 0;
}

.DataOriginsContainer .AccordionSideMenu .AccordionTitle {
    box-shadow: none !important;
}

.BngHorizontalCard.BngConnexionGreen {
    border-left: 4px solid rgba(var(--bng-connection-green), 1);
}

.BngHorizontalCard.BngConnexionGreen.Selected {
    border-color: rgba(var(--bng-connection-green), 1);
    background-color: rgba(var(--bng-connection-green), 0.1);
}

.BngHorizontalCard.BngConnexionOrange {
    border-left: 4px solid rgba(var(--bng-connection-orange), 1);
}

.BngHorizontalCard.BngConnexionOrange.Selected {
    border-color: rgba(var(--bng-connection-orange), 1);
    background-color: rgba(var(--bng-connection-orange), 0.1);
}

.BngHorizontalCard.BngConnexionBlue {
    border-left: 4px solid rgba(var(--bng-connection-blue), 1);
}

.BngHorizontalCard.BngConnexionBlue.Selected {
    border-color: rgba(var(--bng-connection-blue), 1);
    background-color: rgba(var(--bng-connection-blue), 0.1);
}

.BngHorizontalCard.BngConnexionRed {
    border-left: 4px solid rgba(var(--bng-connection-red), 1);
}

.BngHorizontalCard.BngConnexionRed.Selected {
    border-color: rgba(var(--bng-connection-red), 1);
    background-color: rgba(var(--bng-connection-red), 0.1);
}

.DataOriginsFilterWrapper {
    display: flex;
    align-items: center;
    padding: 7px;
}

.DataOriginsFilterWrapper .Icon {
    font-size: 14px;
    color: orange;
}

.DataOriginsContainer .EdgeCard {
    margin-right: 0 !important;
}

.DataOriginsContainer .CardWrapper {
    width: 100% !important;
}

.DataOriginsContainer .BngForm label {
    margin-bottom: 0;
}

@media only screen and (min-width: 1450px)
{
    .DataOriginsContainer .CardWrapper {
        width: 25% !important;
    }
}

@media only screen and (min-width: 1250px) and (max-width: 1450px)
{
    .DataOriginsContainer .CardWrapper {
        width: 33.33% !important;
    }
}

@media only screen and (min-width: 970px) and (max-width: 1250px)
{
    .DataOriginsContainer .CardWrapper {
        width: 50% !important;
    }
}