.AccessTimeRestrictionFormDialog {
  width: 860px;
  overflow: hidden;
}

.AccessTimeRestrictionFormDialog :global(.modal-body) {
  overflow: hidden !important;
}

.AccessTimeRestrictionFormDialog :global(.DialogBody) {
  background-color: #f6f6f6;
  border-bottom: 1px solid #e6e7ea;
  height: 450px;
  max-height: 450px;
  overflow-y: auto;
}

.AccessTimeRestrictionFormDialog :global(.DialogFooter) {
  padding: 12px 10px;
}

.AccessTimeRestrictionFormDialog :global(.widget-body),
.AccessTimeRestrictionFormDialog :global(.widget-main) {
  padding: 0;
}

.AccessTimeRestrictionFormDialog :global(.widget-header) {
  border: 1px solid #e6e7ea;
}

.AccessTimeRestrictionFormDialog :global(.BngInput) {
  height: 35px;
}

.container {
  margin: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dayButtons {
  height: 40px;
}

.formDays {
  display: flex;
  flex-direction: column;
}

.formDayOrTime {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formOptSchedules {
  border: none;
  background-color: #f6f6f6;
}

.intervalTimeGrid {
  display: grid;
  grid-template-columns: 150px 1fr 150px 1fr auto;
  align-items: baseline;
  gap: 20px;
}

.intervalTimeGrid > label {
  justify-self: start;
}

.intervalTimeGrid > .field {
  justify-self: stretch;
}

.intervalTimeGrid > .buttonClose {
  justify-self: center;
  visibility: hidden;
}

.intervalTimeGrid.hasRemoveButton > .buttonClose {
  visibility: visible;
}

.intervalTimeButton {
  color: #005dff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  width: 200px;
}

.timeZone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeZoneSelect {
  width: 500px;
}

.extraRestrictionsButtonGroup {
  height: 45px;
  line-height: 8px;
  letter-spacing: -0.5px;
}

.isComing {
  display: flex;
  gap: 10px;
}

.labelIscComing {
  padding: 0 8px;
  color: #FFFDFD;
  background: #0DA239;
  border-radius: 8px;
}