.listIcons {
  margin-right: 21px;
  color: #005dff;
}

.draggableIcon {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  display: flex;
  overflow: hidden;
  line-height: 37px;
  padding-left: 10px;
  font-size: 12px;
  color: #373737;
  font-weight: 500;
  opacity: 0.8;
  align-items: center;
}

.draggableIcon :first-child {
  font-size: 20px;
}

.options {
  text-align: end !important;
  width: 1%;
  min-width: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.addCockpitButton {
  height: 45px;
  width: 45px;
}

.uiBlockerStyle > div {
  height: 99%;
}

.uiBlockerStyle > div > table {
  height: 93%;
  display: block;
}

.uiBlockerStyle > div > table > thead > tr {
  background: none;
}

.uiBlockerStyle > div > table > div {
  min-height: 100%;
  max-height: 100%;
}

.ownerTd {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: 25%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 37px;
  padding-left: 10px;
  font-size: 12px;
  color: #373737;
  font-weight: 500;
  align-items: center;
}

.ownerTd :first-child {
  justify-content: center;
  opacity: unset;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  display: flex;
  overflow: hidden;
  line-height: 37px;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
}

.ownerTd :first-child :first-child {
  justify-content: center;
  margin-left: 0 !important;
}

.ownerTd :global(.div-information-v) {
  opacity: unset;
}

.ownerTd :global(.div-information-cockpit .cockpit-item-slide-name) {
  opacity: unset;
}

.ownerTd :global(.div-information-cockpit .cockpit-item-slide-name .nameOwnerWrapper) {
  display: flex;
  align-items: center;
}

.ownerTd :global(.div-information-cockpit .cockpit-item-slide-name .nameOwnerWrapper):first-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #3a87ad;
  color: white;
  border-radius: 15px;
  padding-right: 5px;
  padding-left: 5px;
  height: 20px;
}

.objectsLinked {
  width: 25%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 37px;
  padding-left: 10px;
  font-size: 12px;
  color: #373737;
  font-weight: 500;
  align-items: center;
}

.objectsLinked :first-child {
  justify-content: center;
  opacity: unset;
}

.objectsLinked :global(.div-information-cockpit .cockpit-item-slide-name) > div {
  display: flex;
  align-items: center;
}

.objectsLinked:global(.div-information-cockpit) {
  justify-content: center;
  opacity: unset;
}

.objectsLinked:global(.cockpit-item-slide-name) {
  opacity: unset;
}

.accessPermissions {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: 25%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 37px;
  padding-left: 10px;
  font-size: 12px;
  color: #373737;
  font-weight: 500;
  align-items: center;
}

.accessPermissions :first-child {
  justify-content: center;
  padding-left: 0;
  margin-left: 0;
  opacity: unset;
}

.accessPermissions :global(.div-information-cockpit .cockpit-item-slide-name) > div {
  display: flex;
  align-items: center;
}

.accessPermissions:global(.div-information-cockpit) {
  justify-content: center;
  opacity: unset;
}

.accessPermissions:global(.cockpit-item-slide-name) {
  opacity: unset;
}

.tagsDropdownGroupOptionCockpit {
  padding: 0 !important;
}

.tagsDropdownGroupOptionCockpit :global(.BngDropdown.BngDropdownTags) {
  width: 100%;
}

.cockpitManagementMenuPopper {
  z-index: 1 !important;
  background-color: transparent;
}

.cockpitManagementMenuPopper :global(.CockpitManagementMenuPopper .Overlay) {
  z-index: 1 !important;
}

.briefIcon {
  font-size: 22px !important;
  margin-right: 15px;
  color: rgba(0, 0, 0, 0.4);
}

.userAvatarOuter {
  background: #ffffff;
  border-radius: 100%;
  width: 34px;
  height: 34px;
}

.groupIcon {
  height: 34px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: #7f7f7f;
}

.userAvatar {
  width: auto;
  height: 34px;
  border-radius: 100%;
  background-size: cover !important;
}

.selectedRowBriefIcon {
  font-size: 22px !important;
  margin-right: 15px;
  color: rgba(0, 93, 255, 1);
}

.tag {
  margin-left: 5px;
}

.tag :global(.groupName) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.columnHeadName {
  width: 25%;
}

.columnHeadOwner > div {
  justify-content: center;
  padding-left: 15px;
}

.columnHeadLinkedObjects > div {
  justify-content: center;
}

.columnHeaderAccessPermissions > div {
  justify-content: center;
}

.disableUserButton {
  width: 40px;
}

.cockpitName {
  display: contents;
  font-size: 14px !important;
  color: #7f7f7f !important;
  font-weight: 500 !important;
}

.nameWrapper {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  display: -webkit-box;
  overflow: hidden;
  line-height: 37px;
  font-size: 12px;
  color: #373737;
  font-weight: 500;
  opacity: 0.8;
  align-items: center;
}

.draggableTd {
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 65px;
}

.nameTd {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: 25%;
}

.nameTd :first-child {
  padding-left: 0 !important;
  opacity: unset;
  margin-right: 5px;
}
