.GroupsDialog .scroll-bar-groups-table {
    overflow: auto;
    max-height: 350px;
    background-color: #f6f6f6;
    border: 1px solid #E4E4E4;
    border-style: solid none;
}

.GroupsDialog .widget-main {
    padding-bottom: 0 !important;
}

.GroupsDialog .scroll-bar-table-wrapper {
    background-color: #f6f6f6;
    height: 350px;
    padding-bottom: 15px;
}

.GroupsDialog .group-search {
    margin: 0 15px;
    width: 665px;
}

.GroupsDialog .search-button {
    color: #373737;
}

.GroupsDialog .scroll-bar-groups-table .table {
    margin-bottom: 0;
    border-top: none;
}

.GroupsDialog .groups-header {
    display: flex;
    align-items: center;
    padding: 6px 0;
    border-top: 1px solid #E6E7EA;
}

.GroupsDialog .groups-table-header {
    position: sticky;
    top: 0;
    z-index: 1;
}

.GroupsDialog .groups-table-title-color,
.GroupsDialog .groups-table-title-name,
.GroupsDialog .groups-table-title-actions {
    height: 37px;
    line-height: 37px !important;
    padding-left: 8px;
}

.GroupsDialog .groups-table-title-color,
.GroupsDialog .groups-table-title-actions {
    text-align: center;
}

.GroupsDialog .grouplist-table-tr {
    height: 46px;
    line-height: 37px !important;
    font-size: 14px;
    padding: 4px 5px;
    vertical-align: middle;
}

.GroupsDialog .grouplist-table-tr:nth-child(odd) {
    background-color: #ffffff !important;
}

.GroupsDialog .grouplist-table-tr:nth-child(even) {
    background-color: #f6f6f6 !important
}

.GroupsDialog .table-condensed td {
    vertical-align: middle !important;
}

.grouplist-table-td-name {
    width: 610px;
    min-width: 610px;
    font-weight: bold;
    color: #707070;;
}

.GroupsDialog .grouplist-table-td-color,
.GroupsDialog .grouplist-table-td-actions {
    text-align: center;
}

.GroupsDialog .grouplist-table-td-actions {

}

.GroupsDialog .group-color-input {
    height: 20px !important;
    width: 20px !important;
}

.GroupsDialog .group-color-picker {
    opacity: 1 !important;
}

.grouplist-table-td-actions i {
    font-size: 20px !important;
}

.GroupsDialog .group-name-input {
    margin: 0;
    width: 100%;
}