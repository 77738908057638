.load-limit-table-tr td {
    line-height: 37px;
    max-width: 260px;
    max-height: 40px;
}

.load-limit-table-tr:nth-child(even) {
    background-color: #f6f6f6 !important;
}

.load-limit-table-tr:nth-child(odd) {
    background-color: #ffffff !important;
}

.load-limit-table-input {
    background-color: transparent;
    border: none;
    border-radius: 4px;
    color: rgb(51, 51, 51);
    font-size: 14px;
    outline: none;
    width: 70%;
    border: 1px solid #ccc;
    padding: 5px;
    transition: border linear .2s, box-shadow linear .2s;
}

.load-limit-table-input:focus {
    border: 1px solid rgba(82, 168, 236, 0.8);
}

.load-limit-dialog-header {
    display: flex;
    justify-content: space-between;
    margin: 5px 25px 5px 20px;
}

.load-limit-table-global {
    color: rgba(0,0,0,0.5);
}

.load-limit-table {
    border: none;
    margin-bottom: 0 !important;
}

.load-limit-help-text {
    margin-bottom: 0;
}

.load-limit-error-text {
    margin-bottom: 0;
    color: #da4f49;
}