.BngAnalysisTypeSelected {
    max-width: 1320px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.BngAnalysisTypeSelected .Preview {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BngAnalysisTypeSelected .Preview span {
    height: 300px;
    font-size: 24px;
    color: #595959;
    display: flex;
    align-items: center;
}

.BngAnalysisTypeSelected .BngHorizontalScroller .BngHorizontalCardBorder {
    display: inline-flex;
}

.BngAnalysisTypeSelected .BngHorizontalScroller .BngHorizontalCard {
    transition: ease-in-out 200ms;
    margin-right: 20px;
    height: 120px;
    max-height: 120px;
}

.BngAnalysisTypeSelected .BngHorizontalScroller .BngHorizontalCard:last-child {
    margin-right: unset;
}


.BngAnalysisTypeSelected .ParamsBox {
    box-sizing: border-box;
    width: 375px;
    border: 1px solid #E9E9E9;
    border-radius: 0 0 6px 6px;
    border-top: 0;
    background-color: #FFFFFF;
    padding: 10px 25px 25px 25px;
    display: inline-block;
}

.BngAnalysisTypeSelected .ParamsBox .Explanation {
    max-height: 100px;
    color: #595959;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 20px;
    overflow: auto;
    margin-bottom: 15px;
}

/* Input adjusts*/

.BngAnalysisTypeSelected .BngForm input,
.BngAnalysisTypeSelected .BngForm select,
.BngAnalysisTypeSelected .BngForm textarea {
    padding: 23px 14px;
    font-weight: 500;
}

.BngAnalysisTypeSelected .BngSelectSearch {
    border: 1px solid #EFEFEF;
    border-radius: 4px;
    box-shadow: unset;
}

.BngAnalysisTypeSelected .BngSelectSearch .OptionPreview {
    padding: 8.5px 14px;
}

.BngAnalysisTypeSelected .BngSelectSearch .OptionPreview label,
.BngAnalysisTypeSelected .BngForm .BngTreeDropdown .FieldPreview {
    font-weight: 500;
}

.BngAnalysisTypeSelected .BngSelectSearch:not(.WithIcon) .OptionPreview label {
    font-size: 12px;
}

.BngAnalysisTypeSelected .BngSelectSearch .OptionPreview .Icon {
    content: 'arrow_drop_down';
}

.BngAnalysisTypeSelected .BngSelectSearch .BngSelectDropdown .bng-search {
    padding: 5.5px 4px;
}

.BngAnalysisTypeSelected .BngSelectSearch .BngSelectDropdown .bng-search .bng-search-input .search-field {
    font-size: 14px;
}

.BngAnalysisTypeSelected .Submit {
    padding-top: 13px;
    padding-bottom: 13px;
    height: auto;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    letter-spacing: 1px;
}

.BngAnalysisTypeSelected .BngSelectSearch .SearchContainer .BngIconButton {
    top: 14px;
}

.BngAnalysisTypeSelected .BngSelectSearch .Icon:not(.material-icons) {
    font-size: 20px;
    padding-left: 5px;
}

.BngAnalysisTypeSelected .BngHorizontalScroller .BngHorizontalCard.selected {
    box-shadow: 0 0 2px #3558EF;
}

.BngAnalysisTypeSelected .BngHorizontalScroller .BngHorizontalCard.selected {
    border-color: #3558EF !important;
}

.BngAnalysisTypeSelected .BngHorizontalScroller .BngHorizontalCard.selected .Icon {
    color: #3558EF !important;
    opacity: 0.9 !important;
}

.BngAnalysisTypeSelected .RightPanel .BngHorizontalCardBorder {
    margin-left: 0;
    margin-right: 0;
}

.BngAnalysisTypeSelected .BngForm .BngTreeDropdown .FieldPreview {
    padding: 6px 6px 6px 12px;
    height: 36px;
}

.BngAnalysisTypeSelected .BngFolderFieldInput .button-create-folder {
    height: 46px;
}