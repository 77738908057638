.OpenAiForm {
  display: flex;
  flex-direction: column;
}

.OpenAiForm :global(.d-flex.gap-2.mt-2) {
  margin-top: 0 !important;
}

.OpenAiForm :global(.BngTextArea) {
  min-height: 110px !important;
  margin-bottom: 0;
}

.OpenAiForm :global(.BngField.control-group.mt-2.Required) {
  margin-top: 0 !important;
}

.DivOpenAiFormBngIconButton {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

.DivBngAddButton {
  display: flex;
  justify-content:space-between;
  align-items: center;
  width: 100%;
}

.DivBngAddButtonSpan {
  font-weight: bold;
  margin: 2px 0 10px 0;
}

.OpenAiFormBngIconButton {
  margin-bottom: 10px;
}

.OpenAiFormBngIconButton i {
  border-radius: 50%;
  width: 24px;
  color: #005dff;
}

.OpenAiFormBngIconButton span {
  padding-left: 10px;
  font-weight: bold;
}

.actionsDropdownPopper {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.195822);
  width: 300px;
  display: block;
  margin: 5px 0;
  border: 1px;
  border-radius: 4px;
  text-align: left;
  font-weight: 500 !important;
  max-width: 260px;
}

.actionsDropdownPopper :global(i) {
  font-size: 16px;
}

.actionsDropdownPopper :global(span) {
  font-size: 14px;
}

.actionButtonWrapper :global(div:not(#userLevelWrapper)) {
  display: flex;
  align-items: center;
  padding: 11px 15px;
  font-weight: 500;
  color: #6e6e6e;
  cursor: pointer;
  line-height: 30px;
}

.actionButtonWrapper :global(.BngDropdown) {
  padding: 0 !important;
}

.actionButtonWrapper :global(hr) {
  color: #e2e2e2;
  margin: 0;
  width: 100%;
}

.actionItem {
  max-height: 25px;
}

.actionButtonWrapper :global(div:hover:not(#userLevelWrapper)) {
  background: #efefef;
}

.actionButtonWrapper :global(i) {
  padding: 0 8px 0 5px;
}
