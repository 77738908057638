.UserMiniature {
    background: #FFFFFF;
    border-radius: 4px;
    display: flex;
    width: 32%;
    height: 60px;
    margin: 4px;
    align-items: center;
    cursor: pointer;
}

.UserMiniature.online {
    box-shadow: 0px 1px 6px rgba(0, 93, 255, 0.6)
}

.UserMiniature.offline {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
}

.UserMiniature .avatarAura {
    border: 2px solid;
    margin-left: 10px;
    border-radius: 100%;
}

.UserMiniature.online .avatarAura {
    border-color: #005DFF;
}

.UserMiniature.offline .avatarAura {
    border-color: #757575;
}

.UserMiniature .BngAvatar {
    margin: 1px;
    height: 40px;
    width: 40px;
}

.UserMiniature .userNameWrapper {
    margin-left: 10px;
    font-size: 10px;
    width: 63%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.UserMiniature .userMiniatureCheckbox {
    position: relative;
}

.UserMiniature .userPermissionWrapper {
    display: flex;
    align-items: center;
}