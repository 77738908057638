.HtmlItemDialog {
  border-top: 0;
  outline: none;
}

.htmlVarsButton {
  font-size: 11px !important;
  padding: 0 6px !important;
  border-radius: 2px !important;
  background-color: #2283c5 !important;
  margin-left: 3px;
}