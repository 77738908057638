.pageWrapper:not(.embedded) {
  padding: 40px 40px 0;
  position: relative;
}

.pageWrapper.embedded {
  border: 1px solid #e4e4e8;
  border-radius: 4px;
}

.pageWrapper.embedded .pagePanel {
  box-shadow: none;
}

.pageWrapper :global(.search-button) {
  color: rgba(0, 0, 0, 0.54);
  margin-right: 0 !important;
  padding: 8px !important;
  transition: ease-in-out 250ms;
  transition-property: background-color, color;
}

.pageWrapper :global(.search-button:not(:disabled):hover) {
  background-color: rgba(0, 0, 0, 0.08);
}

.pagePanel {
  border-radius: 4px;
  width: 100%;
}

.pagePanel :global(.Header) {
  padding: 0 32px;
}

.pageTitle {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
  white-space: nowrap;
}

.embedded .pageTitle {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 28px;
}

.floatActionButton {
  position: absolute;
  left: 15px;
  top: 45px;
}

.floatActionButton :global(.BngIconButton) {
  padding: 12px;
  transition: transform 250ms linear, filter 250ms linear;
}

.floatActionButton :global(.BngIconButton.Open) {
  transform: rotate(45deg);
}

.optionalButtonsWrapper {
  border-right: 1px solid #e2e2e2;
  margin-right: 5px;
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.itemsCounterContainer {
  background: rgba(0, 93, 255, 0.1);
  height: 24px;
  border: 1px solid rgba(0, 93, 255, 0.3);
  border-radius: 12px;
  color: #005dff;
  padding: 0 12px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}


.headerActions {
  transition: opacity 0.2s ease-in-out, top 0.3s ease-out;
  opacity: 1;
}

.closed {
  opacity: 0;
}

.pageWrapper.embedded .headerActions {
  padding: 0 8px !important;
}
