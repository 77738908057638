.StructureNotLoadedPage {
    height: calc(100vh - 100px);
    margin-right: 44px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    width: calc(100vw - 100px);
}

.StructureNotLoadedPage:global(.withFilterBar) {
    height: calc(95vh - 100px);
}

.renderForCockpit,
.StructureNotLoadedPage:global(.cockpitDashStructureNotLoadedPage){
    width: 100vw !important;
    margin-right: 0;
}

.notLoadedSpinner {
    left: calc(50% - 25px);
}

.notLoadedText {
    color: #555555;
    font-weight: 500;
    margin-top: 30px;
    max-width: 406px;
    text-align: center;
}

.StructureNotLoadedPage :global(.center-spinner) {
    top: 35%;
    position: unset !important;
}

.continueButton {
    background: transparent !important;
    color: #888888 !important;
    font-weight: 500;
    margin-top: 33px;
}

.continueButton:hover {
    background: transparent !important;
    color: #555555;
    text-decoration: underline;
}

.whyLink {
    position: absolute;
    bottom: 20px;
}