.BngPanel.eventlist-bng-panel {
  display: flex;
  flex-direction: column;
  width: 96.4%;
  margin: 20px;
  border: #dfdfdf 1px solid;
}

.Panel.Header.eventlist-bng-panel-header {
  height: 65px;
  display: flex;
  align-items: center;
}

.eventlist-title-text {
  font-size: 35px;
}

.headerTipWrapper {
  background-color: #add9fe;
  padding: 10px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}