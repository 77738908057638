.cockpit-navigator-dropdown {
    border-top: 1px solid #f6f6f6;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    height: calc(100% - 85px);
    margin-left: -5px;
    margin-top: 30px;
    z-index: 10;
}

.cockpit-header-hidden .cockpit-navigator-dropdown {
    height: calc(100% - 35px);
    margin-top: 30px;
}

.cockpit-navigator-dropdown .cockpit-dropdown-popper-header,
.cockpit-dropdown-popper .cockpit-dropdown-popper-action.link {
    height: 24px;
}

.cockpit-navigator-dropdown .cockpit-dropdown-popper {
    box-shadow: none !important;
    border: 0 !important;
    height: 100%;
}

.cockpit-navigator-button {
    padding: 6px 10px;
    color: rgba(0,0,0,.75);
    cursor: pointer;
}

.cockpit-navigator-body {
    position: relative;
    width: 252px; /*initial size*/
    height: calc(100% - 104px);
    background-color: #F8F8F8;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    resize: horizontal;
}

.cockpit-navigator-dropdown,
.cockpit-navigator-body {
    min-width: 255px;
    max-width: 500px;
}

.cockpit-navigator-dropdown.expanded,
.cockpit-navigator-dropdown.expanded .cockpit-navigator-body {
    width: 504px;
}

.cockpit-navigator-item {
    cursor: pointer;
    position: relative;
    width: 93px;
    padding: 5px;
    text-align: center;
    margin: 8px;
    border: 1px solid transparent;
    border-radius: 4px;
    display: inline-block;
    color: #333;
    transition: background-color, box-shadow 0.2s ease-out;
}

.cockpit-navigator-item:hover {
    background-color: rgba(0,0,0,0.02);
    border-color: rgba(0,0,0,0.1);
}

.cockpit-navigator-item.selected{
    color: #3558EF;
    border-color: transparent !important;
    background-color: #fff !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}

.cockpit-navigator-item .cockpit-navigator-item-icon {
    font-size: 38px;
    padding: 10px 5px;
}

.cockpit-navigator-item .cockpit-navigator-favorite-icon {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 16px;
    color: transparent;
    margin: 3px;
    padding: 3px;
    border-radius: 100%;
}

.cockpit-navigator-item.favorite .cockpit-navigator-favorite-icon {
    color: #3558EF;
}

.cockpit-navigator-item:not(.favorite):hover .cockpit-navigator-favorite-icon {
    color: #555;
}

.cockpit-navigator-item .cockpit-navigator-favorite-icon:hover {
    background: #e6e6e6;
}

.cockpit-navigator-item:not(.selected) .cockpit-navigator-item-icon {
    color: #666;
}

.cockpit-navigator-item .cockpit-navigator-item-description {
    font-size: 11px;
    font-weight: 500;
}

.cockpit-navigator-item .cockpit-data {
    height: 76px;
    padding-top: 10px;
}

.cockpit-navigator-item .cockpit-data > * {
    padding: 5px;
    display: block;
}

.cockpit-navigator-item .cockpit-data .cockpit-navigator-item-description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/*list view*/


.cockpit-dropdown-popper.navigator-list .cockpit-navigator-item {
    width: 208px;
    text-align: left;
    padding: 5px 12px;
    margin: 8px 8px 0;
}

.cockpit-dropdown-popper.navigator-list .cockpit-navigator-item .cockpit-data {
    display: inline-flex;
    align-items: center;
    padding: 0;
    height: unset;
}

.cockpit-dropdown-popper.navigator-list .cockpit-navigator-item .cockpit-navigator-item-icon {
    font-size: 20px;
    width: 20px;
    padding-left: 0;
}

.cockpit-dropdown-popper.navigator-list .cockpit-navigator-item .cockpit-navigator-item-description {
    font-size: 12px;
    width: 164px;
}

.cockpit-dropdown-popper.navigator-list .cockpit-navigator-item .cockpit-navigator-favorite-icon {
    top: calc(50% - 14px);
}
/*end list view*/


/*sobrescreve BngSearch*/
.cockpit-dropdown-popper .bng-search {
    width: calc(100% - 60px);
}

.cockpit-dropdown-popper .change-view-cockpit {
    border-radius: 100%;
    padding: 3px;
    cursor: pointer;
}

.cockpit-dropdown-popper .change-view-cockpit:hover {
    background-color: #eee;
}

.cockpit-dropdown-popper .cockpit-dropdown-popper-action.link {
    text-align: center;
}

.cockpit-dropdown-popper .cockpit-dropdown-popper-action.link a {
    display: block;
    width: 100%;
}

.cockpit-dropdown-popper-action.link.disabled{
    pointer-events: none;
}