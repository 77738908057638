.addButton {
  padding: 12px !important;
}

.rowSpan {
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.captionContainer {
  max-width: 500px;
  display: flex;
  align-items: center;
}

.captionIcon {
  margin-right: 20px;
  color: #005dff;
}

.captionIcon :global(i) {
  font-size: 28px;
}

.deliveryMethodIcon {
  margin-right: 3px;
}

.deliveryMethodIcon :global(i) {
  font-size: 16px;
}

.frequencyHelpWrapper {
  display: flex;
  align-items: center;
}

.labelContainer {
  height: 18px;
  border-radius: 12px;
  padding: 2px 8px 2px 8px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start;
  display: table-cell;
  max-width: 200px;
}

.labelContainer.responsible {
  background-color: #337dff;
}

.labelContainer.active {
  background-color: #00a355;
}

.labelContainer.inactive {
  background-color: #f98900;
}

.actionsDropdownPopper {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.195822);
  width: 300px;
  display: block;
  margin: 5px 0;
  border: 1px;
  border-radius: 4px;
  text-align: left;
  font-weight: 500 !important;
  max-width: 260px;
}

.rolesDropdown :global(i),
.actionsDropdownPopper :global(i) {
  font-size: 16px;
}

.rolesDropdown :global(span),
.actionsDropdownPopper :global(span) {
  font-size: 14px;
}

.actionButtonWrapper {
  display: flex;
  align-items: center;
  padding: 11px 15px;
  font-weight: 500;
  color: #6e6e6e;
  cursor: pointer;
  line-height: 30px;
}

.actionButtonWrapper :global(hr) {
  color: #e2e2e2;
  margin: 0;
  width: 100%;
}

.actionItem {
  max-height: 25px;
}

.actionButtonWrapper {
  background: #efefef;
}

.actionButtonWrapper :global(i) {
  padding: 0 8px 0 5px;
}
