.NavPanel {
  background: #ffffff;
  border-right: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 300px;
}

.AccountSelect {
  align-items: center;
  background: #f6f6f6;
  border-top: 1px solid #e4e4e4;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  height: 60px;
  padding: 6px;
}

.accountName {
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: center;
}

.accountTabs {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  width: 100%;
}

.tabItem,
.tabChildren {
  align-items: center;
  color: #555555;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  justify-content: space-between;
  width: 100%;
}

.tabItem {
  border-left: 5px solid transparent;
  width: calc(100% - 5px);
}

.tabItem:hover,
.tabChildren:hover {
  background: #dce9f8;
}

.selectedTab {
  border-left-color: var(--blue-default);
}

.tabItem.disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.tabTitle {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  padding: 10px 8px 10px 24px;
}

.tabItem .tabIcon {
  color: var(--blue-default);
}

.tabItem :global(.BngIconButton) {
  margin-right: 20px;
}

.tabContent {
  height: 100%;
  overflow-y: auto;
  width: 100%;
}

.tabChildren.lastChild {
  border-bottom: 1px solid #e4e4e4;
}

.tabChildren span {
  border-left: 1px dashed #d1d1d1;
  margin-left: 42px;
  padding: 10px 0 10px 16px;
}

.tabChildren.notVisible {
  display: none;
}

.tabItem:global(.disabled) {
  color: #c2c1c1 !important;
  background-color: unset !important;
}

.tabItem:global(.disabled) i {
  color: #c2c1c1 !important;
}

.tabItem:global(.disabled):hover {
  cursor: not-allowed;
}

.accountsSpinner svg {
  width: 70px;
  height: 70px;
}

.soonTag {
  background: transparent;
  border: 1px solid #269c59;
  color: #269c59;
  font-size: 10px;
  margin-right: 5px;
  padding: 1px 5px;
  white-space: nowrap;
}
