.NoteView .Content {
    height: 350px;
}

.NoteView .Content .Messages {
    padding: 20px 16px;
}

.NoteView .Content .Messages .MessagesGroup {
    margin-bottom: 10px;
}

.NoteView .Content .Messages .MessagesGroup:last-child {
    margin-bottom: 0;
}

.NoteView .Content .Messages .MessagesGroup .TimeDiv {
    text-align: center;
    margin: 20px 0;
    height: 30px;
    line-height: 30px;
}

.NoteView .Content .Messages .MessagesGroup:first-child .TimeDiv {
    margin-top: 0;
}

.NoteView .Content .Messages .MessagesGroup .TimeDiv time {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 10px 0;
}

.NoteView .Content .Messages .MessagesGroup .TimeDiv hr {
    margin-top: 0;
}

.NoteView .Panel.Footer {
    padding: 8px 12px;
    max-height: 80px;
}

.NoteView .Footer form {
    display: flex;
}

.NoteView .Footer .Actions {
    display: inline-flex;
}

.NoteView .Footer .Actions button {
    height: 40px;
    margin: auto 10px auto;
}

.NoteView .Footer .Actions button:last-child {
    margin-right: 0;
}

.NoteView .MessageInput .BngMention__input::placeholder {
    font-size: 14px;
    font-weight: 500;
}

.NoteView .MessageInput {
    flex-grow: 2;
    border: none;
    margin: 0 10px 0 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    color: inherit;
}

.NoteView .MessageInput .BngMention__highlighter {
    padding: 10px 6px;
    line-height: 18px;
}

.NoteView .MessageInput .BngMention__input {
    border: none;
    margin: auto 10px auto 0;
    font-size: 14px;
    font-weight: 500;
    color: inherit;
    line-height: 18px;
    max-height: 60px;
    padding: 10px 6px;
    overflow: auto !important;
}

.NoteViewPopper {
    z-index: 101;
}

.NoteViewPopper.NoteViewPopperRight {
    margin-left: -520px;
}

/******************************************************************
 ChatMessage
******************************************************************/

.ChatMessage {
    display: flex;
    flex-direction: row;
    margin-bottom: 35px;
    position: relative;
}

.ChatMessage.invert {
    flex-direction: row-reverse;
}

.ChatMessage:last-child {
    margin-bottom: 0;
}

.ChatMessage .Message .Name {
    margin-bottom: 10px;
}

.ChatMessage .Message {
    background-color: #005DFF;
    width: 100%;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 10px 0;
    margin-left: 15px;
    word-break: break-word;
}

.ChatMessage.invert .Message {
    background-color: #E3E5E9;
    color: #292C31;
    margin-right: 15px;
    margin-left: 0;
}

.ChatMessage .Avatar {
    height: 32px;
    width: 32px;
    min-height: 32px;
    min-width: 32px;
    border: 2px solid #CED0D5;
    border-radius: 100%;
    overflow: hidden;
}


.ChatMessage .Avatar > * {
    height: 32px;
    width: 32px;
}

.ChatMessage .Arrow {
    height: 12px;
    width: 12px;
    transform: rotate(315deg);
    border-radius: 2px;
    background-color: #005DFF;
    position: absolute;
    left: 45px;
    top: 9px;
}

.ChatMessage.invert .Arrow {
    background-color: #E3E5E9;
    left: unset;
    right: 45px;
}

.ChatMessage .Message .Details {
    display: inline-flex;
    float: right;
}

.ChatMessage .Message .Details .Delete{
    font-size: 17px;
    visibility: hidden;
    cursor: pointer;
}

.ChatMessage.invert .Message:hover .Details .Delete {
    visibility: visible;
}

.ChatMessage.invert .Message .Details .Delete:hover {
    color: #E23D3D;
}

.ChatMessage .Message time {
    display: block;
    text-align: right;
    font-size: 10px;
    padding-left: 6px;
}


/******************************************************************
 ChatMessage
******************************************************************/

.UserSelectionMenuParent {
    z-index: 5;
    width: 340px;
    height: 300px;
    box-shadow: inset 0 0 0 0 #B5B5B5, 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #F7F8FA;
}