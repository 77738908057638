.BngNavHeader {
    background-color: #FFF;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BngNavHeader-Icon {
    font-size: 18px;
    margin-right: 6px;
    margin-bottom: 1px;
}

.BngNavHeader-Title {
    color: #005DFF;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.BngNavHeader-Item {
    color: #005dff;
    font-size: 15px;
}

.BngNavHeader-Item:last-child {
    color: #000;
}

.BngNavHeader-Back {
    color: #005DFF;
    cursor: pointer;
}