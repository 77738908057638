.bs-dialog {
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.bs-dialog.maximized,
.bs-dialog.maximized.modal.fade.in {
    width: 100vw;
    height: 100vh;
    margin: 0;
    left: 0;
    top: 0;
}

.bs-dialog .widget-header,
.DialogJsf .widget-header {
    background: #ffffff;
    display: flex;
    min-height: 25px;
}

.bs-dialog.maximized .modal-dialog,
.bs-dialog.maximized .modal-content,
.bs-dialog.maximized .dialog-body {
    height: 100%;
}

.bs-dialog .modal-body {
    padding: 0;
    max-height: none;
}

.bs-dialog .widget-box {
    margin: 0;
}

.bs-dialog.lg,
.bs-dialog.large {
    width: 850px;
    margin-left: -425px;
}

.bs-dialog.small {
    width: 450px;
    top: 30%;
    margin-left: -210px;
}

.bs-dialog.xlg,
.bs-dialog.xlarge {
    width: 1024px;
    margin-left: -512px;
}

.bs-dialog .widget-toolbar button {
    margin-right: 5px;
}

.bs-dialog .widget-toolbar button:last-child {
    margin-right: 0;
}

.bs-dialog hr {
    margin: 10px 0;
}

.bs-dialog .widget-body {
    cursor: default;
    background: #ffffff;
    padding: 12px 10px 12px 10px;
}

.widget-body.content-full-width {
    padding: 0 !important;
}

.widget-body.content-full-width .widget-main {
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding-top: 0 !important;
}

.modal.olap-monitor {
    z-index: 321051 !important;
}

.modal-backdrop {
    z-index: 321050 !important;
    background-color: #c1c1c1 !important;
}

.bs-dialog .widget-toolbar {
    display: inline-flex;
    padding: 0 10px;
    position: absolute;
    right: 10px;
    top: 17px;
}

.bs-dialog .widget-header {
    padding: 5px 0 0 20px;
    border-bottom: 0;
}

.modal-backdrop, .modal-backdrop.fade.in {
    opacity: .5;
    filter: alpha(opacity=50) !important;
}

.modal.filter-selection-dialog {
    border: 0;
    border-radius: 4px;
}

/*.filter-selection-dialog.modal-content .modal-header {
    padding: 0 14px;
    border-bottom: 0;
}

.filter-selection-dialog.modal-content .modal-header button {
    margin-right: 14px;
}*/

.filter-selection-dialog.modal-content .modal-footer {
    background: #fff;
    border-top: 0;
}

.modal.filter-selection-dialog {
    border: 0;
    border-radius: 4px;
}

.bs-dialog .content-full-width .dialog-body {
    border-radius: 0;
}

.bs-dialog.ui-draggable.DialogNewLayout .widget-body {
    background: #F6F6F6;
}

.DialogNewLayout .widget-header {
    box-shadow: inset 0 -1px 0 0 #E2E2E2;
}

.bs-dialog .widget-header .DialogIcon {
    color: #005DFF;
    display: flex;
    align-items: center;
    font-size: 25px;
    padding-right: 10px;
    padding-top: 5px;
}

.DialogNewLayout .DialogFooter {
    box-shadow: inset 0 1px 0 0 #E2E2E2;
    padding: 22px;
    background-color: white;
}

.DialogNewLayout .DialogFooter .bng-button {
    margin: 0 20px 0 0;
}

.DialogNewLayout .DialogFooter .bng-button .material-icons {
    vertical-align: bottom;
}

.DialogNewLayout .DialogFooter .bng-button:last-child {
    margin: 0;
}

.bs-dialog.ui-draggable.DialogNewLayout .widget-body,
.bs-dialog.ui-draggable.DialogNewLayout .widget-main {
    padding: 0;
    border: none;
}

.bs-dialog.ui-draggable.DialogNewLayout .DialogBody {
    padding: 22px !important;
}

.DialogNewLayout .DialogBody .navigation-tab-link.active {
    background: white;
    border-bottom: none;
}

.bs-dialog.VerticalCenter {
    top: 50%;
    /* Fix for blurry text, see: https://stackoverflow.com/a/50412034 */
    transform: translateY(calc(-50% + 0.5px));
}

.bs-dialog.VerticalCenter.Dragged {
    transform: unset;
}

.PermissionDialog .DialogBody {
    padding: 22px 22px 0px 22px !important;
}

.bs-dialog .btnnmodalbtclose {
    height: 24px;
}

.bs-dialog.minimized {
    height: 52px;
    overflow: hidden;
}

.bs-dialog.minimized,
.bs-dialog.minimized.modal.fade.in {
    height: 52px;
    right: 1% !important;
    bottom: 1%;
}

.bs-dialog.minimized .modal-dialog,
.bs-dialog.minimized .modal-content,
.bs-dialog.minimized .dialog-body {
    height: 100%;
}

.bs-dialog .btnmodalminimize {
    color: #585859;
}

.bs-dialog .widget-toolbar a.disabled {
    pointer-events: none;
    opacity: 0.5;
}