.CancelConfirmationDialog {
  top: 30% !important;
}

.CancelConfirmationDialog .widget-header {
  height: 25px !important;
}

.bng-button.cancel-confirmation-dialog-button.save.enable {
  background-color: #f44336 !important;
  color: #fff !important;
  border-color: #f44336 !important;
  font-weight: 700;
  width: 61px;
}

.bng-button.cancel-confirmation-dialog-button.save.enable:hover {
  background-color: #e53935 !important;
  color: #fff !important;
  border-color: #e53935 !important;
}

.bng-button.cancel-confirmation-dialog-button.cancel.enable {
  border: 0 !important;
  font-weight: 700;
}

.bng-button.cancel-confirmation-dialog-button.cancel.enable:hover {
  border: 0 !important;
  background-color: #fff !important;
}

p.cancel-confirmation-description {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #555555;
}
