.inputIconContainer {
  display: inline-flex;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.inputIconContainer:hover,
.inputIconContainer:focus {
  border-color: #9d9d9d;
}

.inputIconContainer .inputClassname {
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  border: none;
  border-radius: 8px 0 0 8px;
}

/*Chromium browser fix so it doesn't show two icons when input is time type*/
.inputIconContainer .inputClassname::-webkit-calendar-picker-indicator {
  display: none;
  background: none;
}

.inputIconContainer .iconContainer {
  display: inline-flex;
  background-color: #f6f6f6;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #ccc;
  border-radius: 0 8px 8px 0;
  padding: 0 8px;
}

.icon {
  color: #555;
  font-weight: 400;
  font-size: 18px;
}

:global(.control-group.error) .iconContainer {
  border: none;
}

:global(.control-group.error) .inputIconContainer :global(.BngInput) {
  border-right: inherit;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

:global(.control-group.error) .inputIconContainer {
  border-color: #f09784;
  box-shadow: none;
}

:global(.control-group.error) .inputIconContainer .inputClassname:focus {
  box-shadow: none;
}

:global(.control-group.error) .inputIconContainer .iconContainer {
  border-left-color: #f09784;
}
