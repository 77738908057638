.main {
}

.main :global(.DialogBody) {
  height: 300px;
  overflow: auto;
  position: relative;
  margin-bottom: 20px;
}

.main :global(.BaseTableStep .BngTable .Icon) {
  color: #005dff;
  font-size: 20px;
}

.main :global(.BaseTableStep .BngTable .BngTableTh) {
  z-index: 15;
}

.main :global(.BaseTableStep .BngTable .BngEmpty) {
  margin-top: 20px;
}

.main :global(.TableContainer) {
  height: 235px;
  overflow: auto;
  position: relative;
}

.main :global(.AppInfo) {
  position: relative;
}

.main :global(.AppInfo .BngCheckbox) {
  position: absolute;
  right: -2px;
  top: 8px;
}

.main :global(.AppInfo b) {
  font-size: 16px;
  color: #005dff;
  opacity: 0.8;
}

.main :global(.AppInfo.Selectable) {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  cursor: pointer;
  transition: background-color 500ms;
  width: calc(33% - 50px);
  height: 80px;
  overflow: hidden;
}

.main :global(.AppInfo.Selectable:hover) {
  background-color: rgba(63, 81, 181, 0.03);
  opacity: 1;
}

.main :global(.AppInfo.Selected) {
  background-color: rgba(0, 93, 255, 0.04);
  border: 1px solid #005dff;
  opacity: 1;
}

.dialogUpdatingApps {
}

.dialogUpdatingApps :global(.DialogFooter) {
  display: none;
}

.dialogUpdatingApps :global(.DialogBody) {
  height: 410px;
}

.onInfoWizardStep {
}

.onInfoWizardStep :global(.DialogBody) {
  height: 370px;
}

.updatedStep {
}

.updatedStep :global(.TableContainer) {
  height: 220px;
}
