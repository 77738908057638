.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.lockClock {
  color: #005dff;
  font-size: 42px !important;
}

.table {
  width: 650px;
  max-height: 250px;
  overflow-y: auto;
}

.otherProject {
  font-size: 14px;
  font-weight: 500;
  color: #555555;
}

.inputs {
  display: flex;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
}

.selectSearch {
  width: 300px;
}

.btnDanger {
  background: #e9443b;
}

.day {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
}

.releasedDays {
  background-color: #005dff;
  color: #fff;
}

.restrictionDays {
  background-color: #d1d1d1;
  color: #fff;
}

.days :global(.div-information .item-slide-name) > div {
  display: flex;
  align-items: center;
}

.submitButton {
  margin-bottom: 5px;
}
