.selectSearchPopper {
}

.selectSearchPopper :global(.SearchContainer .bng-search) {
  height: 44px !important;
}

.selectSearch {
  box-shadow: none;
  background-color: inherit;
}

.selectSearchPopper :global(.BngSelectDropdown),
.selectSearchPopper :global(.BngSelectSearch) {
  border-radius: 8px;
  margin-top: 2px;
}

.selectSearchPopper :global(.BngSelectDropdown) {
  border: 1px solid #d4d4d4;
}

.userItemTag {
  background-color: #005dff;
  width: fit-content;
  color: #fff;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  user-select: none;
}

.userItemTag .userItemTagCloseButton,
.userItemTag .userDeliveryDropdownBtn {
  padding: 0;
  color: #fff;
  transition: text-shadow ease-in-out 0.2s;
  display: flex;
  height: 100%;
}

.userItemTag .userItemTagCloseButton:hover,
.userItemTag .userDeliveryDropdownBtn:hover {
  text-shadow: 0 0 5px #ffffff;
  background-color: inherit;
}

.userItemTag .userItemTagCloseButton :global(.Icon),
.userItemTag .userItemTagInfoIcon,
.userItemTag .userDeliveryDropdownBtn :global(i) {
  font-size: 18px;
}

.dropdownOpt {
  box-shadow: none !important;
}

.dropdownOpt:hover:not(:global(.disabled)) {
  background: #e6efff !important;
  color: #005dff !important;
}

.dropdownOptSelected :global(.Icon) {
  color: #005dff;
}

.dropdownOpt :global(.Icon) {
  font-size: 20px;
}

.separatorOpt {
  border-radius: 12px 12px 0 0;
}
