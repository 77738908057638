.BngListField {
  display: flex;
  flex-direction: column;
  padding-bottom: 18px;
}

.fieldLabel {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}

.fieldWrapper {
  align-items: baseline;
  display: inline-flex;
}

.addItemButton {
  color: #005dff;
  padding-left: 0;
  padding-bottom: 0;
}

.itemsWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.BngListField.hasError .fieldLabel,
.BngListField.hasError .addItemButton,
.BngListField.hasError .errorMessage {
  color: #d16e6c;
}