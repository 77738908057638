.UpdateInfo {
  width: 100%;
}

.UpdateInfo :first-child {
  display: flex;
  flex-direction: row;
}

.UpdateInfo :first-child > b {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 139px;
}
