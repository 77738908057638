.ProjectionDetailsDialog {
  height: fit-content;
  min-height: 480px;
  max-height: 90vh;
  overflow-y: scroll;
}

.ProjectionDetailsDialog :global(.widget-body) {
  min-height: 428px;
  overflow: scroll;
}

.paidTag {
  background: #33b577;
}

.openTag {
  background: #e02523;
}

.paidTag,
.openTag {
  gap: 1px;
  width: fit-content;
}

.itemPricing,
.itemStatus {
  white-space: nowrap;
}

.totalPricingWrapper {
  color: #333333;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding: 12px;
}

.totalPricingLabel {
  font-weight: bold;
}

.totalPricing {
  font-weight: 500;
}

.buyerInfo {
  margin-bottom: 15px;
}

.buyerTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.projectionItemsTableWrapper {
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  overflow: hidden;
  max-height: 265px;
  overflow-y: scroll;
}

.buyerInfoRow {
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  padding: 10px 25px;
  width: 100%;
  gap: 8px;
}

.infoItem {
  display: inline-flex;
  gap: 5px;
}

.dialogBody {
  min-height: 275px;
}

.projectionItemsTableWrapper table {
  color: #333333;
}
