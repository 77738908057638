.ProjectMenuButton.dropdown-menu {
    width: 400px;
}

li.project-li {
    border-left: 4px solid #575BD8;
}

li.project-li.theme-skin-1 {
    border-left: 4px solid #404040;
}

li.project-li.theme-skin-2 {
    border-left: 4px solid #e89702;
}

li.project-li.theme-skin-3 {
    border-left: 4px solid #5d5d5d;
}

li.project-li.theme-skin-4 {
    border-left: 4px solid #d23d3d;
}

li.project-li.theme-skin-5 {
    border-left: 4px solid #269c59;
}

li.project-li.theme-skin-6 {
    border-left: 4px solid #AAAAAA;
}

li.project-li.theme-skin-7 {
    border-left: 4px solid #2C6AA0;
}

.ProjectMenuButton .new-project-btn {
    margin-bottom: 5px;
}

.ProjectMenuButton .new-project-btn-container {
    border-bottom: 1px solid #e4ecf3;
}

.ProjectMenuButton .dropdown-navbar > li {
    background-color: white;
}

.item-project-menu {
    width: inherit !important;
}

.item-project-link {
    width: fit-content !important;
    margin-right: 10px;
    max-width: 305px;
}

button.new-project-btn {
    background-color: var(--blue-default) !important;
}