.BngBigTable__themeSelector {
    padding: 15px 5px;
    background-color: #fff;
}

.BngBigTable__theme {
    margin-bottom: 20px;
}

.BngBigTable__themeSelectorTable {
    cursor: pointer;
    flex: 0 0 83px;
    height: 60px;
    margin-bottom: 10px;
}

.BngBigTable__themeSelectorTable.Dummy {
    cursor: none;
    margin-bottom: 0;
}

.BngBigTable__themeSelectorContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.BngBigTable__tables {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.BngBigTable__pages {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.BngBigTable_themeSelectorPagination {
    flex: 24px 0 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
}

.BngBigTable__pageDot {
    border-radius: 6px;
    height: 6px;
    width: 6px;
    background-color: #ccc;
    margin: 0 3px;
}

.BngBigTable__pageDot--active {
    background-color: #666;
}