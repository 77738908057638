.moreItemsIndicator {
  background-color: #005dff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 17px;
  color: white;
  height: 19px;
  display: flex;
  align-items: center;
  padding: 0 5px 0 4px;
  white-space: nowrap;
}
