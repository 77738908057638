.main {
  width: 100%;
  background: rgba(0, 93, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 12px 20px;
  box-sizing: border-box;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.main a {
  color: #285fff;
}