.presentation-item-container {
    display: flex;
}

.presentation-item-container .sched-name .Icon {
    margin-left: 4px;
    margin-top: 2px;
    font-size: 20px;
}

.presentation-buttons {
    text-align: right;
    width: 40px;
    padding: 1px 3px;
}

.PresentationMenuButton.dropdown-menu {
    width: 360px;
}

.presentation-item {
    width: 312px;
    float: left;
    margin: 7px 0 0 7px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PresentationMenuButton button.btn-only-icon i.material-icons {
    font-size: 16px !important;
}

.PresentationMenuButton .item-link i.material-icons {
    font-size: 23px;
    vertical-align: top;
}

.PresentationMenuButton.dropdown-menu > li > a:hover {
    background: #fff !important;
}