.AddonsPageBlocker {
  height: calc(100vh - 84px);
  left: 0;
  overflow: scroll;
  position: absolute;
}

.AddonsPageBackground {
  background: #f6f6f6;
  padding: 30px 10%;
}

.AddonsPageBody {
  background: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.AddonsPageBody p {
  line-height: unset;
  margin-bottom: 18px;
}

.bodyHeadingInfo {
  display: inline-flex;
  gap: 40px;
  padding: 20px 30px 0 30px;
  border-bottom: 1px solid #e4e4e4;
}

.bodyContent {
  padding: 18px 30px;
}

.headingInfoWrapper {
  max-width: 100%;
}

.headingImageWrapper {
  margin-right: 95px;
  object-fit: contain;
  width: 215px;
}

.headingNewLabel {
  background: #e6efff;
  border-color: #005dff;
  border-radius: 15px;
  color: #005dff;
  font-size: 12px;
  padding: 5px 10px;
}

.AddonsPageBody :global(.bng-search) {
  background: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.18) !important;
  border-radius: 4px;
  padding-left: 5px;
}

.addonWrapper {
  display: inline-flex;
  flex-flow: row wrap;
  gap: 10px;
  width: 100%;
}

.addonItem {
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 15px;
  min-width: calc((100% - 116px) / 3);
  transition: transform 0.2s;
  width: calc((100% - 116px) / 3);
}

.addonItem:hover:not(.addonEnabled) {
  background-color: #eee;
  cursor: pointer;
  transform: scale(1.02);
}

.groupRender h5 {
  margin-bottom: 8px;
}

.groupRender {
  margin-bottom: 18px;
}

.addonItemHeader {
  align-items: center;
  display: inline-flex;
  margin-bottom: 8px;
}

.AlertContainer {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #fff;
  color: var(--blue-default);
  position: relative;
  float: right;
  bottom: 24px;
  left: 22px;
}

.AlertContainer :global(i) {
  font-size: 20px;
}

@media (max-width: 800px) {
  .headingDescription,
  .headingImageWrapper {
    display: none;
  }
  .addonItem {
    width: calc((100% - 116px) / 2);
  }
}
