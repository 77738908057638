.BngSwitch {
    --bng-switch-height: 18px;
    --bng-switch-width: 36px;
}

.BngSwitch .switch-input {
    display: none;
}

.BngSwitch .switch-label:before, .switch-label:after {
    content: "" !important;
    position: absolute;
    margin: 0;
    outline: 0;
    top: 50%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    cursor: pointer;
}

.BngSwitch .switch-label:before {
    left: 1px;
    padding: 0 !important;
    width: var(--bng-switch-width, 36px) !important;
    border: 0 !important;
    height: var(--bng-switch-height, 18px) !important;
    background-color: #9E9E9E !important;
    border-radius: var(--bng-switch-height, 18px) !important;
}

.BngSwitch .switch-label:after {
    left: 4px;
    width: calc(var(--bng-switch-height, 18px) - 5px);
    height: calc(var(--bng-switch-height, 18px) - 5px);
    background-color: #FAFAFA;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
}

.BngSwitch .switch-label .toggle--on {
    display: none;
}

.BngSwitch .switch-label .toggle--off {
    display: inline-block;
}

.BngSwitch .switch-input:checked + .switch-label:before {
    background-color: #005dff !important;
}

.BngSwitch .switch-input:checked + .switch-label:after {
    background-color: #fafafa;
    -ms-transform: translate(calc(var(--bng-switch-width, 36px) - 1px - var(--bng-switch-height, 18px)), -50%);
    -webkit-transform: translate(calc(var(--bng-switch-width, 36px) - 1px - var(--bng-switch-height, 18px)), -50%);
    transform: translate(calc(var(--bng-switch-width, 36px) - 1px - var(--bng-switch-height, 18px)), -50%);
}

.BngSwitch .switch-input:checked + .switch-label .toggle--on {
    display: inline-block;
}

.BngSwitch .switch-input:checked + .switch-label .toggle--off {
    display: none;
}

.BngSwitch.vertical-title {
    flex-direction: column;
}

.BngSwitch.vertical-title.features {
    flex-direction: column;
    flex-grow: 1;
    margin-right: 80px;
    align-items: end;
    word-break: normal;
}

.BngSwitch {
    display: flex;
    align-items: center;
}

.BngSwitch .switch-label {
    width: var(--bng-switch-width, 36px);
    height: calc(var(--bng-switch-height, 18px) * 2);
}

.BngSwitch .BngSwitchLabel {
    margin-left: 10px;
}

.BngSwitch.vertical-title .BngSwitchLabel {
    margin-left: 0;
}