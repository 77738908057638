.featureWrapper {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  height: 121px;
  display: flex;
}

.featureWrapper.expanded {
  height: fit-content;
}

.featureContainer {
  display: flex;
  padding: 1.35vh 1.5vw;
  gap: 1.5vw;
  width: 100%;
}

.expanded .featureContainer {
  align-items: start;
}

.headerContainer {
  display: flex;
  gap: 1.5vw;
}

.votingCounterContainer {
  height: 97px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.votingCounterContainer :global(span) {
  font-size: 18px;
  font-weight: 700;
}

.votingCounterContainer :global(i) {
  font-size: 24px;
}

.imageContainer {
  width: 167px;
  height: 94px;
  flex-shrink: 0;
  border-radius: 8px;
}

.featureTextContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.featureCategory {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.titleHeader {
  display: flex;
  gap: 8px;
}

.subTitleContainer {
  display: flex;
  gap: 4px;
}

.subTitle {
  font-size: 10px;
  color: #727272;
}

.subTitle.dot {
  font-size: 15px;
}

.featureTitle {
  color: var(--dark-gray-title);
  font-size: 16px;
  font-weight: 700;
}

.statusTag {
  width: fit-content;
}

.statusTag :global(span) {
  margin: 4px 0;
}

.featureDesc {
  color: var(--gray-description);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.seeMoreLabel,
.seeLessLabel {
  color: #005dff;
  font-size: 12px;
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
  margin-left: 5px;
}

.ONGOING {
  background-color: #f89406;
}

.APPROVED {
  background-color: #00a355;
}

.upvoteArrow :global(i),
.thumbsUp.upvote :global(i) {
  background: #00a355;
  color: white;
  border-radius: 100%;
}

.downvoteArrow :global(i) {
  background: #e23d3d;
  color: white;
  border-radius: 100%;
}

.upvote {
  color: #00a355;
}

.thumbsUp :global(i) {
  font-size: 18px !important;
  padding: 5px;
}

.downvote {
  color: #e23d3d;
}
