.AccFeaturesTabBlocker {
  height: 100%;
}

.AccFeaturesTab {
  align-items: center;
  display: flex;
  justify-content: center;
}

.featuresForm {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 1240px;
  padding: 32px;
}

.featureItem {
  align-items: center;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  display: inline-flex;
  margin: 10px 0;
  padding: 10px 0;
  width: calc(50% - 7px);
}

.featureIcon {
  background-color: #419441;
  border-radius: 100%;
  color: #fff;
  font-size: 32px;
  margin: 0 22px;
  padding: 15px;
}

.featureDetails {
  display: inline-grid;
  margin-right: 40px;
}

.featureTitle {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.featureDescription {
  color: #888;
  font-size: 13px;
}

.featureSwitch {
  margin-left: auto;
  margin-right: 15px;
}
