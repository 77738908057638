.circle-icon-user {
    border-radius: 100%;
    border: 2px solid #7b7b7c;
    width: 9px;
    height: 10px;
    background-color: #7b7b7c;
}

.PublisherMenuButton.dropdown-menu {
    width: 340px;
}

.PublisherMenuButton.dropdown-menu a.item-link .item-container {
    height: 20px;
    line-height: 20px;
}

.PublisherMenuButton.dropdown-menu a.item-link .action-btn {
    height: 18px;
    width: 18px;
    padding: 0 0 0 2px;
    margin: 0 0 0 3px;
}

.PublisherMenuButton.dropdown-menu .ph-user-info {
    display: block;
    width: 170px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PublisherMenuButton.dropdown-menu .ph-user-info .ph-name {
    font-size: 10px;
    margin-left: 3px;
}

.PublisherMenuButton.dropdown-menu > li:hover {
    background: #fff !important;
}