.optionsTabWrapper {
    width: calc(100% - 48px);
    height: 300px;
    background-color: #f6f6f6;
}

.optionsTypeButtons {
    height: 44px;
    margin-bottom: 24px;
}

.exportDropdownWrapper {
    width: 352px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    margin-top: 8px;
}

.exportDropdownWrapper .exportDropdownHeader {
    padding: 14px;
}

.exportDropdownWrapper .exportDropdownHeader :global(span) {
    font-weight: 500;
    font-size: 14px;
}

.exportDropdownBody {
    max-height: 205px;
    overflow-y: scroll;
    position: relative;
}

.exportBngEmpty {
    position: unset;
    transform: unset;
    margin: 0;
    background-color: #f4f4f4;
    padding-top: 8px;
}

.exportDropdownObjects {
    border-top: 1px solid #E5E5E5;
    padding: 8px 14px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
}

.exportDropdownObjects:nth-child(odd) {
    background-color: #F6F6F6;
}

.exportDropdownObjectsLabel {
    font-weight: 500;
    font-size: 12px;
    color: #555555;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 10px;
}

.exportDropdownObjects :global(i) {
    font-size: 18px;
    margin-right: 6px;
}

.exportDropdownFooter {
    height: 14px;
    border-top: 1px solid #e5e5e5;
}

.exportToDropdownObjectsSelect {
    margin-left: auto;
}

.exportToDropdownObjectsSelect :global(select) {
    width: 86px;
    margin-bottom: 0;
    height: 22px;
    font-size: 12px;
    padding: 3px;
}

.optionsTabWrapper .optionsFieldLabel {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
}

.sendAttachmentsAndLinksContainer {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.sendAttachmentsAndLinksContainer .optionsFieldLabel.sendAttachmentsAndLinksTip {
    margin-bottom: 0;
}

.sendAttachmentsAndLinksContainer :global(i) {
    margin-bottom: 0 !important;
}