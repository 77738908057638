.BngMarkdownEditor {
  position: relative;
}

.BngMarkdownEditor .te-mode-switch-section {
  display: none !important;
}

.BngMarkdownEditor .SizeLimitAlert {
  margin-top: 5px;
  text-align: right;
  color: red;
}
