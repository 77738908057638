.nav-search-new-item .search-item-folder {
    font-size: 9px;
}

.nav-search-new-item .search-item-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 17px;
}

.nav-search-new-item .SearchResultDiv {
    display: flex;
    flex-direction: column;
    width: 572px;
    border-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .5px;
    padding: 8px 14px;
    position: absolute;
    background: #F6F6F6;
    z-index: 99;
    text-align: left;
    gap: 15px;
    height: 400px;
}

.nav-search-new-item .search-results {
    max-height: 400px;
    overflow: auto;
    margin: 0px;
}

.nav-search-new-item .search-result-item {
    display: flex;
    align-items: center;
    background: #F6F6F6;
    height: 50px;
    cursor: pointer;
    gap: 10px;
    padding-left: 10px;
    border-radius: 4px;
}

.nav-search-new-item .search-result-item:hover {
    background: #FFFFFF;
}

.nav-search-new-item .search-item-icon i {
    font-size: 20px;
    color: #005DFF;
}

.nav-search-new-item .objectsButtonsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: flex-start;
}

.nav-search-new-item .object-type-buttons {
    display: flex;
    align-items: center;
    height: 35px;
    width: fit-content;
    padding: 4px 12px 4px 12px;
    background-color: #FFFFFF !important;
    white-space: nowrap;
    color: #7B7B7C;
    border-radius: 4px;
    margin-right: 10px;
    gap: 10px;
    font-weight: 500;
    border: 1px solid #A6A6A7;
    transition: background-color 0.3s, color 0.3s;
}

.nav-search-new-item .is-selected {
    background-color: #1A6DFF !important;
    color: #FFFFFF;
}

.nav-search-new-item .nav-search-input-new-line {
    height: 2px;
    background: #fff;
    margin-top: -10px;
    margin-bottom: 8px;
    position: relative;
    width: calc(100% - 15px);
    opacity: 1;
    transition: opacity .6s ease, transform .6s ease;
    animation: lazy-grow 0.8s ease;
}

@keyframes lazy-grow {
    0% {
        opacity: 0;
        width: 0;
    }
    100% {
        opacity: 1;
        width: calc(100% - 10px);
    }
}

.nav-search-new-item .nav-search-button,
.nav-search-new-item .nav-search-input-box {
    margin-right: 10px;
}

.nav-search-new-item .nav-search-button-on-input-right-box {
    width: 95px;
    padding: 1px 5px 3px 5px;
    border: 1px solid #fff;
    position: absolute;
    top: 13px;
    color: #fff;
    right: 0;
    border-radius: 4px;
    line-height: 14px;
    cursor: pointer;
}

.nav-search-new-item .nav-search-input-box {
    overflow: hidden;
    height: 48px;
    opacity: 1;
    transition: opacity .6s ease, transform .6s ease;
}

.nav-search-new-item .nav-search-new input[type="text"] {
    border-radius: 0;
    color: rgba(255, 255, 255, .75);
    background: transparent;
    border: 0 transparent;
    width: 100%;
    margin-top: 9px;
    height: 32px;
    padding: 0 30px;
}

.nav-search-new-item .nav-search-new input[type="text"]::placeholder {
    color: rgba(255, 255, 255, .75);
}

.nav-search-new-item .nav-search-new {
    position: relative;
}

.nav-search-new-item,
.nav-search-new-item .nav-search-new {
    z-index: 99;
    height: 50px;
}

.nav-search-new-item .nav-search-new,
.nav-search-new-item .nav-search-new .nav-search-button {
    cursor: pointer;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.nav-search-new-item .nav-search-input-box,
.nav-search-new-item .nav-search-button {
    top: 0;
    position: relative;
}

.nav-search-new-item .nav-search-new.expanded {
    cursor: unset;
    display: inline-flex;
}

.nav-search-new-item .hidden-bottom {
    transform: translateY(39px);
    right: 100%;
    opacity: 0;
    z-index: 1;
    position: absolute;
    animation: .6s lazy-hide;
    pointer-events: none;
}

@keyframes lazy-hide {
    0% {
        z-index: 0;
    }

    99% {
        z-index: 0;
    }
    100% {
        z-index: -1;
    }
}

