.AddonInfoBlocker {
  height: calc(100vh - 84px);
  left: 0;
  overflow: scroll;
  position: absolute;
  width: 100%;
}

.AddonInfoHeader {
  background: #0083fb !important;
  box-sizing: border-box;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  max-height: 550px;
  padding: 50px 100px;
  width: 100%;
}

.AddonInfoHeader h3 {
  font-weight: 800;
  font-size: 50px;
  line-height: 95.69%;
  margin: 0;
  text-align: start;
}

.AddonInfoHeader p {
  font-weight: 400;
  font-size: 18px;
  line-height: unset;
  margin: 0;
  text-align: start;
}

.headingImgWrapper {
  display: flex;
  justify-content: center;
  max-width: 650px;
  width: 50%;
}

.headingImg {
  max-width: 350px;
}

.headingVideoWrapper {
  background: linear-gradient(120deg, rgba(35, 41, 214, 1) 0%, rgba(0, 172, 248, 1) 100%);
  width: 80vw !important;
  height: 80vh !important;
  z-index: 10004;
}

.titleWrapper {
  color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 750px;
  justify-content: center;
}

.titleWrapper h1 {
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 8px;
}

.titleWrapper p {
  margin-bottom: 32px;
}

.headerButtonWrapper {
  display: inline-flex;
  gap: 15px;
}

.buttonLink {
  width: fit-content;
}

.buttonStyle {
  border-radius: 4px;
  font-size: 17px;
  font-weight: 700;
  padding: 16px 20px;
  transition: transform 0.2s;
}

.buttonStyle:hover {
  transform: scale(1.02);
}

.primaryButton {
  color: #fff;
  border: none;
}

.primaryButton:hover {
  filter: brightness(110%);
  transform: scale(1.02);
}

.secondaryButton {
  background: #fff !important;
  border: 1px solid #6c6c6c;
  color: #333 !important;
}

.secondaryButton:hover {
  background-color: #eee !important;
}

.secondaryButton[disabled] {
  background-color: #eee !important;
}

.MultiInfoBlock {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.textContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 45%;
  padding: 18px 40px 0 40px;
  text-align: center;
}

.textContent h5 {
  color: #fd7a4e;
  font-weight: 700;
  font-size: 35px;
  line-height: 38px;
  text-align: center;
}

.infoContent {
  box-sizing: border-box;
  display: inline-flex;
  gap: 50px;
  justify-content: center;
  padding: 30px 100px;
  width: 100%;
}

.selectInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 650px;
  width: 50%;
}

.InfoContentCard {
  border-radius: 8px;
  padding: 15px;
  overflow: hidden;
}

.InfoContentCard.expandedCard,
.InfoContentCard:hover {
  background: #f6f6f6;
  cursor: pointer;
}

.InfoContentCard.expandedCard .infoTitleWrapper,
.InfoContentCard:hover .infoTitleWrapper,
.InfoContentCard.expandedCard .contentCardIcon,
.InfoContentCard:hover .contentCardIcon {
  color: #1a6dff;
}

.contentCardDesc {
  font-size: 14px;
  line-height: 0;
  margin-bottom: 0;
  overflow: hidden;
  padding-left: 30px;
}

.descExpanded {
  line-height: 18px;
}

.infoMedia {
  display: flex;
  justify-content: center;
  height: 330px;
  max-width: 650px;
  overflow: hidden;
  position: relative;
  width: 50%;
}

.infoCardImg {
  border-radius: 20px;
  max-height: 330px;
  object-fit: contain;
  opacity: 0;
  position: absolute;
  transition: opacity 1s ease-in-out;
}

.infoCardImg.visibleInfoCardImg {
  opacity: 1;
}

.infoTitleWrapper {
  color: #555555;
  display: inline-flex;
  gap: 5px;
  align-items: center;
}

.contentCardIcon {
  color: #555555;
}

.contentCardTitle {
  font-weight: 700;
  font-size: 14px;
  margin: 0;
}

.priceContent {
  align-items: center;
  background: #f6f6f6;
  border-radius: 0 0 14px 14px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.priceHeader {
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  padding: 40px 100px 20px 100px;
  width: 100%;
}

.priceTextContent {
  display: flex;
  flex-direction: column;
}

.priceTextContent h5 {
  color: #fd7a4e;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
}

.PriceCard {
  border-radius: 14px;
  padding: 4px;
  width: 250px;
}

.priceName {
  display: flex;
  justify-content: center;
  padding: 8px 32px;
}

.priceNameText {
  color: #fff;
  font-weight: 700;
  font-size: 24px;
}

.plansWrapper {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
  padding-bottom: 60px;
  width: 100%;
}

.priceValueWrapper {
  display: inline-flex;
  font-weight: 700;
  gap: 5px;
}

.mainPrice {
  font-size: 48px;
  line-height: 56px;
}

.planInfo {
  color: #888888;
  text-align: center;
  padding: 15px;
}

.pricePrefix {
  align-items: end;
  display: flex;
  padding-bottom: 8px;
}

.priceSufix {
  padding-top: 8px;
}

.selectButtonWrapper {
  border-top: 1px solid #d1d1d1;
  display: flex;
  justify-content: center;
  padding: 12px;
  margin-top: 12px;
  width: 75%;
}

.selectButton {
  background: #ffffff !important;
  border: 1px solid #555555;
  border-radius: 32px;
  color: #333333 !important;
  width: 95%;
}

.selectButton:hover {
  background-color: #eee !important;
}

.selectedButton {
  background: #005dff !important;
  border-color: #005dff !important;
  color: #fff !important;
  cursor: default;
}

.selectedButton:hover {
  background: #005dff !important;
  border-color: #005dff !important;
  transform: none !important;
}

.LinkInfoBlock {
  align-items: start;
  box-sizing: border-box;
  display: inline-flex;
  gap: 30px;
  justify-content: center;
  padding: 50px 100px;
  width: 100%;
}

.linkContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 650px;
  width: 50%;
}

.linkContent h5 {
  color: #fd7a4e;
  font-weight: 700;
  font-size: 35px;
  line-height: 38px;
}

.linkContent p {
  color: #555555;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.linkInfoImg {
  max-height: 400px;
  max-width: 650px;
  object-fit: contain;
  width: 50%;
}

.linkInfoButton {
  background: #ffffff !important;
  border: 1px solid #555555;
  border-radius: 4px;
  color: #333333;
}

.linkInfoButton:hover {
  background-color: #eee !important;
}

.FaqBlock {
  background: #f6f6f6;
  padding: 50px 100px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.FaqBlock h5 {
  color: #fd7a4e;
  font-weight: 700;
  font-size: 35px;
  line-height: 38px;
  text-align: center;
}

.questionsWrapper {
  display: inline-flex;
  flex-flow: row wrap;
  gap: 32px;
  justify-content: center;
  max-width: 2000px;
}

.questionCard {
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  max-width: 430px;
  padding: 24px;
  width: calc((100% - 360px) / 3);
}

.questionTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

.questionAnswer {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.addonPageFooter {
  align-items: center;
  background: #4e4e4e;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
}

.addonPageFooter img {
  height: 35px;
}

.AddonInfoBlockWrapper .Block:nth-child(even) {
  background: #fff;
}

.SinglePriceBlock {
  align-items: center;
  display: inline-flex;
  gap: 32px;
  justify-content: center;
  width: 100%;
}

.priceSmallPrint {
  font-size: 12px;
  position: relative;
  top: -12px;
}

.singlePriceContentWrapper,
.addonStarsWrapper {
  padding: 30px 50px;
  position: relative;
  max-width: 650px;
  width: 50%;
}

.addonStarsWrapper {
  height: fit-content;
  width: fit-content;
}

.addonStarsWrapper svg {
  height: 100%;
}

.singlePriceContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.SinglePriceBlock h5 {
  color: #fd7a4e;
  font-weight: 800;
  font-size: 35px;
  line-height: 38px;
}

.pricingIcon {
  font-size: 102px !important;
  left: 40%;
  position: absolute;
  top: 38%;
}

.singlePriceActivate {
  color: #fff;
  border-radius: 4px;
  width: fit-content;
  border: none;
}

.enabledButton {
  cursor: not-allowed;
}

.enabledButton:hover {
  transform: unset;
}

.LinedMediaBlock {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 50px;
  width: 100%;
}

.LinedMediaBlock h5 {
  color: #fd7a4e;
  font-size: 35px;
  font-weight: 800;
  line-height: 38px;
}

.LinedMediaBlock p,
.linedMediaTitle {
  font-size: 18px;
  font-weight: 600;
}

.LinedMediaBlock p {
  padding-top: 20px;
}

.linedMediaTitle {
  padding: 20px 0;
}

.linedMediaWrapper {
  display: inline-flex;
  gap: 80px;
  justify-content: center;
  width: 100%;
}

.linedMediaContent {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.linedMediaImg {
  border-radius: 12px;
  max-width: 254px;
}

.AddonInfoHeader:global(.Addon-DOMAIN_NAME) {
  padding: 0;
}

:global(.Addon-DOMAIN_NAME) .headingImg {
  max-width: 567px;
  padding-right: 100px;
}

:global(.Addon-DOMAIN_NAME) .titleWrapper {
  padding: 50px 0 50px 100px;
}

.contractButtonWrapper {
  align-items: center;
  display: inline-flex;
  gap: 14px;
}

.quantitySelector {
  display: inline-flex;
}

.quantitySelectButton,
.quantityCounter {
  align-items: center;
  background: #fff;
  border: 1px solid #e4e4e4;
  display: flex;
  justify-content: center;
}

.quantitySelectButton {
  cursor: pointer;
  height: 28px;
  user-select: none;
  width: 26px;
}

.quantitySelectButtonLeft {
  border-radius: 4px 0 0 4px;
  border-right: none;
}

.quantitySelectButtonRight {
  border-radius: 0 4px 4px 0;
  border-left: none;
}

.quantityCounter {
  height: 28px;
  width: 32px;
}
