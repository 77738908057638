.imageButton {
  color: var(--blue-default);
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  transition: transform 0.2s;
}

.imageButton:hover {
  transform: scale(1.01);
  color: #003cff;
}
