.SpecialistDropdownPopper {
    z-index: 100;
}

.SpecialistDropdown {
    width: 650px;
    background-color: #fff;
    border-radius: 6px;
    padding: 25px 25px 0;
    overflow: hidden;
    background-image: url('assets/BIM_SPECIALIST.png');
    background-position: 135% 115%;
    background-size: 500px auto;
    background-repeat: no-repeat;
}

.SpecialistDropdown .SpecialistDropdownContainer {
    display: flex;
}

.SpecialistDropdown .SpecialistDetails {
    width: 330px;
    color: #2B506A;
}

.SpecialistDropdown .SpecialistDetails > * {
    margin-bottom: 20px;
    display: block;
}

.SpecialistDropdown .SpecialistDetails .SpecialistTitle {
    font-size: 18px;
    font-weight: bold;
}

.SpecialistDropdown .SpecialistDetails .SpecialistOptions {
    width: 250px;
}

.SpecialistDropdown .SpecialistDetails .SpecialistOptions li {
    margin-bottom: 15px;
    line-height: normal;
    list-style: none;
    position: relative;
}

.SpecialistDropdown .SpecialistDetails .SpecialistOptions li::before {
    content: "check";
    font-family: 'Material Icons';
    font-style: normal;
    color: #005dff;
    font-weight: bold;
    font-size: 18px;
    line-height: 0;
    position: absolute;
    left: -25px;
    top: 7px;
}

.SpecialistMenuButton .BngIsNewLabel {
    margin-left: 12px;
}

.SpecialistDropdown .SpecialistDetails .SpecialistHire {
    background-color: #005dff !important;
    border-radius: 100px;
    padding: 15px 25px;
    font-weight: bold;
}

.SpecialistDropdown .SpecialistDetails .SpecialistHire:hover {
    background-color: #3295cb !important;
}

@media screen and (max-width: 1366px) {
    .SpecialistMenuButton {
        margin-left: 7px !important;
    }

    .SpecialistMenuButton .BngIsNewLabel {
        margin-left: 8px !important;
    }
}
