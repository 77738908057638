table.tree-table-object {
    border: 0 !important;
    position: relative !important;
}

.tree-table-object td {
    border-top: 1px solid transparent;
    color: #666;
    padding: 1px 0;
    display: block;
}

.tree-table-object tr {
    max-height: 44px;
}

.tree-table-object td.expanded .folder-icon,
.tree-table-object td:hover .folder-icon {
    color: #3558EF;
}

.tree-table-folder-description {
    display: inline-block;
    font-size: 14px;
}

.tree-color-objects .lbl {
    margin-left: 2px !important;
    display: inline-flex;
    align-items: center;
}

.icon-object-text {
    font-family: 'Roboto', "Open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.select-path {
    max-height: 40px;
}

.select-path .Icon {
    font-size: 20px;
    margin-right: 5px;
    padding: 5px;
}

.select-path .Icon.toggleFolderExpandButton {
    border-radius: 100%;
    font-size: 24px;
    padding: 8px;
    transition: background-color 250ms linear, color 250ms linear, filter 250ms linear;
    vertical-align: middle;
}

.select-path .Icon.toggleFolderExpandButton:hover {
    background-color: rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.select-path input[type="checkbox"] + .lbl::before,
.select-path input[type="radio"] + .lbl::before {
    margin: 8px 13px;
}

.select-path label .lbl {
    align-items: center;
    display: inline-flex;
    margin-top: 5px;
}

.select-path .lbl {
    margin-left: 12px;
    max-width: 400px;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select-path input[type=radio]:after,
.select-path input[type=radio] {
    position: relative !important;
    margin-right: 5px;
}

.bng-tree-table-box {
    position: relative;
    display: block;
}

.bng-tree-table-action-box {
    display: flex;
    width: 100%;
    margin-bottom: 2px;
}

.button-search-dialog,
.button-clean-dialog,
.button-retract-dialog {
    cursor: pointer;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    padding: 0 5px;
    min-width: fit-content;
}

.button-retract-dialog {
    width: 110px;
}

.button-search-dialog:hover,
.button-clean-dialog:hover,
.button-retract-dialog:hover {
    background-color: #eee;
}

.bng-tree-table-box a[class^="button"] .Icon,
.bng-tree-table-box .tree-table-text {
    color: #404040;
}

.tree-table-text {
    padding-left: 5px;
}

.bng-tree-table-box .bng-search {
    position: relative;
}