.radio .input-right input[type='radio'] {
  right: 10px;
}

.radio .input-right span.lbl {
  margin-left: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.radio .ace {
  display: flex;
  justify-content: center;
  align-items: center;
}
