.BngSlider {
  display: flex;
  align-items: center;
}

.BngSliderWrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.BngSliderWrapper,
.BngSliderGrab {
  height: 14px;
}

.BngSliderGrab {
  position: absolute;
  cursor: pointer;
  width: 14px;
  background-color: #fff;
  box-shadow: inset 0 0 0 2px rgb(0, 93, 255);
  border-radius: 100%;
}

.BngSliderTracker,
.BngSliderSelection {
  height: 4px;
  border-radius: 4px;
  pointer-events: none;
}

.BngSliderTracker {
  width: 100%;
  background-color: #6c6c6c;
}

.BngSliderSelection {
  position: absolute;
  left: 0;
  background-color: rgb(0, 93, 255);
}

.BngSlider input {
  width: 58px;
  margin-left: 20px;
  margin-top: 5px;
  padding: 8px;
}

.BngSlider .customTooltip {
  opacity: 1;
  position: relative;
  align-self: start;
}

.BngSlider .tooltip-arrow {
  background: #393e42;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  margin: 10px 1px;
}

.BngSlider .customTooltip .tooltipInner {
  background: #393e42;
  margin: 15px -42px;
  color: white;
  z-index: 1;
  padding: 5px;
  border-radius: 3px;
  position: relative;
  text-align: center;
}

.BngSlider input.show-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.BngSlider input.show-arrow[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
