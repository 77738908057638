.usermobile-table-tr:nth-child(even) {
  background-color: #f6f6f6 !important;
}

.usermobile-table-tr:nth-child(odd) {
  background-color: #ffffff !important;
}

.table-title-column {
  padding-left: 14px;
  height: 37px;
  line-height: 37px;
}

.NoFormCheckbox.checkbox.enableuser-mobile-checkbox > label > input[type='checkbox'] {
  width: 16px !important;
  height: 16px !important;
  margin-top: 0;
}

.NoFormCheckbox.checkbox.enableuser-mobile-checkbox > label > span.lbl::before {
  margin: 0px;
}

.usermobile-table-tr .publisherlist-table-td div .help {
  color: #337dff;
}

.usermobile-table-tr .publisherlist-table-td div .cancel {
  color: #e23d3d;
}

.usermobile-table-tr .publisherlist-table-td div .check_circle {
  color: #4ed624;
}

.usermobile-table-tr .publisherlist-table-td div .help,
.usermobile-table-tr .publisherlist-table-td div .cancel,
.usermobile-table-tr .publisherlist-table-td div .check_circle,
.usermobile-table-tr .publisherlist-table-td div .send-solicitation.disabled,
.usermobile-table-tr .publisherlist-table-td div .send-solicitation {
  font-size: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.usermobile-table-tr .publisherlist-table-td div .send-solicitation.disabled {
  color: #e4e4e4;
}

.usermobile-table-tr .publisherlist-table-td div .send-solicitation {
  color: #555555;
}

.usermobile-table-tr .publisherlist-table-td div .send-solicitation:not(.disabled):hover {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  transform: scale(1.1);
}

.usermobile-table-tr .publisherlist-table-td div .send-solicitation.disabled:hover {
  cursor: not-allowed;
}
