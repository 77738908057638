.CentralMonitoringMenuButton.dropdown-menu {
    width: 425px;
}

.CentralMonitoringMenuButton .details-container .icon-conf {
    margin-right: 0 !important;
    font-size: 11px;
}

.CentralMonitoringMenuButton .details-container span:first-child {
    margin-left: auto;
}

.CentralMonitoringMenuButton .details-container span {
    margin-left: 5px;
}

.CentralMonitoringMenuButton .msg-container {
    padding: 6px 0;
}

.CentralMonitoringMenuButton .details-container {
    margin-left: 5px;
    font-size: 11px;
}

.CentralMonitoringMenuButton .event-criticity {
    padding: 0;
    width: 5px;
}

.CentralMonitoringMenuButton .notificationsTable {
    width: 100%;
    background: #f7f8fa;
}

div.monitorMessageDiv {
    margin-left: 10px;
    font-size: 11px;
    font-weight: normal;
    line-height: 22px;
    color: #555555;
}

.notificationsTable .details-container {
    margin: 2px 8px;
    height: 20px;
}

.notificationsTable i.material-icons {
    font-size: 16px;
    color: #555555;
}

.details-container {
    color: #7e7e7e;
}

td.notificationCriticityFlag {
    width: 4px;
    padding: 0;
}

.monitorName {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .5px;
}

.ruleDescription {
    font-size: 12px;
}

tr.notificationsTableRow:hover {
    background: #ffffff;
}