.types {
}

.type {
  width: 52px;
  height: 42px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  opacity: 0.8;
  transition: opacity ease-in-out 200ms, background-color ease-in-out 200ms;
  box-sizing: border-box;
}

.type:hover {
  background-color: #f8f8f8;
  opacity: 1;
}

.type.selected {
  border: 2px solid #005dff;
}

.type img {
  max-width: calc(100% - 8px);
  max-height: calc(100% - 8px);
}

:global(.KpiEditMenu .ColorCircle) {
  height: 28px !important;
}

:global(.KpiEditMenu .ObjectRightMenuAccordion) {
  overflow: hidden;
}

.previewInvalidData :global(.bng-snackbar) {
  width: 90%;
}