.EchartsToolbar.ContainerIsNotAvailable {
  position: absolute;
  z-index: 20;
  box-shadow: 0 0 3px 0 rgba(148, 148, 148, 0.6);
  border-radius: 20px;
  top: 22px;
  right: 15px;
}

.DashboardTheme-BLACK .EchartsToolbar.ContainerIsNotAvailable,
.DashboardTheme-CORPORATIVE .EchartsToolbar.ContainerIsNotAvailable {
  box-shadow: 0 0 3px 0 #fff;
}

.MenuButtonsContainer .EchartsToolbar {
  border-right: 2px solid #e9e9e9;
}

.AnalysisChartRenderChart .EchartsToolbar {
  top: 12px;
  transition: background-color 250ms linear, color 250ms linear, filter 250ms linear, opacity 250ms linear;
  visibility: hidden;
  opacity: 0;
}

.EChartModelAnalysisContainer:hover .EchartsToolbar {
  visibility: visible;
  opacity: 1;
}

.DashboardItemOptsButton.enabled {
  color: #005dff !important
}

.DashboardTheme-BLACK .EchartsToolbar button .Icon,
.DashboardTheme-CORPORATIVE .EchartsToolbar button .Icon {
  color: #ececec;
}