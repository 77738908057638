.CoolPabloProgressBar {
  position: relative;
}

.CoolPabloProgressBar div {
  height: 10px;
  width: 33.33%;
}

.CoolPabloProgressBar .red {
  border-radius: 5px 0 0 5px;
  background-color: #ff0052;
}

.CoolPabloProgressBar .yellow {
  background-color: #ffb642;
  border: 2px white solid;
  border-top: 0;
  border-bottom: 0;
}

.CoolPabloProgressBar .green {
  background-color: #09b62c;
  border-radius: 0 5px 5px 0;
}

.CoolPabloProgressBar .CoolEmoji {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #bbbbbb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  position: absolute;
  left: calc(6.4% - 32px);
  top: -11px;
  box-sizing: border-box;
  line-height: 31px;
  font-size: 19px;
}
