.required-filter-box {
    width: 500px;
    padding: 40px 0 10px;
    margin: 30px auto;
    background-color: #f2f2f2;
    border: 1px solid #dadada;
    border-radius: 4px;
    color: #292C31;
    text-align: center;
}

@media only screen and (max-width: 991px) {
    .required-filter-box {
        width: unset;
    }

    #body-dashboard-home {
        margin: auto;
    }
}

.required-filter-box > * {
    padding: 0 30px;
}

.required-filter-list {
    border-top: 1px solid #DEDEDE;
    padding-top: 10px;
    margin-bottom: 0;
}

.required-filter-list .required-filter-tag {
    margin: 5px 8px;
    display: inline-block;
    white-space: nowrap;
    background: #575bd8;
    color: #fff;
    border-radius: 20px;
    font-size: 13px;
    padding: 4px 10px 6px;
    cursor: pointer;
}

.required-filter-tag .icon-bim-filter {
    font-size: 18px;
    top: 3px;
    position: relative;
}