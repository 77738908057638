.pageWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.pageWrapper :global(.block-ui-container) {
  position: fixed;
}

.AccStructuresTab {
  box-sizing: border-box;
  height: 100%;
  justify-content: center;
  max-width: 1240px;
  padding: 32px;
  position: relative;
  width: 100%;
}

.colEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userNameWrapper {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 285px;
}

.tableSection,
.historyGraphSection {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: fit-content;
  width: 100%;
}

.tableSection {
  padding-bottom: 32px;
}

.sectionTitle {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
}

.structuresTable {
  background: #f6f6f6;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  position: relative;
}

.structuresTable table {
  border-radius: 8px;
}

.structureIcon {
  color: #005dff;
  font-size: 24px;
  height: 24px;
  width: 24px;
}

.structuresTable table tr td,
.structuresTable table tr th {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

.structuresTable table tr td:first-child {
  padding-left: 20px;
}

.dataUpdateWrapper {
  align-items: center;
  display: flex;
}

.dataUpdate {
  align-items: center;
  cursor: help;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
}

.dataUpdateIcon {
  font-size: 21px;
}

.openProjectButton {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.structuresTable {
  min-height: 250px;
}

.tableTitleWrapper {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
}

.structureTypeFilter :global(.flex-center-items) > div {
  height: 24px;
}

.structuresCount {
  color: #333333;
  font-weight: 500;
  margin-right: 10px;
}

.QuotaChart {
  height: 250px;
  width: 100%;
}

.quotaGraphWrapper {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 4px 0 #00000040;
  margin-bottom: 10px;
}

.quotaGraphWrapper canvas {
  cursor: default;
}

.AccStructuresTabFilterPopper:global(:not(.BngClickOutsideOverlay)) {
  height: 464px;
}

.AccStructuresTabFilterPopper > div {
  max-height: 464px;
}
