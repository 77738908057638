.countComponent {
  display: inline-flex;
  align-items: center;
  color: #005dff;
}

.countComponent div {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 93, 255, 0.1);
}

.countComponent:global(.success) {
  color: #00b84a;
}

.countComponent:global(.success) div {
  background-color: rgba(0, 184, 74, 0.1);
}

.countComponent:global(.danger) {
  color: #ff0000;
}

.countComponent:global(.danger) div {
  background-color: rgba(255, 0, 0, 0.1);
}

.countComponent:global(.warning) {
  color: #f89406;
}

.countComponent:global(.warning) div {
  background-color: rgba(248, 148, 6, 0.1);
}

.countComponent:global(.purple) {
  color: #7b68ee;
}

.countComponent:global(.purple) div {
  background-color: rgba(123, 104, 238, 0.1);
}

.countComponent :global(.Icon) {
  font-size: 18px;
}

.countComponent b {
  font-size: 18px;
}
