.GroupRender {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.Dot {
  width: 7px;
  height: 7px;
  background-color: #005dff;
  border-radius: 100%;
}

.GroupRender.FilterByTag .groupName {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 156px;
  white-space: nowrap;
}
