.BimIntegrationEulaDialog {
  width: 90%;
  max-width: 1120px;
  border-radius: 12px;
  max-height: calc(90% - 90px);
  margin: unset !important;
  border: unset;
  transform: translate(-50%);
}

.BimIntegrationEulaDialog .Body {
  display: flex;
  height: 65vh;
}

.BimIntegrationEulaDialog .Container {
  display: flex;
  padding: 32px 48px 8px 48px;
  flex: 1;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
  position: relative;
  background-color: #f6f6f6;
}

.BimIntegrationEulaDialog .SpanText {
  padding: 32px 72px 0 72px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px 4px 0 0;
  width: 100%;
  min-height: 600px;
}

.BimIntegrationEulaDialog .ImageWrapper {
  display: flex;
  justify-content: center;
  height: 72px;
  padding: 16px 0;
}