.WhatsAppDialog {
  background-color: #fff;
  z-index: 9999;
  overflow: auto;
}

.messaging-table-user-display-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #005dff;
  color: white;
  border-radius: 15px;
  padding: 2px 7px;
}

.messaging-table-user-success-error,
.messaging-table-user-resource-type {
  display: flex;
  align-items: center;
  gap: 4px;
}

.messaging-table-user-success-error.between-items {
  gap: 8px;
}

.WhatsAppConsumption .material-icons.success {
  color: #00a355;
}

.WhatsAppConsumption .material-icons.failed {
  color: #e23d3d;
}

.whatsapp-table-wrapper {
  border: 1px solid #e4e4e4;
  background-color: #ffffff;
}

.whatsapp-dialog-title {
  display: flex;
  border-bottom: 1px solid #e4e4e4;
  padding-right: 14px;
}

.whatsapp-dialog-title-text {
  box-shadow: 0px 1px 0px #e4e4e4;
  padding: 2px 2px 12px 25px;
  margin-top: 12px;
  color: #555555;
  margin-bottom: 0;
  flex-grow: 1;
}

.whatsapp-dialog-title .BngIconButton {
  margin-top: 3px;
  height: 50%;
}

.WhatsAppConsumption .BngTable .BngTableTr .BngTableTd:first-child,
.WhatsAppConsumption .BngTable.StickyHeader thead th:first-child {
  padding-left: 25px;
}

.material-icons.whats-send-success {
  color: #00b300;
}

.material-icons.whats-send-error {
  color: #ff0000;
}

.material-icons.resource-type {
  color: #005dff;
}

.WhatsAppDialog .widget-body {
  background-color: #f6f6f6;
  padding: 0px;
}

.WhatsAppDialog .widget-main,
.WhatsAppConsumption {
  padding: 0px 24px 24px 24px;
}

.WhatsAppConsumption .pagination-table-footer {
  padding-bottom: 6px;
  margin-right: 20px;
  justify-content: flex-end;
}

.WhatsAppConsumption .consumption-item {
  display: inline-flex;
  width: 50%;
  padding: 5px 0;
  margin: 10px 0;
  align-items: center;
}

.WhatsAppConsumption .consumption-illustration {
  width: 100px;
  text-align: center;
}

.WhatsAppConsumption .consumption-illustration > img {
  height: 60px;
}

.WhatsAppConsumption .consumption-description {
  padding: 3px 50px 3px 0px !important;
}

.WhatsAppConsumption .consumption-description > * {
  display: block;
}

.WhatsAppConsumption .consumption-description .consumption-quantity {
  font-weight: 600;
  color: #000;
}

.WhatsAppConsumption .consumption-description .consumption-capacity {
  color: #888;
  font-size: 13px;
}

.WhatsAppConsumption .consumption-additional {
  text-align: right;
}

.WhatsAppConsumption .consumption-additional input {
  width: 65px;
  margin-left: calc(100% - 65px);
  display: block;
}

.WhatsAppConsumption .consumption-illustration .percentage {
  vertical-align: middle;
  height: 64px;
  width: 64px;
  line-height: 64px;
}

.WhatsAppConsumption .consumption-illustration .percent {
  font-size: 14px;
  color: #269c59;
}

.WhatsAppConsumption .consumption-illustration .infobox-circle {
  border-radius: 100%;
  height: 64px;
  width: 64px;
  margin: auto;
  background: #f5b650;
  text-shadow: none;
}

.WhatsAppConsumption .consumption-illustration .infobox-circle .infobox-iconcircle {
  margin: auto;
  padding: 5px 0;
  display: block;
}
