.ConfigureAccordion .PrinterConfigValues {
    justify-content: space-between;
    display: flex;
}

.ConfigureAccordion .PrinterConfigValues > * {
    margin-right: 10px;
}

.ConfigureAccordion .PrinterConfigValues > *:last-child {
    margin-right: 0;
}

.ConfigureAccordion .PrinterConfigCheckboxOpts .BngCheckbox {
    margin-bottom: 10px;
}

.ConfigureAccordion .VersionActionList .ActionListItemDescription {
    font-size: 13px;
}

.ConfigureAccordion #VersionAccordion .ActualVersion > .Icon {
    color: #005dff;
}

.AccordionContent .FolderContainer {
    max-width: 300px;
    width: 300px;
}

.ConfigureAccordion .RenameField button {
    height: 32px;
    padding: 0 3px;
    display: flex;
    align-items: center;
    margin: 10px 0 0 15px;
    width: unset;
}

.ConfigureAccordion .RenameField button .Icon {
    font-size: 22px;
    margin: 0;
}