.pagination-table-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-table-footer > * {
    /*width: calc(50% - 5px);*/
    width: calc(48% - 5px);
    margin-left: 16px;
}

.pagination-select-per-page {
    display: inline-flex;
    align-items: center;
}

.pagination-select-per-page * {
    padding-right: 5px;
    margin: 0;
}

.pagination-select-per-page label {
    margin-right: 10px;
}

.pagination-select-per-page select {
    width: 60px;
    border-color: #e6e6e6;
}

.pagination-select-per-page select:hover {
    background-color: #e6e6e6;
}

.pagination-select-per-page select:focus {
    background-color: white;
}

.pagination-select-per-page *:last-child {
    margin-left: 10px;
    padding-left: 5px;
}
