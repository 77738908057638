.main {
  width: fit-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
  border-radius: 4px;
  gap: 8px;
}

.primary {
  background-color: #005dff;
}

.warning {
  background-color: #f89406;
}

.danger {
  background-color: #dc3545;
}

.success {
  background-color: #87b87f;
}

.hover {
  transition: filter ease 0.3s;
}

.hover:hover {
  filter: brightness(92%);
}

.hover:active {
  filter: brightness(85%);
}

.sm {
  font-size: 12px;
  padding: 4px 8px;
  gap: 4px;
}

.sm :global(.Icon) {
  font-size: 18px;
}

.md {
  font-size: 14px;
  padding: 10px 18px;
}

.md :global(.Icon) {
  font-size: 20px;
}

.lg {
  font-size: 16px;
  padding: 12px 20px;
}

.lg :global(.Icon) {
  font-size: 22px;
}

/*Variant css should be more important than Type and Size*/
.textButton,
.outline {
  background-color: transparent;
  color: black;
  font-weight: 500;
}

.textButton {
  transition: text-shadow ease-in-out 0.3s;
}

.textButton:hover,
.textButton:active {
  background-color: unset;
  text-shadow: 0 0 2px #b9b9b9;
}

.outline {
  border: 1px black solid;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.disabled:hover {
  filter: none;
}