.AssistantDialog {
  width: 60%;
  left: 35%;
}

.AssistantDialog:global(.bs-dialog .modal-dialog .modal-content .modal-body .UiBlocker .widget-box .widget-body .widget-main) {
  padding: 0 !important;
}

.AssistantDialog:global(.bs-dialog.ui-draggable.DialogNewLayout .DialogBody) {
  overflow: scroll;
  height: 404px;
}