.BngCheckbox {
    padding-left: 0;
    position: relative;
}

.BngCheckbox label {
    margin-bottom: 0;
}

.BngCheckbox label span.lbl {
    display: flex;
    align-items: center;
}

.BngCheckbox label span.lbl::before {
    margin-right: 10px;
}

.BngCheckbox.Disabled {
    opacity: 0.8;
    cursor: not-allowed;
}

.BngCheckbox.Disabled input[type=checkbox]:hover + .lbl::before {
    cursor: not-allowed !important;
}

.BngCheckbox.Disabled .lbl::before {
    background-color: #ccc;
}

.BngCheckbox.Disabled .lbl {
    cursor: not-allowed;
}

.BngCheckboxType-danger input[type=checkbox]:checked,
.BngCheckboxType-danger input[type=checkbox]:checked + .lbl::before {
    background: #E24C38;
}

.BngCheckboxSize-lg {
    transform: scale(1.2);
    display: flex;
    align-items: center;
}

.BngCheckbox.fw-700 .lbl {
    font-weight: 700;
}

.DarkMode .BngCheckbox .lbl {
    color: var(--default-text-color);
}