.agreedPricingIndicator {
  padding: 25px;
}

.proposalInflatorsTableWrapper {
  background: #f6f6f6;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  height: 150px;
  margin: 0 25px;
  overflow-y: scroll;
}

.proposalInflatorsTable {
  border-radius: 8px;
  color: #333333;
  overflow: hidden;
}

.agreedPricingIndicator,
.quantityIndicator {
  box-sizing: border-box;
  display: inline-flex;
  font-size: 16px;
  justify-content: space-between;
  width: 100%;
  color: #333333;
}

.quantityIndicator {
  padding: 25px 25px 0 25px;
}

.rowData {
  font-weight: 400;
}

.noReviewRatesIndicator {
  align-items: center;
  justify-content: center;
  width: 100%;
}