.dialog {
  width: 50vw;
  margin-left: 0;
  margin-right: 0;
  left: 24vw;
}

.dialog div:global(.dialog-body),
.dialog div:global(.widget-body) {
  min-height: 55vh;
}

.searchBtn {
  height: 40px;
}

.dialogBody {
  height: 53vh;
}

.dialogFooter {
  padding-top: 15px;
}
