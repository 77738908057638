.IpRestrictionLogsDialog {
  margin-left: -150px;
  height: 550px;
  width: 855px;
}

.IpRestrictionLogsDialog :global(.UiBlocker) {
  height: 100%;
  min-height: 500px;
}

.IpRestrictionLogsDialog :global(.logsTableWrapper) {
  min-height: 435px;
}

.IpRestrictionLogsDialog :global(.logsTableWrapper .BngTableTd:first-child),
.IpRestrictionLogsDialog :global(.logsTableWrapper .BngTableTh:first-child) {
  padding-left: 40px;
}

.logsSearch {
  margin-left: 6px;
  width: 100%;
}

.dateField {
  background-color: white !important;
  border-radius: 4px !important;
  box-shadow: inset 0 0 0 1px #ccc !important;
  color: #555 !important;
  font-size: 14px !important;
  height: 38px !important;
  position: relative !important;
}

.userAvatar {
  background: #ffffff;
  background-size: cover !important;
  border-radius: 100%;
  height: 34px;
  width: 34px;
}

.avatarAndName {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.avatarAndName label {
  margin-bottom: 0;
}
