.schedulingLogBody {
  height: 400px;
}

.schedulingLogBody .log {
  color: #555;
  padding: 0 20px 20px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  cursor: not-allowed;
  resize: none;
  width: 100%;
  height: 100%;
  margin: 0;
}
