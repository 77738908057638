.AccProjectsTab :global(.BngTableTh):first-child {
  padding-left: 25px;
}

.AccProjectsTab :global(.BngTableTd):first-child {
  padding-left: 40px;
}

.projectTypeWrapper {
  align-items: center;
  display: inline-flex;
  gap: 5px;
}

.projectTypeBadge {
  border-radius: 4px;
  color: #fff;
  display: flex;
  height: 18px;
  justify-content: center;
  width: 18px;
}

.ProjectsActionPopper li i {
  font-size: 20px !important;
}

.ProjectsActionPopper li span {
  font-size: 16px;
}

.projectInactiveIcon {
  margin-left: 15px;
  cursor: pointer;
  color: red;
  opacity: 60%;
}

.projectCount {
  color: #333333;
  font-weight: 500;
  margin-right: 10px;
}
