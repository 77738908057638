.labsVotingWrapper {
  padding: 10px 18px;
  margin-top: 8px;
}

.labsSuggestionContainer {
  padding: 0 12px;
}

.votingHeader {
  display: flex;
  gap: 12px;
  align-items: center;
}

.infoLabel {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  height: fit-content;
  border-radius: 4px;
  background-color: #e6efff;
  border: 1px solid #ccdfff;
  padding: 8px 18px;
}

.infoLabel :global(i) {
  font-size: 18px;
  color: var(--blue-default);
}

.infoLabel :global(span) {
  color: #555555;
}

.infoLabel :global(button) {
  border-radius: 2px;
  border: 1px solid #005dff;
  background: #fff;
  color: #005dff;
  text-align: center;
  font-weight: 700;
  padding: 6px 12px;
  margin-left: auto;
}

.optionsWrapper {
  min-height: calc(100vh - 450px);
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 24px 0;
}

.orders {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.orders .ordersButtons {
  padding: 8px 12px;
  border-radius: 4px 0 0 4px;
  border: 1px solid #e4e4e4;
  background: #f6f6f6;

  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.search {
  width: 230px;
}

.orders .ordersSelected {
  color: #005dff;
  background-color: #fff;
}

.labsVotingWrapper :global(.BngEmpty.LabsVotingForm) {
  top: unset !important;
  transform: none !important;
}

.labsVotingWrapper :global(.BngEmpty.LabsVotingForm .Image) {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.labsVotingWrapper :global(.BngEmpty.LabsVotingForm .Title) {
  position: absolute;
  top: 45px;
  text-align: center;
  font-size: 16px;
  color: #292c31;
  font-weight: bold;
  letter-spacing: 0.5px;
  word-break: normal;
  z-index: 2;
}

.labsVotingWrapper :global(.BngEmpty.LabsVotingForm .Message) {
  position: absolute;
  top: 90px;
  text-align: center;
  line-height: 16px;
  z-index: 2;
  word-break: normal;
}

.labsVotingWrapper :global(.BngEmpty.LabsVotingForm .Container) {
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
}
