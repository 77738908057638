.dialog :global(.DialogBody) {
  display: flex;
  justify-content: center;
}

.dialog :global(.DialogBody) :global(.image) {
  height: 500px;
  width: 500px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
