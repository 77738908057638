.featureWrapper {
  width: 100%;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  background-color: #ffffff;
  align-items: center;
}

.featureDescriptionContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.featureDescriptionCategory {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
}

.featureTitleContainer {
  display: flex;
  margin: 4px 0 8px 0;
}

.featureTitle {
  color: #555555;
  font-size: 18px;
  font-weight: 700;
}

.featureDescription {
  color: #888888;
  font-weight: 400;
  font-size: 12px;
}

.featureButtonsContainer {
  height: 15px;
  padding: 20px;
  display: flex;
  align-items: center;
  user-select: none;
  border-top: solid 1px #d1d1d1;
}

.featureFeedbackLink {
  display: flex;
  gap: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.featureFeedbackLink :global(i) {
  position: relative;
  font-size: 16px;
  top: 2px;
}

.featureSwitchButton,
.featuresUnavailableContainer {
  margin-left: auto;
}

.featuresUnavailableContainer {
  display: flex;
  color: #f98900;
  font-weight: 600;
  font-size: 14px;
  gap: 3px;
}

.featuresUnavailableContainer :global(i) {
  font-size: 15px;
  position: relative;
  top: 3px;
}

.featureLearnMore {
  cursor: pointer;
  user-select: none;
}

.featureIconButtons {
  gap: 30px;
  display: flex;
}

.featuresVideoModalOverlay {
  position: fixed;
  z-index: 5001;
}

.featuresVideoModal {
  position: fixed !important;
  z-index: 5002 !important;
  width: 70% !important;
  height: 600px !important;
}

.featuresVideoModal :global(iframe) {
  height: 540px !important;
}

.featuresAdaHelper :global(.AdaOpenHandImg) {
  margin-right: 0 !important;
}

.featuresAdaHelper :global(.BngAdaBackground) {
  margin-right: 180px !important;
  margin-bottom: 190px !important;
  z-index: 5000 !important;
}

.requestButton {
  border: none;
  width: 89px;
  height: 36px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 700;
}

.availableRequestButton {
  background-color: var(--blue-default);
  color: white;
  transition: background-color 0.2s ease;
}

.availableRequestButton:hover {
  background-color: #1e498c;
}

.unavailableRequestButton {
  background-color: #e5e5e5;
  color: #aeaeae;
  cursor: not-allowed;
}

.releaseLabelContainer {
  margin-left: 10px;
}

.imageContainer {
  max-width: 20%;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 8px;
  align-self: center;
}

.infoContainer {
  display: flex;
}

.AdaFeatureCensus :global(.wrapperAdaOpen img) {
  margin-right: 50px;
  z-index: 10001;
}

.AdaFeatureCensus :global(.contentContainer) {
  margin-right: 200px;
  z-index: 10004;
}

.AdaFeatureCensus :global(.contentContainer) {
  position: fixed;
  z-index: 10004;
}

.AdaFeatureCensus :global(.disableInfo) {
  background: rgba(0, 0, 0, 0.45);
}
