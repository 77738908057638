.generalInfo {
  box-sizing: border-box;
  display: inline-grid;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  padding: 12px 40px;
  margin-bottom: 15px;
  width: 100%;
}

.infoTitle {
  font-weight: bold;
}

.infoItem {
  align-items: center;
  display: inline-flex;
  gap: 5px;
}

.detailsWrapper {
  align-items: center;
  display: flex;
  justify-content: start;
  padding: 12px 40px;
}

.infoDetails {
  border-left: 3px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
  padding-left: 12px;
  padding-right: 30px;
}

.blueBall {
  background: #669eff;
  border-radius: 4px;
  height: 8px !important;
  margin: 0 6px 0 0;
  width: 8px !important;
}

.voucherSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.voucherTitle {
  padding: 0 40px;
}