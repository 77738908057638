body {
    --main-bg-color: #575BD8;
    --main-highlight-color: #575BD8;
}

body.skin-1 {
    --main-bg-color: #404040;
    --main-highlight-color: #4272FE;
}

body.skin-2 {
    --main-bg-color: #e89702;
    --main-highlight-color: #e89702;
}

body.skin-3 {
    --main-bg-color: #5d5d5d;
    --main-highlight-color: #4272FE;
}

body.skin-4 {
    --main-bg-color: #d23d3d;
    --main-highlight-color: #d23d3d;
}

body.skin-5 {
    --main-bg-color: #269c59;
    --main-highlight-color: #269c59;
}

body.skin-6 {
    --main-bg-color: #AAAAAA;
    --main-highlight-color: #4272FE;
}

body.skin-7 {
    --main-bg-color: #2C6AA0;
    --main-highlight-color: #2C6AA0;
}

.nt-text-color-white {
    color: rgba(255, 255, 255, 0.9);
}

.nt-text-color-black {
    color: rgba(0, 0, 0, 0.9);
}

.nt-box-shadow {
    box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.25);
}

.nt-text-shadow {
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.nt-margin {
    margin: 6px 3px;
}

.nt-bold {
    font-weight: 500;
}

.div-icon-material {
    display: flex;
}

.div-icon-material i.material-icons {
    margin-right: 4px;
}

.btn-new {
    border-radius: 4px;
    height: 28px;
    font-size: 11px;
    cursor: pointer;
    transition: all ease .15s;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0 20px;
    letter-spacing: .5px;
}

.default-page-content-padding {
    padding: 16px 20px;
}

.colouredTabBorder.disabled,
.colouredTabBorder.disabled div,
.colouredTabBorder.disabled a.menu_sttings {
    cursor: not-allowed !important;
}

.colouredTabBorder.disabled {
    opacity: .5;
}

.colouredTabBorder:nth-child(12n+1) {
    border-left: 4px solid #E9633A;
}

.colouredTabBorder:nth-child(12n+2) {
    border-left: 4px solid #528DE3;
}

.colouredTabBorder:nth-child(12n+3) {
    border-left: 4px solid #EB354D;
}

.colouredTabBorder:nth-child(12n+4) {
    border-left: 4px solid #269C59;
}

.colouredTabBorder:nth-child(12n+5) {
    border-left: 4px solid #E9B23A;
}

.colouredTabBorder:nth-child(12n+6) {
    border-left: 4px solid #AC4FC6;
}

.colouredTabBorder:nth-child(12n+7) {
    border-left: 4px solid #16879E;
}

.colouredTabBorder:nth-child(12n+8) {
    border-left: 4px solid #DA478B;
}

.colouredTabBorder:nth-child(12n+9) {
    border-left: 4px solid #5FA4C4;
}

.colouredTabBorder:nth-child(12n+10) {
    border-left: 4px solid #5B5FD6;
}

.colouredTabBorder:nth-child(12n+11) {
    border-left: 4px solid #79A93B;
}

.colouredTabBorder:nth-child(12n+12) {
    border-left: 4px solid #EA9E33;
}

/*themes*/
.skin-7 .navbar .navbar-inner, .skin-7 .login-box .toolbar, .skin-7 .forgot-box .toolbar, .skin-7 .signup-box .toolbar {
    background: #2C6AA0;
}

.skin-6 .navbar .navbar-inner, .skin-6 .login-box .toolbar, .skin-6 .forgot-box .toolbar, .skin-6 .signup-box .toolbar {
    background: #AAAAAA;
}

/*theme green start*/
.skin-5 .navbar .navbar-inner, .skin-5 .login-box .toolbar, .skin-5 .forgot-box .toolbar, .skin-5 .signup-box .toolbar {
    background: #269c59;
}

.skin-5 .nav-bar-button .btn-new {
    background: linear-gradient(180deg, #8edc37 0%, #1ec709 100%);
}

/*theme green end*/

/*theme red start*/
.skin-4 .navbar .navbar-inner, .skin-4 .login-box .toolbar, .skin-4 .forgot-box .toolbar, .skin-4 .signup-box .toolbar {
    background: #d23d3d;
}

.skin-4 .nav-bar-button .btn-new {
    background: linear-gradient(180deg, #FF3B3B 0%, #E20808 100%);
}

.skin-3 .navbar .navbar-inner, .skin-3 .login-box .toolbar, .skin-3 .forgot-box .toolbar, .skin-3 .signup-box .toolbar {
    background: #5d5d5d;
}

.skin-3 .nav-search-input-new-line {
    background: #4272FE;
}

/*theme black end*/

/*theme yellow start*/
.skin-2 .navbar .navbar-inner, .skin-2 .login-box .toolbar, .skin-2 .forgot-box .toolbar, .skin-2 .signup-box .toolbar {
    background: #e89702;
}

.skin-2 .nav-bar-button .btn-new {
    background: linear-gradient(180deg, #f7d457 0%, #f0a111 100%);
}

/*theme yellow end*/

/*theme grey start*/
.skin-1 .navbar .navbar-inner, .skin-1 .login-box .toolbar, .skin-1 .forgot-box .toolbar, .skin-1 .signup-box .toolbar {
    background: #404040;
}

.skin-1 .nav-search-input-new-line {
    background: #4272FE;
}

/*theme grey end*/

/*folder nav theme*/

.skin-7 .nav-list > li > .submenu li > .submenu li.open > a,
.skin-7 .nav-list > li > .submenu li > .submenu > li a:hover,
.skin-7 .nav-list > li.active > a,
.skin-7 .nav-list > li.active > a:hover,
.skin-7 .nav-list > li.active > a:focus,
.skin-7 .nav-list > li.active > a:active,
.skin-7 .nav-list > li > .submenu li.open > a:hover,
.skin-7 .nav-list > li .submenu > li > a:hover,
.skin-7 .open td.menutd-icon,
.skin-7 .sidebar .nav > li > a:hover,
.skin-7 .sidebar .nav > li > a:focus,
.skin-7 .menu-min .nav-list > li.active > a,
.skin-7 .sidebar-collapse:hover > [class*="icon-"] {
    color: #2C6AA0;
}

/*minha pagina*/
.skin-7 .menu-home.active > a > i.material-icons,
.skin-7 .menu-home:hover > i.material-icons,
.skin-7 .menu-home:hover > a > i.material-icons,
.skin-7 .menu-home:hover .menutd-icon-home i.material-icons,
.skin-7 .menu-home:hover .menu_option i.material-icons {
    color: #2C6AA0;
}

/*sidebar menu*/
.skin-7 .menu-home td.menutd.menutd-text.menutd-text-home:hover .menu_option,
.skin-7 .menu-home td.menutd.menutd-text.menutd-text-home:hover i.material-icons {
    color: #2C6AA0;
}

.skin-6 .nav-list > li > .submenu li > .submenu li.open > a,
.skin-6 .nav-list > li > .submenu li > .submenu > li a:hover,
.skin-6 .nav-list > li.active > a,
.skin-6 .nav-list > li.active > a:hover,
.skin-6 .nav-list > li.active > a:focus,
.skin-6 .nav-list > li.active > a:active,
.skin-6 .nav-list > li > .submenu li.open > a:hover,
.skin-6 .nav-list > li .submenu > li > a:hover,
.skin-6 .open td.menutd-icon,
.skin-6 .sidebar .nav > li > a:hover,
.skin-6 .sidebar .nav > li > a:focus,
.skin-6 .menu-min .nav-list > li.active > a,
.skin-6 .sidebar-collapse:hover > [class*="icon-"] {
    color: #4272FE;
}

/*minha pagina*/
.skin-6 .menu-home.active > a > i.material-icons,
.skin-6 .menu-home:hover > i.material-icons,
.skin-6 .menu-home:hover > a > i.material-icons,
.skin-6 .menu-home:hover .menutd-icon-home i.material-icons,
.skin-6 .menu-home:hover .menu_option i.material-icons {
    color: #4272FE;
}

/*sidebar menu*/
.skin-6 .menu-home td.menutd.menutd-text.menutd-text-home:hover .menu_option,
.skin-6 .menu-home td.menutd.menutd-text.menutd-text-home:hover i.material-icons
{
    color: #4272FE;
}

.skin-5 .nav-list > li > .submenu li > .submenu li.open > a,
.skin-5 .nav-list > li > .submenu li > .submenu > li a:hover,
.skin-5 .nav-list > li.active > a,
.skin-5 .nav-list > li.active > a:hover,
.skin-5 .nav-list > li.active > a:focus,
.skin-5 .nav-list > li.active > a:active,
.skin-5 .nav-list > li > .submenu li.open > a:hover,
.skin-5 .nav-list > li .submenu > li > a:hover,
.skin-5 .open td.menutd-icon,
.skin-5 .sidebar .nav > li > a:hover,
.skin-5 .sidebar .nav > li > a:focus,
.skin-5 .menu-min .nav-list > li.active > a,
.skin-5 .sidebar-collapse:hover > [class*="icon-"] {
    color: #269c59;
}

/*minha pagina*/
.skin-5 .menu-home.active > a > i.material-icons,
.skin-5 .menu-home:hover > i.material-icons,
.skin-5 .menu-home:hover > a > i.material-icons,
.skin-5 .menu-home:hover .menutd-icon-home i.material-icons,
.skin-5 .menu-home:hover .menu_option i.material-icons {
    color: #269c59;
}

/*sidebar menu*/
.skin-5 .menu-home td.menutd.menutd-text.menutd-text-home:hover .menu_option,
.skin-5 .menu-home td.menutd.menutd-text.menutd-text-home:hover i.material-icons
{
    color: #269c59;
}

.skin-4 .nav-list > li > .submenu li > .submenu li.open > a,
.skin-4 .nav-list > li > .submenu li > .submenu > li a:hover,
.skin-4 .nav-list > li.active > a,
.skin-4 .nav-list > li.active > a:hover,
.skin-4 .nav-list > li.active > a:focus,
.skin-4 .nav-list > li.active > a:active,
.skin-4 .nav-list > li > .submenu li.open > a:hover,
.skin-4 .nav-list > li .submenu > li > a:hover,
.skin-4 .open td.menutd-icon,
.skin-4 .sidebar .nav > li > a:hover,
.skin-4 .sidebar .nav > li > a:focus,
.skin-4 .menu-min .nav-list > li.active > a,
.skin-4 .sidebar-collapse:hover > [class*="icon-"] {
    color: #d23d3d;
}

/*minha pagina*/
.skin-4 .menu-home.active > a > i.material-icons,
.skin-4 .menu-home:hover > i.material-icons,
.skin-4 .menu-home:hover > a > i.material-icons,
.skin-4 .menu-home:hover .menutd-icon-home i.material-icons,
.skin-4 .menu-home:hover .menu_option i.material-icons {
    color: #d23d3d;
}

/*sidebar menu*/
.skin-4 .menu-home td.menutd.menutd-text.menutd-text-home:hover .menu_option,
.skin-4 .menu-home td.menutd.menutd-text.menutd-text-home:hover i.material-icons
{
    color: #d23d3d;
}

.skin-3 .nav-list > li > .submenu li > .submenu li.open > a,
.skin-3 .nav-list > li > .submenu li > .submenu > li a:hover,
.skin-3 .nav-list > li.active > a,
.skin-3 .nav-list > li.active > a:hover,
.skin-3 .nav-list > li.active > a:focus,
.skin-3 .nav-list > li.active > a:active,
.skin-3 .nav-list > li > .submenu li.open > a:hover,
.skin-3 .nav-list > li .submenu > li > a:hover,
.skin-3 .open td.menutd-icon,
.skin-3 .sidebar .nav > li > a:hover,
.skin-3 .sidebar .nav > li > a:focus,
.skin-3 .menu-min .nav-list > li.active > a,
.skin-3 .sidebar-collapse:hover > [class*="icon-"] {
    color: #4272FE;
}

/*minha pagina*/
.skin-3 .menu-home.active > a > i.material-icons,
.skin-3 .menu-home:hover > i.material-icons,
.skin-3 .menu-home:hover > a > i.material-icons,
.skin-3 .menu-home:hover .menutd-icon-home i.material-icons,
.skin-3 .menu-home:hover .menu_option i.material-icons {
    color: #4272FE;
}

/*sidebar menu*/
.skin-3 .menu-home td.menutd.menutd-text.menutd-text-home:hover .menu_option,
.skin-3 .menu-home td.menutd.menutd-text.menutd-text-home:hover i.material-icons
{
    color: #4272FE;
}

.skin-2 .nav-list > li > .submenu li > .submenu li.open > a,
.skin-2 .nav-list > li > .submenu li > .submenu > li a:hover,
.skin-2 .nav-list > li.active > a,
.skin-2 .nav-list > li.active > a:hover,
.skin-2 .nav-list > li.active > a:focus,
.skin-2 .nav-list > li.active > a:active,
.skin-2 .nav-list > li > .submenu li.open > a:hover,
.skin-2 .nav-list > li .submenu > li > a:hover,
.skin-2 .open td.menutd-icon,
.skin-2 .sidebar .nav > li > a:hover,
.skin-2 .sidebar .nav > li > a:focus,
.skin-2 .menu-min .nav-list > li.active > a,
.skin-2 .sidebar-collapse:hover > [class*="icon-"] {
    color: #e89702;
}

/*minha pagina*/
.skin-2 .menu-home.active > a > i.material-icons,
.skin-2 .menu-home:hover > i.material-icons,
.skin-2 .menu-home:hover > a > i.material-icons,
.skin-2 .menu-home:hover .menutd-icon-home i.material-icons,
.skin-2 .menu-home:hover .menu_option i.material-icons {
    color: #e89702;
}

/*sidebar menu*/
.skin-2 .menu-home td.menutd.menutd-text.menutd-text-home:hover .menu_option,
.skin-2 .menu-home td.menutd.menutd-text.menutd-text-home:hover i.material-icons
{
    color: #e89702;
}

.skin-1 .nav-list > li > .submenu li > .submenu li.open > a,
.skin-1 .nav-list > li > .submenu li > .submenu > li a:hover,
.skin-1 .nav-list > li.active > a,
.skin-1 .nav-list > li.active > a:hover,
.skin-1 .nav-list > li.active > a:focus,
.skin-1 .nav-list > li.active > a:active,
.skin-1 .nav-list > li > .submenu li.open > a:hover,
.skin-1 .nav-list > li .submenu > li > a:hover,
.skin-1 .open td.menutd-icon,
.skin-1 .sidebar .nav > li > a:hover,
.skin-1 .sidebar .nav > li > a:focus,
.skin-1 .menu-min .nav-list > li.active > a,
.skin-1 .sidebar-collapse:hover > [class*="icon-"] {
    color: #4272FE;
}

/*minha pagina*/
.skin-1 .menu-home.active > a > i.material-icons,
.skin-1 .menu-home:hover > i.material-icons,
.skin-1 .menu-home:hover > a > i.material-icons,
.skin-1 .menu-home:hover .menutd-icon-home i.material-icons,
.skin-1 .menu-home:hover .menu_option i.material-icons {
    color: #4272FE;
}

/*sidebar menu*/
.skin-1 .menu-home td.menutd.menutd-text.menutd-text-home:hover .menu_option,
.skin-1 .menu-home td.menutd.menutd-text.menutd-text-home:hover i.material-icons
{
    color: #4272FE;
}

/*folder nav theme end*/

/**/

.skin-7 .menu-min .nav-list > li.open:hover > a {
    color: #2C6AA0;
}

.skin-6 .menu-min .nav-list > li.open:hover > a {
    color: #4272FE;
}

.skin-5 .menu-min .nav-list > li.open:hover > a {
    color: #269c59;
}

.skin-4 .menu-min .nav-list > li.open:hover > a {
    color: #d23d3d;
}

.skin-3 .menu-min .nav-list > li.open:hover > a {
    color: #4272FE;
}

.skin-2 .menu-min .nav-list > li.open:hover > a {
    color: #e89702;
}

.skin-1 .menu-min .nav-list > li.open:hover > a {
    color: #4272FE;
}

/**/

/* underline navbar active theme start*/

.skin-6 .type-view-button.active {
    border-color: #4272fe;
}

.skin-3 .type-view-button.active {
    border-color: #4272fe;
}

.skin-1 .type-view-button.active {
    border-color: #4272fe;
}

/* underline navbar active theme end*/

/* loading theme */

.skin-7 *[class^="loading-image"] > .spinner > circle.path {
    stroke: #2C6AA0;
}

.skin-5 *[class^="loading-image"] > .spinner > circle.path {
    stroke: #269c59;
}

.skin-4 *[class^="loading-image"] > .spinner > circle.path {
    stroke: #d23d3d;
}

.skin-2 *[class^="loading-image"] > .spinner > circle.path {
    stroke: #e89702;
}

/* end loading theme */

/* edit button theme */
.skin-7 input[type=checkbox].ace-switch:checked + .lbl::before {
    background-color: #2C6AA0;
}

.skin-5 input[type=checkbox].ace-switch:checked + .lbl::before {
    background-color: #269c59;
}

.skin-4 input[type=checkbox].ace-switch:checked + .lbl::before {
    background-color: #d23d3d;
}

.skin-2 input[type=checkbox].ace-switch:checked + .lbl::before {
    background-color: #e89702;
}

/* end edit button theme */

/* cockpit menu theme */
.skin-7 .cockpit-header-controls-container a:hover,
.skin-7 .cockpit-header-controls-container a:focus {
    color: #2C6AA0;
}

.skin-5 .cockpit-header-controls-container a:hover,
.skin-5 .cockpit-header-controls-container a:focus {
    color: #269c59;
}

.skin-4 .cockpit-header-controls-container a:hover,
.skin-4 .cockpit-header-controls-container a:focus {
    color: #d23d3d;
}

.skin-2 .cockpit-header-controls-container a:hover,
.skin-2 .cockpit-header-controls-container a:focus {
    color: #e89702;
}

/* end cockpit menu theme */

/* end object menu theme */

.skin-7 .floating-object-actions *:not(li) > a > i:hover,
.skin-7 .floating-object-actions *:not(li) > a > span.material-icons:hover {
    color: #2C6AA0;
}

.skin-5 .floating-object-actions *:not(li) > a > i:hover,
.skin-5 .floating-object-actions *:not(li) > a > span.material-icons:hover {
    color: #269c59;
}

.skin-4 .floating-object-actions *:not(li) > a > i:hover,
.skin-4 .floating-object-actions *:not(li) > a > span.material-icons:hover {
    color: #d23d3d;
}

.skin-2 .floating-object-actions *:not(li) > a > i:hover,
.skin-2 .floating-object-actions *:not(li) > a > span.material-icons:hover {
    color: #e89702;
}

/*  end object menu theme */

/*tema dos filtros*/

.skin-7 .dashboard-filter-information.filter-block.filtered * {
    background: #2C6AA0 !important;
    color: #fff !important;
}

.skin-7 .dashboard-filter-information.filter-block.filtered {
    border: 1px solid #2C6AA0;
    background: #2C6AA0;
}

.skin-7 .dashboard-filter-information.filter-block.filtered.filter-information-edit-mode {
    background: #2C6AA0;
}

.skin-6 .dashboard-filter-information.filter-block.filtered * {
    background: #4272FE !important;
    color: #fff !important;
}

.skin-6 .dashboard-filter-information.filter-block.filtered {
    border: 1px solid #4272FE;
    background: #4272FE;
}

.skin-6 .dashboard-filter-information.filter-block.filtered.filter-information-edit-mode {
    background: #4272FE;
}

.skin-5 .dashboard-filter-information.filter-block.filtered * {
    background: #269c59 !important;
    color: #fff !important;
}

.skin-5 .dashboard-filter-information.filter-block.filtered {
    border: 1px solid #269c59;
    background: #269c59;
}

.skin-5 .dashboard-filter-information.filter-block.filtered.filter-information-edit-mode {
    background: #269c59;
}

.skin-4 .dashboard-filter-information.filter-block.filtered * {
    background: #d23d3d !important;
    color: #fff !important;
}

.skin-4 .dashboard-filter-information.filter-block.filtered {
    border: 1px solid #d23d3d;
    background: #d23d3d;
}

.skin-4 .dashboard-filter-information.filter-block.filtered.filter-information-edit-mode {
    background: #d23d3d;
}

.skin-3 .dashboard-filter-information.filter-block.filtered * {
    background: #4272FE !important;
    color: #fff !important;
}

.skin-3 .dashboard-filter-information.filter-block.filtered {
    border: 1px solid #4272FE;;
    background: #4272FE;;
}

.skin-3 .dashboard-filter-information.filter-block.filtered.filter-information-edit-mode {
    background: #4272FE;;
}

.skin-2 .dashboard-filter-information.filter-block.filtered * {
    background: #e89702 !important;
    color: #fff !important;
}

.skin-2 .dashboard-filter-information.filter-block.filtered {
    border: 1px solid #e89702;
    background: #e89702;
}

.skin-2 .dashboard-filter-information.filter-block.filtered.filter-information-edit-mode {
    background: #e89702;
}

.skin-1 .dashboard-filter-information.filter-block.filtered * {
    background: #4272FE !important;
    color: #fff !important;
}

.skin-1 .dashboard-filter-information.filter-block.filtered {
    border: 1px solid #4272FE;;
    background: #4272FE;;
}

.skin-1 .dashboard-filter-information.filter-block.filtered.filter-information-edit-mode {
    background: #4272FE;;
}

/*fim do tema dos filtros*/

/*breadcrumb theme*/

.skin-7 .breadcrumb-is-object {
    color: #2C6AA0 !important;
}

.skin-6 .breadcrumb-is-object{
    color: #4272FE !important;
}

.skin-5 .breadcrumb-is-object {
    color: #269c59 !important;
}

.skin-4 .breadcrumb-is-object {
    color: #d23d3d !important;
}

.skin-3 .breadcrumb-is-object {
    color: #4272FE !important;
}

.skin-2 .breadcrumb-is-object {
    color: #e89702 !important;
}

.skin-1 .breadcrumb-is-object {
    color: #4272FE !important;
}
/*end breadcrumb theme*/

/*clear item theme*/

.skin-7 .clear-item {
    color: #2C6AA0;
}

.skin-6 .clear-item {
    color: #4272FE;
}

.skin-5 .clear-item {
    color: #269c59;
}

.skin-4 .clear-item {
    color: #d23d3d;
}

.skin-3 .clear-item {
    color: #4272FE;
}

.skin-2 .clear-item {
    color: #e89702;
}

.skin-1 .clear-item {
    color: #4272FE;
}

.clear-item {
    color: #575bd8;
}

/*end clear item theme*/

/*start required filter warning*/

.skin-7 .dashboard-required-filter-container .required-filter-list .required-filter-tag {
    background: #2C6AA0;
}

.skin-6 .dashboard-required-filter-container .required-filter-list .required-filter-tag {
    background: #4272FE;
}

.skin-5 .dashboard-required-filter-container .required-filter-list .required-filter-tag {
    background: #269c59;
}

.skin-4 .dashboard-required-filter-container .required-filter-list .required-filter-tag {
    background: #d23d3d;
}

.skin-3 .dashboard-required-filter-container .required-filter-list .required-filter-tag {
    background: #4272FE;
}

.skin-2 .dashboard-required-filter-container .required-filter-list .required-filter-tag {
    background: #e89702;
}

.skin-1.dashboard-required-filter-container .required-filter-list .required-filter-tag {
    background: #4272FE;
}
/*end required filter warning*/


/*ITENS GERAIS*/

span.badge.badge-square {
    border-radius: 2px;
    padding: 2px 5px;
}

.slimScrollBarHide {
    opacity: 0 !important;
}

/*novos padroes de cores*/
.color-blue {
    color: #528DE3;
}

.background-blue {
    background-color: #528DE3;
}

.color-red {
    background-color: #EB354D;
}

.background-red {
    background-color: #EB354D;
}

.color-green {
    background-color: #269C59;
}

.background-green {
    background-color: #269C59;
}

.color-yellow {
    color: #E9B23A;
}

.background-yellow {
    background-color: #E9B23A;
}

.color-purple {
    color: #AC4FC6;
}

.background-purple {
    background-color: #AC4FC6;
}

.color-blue-green {
    color: #16879E;
}

.background-blue-green {
    background-color: #16879E;
}

.background-pink {
    color: #DA478B;
}

.background-pink {
    background-color: #DA478B;
}

.color-orange {
    color: #E9633A;
}

.background-orange {
    background-color: #E9633A;
}

.color-blue-dark {
    background-color: #5B5FD6;
}

.background-blue-dark {
    background-color: #5B5FD6;
}

.color-blue-light {
    color: #5FA4C4;
}

.background-blue-light {
    background-color: #5FA4C4;
}

.color-green-light {
    color: #79A93B;
}

.background-green-light {
    background-color: #79A93B;
}

.color-yellow-dark {
    background-color: #EA9E33;
}

.background-yellow-dark {
    color: #EA9E33;
}

.analyst-menu-toggle-button.open {
    color: var(--main-highlight-color);
    background-color: white;
}

.themed-component-open.open {
    color: var(--main-highlight-color);
}

.themed-component-hover:hover {
    color: var(--main-highlight-color);
}