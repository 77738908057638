.MobileMenuDropdown {
    display: none;
    width: 250px;
    position: fixed;
    right: 52px;
}

.MobileMenuDropdown.Open {
    display: block;
}

.MobileMenuDropdown ul {
    margin: 0;
}

.MobileMenuDropdown ul li {
    display: block;
}

.MobileMenuDropdown .menu-text {
    display: block;
}