.filter-selection-dialog.modal .widget-header h5 {
    line-height: 52px;
}

.filter-selection-dialog.modal .widget-header {
    justify-content: space-between;
}

.filter-selection-dialog.modal .widget-header .widget-toolbar {
    position: relative;
    right: unset;
    padding: 0;
}

.filter-selection-dialog.modal .widget-header .widget-toolbar .btnnmodalbtclose {
    margin-top: 3px;
}


.filter-selection-dialog.modal .widget-header {
    padding: 0 20px;
    height: 52px;
    box-sizing: border-box;
    box-shadow: inset 0 -1px 0 0 #E7E8EB;
}

.filter-selection-dialog .widget-header h5 {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
}

.bs-dialog.ui-draggable.filter-selection-dialog .widget-body {
    background-color: #F7F8FA;
}

.filter-selection-dialog .Footer {
    background-color: white;
    box-shadow: inset 0 1px 0 0 #E7E8EB;
    box-sizing: border-box;
    display: inline-flex;
    height: 62px;
    justify-content: space-between;
    padding: 16px 20px;
    width: 100%;
}

.filter-selection-dialog .Footer > div:last-child > button:first-child {
    margin-right: 10px;
}


.bs-dialog.ui-draggable.filter-selection-dialog .dialog-body {
    overflow: hidden;
}

.bs-dialog.ui-draggable.filter-selection-dialog .widget-body,
.bs-dialog.ui-draggable.filter-selection-dialog .widget-main {
    padding: 0;
}

.filter-selection-dialog .dialog-body .DialogContent {
    padding: 10px 20px;
}

.filter-selection-dialog .Footer .filter-action {
    margin: 0;
    height: 30px;
    text-transform: uppercase;
    font-size: 13px;
    min-width: 90px;
}

.filter-selection-dialog .Footer .clear-filter-action {
    float: left;
    width: unset;
}

.filter-selection-dialog .search-input-box {
    display: flex;
}

.filter-selection-dialog .search-input-box input {
    color: #292C31;
    width: 100%;
    padding: 4px 6px 4px 0px;
}

.filter-selection-dialog .search-input-box i {
    color: #000000
}

.filter-selection-dialog .search-input-box input,
.filter-selection-dialog .search-input-box button {
    outline: none;
    margin-bottom: 5px;
    background-color: transparent;
    border-color: transparent;
}

.filter-selection-dialog .select-items-component-items-container .radio label {
    margin-bottom: 12px;
    margin-left: 5px;
}

.filter-selection-dialog .disabledOverlay {
    background: rgba(0, 0, 0, 0.20);
    cursor: not-allowed;
    height: calc(100% - 52px - 62px);
    left: 0;
    position: absolute;
    top: 52px;
    width: 100%;
    z-index: 10000000;
}

.filter-selection-dialog .extraFilterActionsWrapper,
.filter-selection-dialog .filterActionButtonsWrapper {
    display: inline-flex;
}

.filter-selection-dialog .extraFilterActionsWrapper .BngField {
    align-items: center;
    display: inline-flex;
    gap: 12px;
    height: 30px;
}