.dialogWrapper {
  width: 920px;
  left: 36%;
}

.dialogWrapper .emptyTable {
  padding: 40px 0;
  position: relative;
  transform: none;
}

.dialogWrapper .tableWrapper {
  max-height: 350px;
  overflow: auto;
}

.executionTimesTable {
  margin: 20px;
}

.executionTimesTableColumn {
  width: 200px;
}

.IconSuccess {
  color: #21a55f;
}

.IconError {
  color: #e23d3d;
}

.innerTableRow {
  padding: 0 !important;
}
