.messageAction {
  max-height: 400px;
  overflow-y: auto;
}

.fieldLabel {
  font-size: 14px !important;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.sendSwitch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionMessage {
  display: flex;
}

.labelMessage {
  margin-right: 8px;
}

.lightLabel {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #888;
}

.containerMessage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attachmentPdf {
  display: flex;
  justify-content: right;
}
