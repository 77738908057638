.logsDialog {
  width: 60vw;
  left: 32%;
  height: 70vh;
}

.dialogInnerWrapper {
  height: calc(70vh - 60px);
}

.logsHeader {
  width: calc(100% - 24px);
  height: 50px;
  background-color: white;
  padding: 6px 12px;
  border-bottom: #e2e2e2 1px solid;
  display: flex;
  align-items: center;
}

.headerButtons {
  width: 100%;
  display: flex;
  margin-left: auto;
}

.logsTableWrapper {
  overflow: auto;
}
