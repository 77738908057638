.proposalPage {
  box-sizing: border-box;
  height: calc(100vh - 48px);
  overflow-y: auto;
  position: relative;
}

.proposalWrapper {
  background: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 25px 35px;
  max-width: 670px;
  margin: 30px auto 50px auto;
  min-width: 550px;
}

.proposalHeader {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  text-align: center;
}

.proposalPageLoader {
  min-height: 150px;
}

.titleSmallPrint {
  color: #888888;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}
