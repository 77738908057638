.MobileOrientationSwitch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
}

.MobileOrientationSwitch .MobileOrientationLabel {
    font-weight: 500;
    font-size: 14px;
}

.MobileOrientationSwitch .MobileOrientationSwitchButtons {
    color: #fff;
    display: flex;
    align-items: center;
}

.MobileOrientationSwitch .MobileOrientationSwitchButtons > div {
    cursor: pointer;
    opacity: 0.5;
    background-color: #005DFF;
    padding: 4px 7px;
}

.MobileOrientationSwitch .MobileOrientationSwitchButtons > div .Icon {
    font-size: 16px;
}

.MobileOrientationSwitch .MobileOrientationSwitchButtons > div:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.MobileOrientationSwitch .MobileOrientationSwitchButtons > div:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.MobileOrientationSwitch .MobileOrientationSwitchButtons > div.active {
    opacity: 1;
}