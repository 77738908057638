.ActivateBimDialog {
    width: 1094px;
    position: absolute;
    left: 33%;
    top: 5%;
    max-height: 90vh !important;
    overflow: hidden;
    cursor: auto !important;
}

.customButtonClass {
    min-width: 108px;
    width: fit-content;
}

.ActivateBimDialog :global(.bng-button.save) {
    min-width: 108px;
    width: fit-content;
}

.ActivateBimDialog :global(.DialogBody) {
    background: #FAFAFA;
    padding: 32px 62px;
}

.ActivateBimDialog :global(.widget-header) {
    border: 1px solid #E4E4E8;
}

.ActivateBimDialog :global(.widget-main) {
    padding-bottom: 0;
}

.ActivateBimDialog :global(.DialogFooter) {
    border-top: 1px solid #E4E4E8;
    max-height: 74px;
    min-height: 74px;
}

.ActivateBimDialog :global(h3) {
    color: black;
}

.ActivateBimDialog :global(table) {
    border: none !important;
    margin-bottom: 2px !important;
}

.ActivateBimDialog :global(th:first-of-type) {
    background: #FAFAFA;
    border: none;
}

.ActivateBimDialog :global(th:not(th:first-of-type)) {
    padding: 0;
    border: 1px solid #E5E5E5 !important;
    border-radius: 12px 12px 0 0 !important;
}

.tableBody {
    border-radius: 12px 0 0 12px !important;
    background: white;
    border: 1px solid #E5E5E5;
    font-weight: 500;
}

.tableBody :global(td > *) {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.ActivateBimDialog :global(td > li) {
    list-style: none;
}

.tableBody :global(tr > td:first-child) {
    vertical-align: middle;
    padding-left: 20px;
}

.selectButton {
    background: #005DFF !important;
    border-radius: 10px;
    width: 100%;
    color: white;
    padding: 10px 20px;
}

.tableHeader {
    border: 0;
    border-radius: 12px;
    border-top: 12px solid;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 28px 16px 28px;
}

.tableHeader .planName {
    color: #333333;
    font-size: 20px;
    text-align: center;
}

.tableBody :global(td) {
    border: 1px solid #E5E5E5;
    padding: 12px;
}

.darkText {
    color: #333333;
}

.enabled {
    color: #333333;
}

.disabled {
    color: #B8B8B8;
}

.enabled :global(i) {
    color: #005DFF;
}

.ActivateBimDialog :global(.DialogBody) {
    height: calc(65vh - 74px);
    overflow: scroll;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.footNote {
    font-size: 10px;
    color: #858585;
    width: 100%;
    text-align: right;
    display: block;
}

.envDimensions :global(h3) {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
    text-align: left;
}

.envDescription {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #858585;
    width: 95%;
}

.allSlidersWrapper {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
}

.sliderWrapper {
    width: 45%;
}

.sliderWrapper :global(span) {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
    display: flex;
    align-items: center;
}

.slider {
    width: 100%;
}

.slider {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}

.ActivateBimDialog :global(.BngSliderWrapper) {
    height: 10px;
}

.ActivateBimDialog :global(.BngSliderTracker),
.ActivateBimDialog :global(.BngSliderSelection) {
    height: 8px;
}

.ActivateBimDialog :global(.BngSliderGrab) {
    height: 18px;
    width: 18px;
    background-color: rgb(0, 93, 255);
    box-shadow: inset 0 0 0 2px #fff;
}

.selectedColumn :global(strong),
.selectedColumn :global(span) {
    color: white !important;
}

.selectedColumn :global(button),
.selectedColumn :global(button:hover) {
    background: white !important;
    color: #005DFF !important;
}

.ActivatedBody {
    height: 492px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.ActivatedBody :global(span),
.ActivatedBody :global(h5) {
    margin-top: 10px;
    max-width: 515px;
    text-align: center;
}

.ActivatedBody :global(button) {
    margin-top: 20px;
    max-width: 100px;
}

.buttonDisabled {
    cursor: not-allowed;
    opacity: 0.3;
}

td li.objectColumnItem {
    align-items: start;
    justify-content: flex-start;
    text-align: left;
}

td li.objectColumnItem .itemLine {
    align-items: center;
    display: inline-flex;
    gap: 6px;
    width: 100%;
}

td li.objectColumnItem span {
    align-items: center;
    display: inline-flex;
    gap: 6px;
    margin-top: 4px;
    white-space: nowrap;
}

td li.objectColumnItem .infoIcon {
    color: #333333;
    font-size: 14px;
}