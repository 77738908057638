.CreateObjectMenuButton.dropdown-menu {
    width: 560px;
}

.CreateObjectMenuButton.dropdown-menu .nav-header {
    background-color: #ecf2f700 !important;
    color: #333333 !important;
    border-bottom: 1px solid #dddddd;
}

.CreateObjectMenuButton.dropdown-menu .nav-header .icon-plus {
    display: none;
}

.create-object-itens-container-div {
    width: 280px;
    cursor: pointer;
}

.create-object-itens-container-div > div:last-child {
    border-bottom: 0;
}

.create-object-item-container {
    width: 256px;
    height: 109px;
    padding: 6px 10px;
    float: left;
    border-bottom: 1px solid #ededed;
}

.create-object-item-container .menu_sttings {
    cursor: pointer !important;
    width: 256px !important;
}

.create-object-item-container:hover {
    background: #ffffff;
}

.create-object-itens-container table tr td {
    vertical-align: top;
    padding: 0;
}

.create-object-item-container.cockpit {
    border-left: 4px solid #528DE3;
}

.create-object-item-container.dash {
    border-left: 4px solid #EB354D;
}

.create-object-item-container.analysis {
    border-left: 4px solid #269C59;
}

.create-object-item-container.kpi {
    border-left: 4px solid #E9B23A;
}

.create-object-item-container.bigtable {
    border-left: 4px solid #AC4FC6;
}

.create-object-item-container.map {
    border-left: 4px solid #16879E;
}

.create-object-item-container.bigTable {
    border-left: 4px solid #528DE3;
}
