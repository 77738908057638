.ColorsDialog {
  width: 300px;
  height: 350px !important;
  margin-left: 0;
}

.ColorsList {
  height: 270px;
  overflow: auto;
  padding: 0 15px;
}

.ColorContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d4d4d4;
  height: 45px;
}

.ColorContainer:first-of-type {
  border-top: none;
}

.ColorDescriptionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
}

.ColorPickerPreview {
  width: 26px;
  height: 26px;
  border-radius: 20px;
  margin-right: 10px;
}

.ColorsDialog :global(.widget-header) {
  border-bottom: 1px solid #d4d4d4;
}

.ColorsDialog :global(.widget-main),
.ColorsDialog :global(.widget-body) {
  padding: 0;
}

.DialogFooter {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #d4d4d4;
}

.DialogFooter :global(.BngButton) {
  border-radius: 5px;
}

.DialogFooter > div > div > div > button:first-of-type {
  margin-right: 15px;
}
