.FiltersDialog {
    z-index: 24999 !important;
    height: 560px;
}

.FiltersDialogBackdrop {
    z-index: 24998 !important;
}

.FiltersDialog .widget-main {
    padding-bottom: 0 !important;
}

.FiltersDialog .conection-dialog-division.top {
    margin: 6px 0;
}

.FiltersDialog .dialog-body {
    padding-bottom: 10px;
}

.FiltersDialog .scroll-bar-table-wrapper {
    margin-top: 6px;
    background-color: #f6f6f6;
    margin-left: -2px;
}

.FiltersDialog .scroll-bar-filters-table {
    overflow: auto;
    max-height: 438px;
    background-color: #f6f6f6;
    border: 1px solid #E4E4E4;
}

.FiltersDialog .filter-search {
    width: 555px;
    margin-left: 20px;
    margin-right: 8px;
}

.select-structure-field-popper .Option i {
    font-size: 13px !important;
}

.FiltersDialog .search-button {
    color: #373737;
}

.FiltersDialog .scroll-bar-filters-table .table {
    margin-bottom: 0;
    border-top: none;
}

.FiltersDialog .filters-header {
    display: flex;
    align-items: center;
}

.FiltersDialog .filters-table-header {
    position: sticky;
    top: 0;
    z-index: 1;
}

.FiltersDialog .filters-table-title-id,
.FiltersDialog .filters-table-title-name,
.FiltersDialog .filters-table-title-props,
.FiltersDialog .filters-table-title-actions {
    line-height: 29px !important;
    padding: 6px !important;
    color: #707070;
}

.FiltersDialog .filters-table-title-id,
.FiltersDialog .filterlist-table-td-id {
    padding-left: 38px !important
}

.FiltersDialog .filters-table-title-name {
    width: 215px;
}

.FiltersDialog .filters-table-title-props {
    width: 126px;
}

.FiltersDialog .filters-table-title-actions {
    padding-left: 35px !important;
}

.FiltersDialog .filterlist-table-tr {
    height: 46px;
    line-height: 37px !important;
    font-size: 14px;
    padding: 4px 5px;
    vertical-align: middle;
}

.FiltersDialog .filterlist-table-tr:nth-child(odd) {
    background-color: #ffffff !important;
}

.FiltersDialog .filterlist-table-tr:nth-child(even) {
    background-color: #f6f6f6 !important
}

.FiltersDialog .table-condensed td {
    vertical-align: middle !important;
}

.FiltersDialog .BngTableTd {
    padding: 6px;
}

.FiltersDialog .filters-table-title-actions {
    text-align: center;
    width: 120px;
    color: #707070;
}

.FiltersDialog .select-structure-field {
    width: 242px;
    margin-left: 15px;
    margin-right: 10px;
}

.FiltersDialog .filterlist-table-td-actions {
    text-align: center;
}

.FiltersDialog .filter-color-picker {
    opacity: 1 !important;
}

.filterlist-table-td-actions i {
    font-size: 20px !important;
}

.FiltersDialog .filter-name-input {
    margin: 0;
}
