.ValidatePhoneNumberDialog .widget-main {
  padding: 0 10px 12px 10px;
}

.ValidatePhoneNumberDialog-subtitle {
  font-weight: 500;
  line-height: 16px;
  color: #555555;
  margin-bottom: 24px;
  place-self: flex-start;
}

.ValidatePhoneNumberDialog-code {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-bottom: 36px;
}

.ValidatePhoneNumberDialog-code input {
  width: 44px;
  height: 46px;
  background: rgba(0, 0, 0, 0.06) !important;
  border: 2px solid #d1d1d1 !important;
  border-radius: 4px !important;
  font-weight: 700;
  font-size: 32px !important;
  color: #333333 !important;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  padding: 0;
}

.ValidatePhoneNumberDialog-code input.has-value,
.ValidatePhoneNumberDialog-code input:focus {
  background: #ffffff !important;
  border: 2px solid #005dff !important;
}

.ValidatePhoneNumberDialog-code input.correct:focus,
.ValidatePhoneNumberDialog-code input.correct {
  border: 2px solid #00a355 !important;
}

.ValidatePhoneNumberDialog-code input.incorrect:focus,
.ValidatePhoneNumberDialog-code input.incorrect {
  border: 2px solid #e23d3d !important;
}

.ValidatePhoneNumberDialog-description {
  display: flex;
  margin-right: 148px;
  gap: 4px;
  align-items: center;
  position: absolute;
  top: 173px;
}

.ValidatePhoneNumberDialog-description-icon.incorrect {
  color: #e23d3d;
}

.ValidatePhoneNumberDialog-description-icon.correct {
  color: #00a355;
}

.ValidatePhoneNumberDialog-resend {
  margin-bottom: 24px;
}

.ValidatePhoneNumberDialog-resend a {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
}

.ValidatePhoneNumberDialog-resend a:hover {
  color: #005dff;
  text-decoration: underline;
}

.ValidatePhoneNumberDialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
