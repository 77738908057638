.closeAddonDialogButton {
  background: #333333 !important;
  color: #fff !important;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 321055;
}

.AddonActivateDialog {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.0717844);
  width: 540px;
}

.AddonActivateDialog :global(.dialog-body) {
  overflow: hidden;
}

.AddonActivateDialogBackdrop {
  background: #00000073 !important;
}

.AddonActivateDialog :global(.widget-body),
.AddonActivateDialog :global(.widget-header),
.AddonActivateDialog :global(.widget-main) {
  border-radius: 8px;
}

.headerWrapper {
  align-items: center;
  display: inline-flex;
  width: 100%;
}

.headerWrapper :global(.LinkedObjects) {
  align-items: center;
  display: inline-flex;
}

.userAvatarOuter {
  background: #ffffff;
  border-radius: 100%;
  height: 45px;
  width: 45px;
}

.groupIcon {
  align-content: center;
  align-items: center;
  color: #7f7f7f;
  display: flex;
  height: 34px;
  justify-content: center;
}

.userAvatar {
  background-size: cover !important;
  border-radius: 100%;
  height: 45px;
  width: 45px;
}

.arrowIcon {
  color: #888888 !important;
  font-size: 32px !important;
  margin: 0 34px;
}

.addonIcon {
  color: #337dff;
  font-size: 48px !important;
}

.addonActivateRequestTitleWrapper {
  margin: 22px 6px;
}

.addonActivateRequestTitle {
  color: #333333;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}

.addonActivateRequestTitle :global(span) {
  font-weight: 700 !important;
}

.addonActivateRequestDescription {
  color: #555555 !important;
  line-height: 16px;
}

.addonsActionButtons {
  border-radius: 4px;
  color: #fff;
  padding: 10px 25px;
  transition: transform 0.2s;
}

.addonsActionButtons:hover {
  transform: scale(1.02);
}

.rejectButton {
  background: #e23d3d !important;
}

.rejectButton:hover {
  background: #e23d3d !important;
}

.activateButton {
  background: #005dff !important;
}

.activateButton:hover {
  background: #506dea !important;
}

.addonActionButtonWrapper {
  display: flex;
  gap: 14px;
  padding-top: 20px;
  justify-content: center;
}

.changeAddonButtonWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.changeAddonButton {
  background: #005dff !important;
  color: #fff !important;
  position: fixed;
  transition: all 0.2s;
  top: 25%;
  z-index: 321055;
}

.changeAddonButton.disabled {
  background: #e4e4e4 !important;
  cursor: not-allowed;
}

.changeAddonButton:not(.disabled):hover {
  background: #506dea !important;
  padding: 10px;
}

.changeAddonButton.leftButton {
  transform: translateX(-310px);
}

.changeAddonButton.rightButton {
  transform: translateX(310px);
}

.animationWrapper {
  overflow: hidden;
}

.addonChangeAnimationRight {
  animation: swipeRight 500ms ease-out 0s 1 normal none;
  animation-iteration-count: 1;
}

@keyframes swipeRight {
  0.0% {
  }
  50% {
    opacity: 0;
    transform: translate(-500px);
  }
  50.1% {
    transform: translate(500px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.addonChangeAnimationLeft {
  animation: swipeLeft 500ms ease-out 0s 1 normal none;
  animation-iteration-count: 1;
}

@keyframes swipeLeft {
  0.0% {
  }
  50% {
    opacity: 0;
    transform: translate(500px);
  }
  50.1% {
    transform: translate(-500px);
  }
  100% {
    opacity: 0;
    transform: translate(0);
  }
}

.selectAddonButtonWrapper {
  align-items: center;
  display: inline-flex;
  gap: 15px;
  height: 20px;
  justify-content: center;
  position: fixed;
  transform: translateY(50px);
  width: 100%;
  width: 492px;
}

.selectAddonButton {
  background: #f6f6f6 !important;
  border-radius: 100%;
  padding: 8px;
}

.selectAddonButton:not(.selected):hover {
  background: #f6f6f6 !important;
  border: 0 !important;
  padding: 12px;
}

.selectAddonButton.selected {
  background: #005dff !important;
}
