.BaseErrorPage {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
}

.BaseErrorPage .BaseError {
  width: 580px;
  padding: 40px 80px;

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 12px;
}

.BaseErrorPage .BaseError .Title {
  font-size: 24px;
  font-weight: 500;
}

.BaseErrorPage .BaseError .Description {
  font-size: 16px;
  word-break: keep-all;
}

.BaseErrorPage .btn-link {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #005dff !important;
  display: inline-flex;
  align-items: center;
}
