.AdminUserList {
    margin: 20px 10px;
    position: relative;
}

.AdminUserListHeader {
    display: flex;
    align-items: center;
}

.AdminUserListHeader form {
    position: relative;
    width: 100%;
}

.AdminUserListHeader .AddUserButton {
    width: 50px;
    margin-top: 0;
}