.main {
}

.tableContainer {
  height: 50vh;
}

.tableContainer :global(.BngTableTh) {
  padding-top: 0;
}
