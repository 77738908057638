.ChartConfDropListener .color-container .colorhextxt {
    display: none;
}
.ChartConfDropListener .color-container .color {
    border-radius: 0;
    width: 12px;
    height: 12px;
    border: none;
}

.ChartConfDropListener .conf-drop-trigger {
    margin-left: 6px;
    cursor: pointer;
    display: inline-block;
}

.ChartConfDropListener .color-container {
    padding: 0;
    border: 1px solid white;
    width: 12px;
    height: 12px;
    border-radius: 0;
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
}

.ChartConfDropListener td .checkbox {
    padding: 0;
    width: 12px;
    height: 12px;
    margin: 5px 0 0 7px;
}

.ChartConfDropListener table th {
    font-size: 11px !important;
}

.ChartConfDropListener .drop-container {
    width: 380px;
    z-index: 35;
    position: absolute;
    top: 25px;
    left: 90px;
    cursor: auto;
}

.ChartConfDropListener.inner-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 35;
    display: block;
    content: " ";
    background-color: #333;
    opacity: 0.5;
}

.ChartConfDropListener .dropdown-menu {
    width: 320px;
    background-color: white;
    box-shadow: 0 0 5px #ccc;
    padding: 5px 10px !important;
}

.ChartConfDropListener .dropdown-menu .header *,
.ChartConfDropListener .dropdown-menu .fields .span6 > div:first-child {
    color: #333;
    text-shadow: none;
    font-size: 14px !important;
    font-weight: normal;
}

.ChartConfDropListener .dropdown-menu .fields input,
.ChartConfDropListener .dropdown-menu .fields select,
.ChartConfDropListener .dropdown-menu .fields option,
.ChartConfDropListener .dropdown-menu .fields .clear-button {
    font-size: 14px !important;
}

.ChartConfDropListener hr {
    margin: 10px 0;
}

.ChartConfDropListener select {
    width: 100%;
}

.ChartConfDropListener .header {
    margin-bottom: 12px;
    padding-top: 5px;
    border-bottom: 1px solid #Ccc;
    font-weight: 500;
    height: 15px;
}

.ChartConfDropListener .header > i {
    float: right;
}

.ChartConfDropListener .ic-container .colorhextxt {
    display: block;
    width: 125px;
    margin-top: -30px;
    margin-left: 32px;
}

.ChartConfDropListener .ic-container .color {
    width: 28px;
    height: 28px;
    border: 1px solid #ccc;
}

.ChartConfDropListener .ic-container .color-container {
    margin: 0
}

.ChartConfDropListener .ic-container {
    margin-bottom: 15px;
    position: relative;
}

.ChartConfDropListener .chart-visualization-list.disabled .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: lightgray;
    opacity: 0.2;
    cursor: not-allowed;
    z-index: 100;
}

.limit-measure-tag-table {
    overflow: hidden;
    white-space: nowrap;
    max-width: 247px;
    text-overflow: ellipsis;
}