.ExportMenuButton.dropdown-menu {
    width: 400px;
}

a.sched-item div.row-fluid {
    display: flex;
    align-items: center;
}

a.sched-item:focus {
    background-image: none !important;
}

.scheduling-button {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 5px;
}

.SchedulingDropdown.marginDropdown {
    margin-left: 60px;
}

.sched-title {
    width: 274px;
    font-size: 14px;
    padding-left: 10px;
    color: #555555;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
}

a.sched-item:hover {
    background: #ffffff !important;
}

a.sched-item {
    cursor: inherit;
}

a.sched-item:focus {
    background: #f7f8fa;
}

.sched-item .button-export-container {
    width: 16px !important;
    height: 16px !important;
    margin-right: 5px;
}

.ExportMenuButton.dropdown-menu > li:hover {
    background: #fff !important;
}

.export-menu-popper {
    z-index: 1000001;
    width: auto;
}

.export-menu-dropdown {
    width: 40px;
}

.export-menu-dropdown .Icon.notranslate.material-icons {
    width: 23px;
}

.ExportMenuButton .title-description {
    width: 274px;
    font-size: 12px;
    padding-left: 10px;
    color: #888888;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
}

.ExportMenuButton .title-wrapper {
    display: flex;
    flex-direction: column;
}

.button-export-activation .Icon,
.button-export-info .Icon,
.button-export-edit .Icon,
.button-export-description .Icon,
.button-export-send .Icon,
.button-export-delete .Icon {
    width: 24px;
    height: 22px;
    font-size: 24px;
    color: #555555;
}

.button-export-activation span,
.button-export-info span,
.button-export-edit span,
.button-export-description span,
.button-export-send span,
.button-export-delete span {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #555555;
}

.BngClickOutsideOverlay.export-menu-popperOverlay {
    z-index: 1000001;
}

.nav-bar-button.ExportMenuButton > div > i {
    color: white;
    line-height: 50px;
}

.nav-bar-button.ExportMenuButton > div > i:hover {
    cursor: pointer;
}

.BngEmpty.SchedulingEmptyPage {
    position: unset;
    text-align: center;
    transform: unset;
    margin: 20px 0 0 0;
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;
}

.ExportMenuButton > .enabled {
    border-left: 4px solid #528DE3;
}

.ExportMenuButton > .disabled {
    border-left: 4px solid #EB354D;
}

.button-export-activation.enabled .Icon {
    color: blue;
}