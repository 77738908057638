.UserGroupListDialog {
    height: 594px;
}

.headerDivider {
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: #E2E2E2 1px solid;
}

.dialogHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.createButton {
    border-radius: 6px;
    font-size: 17px;
    cursor: pointer;
    right: 0;
    padding: 3px;
    margin-left: 27px;
    color: #005dff;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.createButton:hover {
    background-color: #eee;
}

.searchBar {
    width: 330px;
    margin-right: 17px;
}

.userGroupTableWrapper {
    margin-top: 6px;
    background-color: #f6f6f6;
    margin-left: -2px;
    height: 466px;
}

.userGroupScroll {
    overflow: auto;
    max-height: 438px;
    background-color: #f6f6f6;
    border: 1px solid #E4E4E4;
}

.nameColumn {
    padding-left: 48px !important;
}

.nameRow {
    padding-left: 48px !important;
}

.actionsColumn {
    padding-left: 20px !important;
    width: 120px;
}

.columnHeaderAccessPermissions > div {
    justify-content: center;
}

.accessPermissions {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 37px;
    padding-left: 10px;
    font-size: 12px;
    color: #373737;
    font-weight: 500;
    align-items: center;
}

.accessPermissions :first-child {
    justify-content: center;
    padding-left: 0;
    margin-left: 0;
    opacity: unset;
}

.accessPermissions :global(.div-information-group .group-item-slide-name) > div {
    display: flex;
    align-items: center;
}

.accessPermissions:global(.div-information-group) {
    justify-content: center;
    opacity: unset;
}

.accessPermissions:global(.group-item-slide-name) {
    opacity: unset;
}

.ownerTd :global(.div-information-group .group-item-slide-name) {
    opacity: unset;
}

.ownerTd :global(.div-information-group .group-item-slide-name .nameOwnerWrapper) {
    display: flex;
    align-items: center;
}

.ownerTd :global(.div-information-group .group-item-slide-name .nameOwnerWrapper):first-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #3a87ad;
    color: white;
    border-radius: 15px;
    padding-right: 5px;
    padding-left: 5px;
    height: 20px;
}

.objectsLinked :global(.div-information-group .group-item-slide-name) > div {
    display: flex;
    align-items: center;
}

.objectsLinked:global(.div-information-group) {
    justify-content: center;
    opacity: unset;
}

.objectsLinked:global(.group-item-slide-name) {
    opacity: unset;
}

.accessPermissions:global(.group-item-slide-name) {
    opacity: unset;
}

.accessPermissions :global(.div-information-group .group-item-slide-name) > div {
    display: flex;
    align-items: center;
}

.accessPermissions:global(.div-information-group) {
    justify-content: center;
    opacity: unset;
}

.userAvatarOuter {
    background: #ffffff;
    border-radius: 100%;
    width: 34px;
    height: 34px;
}

.userAvatar {
    width: auto;
    height: 34px;
    border-radius: 100%;
    background-size: cover !important;
}

.columnHeadLinkedObjects > div {
    justify-content: center;
}