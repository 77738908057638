.ExpertConf {
    width: 500px;
    padding: 5px 10px !important;
    overflow: hidden;
    max-height: calc(80vh - 50px);
    left: 28px;
}

.ExpertConf table th,
.ExpertConf table td {
    padding: 4px;
    vertical-align: middle;
}

.ExpertConf table td.text-center {
    text-align: center;
}

.ExpertConf table * {
    font-size: 12px;
}

.ExpertConf legend {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    margin-bottom: 5px;
    height: 25px;
}

.ExpertConf .op-boxes {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0;
}

.ExpertConf .op-box {
    border: 1px transparent;
    border-radius: 4px;
    background-color: #2283c5;
    cursor: pointer;
    height: 32px;
    width: 24%;
    margin: 0 5px 5px 0;
    font-size: 13px;
    text-align: center;
    transition: opacity 0.5s;
}

.ExpertConf .op-box:hover {
    opacity: 0.6;
}

.ExpertConf .op-box img {
    background-color: white;
    margin-bottom: 5px;
    margin-top: 5px;
}

.ExpertConf .op-box span {
    display: block;
    float: none;
    color: #fff;
    font-size: 12px;
    letter-spacing: .4px;
    font-weight: 500;
    padding: 6px 0;
}

.ExpertConf .formula-table-container .formula-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;
}

.ExpertConf .formula-table-container {
    overflow: auto;
    max-height: calc(70vh - 180px);
}

.ExpertConf .formula-table-container table {
    table-layout: fixed;
    margin-bottom: 10px;
}

.ExpertConf .close-button {
    cursor: pointer;
    float: right;
    margin-top: 3px !important;
    color: #000;
    border-radius: 100%;
    transition: background-color .4s ease;
}

.ExpertConf .close-button:hover {
    background-color: #cccccc75;
}