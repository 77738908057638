.DimensionTypeSelector {
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  padding: 6px;
  justify-content: space-between;
}

.DimensionTypeSelector,
.DimensionOption {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  color: #333333;
  padding: 2px 6px;
}

.DimensionOption:global(.AdvancedOptionsPopper) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DimensionOption:hover {
  cursor: pointer;
  background-color: #f3f3f3;
}

.selectedIconWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

.BngDropdown {
  width: 20vh;
}

.BngDropdown:global(.disabled) {
  cursor: not-allowed;
  background-color: #e6e6e6;
  opacity: 0.4;
}

.PopperOptions {
  box-shadow: 0 0 4px 0 #00000040;
  padding: 1px;
  border-radius: 4px;
  z-index: 99;
}

.PopperOptions:global(.AdvancedOptions.bng-dropdown-parent) {
  max-width: 150px;
}

.DimensionOption {
  padding: 2px 0 2px 8px;
  gap: 4px;
  background-color: #ffffff;
  border-top: 1px solid #e4e4e4;
  &:first-child {
    border-top: unset;
  }
}

.DimensionOption:global(.not-allowed),
.DimensionOption:global(.not-allowed) > i {
  cursor: not-allowed;
  background-color: #E6E6E6;
  color: #D1D1D1;
}

.selectedOptionIcon,
.dimensionSelectedOptionIcon {
  color: #333333;
  margin-right: 5px;
}

.dimensionSelectedOptionIcon {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}

.MaskOptionDropdown {
  width: 92%;
  padding: 2px 8px 2px 8px;
  gap: 4px;
  background-color: #ffffff;
  border-top: 1px solid #e4e4e4;
}

.PopperOptions:global(.Overlay) {
  z-index: 99;
  background-color: unset;
}

.MaskOptionDropdownOption {
  border: none;
  border-radius: unset;
  padding: unset;
}

.VisualizationName {
  width: 18vh;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-bottom: 0 !important;
  border-radius: 8px !important;
  color: #333333 !important;
}

.RenameField {
  display: flex;
  align-items: center;
  gap: 5px;
}

.RenameFieldInput {
  width: 130px;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-bottom: 0 !important;
  border-radius: 8px !important;
  color: #333333 !important;
}

.RenameFieldIcon {
  font-size: 20px !important;
  margin-right: 5px;
}

.ConfirmRenameIcon {
  color: blue;
  cursor: pointer;
  font-size: 18px !important;
  border-radius: 100%;
  padding: 2px;
}

.ConfirmRenameIcon:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.selectedIconWrapper,
.selectedIcon {
  max-width: 19vh;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.OriginFieldName {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #333333;
}

.BngTableHeaderClassName {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #333333;
}

.fieldValidationIcon {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.fieldValidationIcon:global(.close) {
  color: #e23d3d;
}

.fieldValidationIcon:global(.priority_high) {
  color: #faa133;
}

.columnConfigurationTable :global(tbody tr td) {
  padding: 0 12px 0 12px !important;
}

.Checkbox:global(.AdvancedOptionCheckbox) {
  display: flex;
  align-items: center;
}

.AdaAiColumnInfoIcon {
  color: #555555;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: left !important;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.AdaAiColumnHeaderWrapper {
  display: flex;
}

.StructureConfigurationAdaAiColumnButton {
  width: 32px;
  height: 24px;
  padding: 4px 8px 4px 8px;
  gap: 4px;
  border-radius: 60px;
}

.AdaAiIcon {
  position: absolute;
}

.CheckMark {
  width: 8px;
  height: 8px;
  color: #00a355;
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  text-align: center;
  position: relative;
  top: 5px;
  left: 15px;
}
