.ProgressBar {
}

.ProgressBar .progress {
    height: 10px;
    margin-bottom: 0;
    border-radius: 8px;
}

.ProgressBar .progress .bar {
    border-radius: 0 8px 8px 0;
}

.ProgressBar .progress-info .bar {
    background-color: #2876fd;
}

.ProgressBar .progress .bar .dot {
    width: 6px;
    height: 6px;
    background-color: black;
    opacity: 0.2;
    float: right;
    border-radius: 100%;
    margin-right: 3px;
    margin-top: 2px
}