.colorGradientWrapper {
  width: inherit;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.colorGradientSelectionBarContainer {
  height: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorGradientSelectionBar {
  width: inherit;
  height: inherit;
  border-radius: 16px;
}

.colorGradientNode {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  background-color: white;
  border: 2px solid #e4e4e4;
  box-shadow: 0px 1px 2px 0px #00000080;
  transition: transform 0.1s;
}

.colorGradientNode:hover {
  transform: scale(1.3);
  cursor: pointer;
}

.transparentSemiCircle {
  background-image: url('MinimalBgTransparent.png');
  width: 5px;
  height: 10px;
  position: relative;
  border: 2px solid #e4e4e4;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  left: 5px;
  border-left: none;
  bottom: 2px;
}

.rotationWrapper {
  width: inherit;
  height: 22px;
  margin-top: 8px;
}

.rotationContainer {
  display: flex;
  height: inherit;
  width: fit-content;
  margin-left: auto;
  margin-right: 20px;
  gap: 8px;
}

.rotationSpan {
  color: #888888;
  font-size: 10px;
  font-weight: 500;
  line-height: 25px;
}

.rotationInputContainer {
  width: fit-content;
  height: 22px;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  padding-right: 5px;
}

.rotationInputContainer::after {
  content: 'º';
}

.gradientColorSolidWrapper {
  margin-top: 14px;
}

.rotationClock {
  width: 22px;
  height: 22px;
  border: 1px solid #e4e4e4;
  border-radius: 100%;
  position: relative;
}

.rotationClockMiddleDot {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #888888;
  border-radius: 100%;
  left: 10px;
  top: 10px;
}

.rotationClockPointer {
  position: absolute;
  border-left: 1px solid #888;
  width: 0;
  height: 9px;
  left: 11px;
  top: 3px;
  transform: rotate(360deg);
  transform-origin: bottom;
}

.rotationInput {
  width: 45px;
  height: 14px;
  border: none !important;
  color: #333333 !important;
  font-size: 12px !important;
  font-weight: 500;
  margin: 0 !important;
  text-align: end;
  appearance: textfield;
}

.rotationInput::after {
  content: 'º';
}
