.banner {
  width: 120%;
  padding-right: 28px;
}

.innerTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: start;
}

.innerDescription {
  margin-top: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.field {
  margin-bottom: 18px;
}

.field:last-child {
  padding-top: 6px;
}

.field:last-child :global(label) {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  max-width: 400px;
}

.field :global(label) {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.field :global(input) {
  margin-bottom: 0;
  width: 97%;
}

.field :global(input[type='checkbox']) {
  margin-top: 8px;
  margin-left: 1px;
  width: 15px !important;
  height: 16px !important;
}

.field :global(.PhoneInput) {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  width: 97%;
  padding-left: 0px;
}

.field :global(.PhoneInput .PhoneInputCountry) {
  padding: 9px 3px 9px 9px;
  margin-right: 0;
}

.field :global(.PhoneInput .PhoneInputCountry .PhoneInputCountrySelectArrow) {
  margin-bottom: 3px;
  margin-left: 10px;
}

.field :global(.PhoneInput input.PhoneInputInput) {
  border-color: transparent;
  margin-bottom: 0;
}
