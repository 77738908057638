.BngBigTable__fieldList {
    background-color: #fff;
    border-radius: 5px;
    padding: 8px 15px 15px;
    margin: 10px;
}

.BngBigTable__fieldList__field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 7px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.BngBigTable__fieldList__field[data-visible="false"] {
    opacity: 0.8;
}

.BngBigTable__fieldList__field span {
    font-size: 12px;
    width: 100%;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.BngBigTable__dataform-icon {
    font-size: 18px !important;
    cursor: pointer;
    color: #888;
    margin-left: 5px;
}

.BngBigTable__dataform-icon.active {
    color: #3558ef;
}

.BngBigTable__dataform-icon.warning {
    width: 27px;
    cursor: help;
    color: var(--warning-color);
}

.BngBigTable__dataform-check {
    font-size: 14px !important;
    user-select: none !important;
}

.BngBigTable__fieldList__field--container {
    display: flex;
    flex: 0 0 75px;
    justify-content: flex-end;
}

.ApplyBigTableData {
    position: fixed;
    bottom: 0;
    right: 51px;
    min-width: 340px;
    height: 55px;
    border-radius: 0;
    z-index: 50;
}

.ApplyBigTableData .AccordionContent {
    padding: 10px;
}

.ApplyBigTableData .AccordionContent button.bng-button {
    width: 120px;
    margin: 0;
}

.AccordionWrapper.ObjectRightMenuAccordion.BigTableDataAccordion {
    height: calc(100% - 143px);
}

.ApplyBigTableData .AccordionContent button.bng-button.cancel {
    background-color: #e24c38 !important;
    color: #ffffff !important;
    margin-right: 5px;
}

.bng-search.BigTableSearch:not(.SimpleBngSearch) .search-button.alwaysOpen {
    display: none;
}

.bng-search.BigTableSearch:not(.SimpleBngSearch) {
    width: 93%;
    margin-left: 12px;
    margin-bottom: 10px;
}

.bng-search.BigTableSearch {
    background-color: transparent;
}

.BngBigTable__fieldList__field .structure-icon {
    margin-right: 5px;
}