.AddonInfoAd {
  align-items: center;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  display: flex;
  height: 342px;
  overflow: hidden;
  position: relative;
}

.imageWrapper {
  align-items: center;
  background: #0083fb;
  border-radius: 0 48px 48px 0;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 50%;
}

.addonImage {
  width: 95%;
}

.titleWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 10px;
  overflow: hidden;
  position: relative;
  width: 50%;
}

.backgroundImage {
  mix-blend-mode: darken;
  opacity: 0.3;
  position: absolute;
}

.addonPrefix {
  font-weight: 500 !important;
  font-size: 32px !important;
  line-height: 32px !important;
  width: 100%;
}

.addonTitle {
  font-weight: 800;
  font-size: 32px;
  margin: 0;
  width: 100%;
}
