.objectTagWrapper {
  background-color: #005dff;
  width: fit-content;
  padding: 2px 8px;
  color: #fff;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  user-select: none;
}

.objectTagWrapperFullSize {
  width: 95%;
}

.objectIcon {
  margin-left: auto;
}

.shareObjectsDropdownPopper {
  z-index: 321051;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  width: 320px;
  height: fit-content;
  max-height: 600px;
}

.shareObjectsDropdownHeader {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.shareObjectsDropdownHeaderTitle {
  font-size: 17px;
  font-weight: 500;
}

.shareObjectsDropdownDraggableContainer {
  padding: 12px;
  max-height: 200px;
  overflow: auto;
}

.shareObjectsDropdownButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 12px;
}

.shareObjectsDropdownButtonsContainer .OrderDropdownConfirmButton {
  width: 120px;
}

.draggableItem {
  margin-bottom: 5px;
}
