.UserGroupsDialog .widget-header {
  border-bottom: 1px solid #e6e7ea;
}

.UserGroupsDialog .DialogFooter {
  border-top: 1px solid #e6e7ea;
}

.UserGroupsDialog .modal-body {
  overflow: unset;
}

.UserGroupsDialog .DialogBody {
  background: #f6f6f6;
  height: 52vh;
  max-height: 550px;
  min-height: 420px;
  padding: 0 32px;
  overflow-y: hidden;
}

.UserGroupsDialog .usrGrpNameWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding: 18px 0;
}

.UserGroupsDialog .usrGrpNameLbl {
  width: 16%;
  color: rgba(0, 0, 0, 0.65);
}

.UserGroupsDialog .usrGrpNameInput {
  line-height: 34px;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  color: #2b2b2b;
  padding: 0 6px;
}

.UserGroupsDialog .usrGrpNameInput:focus {
  border-color: #005dff;
}

.UserGroupsDialog .usrGrpSearch {
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 18px;
  transition: border linear 0.2s, box-shadow linear 0.2s;
}

.UserGroupsDialog .usrGrpSearch:hover {
  border-color: #b5b5b5;
}

.UserGroupsDialog .usrGrpSearch input {
  color: #000000;
  font-weight: normal !important;
}

.UserGroupsDialog .userList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  height: 34vh;
  min-height: 290px;
  max-height: 370px;
  align-content: flex-start;
}

.UserGroupsDialog .DialogFooter {
  display: flex;
  justify-content: end;
}

.UserGroupsDialog .GroupDeleteButton {
  color: #ff0000;
  display: flex;
  align-items: center;
  margin: 10px 0 0 20px;
  cursor: pointer !important;
  padding: 6px;
  border-radius: 2px;
  white-space: nowrap;
}

.UserGroupsDialog .GroupDeleteButton:hover {
  background: #e4e6e9;
}

.UserGroupsDialog .labelDeleteGroup {
  margin: 0;
  padding: 0 6px;
  cursor: pointer !important;
}
