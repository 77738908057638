.DeleteFileDialog .FooterButtons {
    display: flex;
    padding: 10px 15px;
    justify-content: flex-end;
}

.DeleteFileDialog .removeButton {
    background-color: #FF0000 !important;
    border-color: #fff;
    color: #fff !important;
}

.DeleteFileDialog .widget-header {
    border-bottom: #E2E2E2 1px solid;
    flex-wrap: wrap;
}

.DeleteFileDialog .DialogBody {
    display: flex;
    height: 300px;
    background: #F6F6F6;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
}

.DeleteFileDialog .fileToBeDeleted {
    background: #f8d775;
    border-radius: 10px;
    display: flex;
    align-items: center;
    color: #000;
    margin: 8px;
    height: 34px;
    padding: 5px;
}

.DeleteFileDialog .fileIcon {
    padding: 5px;
    margin-right: 7px;
}

.DeleteFileDialog .fileName {
    padding-right: 5px;
}

.DeleteFileDialog .BngTable .BngTableTd:first-child {
    padding: 0;
}

.DeleteFileDialog .BngTable .BngTableTh:first-child {
    padding-left: 22px;
}

.DeleteFileDialog .nameTableRow {
    height: 100%;
    line-height: 45px;
    align-items: center;
    display: flex;
}

.DeleteFileDialog .nameTableRow i {
    font-size: 24px;
}

.DeleteFileDialog .removeWarn {
    background: #FCF8E3;
    border: 1px solid #FBEED5;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 16px;
    width: 97%;
    padding: 14px 14px 14px 22px;
}

.deleteFileDialogCheckbox {
    margin-right: auto;
    margin-top: 28px;
}