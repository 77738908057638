.bng-dropdown-parent.export-toolbar {
    z-index: 100;
}

a.unstyled-link {
    color: unset;
    display: flex;
    align-items: center;
}

a.unstyled-link.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.share-button .selected {
    background-color: #E6EFFF;
    color: #005DFF;
    height: 22px;
    border-radius: 14px;
    padding: 0 8px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.ExportToolbarItem.Disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.bng-dropdown-parent.export-toolbar .ul-elements-list li a,
.bng-dropdown-parent.export-toolbar .ul-elements-list .CopyClipboardComponent {
    padding: 10px 0 !important;
    margin-left: 24px;
    width: 90% !important;
}

.export-header-dropdown {
    gap: 8px;
    display: inline-flex;
    align-items: center;
    width: 80%;
    padding-top: 6px !important;
    padding-bottom: 0 !important;
    margin-left: 24px;
}

.bng-dropdown-parent.export-toolbar .ul-elements-list .CopyClipboardComponent {
    padding-bottom: 12px !important;
}

.bng-dropdown-parent.export-toolbar .ul-elements-list li a > i,
.bng-dropdown-parent.export-toolbar .ul-elements-list .CopyClipboardComponent > i,
.bng-dropdown-parent.export-toolbar .ul-elements-list .export-header-dropdown > i {
    font-size: 24px;
    width: 24px;
}

.bng-dropdown-parent.export-toolbar .ul-elements-list li a,
.bng-dropdown-parent.export-toolbar .ul-elements-list .CopyClipboardComponent {
    gap: 8px;
    font-weight: 500;
}

.bng-dropdown-parent.export-toolbar .ul-elements-list {
    width: 240px;
    display: block;
    margin: 5px 0;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 20%);
    background: #fff;
    border: 1px;
    border-radius: 12px;
    text-align: left;
    right: 8px;
    z-index: 1;
    cursor: default;
    padding: 0;
    left: 0;
    position: absolute;
    list-style: none;
}

.bng-dropdown-parent.export-toolbar .ul-elements-list li:not(:first-child):hover {
    background-color: #E6EFFF;
    color: #005DFF;
    cursor: pointer;
}

.bng-dropdown-parent.export-toolbar .ul-elements-list li:last-child:hover {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
