.bng-add-button {
    margin: 2px 0;
    padding: 4px 8px;;
    border-radius: 4px;
    display: flex;
    line-height: 24px;
    cursor: pointer;
}

.bng-add-button:hover {
    background-color: #e6e6e6;
}

.bng-add-button span {
    padding-left: 5px;
}