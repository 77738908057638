.Panel {
  width: 70vw !important;
  height: 850px !important;
  height: 850px;
  min-width: 800px;
  max-width: 1000px;
  margin: 5px;
}

.Header {
  display: flex;
  align-items: center;
  padding: 0 32px !important;
}

.HeaderItems {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
}

.PageTitle {
  margin: 20px 0;
  font-size: 24px;
  white-space: nowrap;
  font-weight: bold;
}

.Body {
  position: relative;
  overflow: auto;
  width: 100%;
}

.FormContainer {
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}

.SelectFeatureContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ConfirmSelectButton {
  margin: 20px 0 0;
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  background-color: rgba(var(--bng-connection-blue), 1) !important;
}

.ConfirmSelectButton:hover {
  background-color: rgba(var(--bng-connection-blue), 0.8) !important;
}

.ButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LockAllButton {
  margin-top: 20px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  background-color: rgba(var(--bng-connection-orange), 1) !important;
}

.LockAllButton:hover {
  background-color: rgba(var(--bng-connection-orange), 0.8) !important;
}

.UnlockAllButton {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(var(--bng-connection-green), 1) !important;
}

.UnlockAllButton:hover {
  background-color: rgba(var(--bng-connection-green), 0.8) !important;
}

.LockButton {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(var(--bng-connection-orange), 1) !important;
}

.LockButton:hover {
  background-color: rgba(var(--bng-connection-orange), 0.8) !important;
}

.UnlockButton {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--bng-connection-green), 1) !important;
}

.UnlockButton:hover {
  background-color: rgba(var(--bng-connection-green), 0.8) !important;
}

.Locked {
  background-color: rgba(var(--bng-connection-orange), 0.8) !important;
}

.Unlocked {
  background-color: rgba(var(--bng-connection-green), 1) !important;
}

.ContentWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.SideMenu {
  height: 754px;
  width: 300px;
  background-color: #fff;
  border-right: 1px solid #f7f8fa;
  padding-left: 32px;
  padding-right: 32px;
}

.SelectButton {
  margin: 0;
  width: 100%;
}

.FeatureSelectionLabel {
  margin-top: 32px;
}

.Divisor {
  display: flex;
  justify-self: center;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Avatar {
  height: 60px;
  width: 60px;
}

.UnlockedBadge {
  width: 100px;
  height: 32px;
  border-radius: 50px;
  color: #fff;
  background-color: rgba(var(--bng-connection-green), 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.LockedBadge {
  width: 100px;
  height: 32px;
  border-radius: 50px;
  color: #fff;
  background-color: rgba(var(--bng-connection-orange), 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Name {
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.FeatureNotSelected {
  position: relative;
  display: flex;
  align-items: center;
  top: 45px;
  left: 5px;
  background-color: #fff;
  max-width: fit-content;
  padding: 10px;
  height: 40px;
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 #b5b5b5, 0 4px 15px 0 rgba(0, 0, 0, 0.4);

  font-size: 20px;
  color: rgba(0, 0, 0, 0.65);
}

.FeatureManagement {
  position: relative;
  padding: 40px 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FeatureManagement :global(.BngTable) {
  width: 100%;
}

.FeatureManagement :global(.BngTable .BngTableTh):first-child,
.FeatureManagement :global(.BngTable .BngTableTd):first-child {
  padding-left: 32px;
}

.FeatureManagement :global(.BngTable .BngTableTh):last-child,
.FeatureManagement :global(.BngTable .BngTableTd):last-child {
  padding-right: 32px;
}
.FeatureManagement .Header :global(.bng-search) {
  width: 400px;
  margin-right: 30px;
}
.FeatureManagement .Header :global(.bng-search.closed) {
  width: 82px;
}

.FeatureManagement :global(.BngTable) tbody {
  min-height: 610px;
}
.FeatureManagement :global(.BngTable) tbody tr {
  height: 78px;
}
