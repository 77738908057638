.SelectObjectDialog .scroll-bar-object-select {
    overflow: auto;
    max-height: 300px;
    min-height: 300px;
    background-color: #fff;
}

.SelectObjectDialog .tree-table-object td {
    border-top: 1px solid #f3f3f3;
}

.SelectObjectDialog .tree-table-object tr:last-child td {
    border-bottom: 1px solid #f3f3f3;
}

.SelectObjectDialog .radio-button-object {
    opacity: 0;
    float: right;
    z-index: 12;
    width: 18px !important;
    height: 18px !important;
}

.SelectObjectDialog .object-select-dialog .modal-body {
    overflow: hidden !important;
    position: unset !important;
}

.SelectObjectDialog .icon-object-text {
    font-family: 'Roboto', "Open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.SelectObjectDialog .span12 .form-group {
    width: 460px;
}

.SelectObjectDialog.bs-dialog {
    width: 850px;
    margin-left: -425px;
}

.SelectObjectDialog.bs-dialog.modal-body {
    overflow-y: hidden;
}

.SelectObjectDialog .select-project-folder {
    height: 30px;
    width: 233px;
    border: 1px solid #EFEFEF;
    border-radius: 4px;
    background-color: #FFFFFF;
    margin-left: 12px;
}

.SelectObjectDialog .label-select-project {
    opacity: 0.8;
    color: #000000;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin-top: 6px;
}

.SelectObjectDialog.TransparentBg .scroll-bar-object-select {
    background-color: transparent;
}

.SelectObjectDialog.RowFullWidth .select-path > div {
    display: flex !important;
}