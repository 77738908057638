.MessageWrapper {
  display: flex;
  justify-items: center;
  height: 85vh;
  flex-direction: column;
  align-items: center;
}

.MessageWrapper img {
  margin-top: 10vh;
  height: 28vh;
}

.MessageWrapper a {
  background-color: #337dff;
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  margin-top: 32px;
  padding: 12px;
  border-radius: 12px;
}

.MessageWrapper :global(.coming_soon) {
  font-size: 40px;
  font-weight: 600;
  line-height: 47px;
  text-align: center;
  margin: 5vh 0 5vh 0;
}

.MessageWrapper :global(.coming_soon_title) {
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  margin-bottom: 1vh;
}

.MessageWrapper :global(.coming_soon_message) {
  font-size: 20px;
  font-weight: 300;
  line-height: 23px;
  text-align: center;
}
