.DialogResponsive.NewConnection {
    width: 450px;
    justify-content: center;
}

.NewConnection label.control-label {
    width: 130px;
    font-size: 14px;
    white-space: normal !important;
}

.NewConnection .ConnectionButtonsContainer {
    display: flex;
    justify-content: center;
    height: 42px;
    box-shadow: 0px 0 0px 1px #EFEFEF;
    border-radius: 4px;
    width: 100%;
}

.NewConnection .ConnectionButtonsContainer button {
    background-color: white !important;
    opacity: 0.7;
    color: #000000 !important;
    letter-spacing: 0.15px;
    line-height: 14px;
    display: flex;
    align-items: center;
    border: 1px solid #EFEFEF;
    border-bottom: 0;
    border-top: 0;
    justify-content: center;
    flex-grow: 1;
}

.New-Connection__form__icon {
    margin-right: 10px;
}

.NewConnection .ConnectionButtonsContainer button:hover{
    background-color: #EFEFEF !important;
}

.NewConnection .ConnectionButtonsContainer button.ConnectionProperties.active {
    background-color: #005dff55 !important;
}

.NewConnection div.AddProperties {
    margin-top: 10px;
    float: right;
}

.NewConnection .BngCheckbox.checkbox {
    width: 15px;
}

.NewConnection .ConnectionButtonsContainer .BngDropdown {
    display: flex;
}

.NewConnection .BlockContent hr {
    margin: 0 0 15px;
}

.NewConnection .BlockContent .EditCredentials {
    margin-bottom: 0;
    float: right;
}

.NewConnection .BlockContent .EditCredentials label {
    width: unset;
}

.bng-add-button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.bng-add-button.disabled .add-button {
    fill: #888;
}