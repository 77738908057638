.UiBlocker.Loading .BngEmpty {
    visibility: hidden;
}
.BngIntegrationRequest {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    margin: 0;
    display: flex;
    justify-content: center;
}

.BngIntegrationRequest .TitleSuccess {
    text-align: center;
}

.BngIntegrationRequest .Container {
    display: flex;
    flex-direction: column;
    width: 400px;
}

.BngIntegrationRequest .Image {
    height: 150px;
    object-fit: contain;
}

.BngIntegrationRequest .Title {
    padding: 16px 0 8px 0;
    word-break: normal;
    color: #595959;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.8px;
    text-align: center;
}

.BngIntegrationRequest .Message {
    word-break: normal;
    color: #595959;
    font-size: 14px;
    letter-spacing: 0.47px;
    line-height: 16px;
}

.BngIntegrationRequest .BngIntegrationRequestForm {
    margin-top: 20px !important;
}

.BngIntegrationRequest .BngIntegrationRequestForm textarea {
    resize: none;
}

.BngIntegrationRequest .BngIntegrationRequestButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.BngIntegrationRequest .BngIntegrationRequestButtonWrapper .Spacer {
    flex: 1;
}

.BngIntegrationRequest .BngButtonSuggestion {
    border-radius: 8px;
    padding: 12px 32px;
    height: auto;
    font-weight: 500;
    background-color: #3558EF !important;
    border-color: #3558EF;
    color: #fff !important;
}

.BngIntegrationRequest .BngButtonSuggestion.Cancel {
    background-color: #dadada !important;
    border-color: #dadada;
    color: #000 !important;
}

.BngIntegrationRequest .BngButtonSuggestion:hover {
    background-color: #617be8 !important;
}


.BngIntegrationRequest .BngButtonSuggestion.Cancel:hover {
    background-color: #a0a0a0 !important;
}