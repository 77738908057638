.activatePlanContainer {
  display: flex;
  margin-left: 10px;
}

.labelWrapper {
  width: fit-content;
  border-radius: 16px;
  background-color: #e6efff;
  border: 1px solid #ccdfff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
}

.activateLabel {
  color: #005dff;
  font-weight: 700;
  font-size: 12px;
  margin: 0;
}
