.BngDraggableIcon,
div[class*=BngDraggableItem] {
    display: flex;
    align-items: center;
}

.BngDraggableItem.HideDraggable .BngDraggableIcon {
    visibility: hidden;
}

.BngDraggableIcon {
    color: #ccc;
}