.floating-object-actions.on-react {
    top: 48px;
    right: 14px;
    margin-top: 0 !important;
    height: 36px;
}

.floating-object-actions.on-react > div,
.floating-object-actions.on-react .breadcrumb-toolbar > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    min-height: unset;
}

.floating-object-actions.on-react #BreadcrumbToolbarContainer {
    float: right;
}

.floating-object-actions.on-react #EditObjectContainer {
    float: left;
}

.floating-object-actions.on-react .breadcrumb-toolbar > div > .toolbox-button {
    margin-left: 14px;
}

.toolbox-button.relative {
    position: relative;
}

.toolbox-button.inactive {
    opacity: 0.5;
}

.toolbox-button.active {
    color: #0088CC;
}

.toolbox-button-dis i.material-icons, .toolbox-button-dis span {
    font-size: 18px;
    color: rgba(0, 0, 0, .5) !important;
    margin: 0;
}

.floating-object-actions i.material-icons, .toolbox-button i.material-icons, .floating-object-actions .iceCmdLnk span.material-icons {
    font-size: 18px;
    cursor: pointer;
    margin: 0;
}

.floating-object-actions li.dropdown-menu-item a span.material-icons, .floating-object-actions li.dropdown-menu-item a i.material-icons {
    padding: 0 6px 0 10px;
    font-size: 16px;
    vertical-align: sub;
}

.floating-object-actions i:hover, .toolbox-button i:hover, .floating-object-actions span.material-icons:hover {
    color: #4272FE;
}

.floating-object-actions {
    position: absolute;
    right: 10px;
    display: inline;
    margin-top: -26px !important;
}

.floating-object-actions .floating-object-button {
    float: left;
    width: 32px;
    text-align: center;
}

.floating-object-button .inline.pull-right.position-relative,
.floating-object-button .inline.pull-left.position-relative {
    float: inherit;
}

/******* ajuste em botoes para compatibilidade (fix que pode ser removido depois de tirar o jsf) ********/
.floating-object-button .btn.btn-primary.iceCmdBtn-dis.toolbox-button-dis {
    background: transparent !important;
    height: 26px;
    width: 26px;
}

.floating-object-button .btn.btn-primary.iceCmdBtn-dis.toolbox-button-dis + i.material-icons {
    margin-top: -26px;
}

.floating-object-button .iceCmdLnk-dis.toolbox-button span.material-icons {
    font-size: 18px;
}


