.GenericFileSource {
}

.GenericFileSource .tab-content {
  height: 210px;
  overflow: auto;
}

.dialog.maximize .GenericFileSource .tab-content {
  height: calc(100vh - 360px);
}
