.BngTable {
    width: 100%;
    border: none;
    border-collapse: separate;
    border-radius: 0;
    color: #7F7F7F;
    font-weight: 500;
}

.BngTable thead {
    background-color: white;
}

.BngTable .BngTableTd {
    border-top: 1px solid #E2E2E2;
}

.BngTable .BngTableTh,
.BngTable .BngTableTd {
    text-align: left;
    padding: 12px;
}

.BngTable .BngTableTh.text-center,
.BngTable .BngTableTh.text-center .flex-center-items,
.BngTable .BngTableTd.text-center {
    text-align: center;
    justify-content: center;
}

.BngTable .BngTableTr .BngTableTd {
    background-color: #F6F6F6;
}

.BngTable .BngTableTr:nth-child(even) .BngTableTd {
    background-color: white;
}

.BngTable .BngTableTr:hover .BngTableTd {
    background-color: #DCE9F8;
    color: #6B87F1;
}

.BngTable.InnerTable {
    border: 0;
}

.BngTable.Narrow td.BngTableTd {
    padding: 4px 12px;
    font-size: 13px;
}

.BngTable .WithoutSort {
    transform: rotate(90deg);
}

.BngTable .Sort .Icon {
    font-size: 16px;
}

.BngTable .Sortable {
    cursor: pointer;
}

.BngTable.StickyHeader thead th {
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: 0 2px 2px -1px #E2E2E2;
    z-index: 1;
}

.BngTable.bt-1 .BngTableTh {
    border-top: 1px solid #e2e2e2;
}

.BngTable.sm .BngTableTh {
    line-height: 29px;
}

.BngTable.sm .BngTableTh,
.BngTable.sm .BngTableTd {
    padding: 6px;
}