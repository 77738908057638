.BngHorizontalScroller {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.BngHorizontalScroller .Arrow {
    position: absolute;
    height: calc(100% - 2px);
    width: 48px;
    border: 1px solid #E9E9E9;
    border-radius: 4px;
    background-color: #FFFFFF;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    top: 0;
}

.BngHorizontalScroller .Arrow.Left {
    left: 0;
    box-shadow: 20px 0 20px 5px rgba(246, 246, 246, 0.8);
}

.BngHorizontalScroller .Arrow.Right {
    right: 0;
    box-shadow: -20px 0 20px 5px rgba(246, 246, 246, 0.8);
}

.BngHorizontalScroller .Arrow .Icon {
    opacity: 0.5 !important;
}

.BngHorizontalScroller .Arrow.Left .Icon {
    transform: rotate(180deg);
}

.BngHorizontalScroller .Children {
    white-space: nowrap;
}