.ProjectMergeDialog .conflict-title {
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #CCC;
    margin: 10px 0;
}

.ProjectMergeDialog .conflict-body {
    max-height: 240px;
    overflow: auto;
}

.ProjectMergeDialog .parentFolder {
    max-width: 100% !important;
}