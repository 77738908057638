.BngGradientSelector {
    padding: 15px 5px;
    background-color: #fff;
}

.BngGradientSelector__Container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.BngGradientSelector__Gradients {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.BngGradientSelector__Gradient {
    cursor: pointer;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    display: flex;
    margin: 10px;
}

.BngGradientSelector__GradientTick {
    height: 24px;
    width: 12px;
}

.BngGradientSelector__Pagination {
    flex: 24px 0 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
}

.BngGradientSelector__Pages {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.BngGradientSelector__PageDot {
    border-radius: 6px;
    height: 6px;
    width: 6px;
    background-color: #ccc;
    margin: 0 3px;
}

.BngGradientSelector__PageDot--active {
    background-color: #666;
}

.BngGradientSelector__Title {
    font-weight: bold;
}