.color-container {
    display: inline-block;
}

.color-container.on-input {
    width: 106px;
    height: 18px;
}

.color-container .color[disabled] {
    background-color: #eee !important;
    cursor: not-allowed;
}

.color-container .color {
    border: 1px solid #ccc;
    display: inline-block;
    width: 28px;
    height: 28px;
}

.color-container .colorhextxt {
    width: 70px;
    margin: 0 0 0 5px;
    vertical-align: top;
}