.bng-dropdown-parent.popper-mobile {
    zoom: 1 !important;
    width: 250px;
}

.bng-dropdown-parent.dashboard-item-popper.popper-mobile {
    min-height: unset !important;
}

.dash-item-newmenu.menu-mobile button:hover .Icon {
    color: #000;
}

.dash-item-newmenu.menu-mobile button {
    background: transparent;
    outline-color: transparent;
    box-shadow: none;
    padding: 0;
    border: 1px solid transparent;
    border-radius: 100%;
}

.dash-item-newmenu.menu-mobile .BngDropdown.DashboardItemMenuDropdown {
    opacity: 1;
}


/*handle overflow of children elements*/
ul.bng-dropdown.container-dash-item-newmenu:not(.prevent-children-overflow) ul {
    left: calc(90%);
}

ul.bng-dropdown.container-dash-item-newmenu.prevent-children-overflow ul {
    left: calc(-100% - 4px);
}

ul.bng-dropdown.container-dash-item-newmenu.prevent-children-overflow ul.sub-container-dash-item-newmenu-align {
    left: -144px;
}

ul.bng-dropdown.container-dash-item-newmenu.prevent-children-overflow ul.sub-container-dash-item-newmenu-explorer {
    left: -276px;
}

ul.bng-dropdown.container-dash-item-newmenu.prevent-children-overflow-top {
    top: unset;
    bottom: 0;
}