.table {
  overflow: auto;
  height: 400px;
  border: 1px solid #ebebeb;
}

.BngUserCockpitConfDialog :global(.modal-dialog),
.BngUserCockpitConfDialog :global(.modal-content),
.BngUserCockpitConfDialog :global(.modal-body) {
  overflow-y: hidden;
}