.colorPickerButton {
  border-radius: inherit;
  width: 26px;
  height: 26px;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.colorPickerButtonTransparentContainer {
  background-image: url('../form/BngInputColorTransparent.png');
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

:global(.BngColorPickerDropdownPopperOverlay) {
  z-index: 321051;
}

:global(.BngColorPickerDropdownPopper) {
  z-index: 321052;
  width: 330px;
  min-height: 425px;
}
