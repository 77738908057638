.FilterWrapper {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
  height: 50px;
}

.NewConnectionButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #005dff;
  color: white;
  height: 35px;
  border-radius: 4px;
  padding: 0 15px 0 15px;
  cursor: pointer;
}

.tableContainer {
  overflow: auto;
  height: 335px;
  position: relative;
}
