/* Same as .iceOutConStat */
.loading-pulse-box.GlobalLoader {
  position: fixed;
  width: 0;
  height: 0;
  border: none;
  font-size: 0.5em;
  right: 80px;
  bottom: 70px;
  z-index: 30000;
  left: unset;
  top: unset;
}
