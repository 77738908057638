.navigation-tabs {
    position: relative;
    background: transparent;
    display: inline-flex;
    padding: 0 15px;
    margin: 0;
    z-index: 2;
}

.internal-tab .navigation-tabs {
    padding: 0;
}

ul.navigation-tabs > li {
    list-style-type: none;
}

.tabs-left .navigation-tabs {
    display: grid;
    margin-right: -1px;
    float: left;
    padding: 0;
}

.navigation-tab-link {
    align-items: center;
    background: transparent;
    border-radius: 8px 8px 0 0;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: transparent;
    display: inline-flex;
    text-align: center;
    cursor: pointer;
    list-style-type: none;
}

.navigation-tab-link.disabled {
    opacity: 0.5;
}

.navigation-tab-link.disabled > a {
    cursor: not-allowed;
}

.tabs-left .navigation-tab-link {
    border-radius: 8px 0 0 8px;
}

.navigation-tab-link:hover {
    background: #fbfbfb;
}

.navigation-tab-link:hover > a {
    opacity: 0.8;
}

.navigation-tab-link:focus {
    outline: none;
}

.navigation-tab-link > a {
    padding: .75em 1em;
    color: #000;
    opacity: 0.5;
    height: 24px;
    align-items: center;
    display: flex;
}

.internal-tab .navigation-tab-link > a {
    padding: .6em 1em .4em;
}

.navigation-tab-link > a > * {
    padding-right: 5px;
    font-size: 24px;
}

.navigation-tab-link.active {
    border: 1px solid #EFEFEF;
    border-bottom-color: #F6F6F6;
    background: #F6F6F6;
    opacity: 1;
    margin-bottom: -1px;
}

.internal-tab .navigation-tab-link.active {
    border-bottom-color: #FFF;
    background-color: #FFF;
}

.tabs-left .navigation-tab-link.active {
    border-right-color: #F6F6F6;
    margin: 0 -1px 0 0;
    border-bottom-color: #EFEFEF;
}

.internal-tab.tabs-left .navigation-tab-link.active {
    border-right-color: #FFF;
}

.navigation-tab-link.active > a {
    color: #3558EF;
    opacity: 1;
}

.tabbable.grey-bg .tab-content {
    background: #F6F6F6;
}

.tabbable .tab-content {
    border: 1px solid #EFEFEF;
    padding: 0;
    position: relative;
    z-index: 1;
}

.properties-tab-size-fix .clearfix .tabbable .tab-content{
    overflow: auto;
    height: 340px;
}

.tabbable .tab-content > div {
    padding: 10px 14px;
}

.tabbable .tab-content > div.content-full-width {
    padding-right: 0;
    padding-left: 0;
}

.tabbable.internal-tab .tab-content {
    background-color: #FFF;
}

.tabbable .tabs-left .tab-content.bordered {
    border-radius: 0 4px 4px 4px;
}

.tabbable .tab-content.bordered {
    border-radius: 4px;
}

.tab-buttons {
    padding: 0 0 0 10px;
}

.tab-buttons .button-container {
    margin-right: 5px;
}

.tab-buttons .button-container:last-child {
    margin-right: 0;
}

.tabbable .tabset-alert-icon {
    color: #F98900;
    left: -8px;
    position: relative;
}

.navigation-tabs .navigation-tab-link .tabTitleError {
    color: #b94a48;
}