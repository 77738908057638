.UserFilterFormDialog {
  max-height: 600px;
  width: 860px;
}

.UserFilterFormDialog :global(.DialogBody) {
  height: 48vh;
  max-height: 450px;
  overflow: hidden;
}

.UserFilterFormDialog :global(.widget-body),
.UserFilterFormDialog :global(.widget-main) {
  padding: 0;
}

.UserFilterFormDialog :global(.DialogFooter) {
  border: 1px solid #e6e7ea;
  padding: 12px 10px;
}

.containerNewUserFilter {
  margin-top: 5px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buttonNewUserFilter {
  color: #005dff !important;
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.usersListWrapper {
  width: 45%;
  padding: 10px 0 0 20px;
}

.usersListContainer {
  height: 43vh;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  overflow: auto;
  margin-top: 8px;
}

.usersListItem {
  border-bottom: 1px solid #d1d1d1;
  margin: 5px 15px;
  height: 40px;
}

.createGroupButton {
  cursor: pointer;
  color: #005dff;
  font-weight: 500;
  gap: 5px;
}

.eyeUserGroupsDialog {
  margin-left: 5px;
  font-size: 15px !important;
}

.eyeUserGroupsDialog:hover {
  color: #6f6e6e;
  cursor: pointer;
}