.BimIntegrationDataOrigin {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    max-height: 627px;
}

.BimIntegrationDataOrigin h6 {
    font-weight: 600;
    margin: 0;
    padding: 0;
    font-size: 15px;
}

.BimIntegrationDataOrigin .IgnoreLoadErrorsCheckbox label {
    margin: 0;
}

.BimIntegrationDataOrigin > hr {
    margin: 10px 0px;
}

.BimIntegrationDataOrigin .AbsoluteButtons {
    position: absolute;
    bottom: -60px;
    z-index: 999;
}

.BimIntegrationDataOrigin .BtnValidate {
    border-radius: 0;
    font-weight: bold;
    background-color: #000000 !important;
}

.BimIntegrationDataOrigin .BtnValidCredentials {
    padding: 7px 12px !important;
}

.BimIntegrationDataOrigin .BtnPreview {
    margin-left: 10px;
    border-radius: 0;
}

.BimIntegrationDataOrigin .Connections {
    display: flex;
    align-items: center;
}

.BimIntegrationDataOrigin .Connections .ConnectionsField {
    width: 95%;
    margin-right: 10px;
}

.BimIntegrationDataOrigin .Connections .ConnectionsButton {
    background-color: #005DFF !important;
    margin-top: 8px;
}

.BimIntegrationDataOrigin .BtnTestGA4 {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 15px;
    font-weight: bold;
    background-color: #005DFF !important;
}

.BimIntegrationDataOrigin .BtnTestGA4:hover {
    background-color: #0053e6 !important;
}
