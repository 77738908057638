.BngDropdownCheckboxPopper .bng-search:not(.SimpleBngSearch) .bng-search-input .search-field {
    border-bottom: none;
}

.bng-dropdown-parent.BngDropdownCheckboxPopper {
    background-color: #fff;
    z-index: 200;
}

.BngDropdownCheckboxPopperOverlay {
    z-index: 199;
}

.BngDropdownCheckboxPopper {
    padding: 10px 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.195822);
    border-radius: 8px;
    z-index: 11;
    width: 250px;
}

.BngDropdownCheckboxPopper .dropdownTitle {
    padding: 5px 0;
    position: relative;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    border-bottom: 1px solid #6e6e6e;
    width: 100%;
}

.BngDropdownCheckboxOpts {
    overflow-y: auto;
    max-height: 180px;
}

.BngDropdownCheckboxOpts .BngCheckbox label {
    margin-bottom: 0;
    white-space: nowrap;
}

.BngDropdownCheckboxOpts .item-icon {
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
    line-height: 20px;
    z-index: 12;
    width: unset !important;
    left: 90% !important;
}

.BngDropdownCheckboxOpts .checkbox-item-parent label span.lbl {
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
}