.on-publisher .cockpit-header-project-info {
    width: 40px;
}

.cockpit-header-panel-ul-container.on-publisher {
    margin-left: 0;
}

.cockpit-header-panel-ul-container.on-publisher .scroll-content {
    margin-left: 0;
    overflow: hidden !important;
}

.on-publisher .btn.btn-link.cockpit-index-anchor-button {
    padding: 8px 0 0 11px !important;
}

.on-publisher .cockpit-header-panel-ul > li {
    margin: 1px 4px 0 0;
}

.on-publisher .cpt-btn-item,
.cockpit-index-list-item-link-publisher {
    cursor: pointer;
}

.cpt-btn-item .icon-cockpit-item {
    position: absolute;
    line-height: 36px;
    font-size: 16px;
}
