.roleWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  width: fit-content;
  padding: 9px 15px;
}

.rolesDropdown :global(i) {
  padding: 0 8px 0 5px;
}

.rolesDropdown {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 8px;
  text-align: left;

  z-index: 321050 !important;
}

.rolesDropdownOverlay {
  z-index: 50;
}

.rolesDropdown :global(i) {
  font-size: 16px;
}

.rolesDropdown :global(span) {
  font-size: 14px;
}

.roleListItem {
  display: flex;
  align-items: center;
  padding: 11px 15px;
  font-weight: 500;
  color: #6e6e6e;
  cursor: pointer;
  line-height: 25px;
}

.roleListItem:hover {
  background: #efefef;
}

.SelectedRole {
  color: #005dff;
}
