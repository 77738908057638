.UiBlocker.Loading .BngError {
    visibility: hidden;
}
.BngError {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    margin: 0;
}

.BngError .Container {
    display: inline-block;
    width: 230px;
    cursor: pointer;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    padding: 10px;
}

.BngError .Container:hover {
    /*background-color: rgba(0, 0, 0, 0.08);*/
}

.BngError .Container:active {
    /*background-color: rgba(0, 0, 0, 0.20);*/
}

.BngError .Image {
    max-height: 100px;
}

.BngError .Title {
    padding: 16px 0 8px 0;
    font-size: 16px;
    color: #292C31;
    font-weight: bold;
    letter-spacing: 0.5px;
}

.BngError .Message {
    line-height: 16px;
}

.BngError .ClickHere {
    text-decoration: underline;
    color: #005dff;

}