.AnalysisMultiStepInfo {
    height: 500px;
    background: linear-gradient(120deg, rgba(35,41,214,1) 0%, rgba(0,172,248,1) 100%);
    position: fixed;
    z-index: 1008;
}

.AnalysisMultiStepInfo .PurpleBackground {
    background-color: #494B81;
}

.AnalysisMultiStepInfo.BngMultiStep .Step .Subtitle {
    color: #fff;
}

.AnalysisMultiStepInfo.BngMultiStep .Step .Title {
    color: #fff;
}

.AnalysisMultiStepInfo .widget-footer-pg-button {
    background-color: #70acf5;
}
.AnalysisMultiStepInfo .widget-footer-pg-button.active {
    background-color: #fff;
}

.AnalysisMultiStepInfo .bng-button.cancel {
    color: white !important;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
}

.AnalysisMultiStepInfo .bng-button.save {
    color: white !important;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
}

.AnalysisMultiStepInfo .bng-button.cancel.enable:hover {
    background-color: transparent !important;
    border: none !important;
}

.AnalysisMultiStepInfo .bng-button.save.enable:hover {
    background-color: transparent !important;
    border: none !important;
}

.AnalysisMultiStepInfo a {
    color: white;
    text-decoration: underline !important;
}