.home-folder-managment {
    border-radius: 4px;
    padding-top: 4px;
}

.FoldersPage > .BngPanel {
    width: 100%;
    border-radius: 4px;
}

.FoldersPage > .BngPanel .Body {
    position: relative;
    height: calc(100vh - 230px);
    overflow: auto;
    transition: height 0.2s ease-in-out, top 0.3s ease-out;
}

.FoldersPage > .BngPanel .Body.closed {
    height: 0;
}

.FoldersPage .BngTableTd {
    padding: 0;
    max-width: 400px;
}

.FoldersPage .BngTableTh {
    padding-left: 0 !important;
}

.FoldersPage .BngTable .BngTableTh:last-child {
    padding-left: 18px;
}

.FoldersPage .BngTable .BngTableTd:last-child {
    padding-right: 32px;
}

.FoldersPage .BngTable .BngTableTd:first-child,
.FoldersPage .BngTable .BngTableTd:nth-child(3),
.FoldersPage .folders-table-title-drilldown {
    padding: 0;
}

.FoldersPage .BngTable .BngTableTh:first-child {
    padding-left: 15px;
}

.FoldersPage .BngTable .BngTableTd:first-child {
    padding-left: 15px;
}

.FoldersPage .Header .bng-search.closed {
    width: 82px;
    padding: 0 10px 0 5px;
}

.FoldersPage .search-button {
    color: rgba(0, 0, 0, 0.54);
    transition: ease-in-out 250ms;
    transition-property: background-color, color;
}

.FoldersPage .FileNameWrapper {
    display: flex;
    align-items: center;
    max-width: 1030px;
}

.FoldersPage > .FloatCreateButton {
    position: absolute;
    left: 15px;
    top: 45px;
    padding: 12px;
}

.FoldersPage .borderedTableRow {
    height: 55px;
    display: flex;
    align-items: center;
    min-width: 100px;
    padding-right: 8px;
}

.FoldersPage .expandIconButton.disabled {
    opacity: 0 !important;
    cursor: alias;
}

.FoldersPage .BngIconButton:disabled {
    opacity: 0.5;
}

.FoldersPage .fileName {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.FoldersPage .fileIcon {
    vertical-align: middle;
    font-size: 24px;
    padding: 0 10px;
    color: #005DFF;
    height: 24px;
    width: 24px;
}

.FoldersPage .ActionsWrapper {
    display: inline-flex;
    width: 100%;
    justify-content: flex-end;
    min-width: 80px;
}

.FoldersPage .MemberPermissionWrapper {
    display: flex;
    justify-items: start;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 55px;
    scrollbar-color: #a3a3a3 transparent;
}

.FoldersPage .MemberPermission {
    background: #005DFF;
    margin: 3px 5px;
    border-radius: 25px;
    color: #FFFFFF;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
}

.FoldersPage .MemberPermission .permissionIcon {
    padding: 0 5px;
    font-size: 15px;
}

.FoldersPage .MemberPermission .permissionMember {
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 225px;
}

.FoldersPage .action-move i,
.FolderMenuPopper .action-move i {
    transform: rotate(45deg);
    font-size: 20px;
}

.FoldersPage .selected-items-wrapper {
    border-right: 1px solid #E2E2E2;
    margin-right: 5px;
    display: flex;
    align-items: center;
    padding-right: 5px;
}

.FoldersPage .BngCheckbox {
    justify-content: center !important;
    padding-left: 0px !important;
}

.FoldersPage .bng-search.closed .bng-search-input {
    width: 0px;
}

.FoldersPage .Header .bng-search.closed {
    width: 30px;
}

.FoldersPage .selected-items-info {
    background: rgba(0, 93, 255, 0.1);
    height: 24px;
    border: 1px solid rgba(0, 93, 255, 0.3);
    border-radius: 12px;
    color: #005DFF;
    padding: 0 12px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.FoldersPage .missingPermissions {
    background: #FCF8E3;
    border: 1px solid #7F7F7F;;
    box-sizing: border-box;
    border-radius: 25px;
    padding: 5px 10px;
}

.FoldersPage .PublicPermission {
    background: #fff;
    margin: 3px 5px;
    border: 1px solid #005DFF;
    border-radius: 25px;
    color: #005DFF;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
}


.FoldersPage .PublicPermission .permissionIcon {
    padding: 0 5px;
    font-size: 15px;
}

.FoldersPage .PublicPermission .permissionPublic {
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 225px;
}