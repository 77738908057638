.idx-tooltip-container {
    max-height: 200px;
    overflow: auto;
}

.idx-tooltip-container .img-rounded {
    margin: 0 10px 0 0;
    float: left;
    width: 144px;
    height: 67px;
}

.indexFilter.bng-search.open {
    width: 320px;
    margin-left: 20px;
    margin-top: 5px;
    border-bottom-color: #000000;
}

.indexFilter.bng-search:not(.SimpleBngSearch) .search-button {
    display: none;
}