.container-presentation-properties {
    border: 1px solid #EFEFEF;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 13px 23px;
}

.container-presentation-row {
    display: inline-flex;
    width: 100%;
    margin-top: 14px;
}

.container-presentation-properties .BngField .control-label {
    font-weight: 500;
}

.container-presentation-properties .BngField .help-inline {
    display: block;
}

.field-presentation-name {
    width: 371px;
    height: 32px;
}

.container-transition-style {
    margin-left: 38px;
}

.container-openObject {
    margin-top: 20px;
}

.container-presentation-row.last {
    margin-top: 27px !important;
    display: flex;
    justify-content: space-between;
}

.container-presentation-properties .container-refresh-time {
    display: inline-flex;
    position: relative;
}

.container-presentation-properties .container-refresh-time .BngField {
    margin-bottom: 0;
}

.container-presentation-properties .container-refresh-time .refresh-time-label {
    position: absolute;
    right: 12px;
    bottom: 5px;
    border-radius: 0 4px 4px 0;
    width: auto;
    height: 20px;
    min-width: 16px;
    padding: 4px 5px;
    font-size: 14px;
    background-color: #eee;
    border: 1px solid #ccc;
}

.presentation-input-transition-style {
    width: 149px;
    height: 32px;
}

.presentation-input-refreshPresentation {
    width: 220px;
}

.container-background-image-upload {
    margin-left: 58px;
}

.PresentationFilterDropdown.Filtered .Icon {
    color: #005dff;
}

.PresentationFilterDropdownPopper {
    width: 280px;
    z-index: 321050;
}
.PresentationFilterDropdownPopper .bng-dropdown {
    width: 224px;
    padding: 28px;
}

.PresentationButtonsWrapperComponent {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 0;
}

.PresentationSearch {
    width: 350px;
}

.BngClickOutsideOverlay.PresentationFilterDropdownPopperOverlay {
    z-index: 321050;
}