.ObjectExecMonitorDialog .TableContainer {
  max-height: 50vh;
  overflow: auto;
}

.ObjectExecMonitorDialog .BngTable.StickyHeader thead th {
  z-index: 12;
}

.ObjectExecMonitorDialog .RefreshBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.ObjectExecMonitorDialog .StatusIcon {
  font-size: 18px;
}