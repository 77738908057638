.content-full-width .bng-button {
    margin: 20px 15px 0 0;
}

.bng-button {
    width: 108px;
    height: 36px;
    border-radius: 2px;
    border: 1px solid;
    outline: 0;
}

.bng-button.save {
    background-color: #005dff !important;
    border-color: #005dff;
    color: #fff !important;
}

.bng-button.save.enable:hover {
    border-color: #506dea;
    background-color: #506dea !important;
}

.bng-button.cancel {
    background-color: #fff !important;
    border: 1px solid #6C6C6C;
    color: #000 !important;
}

.bng-button.cancel.enable:hover {
    background-color: #eee !important;
    border-color: #eee;
}

/*borderless*/

.content-full-width .bng-button.borderless {
    margin: 10px 10px 0 0;
    min-width: 88px;
}

.bng-button.no-placement {
    margin: 0;
}

.bng-button.borderless.save {
    border-color: transparent;
    background-color: transparent !important;
    color: #3558ef !important;
    font-weight: 500;
}

.bng-button.borderless.save.enable:hover {
    background: #005dff !important;
    color: #fff !important;
}

.bng-button.borderless.cancel {
    background-color: #fff !important;
    border-color: transparent;
    color: #000 !important;
    font-weight: 500;
}

.bng-button.borderless.cancel.enable:hover {
    background: #efefef !important;
}

.bng-button.fix,
.bng-button.btn-small.fix {
    margin: 0;
    white-space: nowrap;
    width: auto;
}

.bng-button.fix .material-icons {
    vertical-align: bottom;
}

.bng-button.action,
.bng-button.borderless.cancel.action {
    color: #3d3d3d !important;
    background-color: #0000 !important;
}

.bng-button.action:hover,
.bng-button.borderless.cancel.action:hover {
    background-color: white !important;
}


.btn-transparent {
    text-align: center;
    background-color: transparent !important;
    border: none;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #3A4045 !important;
    outline: 0;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.btn-transparent:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
}

.btn-transparent:active, .btn-transparent.active {
    background-color: rgba(0, 0, 0, 0.20) !important;
}

.btn {
    word-break: normal;
}

.btn.btn-primary.disable:hover {
    pointer-events: auto;
    cursor: default;
}

.Action {
    border-radius: 4px;
    padding: 12px 32px;
    height: auto;
    font-weight: 500;
}

.bng-button.fix.Action .material-icons {
    vertical-align: middle;
}

.bng-button.btn-small {
    width: 80px;
    height: 32px;
    font-weight: 500;
    border-radius: 4px;
}

.bng-button.btn-small .material-icons {
    font-size: 18px;
    line-height: 19px;
}

.btn.BngCancelButton {
    color: #3A4045 !important;
    font-weight: bold;
    border: none !important;
    transition: background-color 300ms;
    background-color: transparent !important;
    padding: 10px 16px;
    outline: none;
    border-radius: 2px;
}

.btn.BngCancelButton:hover {
    background-color: rgba(150, 150, 150, 0.10) !important;
}

.btn.BngCancelButton:active {
    background-color: rgba(150, 150, 150, 0.20) !important;
}

.DarkMode .btn.BngCancelButton {
    color: rgb(239, 239, 239) !important
}

.DarkMode.btn.BngCancelButton:hover {
    background-color: rgba(239, 239, 239, 0.10) !important;
}

.DarkMode.btn.BngCancelButton:active {
    background-color: rgba(239, 239, 239, 0.20) !important;
}

.btn.BngPrimaryButton,
.btn.BngWarnButton {
    font-weight: bold;
    border: none !important;
    transition: background-color 300ms;
    padding: 10px 16px;
    outline: none;
    border-radius: 2px;
}

.btn.BngPrimaryButton {
    background-color: #005DFF !important;
}

.btn.BngPrimaryButton:hover {
    background-color: rgba(0, 93, 255, 0.85) !important;
}

.btn.BngPrimaryButton:active {
    background-color: rgba(0, 93, 255, 0.70) !important;
}

.btn.BngWarnButton {
    background-color: rgb(245, 158, 11) !important;
}

.btn.BngWarnButton:hover {
    background-color: rgba(245, 158, 11, 0.85) !important;
}

.btn.BngWarnButton:active {
    background-color: rgba(245, 158, 11, 0.70) !important;
}

.BngDialogActionButton {
    border-radius: 6px;
    font-size: 17px;
    cursor: pointer;
    right: 0;
    padding: 3px 8px 3px 3px;
    color: #005dff !important;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: auto;
    background-color: transparent !important;
    transition: background-color 300ms ease;
    gap: 5px
}

.BngDialogActionButton:hover {
    background-color: #eee !important;
}