.BngField.Required .control-label:after {
  content: '*';
  color: red;
  padding-left: 2px;
}

.BngField input,
.BngField select {
  margin-bottom: 0;
  color: #555;
}

.BngField .controls > * {
  transition: border linear 0.2s, box-shadow linear 0.2s;
}

.BngField .controls > *:hover,
.BngField .controls > *:disabled {
  border-color: #b5b5b5;
  z-index: 1;
}

.BngField.error .controls > *:hover {
  border-color: #f09784;
  z-index: 2;
}

.BngField.error .controls .cleditorMain {
  border-color: #f09784;
}

.BngField .controls > *:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #696969;
  border-color: #9d9d9d;
  background-color: #ffffff;
  outline: 0;
  z-index: 1;
}

.BngField.Inline {
  display: flex;
  align-items: center;
  gap: 10px;
}

.BngField.Inline .control-label {
  margin: 0;
  white-space: nowrap;
}

.BngField.Inline .controls {
  flex-grow: 1;
}

.BngField .help-inline {
  word-break: break-word;
}

.BngField .BngField {
  margin-bottom: 0;
}

.BngField.floatingLabel {
  position: relative;
}

.BngField.floatingLabel label {
  position: absolute;
  top: 16px;
  left: 13px;
  color: #787878;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  transition: top 200ms ease-in-out, font-size 200ms ease-in-out;
}

.BngField.floatingLabel.focused label,
.BngField.floatingLabel.withValue label {
  top: 6px;
  font-size: 12px;
  font-weight: 400;
}

.BngField.floatingLabel input {
  height: 50px;
  font-weight: 500;
  font-size: 15px;
  padding-top: 28px;
  color: #333333 !important;
}

.DarkMode .BngField input {
  background: #393939 !important;
  background-color: #393939 !important;
}

.DarkMode .BngField input,
.DarkMode .BngField select {
  margin-bottom: 0;
  color: #efefef !important;
}

.DarkMode .BngField.floatingLabel label,
.DarkMode .BngField label {
  color: #999999;
}
