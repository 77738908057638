.centeredParagraphs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.subTitle {
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
}

.text {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  text-align: left;
  margin-bottom: 5px;
}

.buttonGroup {
  display: flex;
  margin-top: 20px;
  gap: 100px;
}

.buttonGroup button {
  padding: 4px 12px;
  border-radius: 16px;
  border: 1px solid #337dff;
  background-color: #f9f9f9;
}

.buttonGroup button:hover {
  background-color: #ccdfff;
}

.buttonGroup button:active {
  background-color: #669eff;
}
