.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}

.levelWrapper {
  width: 95%;
  margin-right: 10px;
}

.hierarchyWrapper {
  width: 95%;
}

.levelSelectorRoot,
.hierarchyHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  border-bottom: 1px solid #c4c4c4;
  color: #79abd7;
  padding-bottom: 10px;
}

.button {
  border-radius: 5px !important;
  height: 25px;
  display: flex;
  align-items: center;
  background-color: #2183c5 !important;
  color: white !important;
  font-size: 12px;
}

.button:global(.disabled) {
  cursor: not-allowed;
  opacity: 0.6;
}

.buttonIcon {
  font-size: 18px !important;
}

.levelPopperClassName {
  height: 250px;
  z-index: 321050;
}

.dropdownBlocker {
  background-color: white;
  border: 1px solid lightgray;
  padding: 5px;
  border-radius: 5px;
  height: 238px;
}

.checkboxLevelSelection {
  margin: 5px 0 5px 0;
}

.fieldsListWrapper {
  height: 205px;
  overflow-y: scroll;
}

.addFieldsButton {
  border-radius: 5px !important;
  height: 30px;
  display: flex;
  align-items: center;
}

.removeAllFieldsButton {
  color: red !important;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
}

.selectedFieldsList {
  height: 322px;
  width: 100%;
  overflow-y: scroll;
}

.selectedField {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectedField:not(:last-child) {
  border-bottom: 1px solid darkgray;
}

.dialogButtonsWrapper {
  display: flex;
  flex-direction: row-reverse;
}

.confirmationButton {
  background-color: #095dff;
}

.confirmationButton:global(.disabled) {
  cursor: not-allowed;
  opacity: 0.6;
}

.cancelButton {
  margin-right: 10px;
}

.dimensionName {
  height: 85px;
}

.draggableIcon {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  display: flex;
  overflow: hidden;
  line-height: 37px;
  padding-left: 10px;
  font-size: 12px;
  color: #373737;
  font-weight: 500;
  opacity: 0.8;
  align-items: center;
}

.draggableIcon :first-child {
  font-size: 20px;
}

.labelStyles {
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.draggableTd {
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 30px;
  width: 10%;
}

.fieldTd {
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 30px;
  width: 129px;
}

.actionTd {
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 30px;
  width: 40px;
}
