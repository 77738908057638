.highlight-items {
    padding: 10px;
}

.widget-header > :first-child {
    float: none;
    line-height: unset;
    font-size: initial;
    align-items: center;
}

.expandable-header {
    display: inline-flex;
}

.highlight-note-content-item-title {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #383838;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: .6px;
    padding-bottom: 7px;
}

.highlight-note-content-item-description {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    height: 45px;
    color: #383838;
    font-size: 12px;
    line-height: 15px;
}

.highlight-note-content-item-card {
    border-radius: 0 0 2px 2px;
    cursor: pointer;
}

.highlight-note-content-item-desc {
    padding: 15px;
    background: #ffffff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.highlight-note-content {
    overflow: hidden;
    height: 400px;
}

.highlight-note-content-item-image {
    border-bottom: 1px solid #e4e4e4;
}

.highlight-note-content-item-image img {
    max-height: 157px;
    border-radius: 4px 4px 0 0;
}

.highlight-note-content-item {
    width: 280px;
    max-height: 265px;
    margin: 10px 5px;
    border: 1px solid #E4E4E8;
    box-shadow: 0 5px 10px -2px rgba(0,0,0,0.07);
    border-radius: 4px;
}

.highlight-note-right-item-title a,
.highlight-note-right-item-description a{
    text-decoration: none;
    color: #ffffff;
}

.dialog-body,
.widget-body{
    border-radius: 4px;
}

.highlight-nav-tab.active{
    display: inline-flex;
}

.highlight-nav-tab{
    display: none;
}

.arrow {
    margin-left: 5px;
    cursor: pointer;
}

.highlight-items-center {
    display: block;
    margin: auto;
}