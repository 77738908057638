.FormDialog {
  width: 450px;
  justify-content: center;
}

.ButtonsContainer {
  display: flex;
  justify-content: center;
  height: 42px;
  box-shadow: 0 0 1px #efefef;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 15px;
}

.ButtonBase {
  letter-spacing: 0.2px;
  line-height: 14px;
  display: flex;
  align-items: center;
  border: 1px solid #efefef;
  border-radius: 5px;
  justify-content: center;
  flex-grow: 1;
}

.ButtonTestConnection {
  background-color: #fff !important;
  opacity: 0.7;
  color: #000 !important;
}

.ButtonTestConnection:hover {
  background-color: #efefef !important;
}

.ButtonTestConnection:disabled {
  background-color: #efefef !important;
}

.ButtonGoogle {
  padding: 0 15px 0 1px;
  font-weight: 500;
  background-color: #4285f4 !important;
  color: #fff !important;
}

.ButtonGoogle:hover {
  cursor: pointer;
  background-color: #397be8 !important;
}

.ButtonGoogle:disabled {
  cursor: pointer;
  background-color: #397be8 !important;
}

.ButtonGoogle > img,
.ButtonMicrosoft > img {
  height: 28px;
  border-radius: 15px;
}

.ButtonMicrosoft {
  padding: 0 15px 0 1px;
  font-weight: 600;
  background-color: #2f2f2f !important;
  color: #fff !important;
}

.ButtonMicrosoft:hover {
  cursor: pointer;
  background-color: #2f2f2ff5 !important;
}

.ButtonMicrosoft:disabled {
  cursor: pointer;
  background-color: #2f2f2ff5 !important;
}

.containerTable {
  overflow-y: auto;
  width: 100%;
  max-height: 200px
}