/*added css to make adjustments to given screen resolutions. Some screen patterns are already pre-defined in this file for easy maintenance*/

/* (320x480) iPhone (Original, 3G, 3GS) */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    /* insert styles here */
}

/* (320x480) Smartphone, Portrait */
@media only screen and (device-width: 320px) and (orientation: portrait) {
    /* insert styles here */
}

/* (320x480) Smartphone, Landscape */
@media only screen and (device-width: 480px) and (orientation: landscape) {
    /* insert styles here */
}

/* (480x800) Android */
@media only screen and (min-device-width: 480px) and (max-device-width: 800px) {
    /* insert styles here */
}

/* (640x960) iPhone 4 & 4S */
@media only screen and (min-device-width: 640px) and (max-device-width: 960px) {
    /* insert styles here */
}

/* (720x1280) Galaxy Nexus, WXGA */
@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
    /* insert styles here */
}

/* (720x1280) Galaxy Nexus, Landscape */
@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) and (orientation: landscape) {
    /* insert styles here */
}

/* (1024x768) iPad 1 & 2, XGA */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    /* insert styles here */
}

/* (768x1024) iPad 1 & 2, Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* insert styles here */
}

/* (1024x768) iPad 1 & 2, Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* insert styles here */
}

/* (2048x1536) iPad 3 */
@media only screen and (min-device-width: 1536px) and (max-device-width: 2048px) {
    /* insert styles here */
}

/* (1280x720) Galaxy Note 2, WXGA */
@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
    /* insert styles here */
}

/* (1366x768) WXGA Display */
@media  screen and (max-width: 1366px) {
    .sidebar.right-side-sidebar .nav.nav-list li a i, .sidebar.right-side-sidebar .nav.nav-list li a span {
        padding: 11px 15px;
    }

    .sidebar.menu-min.right-side-sidebar .nav-list > li > .submenu {
        margin-top: -44px;
    }

    .right-side-sidebar .submenu li .menu-text {
        height: 42px;
    }

    .nav-list > li > a {
        line-height: 42px;
    }

    .sidebar.menu-min.right-side-sidebar .nav-list > li > .submenu.submenu-analysis-visualizations {
        margin-top: -123px;
    }

    .sidebar.menu-min.right-side-sidebar .nav-list > li > .submenu.sort-options.submenu-right-side-dropdown {
        margin-top: -192px;
    }

    .sidebar.menu-min.right-side-sidebar .nav-list > li > .submenu.submenu-right-save-options{
        margin-top: -87px;
    }
}

/* (1280x1024) SXGA Display */
@media  screen and (max-width: 1280px) {

    /*fix para a tabela de origens de dados*/
    .tableDataOrigins td.iceDatTblCol:nth-child(7), .tableDataOrigins th:nth-child(7) {
        width: 125px;
        line-height: 28px !important;
    }

    .tableDataOrigins td.iceDatTblCol:nth-child(4), .tableDataOrigins th:nth-child(4) {
        width: 90px;
    }

}

/* (1440x900) WXGA+ Display */
@media  screen and (max-width: 1440px) {

}

/* (1680x1050) WSXGA+ Display */
@media  screen and (max-width: 1680px) {

}

/* (1920x1080) Full HD Display */
@media  screen and (max-width: 1920px) {

}

/* (1600x900) HD+ Display */
@media  screen and (max-width: 1600px) {

}