.menuWrapper {
}

.selectObjectDialog {
  height: 60vh;
}

.selectObjectDialog div:global(.BngField) {
  margin: 0;
}

.selectObjectTreeWrapper div:global(.bng-tree-table-action-box) {
  background-color: white;
  padding: 10px;
  width: calc(100% - 20px);
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 0;
}

.selectObjectTreeWrapper div:global(.bng-search) {
  max-width: 270px;
  margin-left: auto;
}

.selectObjectTreeWrapper div:global(.scroll-bar-object-select) {
  height: calc(60vh - 109px);
  max-height: none;
  overflow: scroll;
}

.selectObjectSwitch {
  position: absolute;
  display: flex;
  z-index: 2;
  left: 40px;
  top: 65px;
}

.selectObjectSwitch span:global(.lbl) {
  font-weight: 500;
}

.selectCockpitHeader {
  height: 36px;
  background-color: white;
  border-bottom: 1px solid #e2e2e2;
  padding: 10px;
}

.selectCockpitBody {
  height: calc(60vh - 129px);
  padding: 10px 40px;
  overflow: auto;
  position: relative;
}

.dialogFooter {
  background-color: white;
  padding: 10px 30px;
  height: 60px;
  border-top: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
}

.footerButtonContainer {
  display: flex;
  height: 40px;
  gap: 10px;
  margin-left: auto;
}

.confirmButton {
  width: 120px;
  font-weight: 500;
}
