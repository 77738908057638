.NonEmptyAlertPopup {
    z-index: 20;
    top: -5px !important;
}

.NonEmptyAlertPopup .Body {
    background-color: white;
    width: 320px;
    box-shadow: 0 0 10px #eee;
}

.NonEmptyAlertPopup .WarningBg {
    padding: 10px;
    background-color: rgba(253, 255, 111, 0.25);
}

.NonEmptyAlertPopup .ArrowUp {
    margin-left: 8px;
}

.NonEmptyAlertPopup .ArrowUp {
    border-bottom-color: #ffffe5;
}

.ArrowUp {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
}