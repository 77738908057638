.uploadInfoContainer {
  border: 1px dashed #d1d1d1;
  border-radius: 4px;
  padding: 4px 8px;
}

:global(.KpiImage .BngInputIcon.round) {
  width: 32px;
  height: 32px;
}

:global(.KpiImage .BngInputIcon.round .Icon) {
  font-size: 20px;
}

.doUploadBtn {
  border-radius: 4px !important;
  background-color: rgba(51, 181, 119, 1) !important;
  padding: 8px !important;
  font-size: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.doUploadBtn:hover {
  background-color: rgba(51, 181, 119, 0.8) !important;
}

.doUploadBtn :global(.Icon) {
  font-size: 18px !important;
}

:global(.error) .doUploadBtn {
  border: 1px solid #f09784;
}

:global(.KpiImageUpload .spinner) {
  width: 32px;
}

.avatarDialog :global(.avatar-component) {
  width: 32px;
  height: 32px;
}

.userList {
  display: flex;
  flex-wrap: wrap;
  height: 400px;
  overflow: auto;
}

.userContainer {
  background-color: white;
  width: calc(25% - 20px);
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 65px;
}
