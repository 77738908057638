.StatsPanel .LoadingSvg {
  width: 48px;
  height: 48px;
  margin-left: 15px;
}

.panel-account-metrics .painel-metrics-container * {
  color: #ffffff;
}

.panel-account-metrics .painel-metrics-container.MetricObjects.stats-panel-open,
.panel-account-metrics .painel-metrics-container.MetricObjects.stats-panel-open,
.panel-account-metrics .painel-metrics-container.MetricStructures.stats-panel-open,
.StatsPanel:not(.Loading) .painel-metrics-container.MetricObjects:hover,
.StatsPanel:not(.Loading) .painel-metrics-container.MetricUsers:hover,
.StatsPanel:not(.Loading) .painel-metrics-container.MetricStructures:hover {
  animation: StatsPanelGlowAnimation 1s infinite;
}

@keyframes StatsPanelGlowAnimation {
  from {
    text-shadow: 0 0 18px rgb(255, 255, 255, 0.5);
  }
  to {
    text-shadow: 0 0 18px rgb(255, 255, 255, 0.5);
  }
}

.panel-account-metrics .painel-metrics-container.stats-panel-open,
.StatsPanel:not(.Loading) .painel-metrics-container:hover {
  border-color: rgba(255, 255, 255, 0.7);
  color: rgba(255, 255, 255, 0.7);
}

.panel-account-metrics {
  height: 148px;
  width: 560px;
  background-color: #423358;
}

.StatsPanel.Loading .painel-metrics-container {
  cursor: default;
}

.painel-metrics-container {
  color: transparent;
  font-size: 16px;
  font-weight: bold;
  display: grid;
  padding: 40px;
  cursor: pointer;
  width: 105px;
}

.painel-metrics-container * {
  color: #ffffff;
}

.painel-metrics-container .material-icons {
  font-size: 48px;
  pointer-events: none;
}

.stats-panel.last-update {
  position: absolute;
  right: 10px;
  top: 160px;
  color: white;
  font-size: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.stats-panel.last-update .autorenew {
  padding: 0;
}

.stats-panel.last-update i {
  font-size: 20px;
  color: white;
}

.stats-panel.last-update .autorenew.BngIconButton:hover {
  background-color: unset !important;
}