.DashboardLayoutAccordion #ProportionsAccordion .BngField {
  margin-bottom: 0;
}

.ApplyDashLayout {
  position: fixed;
  bottom: 0;
  right: 51px;
  min-width: 340px;
  height: 55px;
  border-radius: 0;
  z-index: 50;
}

.backgroundThemeContainer {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.backgroundThemeInput {
  width: 90%;
}

.ApplyDashLayout .AccordionContent {
  padding: 10px;
}

.ApplyDashLayout .AccordionContent button.bng-button {
  width: 100%;
  margin: 0;
}

.MarginContainer .CheckInputOption input[type="number"] {
    padding: 8px 5px 8px 8px;
}

.DashboardLayoutAccordion .CheckInputOption {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.DashboardLayoutAccordion .CheckInputOption > .BngInput {
  width: 58px;
}

.DashboardLayoutAccordion .CheckInputOption > * {
  margin-bottom: 0;
}

.DashboardLayoutAccordion .TransparencyField label {
  display: flex;
  align-items: center;
}

.DashboardLayoutAccordion #AppearanceAccordion .AccordionContent > .BngCheckbox {
  height: 34px;
  display: flex;
  align-items: center;
}

.DashboardLayoutAccordion .HighlightColor {
  margin-bottom: 0;
}

.DashboardLayoutAccordion .HighlightColor .BngDropdown {
  width: 100%;
}

.ContainerStyleTitle {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 12px;
}

.TitleFontSizeInput {
  width: 60px;
  margin-top: 10px;
}

.TitleFontSizeInput input[type="number"] {
  height: 32px;
  padding: 8px 5px 8px 8px;
}

.ContainerStyleTitle .BngColorPickerDropdown,
.ContainerStyleTitle .bng-button-group-item {
  height: 24px;
}

.ContainerStyleTitle .containerColor,
.ContainerStyleTitle .containerColor .ColorCircle {
  height: 20px !important;
  width: 24px !important;
}

.ContainerStyleTitle .fontSizeSelector {
  border: 1px solid #55555580;
  border-radius: 8px;
  height: 30px;
  overflow: hidden;
  width: 70px;
}

.ContainerStyleTitle .bng-button-group {
  margin-bottom: 0;
}

.ContainerStyleTitle .bng-button-group i {
  font-size: 20px;
}

.ContainerStyleTitle .fontSizeSelector span {
  border: none;
}

.ContainerStyleTitle .bng-button-group .bng-button-group-item {
  align-items: center;
  display: inline-flex;
}

.ContainerColors {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: space-around;
  width: 100%;
}

.ContainerColors .colorFieldWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ContainerColors .colorFieldWrapper .ColorCircle {
  height: 28px !important;
}

.ContainerHeaderStyle .ContainerRoundedBorders {
  align-items: start;
  flex-direction: column-reverse !important;
  gap: 8px;
  margin-bottom: 18px;
}

.ContainerHeaderStyle .ContainerRoundedBorders .BngSwitchLabel {
  font-weight: 500;
}

.ThemeOpt {
  background: #ffffff;
  height: 100%;
  padding-right: 10px;
  width: 100%;
}

.ThemeOpt .fakeContainerHeader {
  background: var(--blue-default);
  height: 18px;
  width: 100%;
}

.ThemeOpt.selectedContainerStyleOpt {
  align-items: center;
  display: flex;
}