.NoFormCheckbox {
    transition: opacity 250ms linear;
}

.NoFormCheckbox label {
    position: relative;
}

.NoFormCheckbox.Disabled {
    opacity: 0.5;
}

.NoFormCheckbox.Disabled .lbl {
    cursor: not-allowed;
}