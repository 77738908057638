.userAvatar {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-size: cover !important;
}

.containerInfos {
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
}

.flexInfos {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 250px;
  overflow-y: auto;
}

.line {
  display: flex;
  align-items: center;
}

.lineDot {
  display: flex;
  align-items: baseline;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #669eff;
  margin-right: 10px;
}

.labelInfos {
  margin: 0;
}

.text {
  font-weight: bold;
}

.dotBottom {
  display: flex;
  width: 34px;
  height: 24px;
  border-radius: 50%;
  background-color: #005dff;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  align-items: center;
  justify-content: center;
}

.labelUsers {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  margin: 0;
}

.hr {
  width: 85%;
  margin: 4px 0 !important;
}

.titleWrapper {
  margin: 18px 0 0 13px !important;
  max-width: 85%;
}

.icon {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 24px !important;
  color: #555555 !important;
  margin-right: 8px;
}

.identifier {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 19px !important;
  color: #333333 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.footer {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #888888;
}
