.AppKeyActionPopper li i {
  font-size: 20px !important;
}

.AppKeyActionPopper li span {
  font-size: 16px;
}

.AccAppKeyTab :global(.BngTableTh):first-child {
  padding-left: 25px;
}

.AccAppKeyTab :global(.BngTableTd):first-child {
  padding-left: 40px;
}
