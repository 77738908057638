.Checkout {
  background: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 24px;
  width: 490px;
}

.checkoutField {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.checkoutFieldDisabled {
  opacity: 0.3;
}

.itemField {
  justify-content: start;
}

.itemNameWrapper {
  width: 55%;
}

.checkoutField :global(.BngField) {
  margin-bottom: 0;
}

.radioButtonWrapper {
  display: inline-flex;
  gap: 5px;
}

.CheckoutDialog {
  width: 490px;
}

:global(.bs-dialog.ui-draggable.DialogNewLayout) .checkoutBody:global(.DialogBody) {
  padding: 0 !important;
}

.checkoutField label {
  margin-bottom: 0;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.radioButtonWrapper :global(.BngRadio) span {
  font-weight: 500;
}

.CheckoutPage {
  align-items: center;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 98px);
  padding-top: 50px;
  position: relative;
}

.CheckoutPage h3 {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.checkoutWrapper {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  max-width: 500px;
}

.checkoutBackButton {
  align-items: center;
  border-radius: 8px;
  color: #555;
  display: flex;
  gap: 5px;
  left: 10px;
  position: absolute;
  top: 14px;
}

.billingType {
  height: 30px;
  width: 175px;
}

.billingType > div {
  height: 22px !important;
}

.divider {
  margin: 5px;
}

.pricingWrapper {
  align-items: end;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
}

.finishButton {
  width: 100%;
}

.supportFooter {
  align-items: center;
  display: inline-flex;
  gap: 5px;
  justify-content: center;
}

.supportFooter i {
  font-size: 18px !important;
}

.emptyCheckout {
  position: relative;
  transform: none;
}

.smallPrint {
  color: #888888;
  font-size: 10px;
}

.removeButton {
  align-items: center;
  background: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 28px;
  width: 26px;
}

.removeButton i {
  font-size: 18px;
}

.itemPricing {
  margin-left: auto;
}

.paymentFormat {
  font-size: 15px !important;
  font-weight: bold !important;
  margin-right: 10px;
}

.itemPricing.itemDetail {
  color: #888888;
  margin-left: auto;
  text-decoration: line-through;
}

.expandVoucherButton {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  gap: 4px;
  justify-content: center;
  user-select: none;
  width: 100%;
}

.expandVoucherButton i {
  color: #333;
  font-size: 14px;
}

.voucherFieldWrapper {
  align-items: center;
  display: inline-flex;
  gap: 4px;
  width: 100%;
}

.voucherFieldWrapper :global(.BngIconButton) {
  border-radius: 12px;
  color: #333;
}

.voucherFieldWrapper :global(.control-group) {
  flex: 1;
  margin-bottom: 0;
}

.proposalMessageField input,
.voucherFieldWrapper input {
  padding: 9px;
}

.voucherWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.removeVoucherIcon {
  border: none;
  color: var(--green);
}

.removeVoucherIcon:hover {
  color: var(--red);
}

.voucherNameWrapper {
  width: fit-content;
}
