.BngTag {
  background-color: #005dff;
  border-radius: 12px;
  padding: 2px 8px;
  border: 1px solid transparent;
  color: #fff;

  height: 18px;
  display: inline-flex;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.BngTag .description.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.BngTag .description {
  display: flex;
  width: auto;
}

.BngTag .Icon {
  font-size: 18px;
  margin-right: 4px;
}

.BngTag.collapsableTag > .Icon {
  margin-right: 0;
}

.BngTag .BngTagCloseButton {
  padding: 0;
  color: #fff;
  transition: text-shadow ease-in-out 0.2s;
  display: flex;
  height: 100%;
  margin-left: auto;
}

.BngTag .BngTagCloseButton:hover {
  text-shadow: 0 0 5px #ffffff;
  background-color: inherit;
}

.BngTag .BngTagCloseButton .Icon {
  font-size: 18px;
}

.BngTag.beta {
  background: transparent;
  border: 1px solid #f98900;
  box-sizing: border-box;
  color: #f98900;
  height: 21px;
  margin-left: 10px;
}

.BngTag .tagBody {
  align-items: center;
  display: inline-flex;
  gap: 2px;
}