@import url('https://fonts.googleapis.com/css?family=Droid+Sans+Mono');
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro');
@import url('https://fonts.googleapis.com/css?family=PT+Sans');
@import url('https://fonts.googleapis.com/css?family=Arimo');
@import url('https://fonts.googleapis.com/css?family=Signika');
@import url('https://fonts.googleapis.com/css?family=Pontano+Sans');
@import url('https://fonts.googleapis.com/css?family=Anton');
@import url('https://fonts.googleapis.com/css?family=Bubblegum+Sans');
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab');
@import url('https://fonts.googleapis.com/css?family=Crimson+Text');
@import url('https://fonts.googleapis.com/css?family=Droid+Serif');
@import url('https://fonts.googleapis.com/css?family=Muli');

.DashboardTextFormDialog {
    width: 700px;
}

.text-preview {
    height: 150px;
    border: 1px solid #ccc;
    white-space: nowrap;
}

.font-family-open-sans {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.font-family-heveltica-neue {
    font-family: "Helvetica Neue", 'Muli', Helvetica, Arial, sans-serif;
}

.font-family-georgia {
    font-family: Georgia, 'Droid Serif', serif;
}

.font-family-palatino-linotype {
    font-family: "Palatino Linotype", 'Crimson Text', "Book Antiqua", Palatino, serif;
}

.font-family-times-new-roman {
    font-family: "Times New Roman", 'Roboto Slab', Times, serif;
}

.font-family-arial {
    font-family: Arial, 'Lato', Helvetica, sans-serif;
}

.font-family-arial-black {
    font-family: "Arial Black", 'Oswald', Gadget, sans-serif;
}

.font-family-comic-sans {
    font-family: "Comic Sans MS", 'Bubblegum Sans', cursive, sans-serif;
}

.font-family-impact {
    font-family: Impact, 'Anton', Charcoal, sans-serif;
}

.font-family-lucida-sans-unicode {
    font-family: "Lucida Sans Unicode", 'Pontano Sans', "Lucida Grande", sans-serif;
}

.font-family-tahoma {
    font-family: Tahoma, 'Signika', Geneva, sans-serif;
}

.font-family-trebuchet {
    font-family: "Trebuchet MS", 'Arimo', Helvetica, sans-serif;
}

.font-family-verdana {
    font-family: Verdana, 'PT Sans', Geneva, sans-serif;
}

.font-family-courier-new {
    font-family: "Courier New", 'Source Code Pro', Courier, monospace;
}

.font-family-lucida-console {
    font-family: "Lucida Console", 'Droid Sans Mono', Monaco, monospace;
}

.font-family-roboto {
    font-family: "Roboto";
}

.background-color-label {
    margin-left: 119px;
    margin-top: -55px;
    width: 115px !important;
}

.DashboardTextFormDialog .textLength {
    position: relative;
    margin: 0;
}

.DashboardTextFormDialog .textLength .textLengthSpan {
    position: absolute;
    right: 8px;
    top: 7px;
}
