.ConnectionsTabAccordionWrapper {
}

.ConnectionsTabAccordionWrapper :global(.UiBlocker) {
  height: 100%;
}

.Selector {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px;
  border-radius: 5px;
  border-left: 4px solid #57a3ff;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.selectionNameWrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 220px;
}

.Selector:global(.opened-true) {
  border-radius: 5px 5px 0 0;
}

.SelectorOptionsDropdown {
  margin-top: 2px;
  border-radius: 0 0 0 0;
  height: 400px;
  overflow-y: scroll;
}

.selectionNameWrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 220px;
}

.SelectorListOption {
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  border-left: 4px solid #57a3ff;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.SelectorListOption:hover {
  cursor: pointer;
}

.SelectorListOption :global(.ConnectionTab-ConnectionListOption-db-logo) {
  height: 32px;
  margin-right: 10px;
  mask-image: linear-gradient(to bottom, #57a3ff00 -50%, #57a3ff 100%);
}

.SelectorListOption i {
  font-size: 24px;
  margin-right: 10px;
  color: #005dff;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.SelectorListOption:global(.loading-selected) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SelectorListOption:global(.new-database-connection) {
  border-radius: 0 0 5px 5px;
}

.SelectorListOptionPopper:global(.BngClickOutsideOverlay.Overlay) {
  z-index: 99;
  background: rgba(0, 0, 0, 0) !important;
}

.SelectorListOptionPopper:global(.bng-dropdown-parent) {
  width: 306px;
  z-index: 99;
}

.SelectorListOption:global(.FirstConnection),
.SelectorListOption:global(.FirstSchema) {
  border-radius: 5px 5px 0 0;
}

.SelectorButton {
  width: 100%;
}

.SelectorWrapper > i {
  position: absolute;
  top: 20px;
  right: 40px;
  font-size: 15px;
}

.connectionIcon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.ConnectionButton {
  padding: 5px;
  position: absolute;
  top: 115px;
  right: 20px;
}

.schemasWrapper {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
  gap: 1px;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow: scroll;
  margin-top: 10px;
}

.TablesViewsAccordionWrapper {
  height: 56vh !important;
}

.TablesViewsAccordionWrapper :global(.AccordionContent) {
  max-height: unset !important;
}

.schemaName {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  gap: 8px;
  padding: 8px 0 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.schemasWrapper > div:last-child .listWrapper:not(:global(.open)) .schemaName,
.subListWrapper > div:last-child .subList:not(:global(.open)) .schemaName,
.subListWrapper > .columnList:last-child .schemaName {
  border: none;
}

.listWrapper {
  position: relative;
}

.subList {
  position: relative;
}

.schemaNameArrowIcon {
  color: #888888;
  font-size: 16px !important;
  cursor: pointer;
}

.schemaNameArrowIcon:global(.cerrado) {
  transform: rotate(0deg);
}

.schemaNameArrowIcon:global(.open) {
  transform: rotate(90deg);
}

.subListWrapper {
  position: relative;
}

.subListWrapper {
  margin-left: 20px;
}

.subListWrapper:global(.closed) {
  transform-origin: top;
  display: none;
  transform: scaleY(0);
}

.subListWrapper:global(.open) {
  transform-origin: top;
  display: flow;
  transform: scaleY(1);
}

.listItemIcon {
  color: #888888;
  font-size: 16px !important;
}

.listItemIcon:global(.JSON) {
  font-size: 10px !important;
}

.itemAddIcon {
  position: absolute;
  right: 0;
  top: 10px;
  color: #888888;
  font-size: 16px !important;
  cursor: pointer;
}

.itemName {
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
}

.itemName:global(.column) {
  width: unset;
}

.keyIcon {
  font-size: 16px !important;
  transform: rotate(90deg);
}

.keyIcon:global(.PRIMARY) {
  color: #c9c90e;
}

.keyIcon:global(.FOREIGN) {
  color: red;
}

.GoBackButton {
  left: 1vh !important;
  background-color: #555555;
}

.NextStepButton,
.GoBackButton {
  position: absolute;
  bottom: 1vh;
  right: 1vh;
  width: 150px;
}

.ToJsonButton {
  background-color: #005dff;
  color: white;
  width: fit-content;
  padding: 0 3px 0 3px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
}

.ToJsonButton:hover:not(.disabled) {
  cursor: pointer;
}

.ToJsonButton:global(.disabled):hover {
  cursor: not-allowed;
}

.widgetBodyClassName {
  height: 90%;
  overflow: scroll;
  padding: 12px 0 5px 10px !important;
}

.widgetMainClassName {
  padding: 0 12px 0 12px;
}

.AddConnectionLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  color: #337dff;
}

.syncingIndicatorWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

.syncingInfoIcon {
  font-size: 12px !important;
  color: #005dff;
  margin: 0 5px 0 5px;
}

.lastSyncDateIcon {
  font-size: 13px !important;
  color: #005dff;
  margin-left: 5px;
}

.syncingClearIcon {
  font-size: 15px !important;
  color: red;
}

.syncingClearIcon:hover {
  cursor: pointer;
}

.SyncButton {
  color: #005dff;
  line-height: 14px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: max-content;
}

.SyncButton:hover {
  cursor: pointer;
}

.SyncButton:global(.disabled):hover {
  cursor: not-allowed;
}

.SyncIcon {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.SyncIcon:global(.syncing) {
  animation: rotation 1s linear infinite reverse;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.TopButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  height: 4vh;
  position: relative;
}

.TopButtons:global(.disabled) {
  opacity: 0.5;
}

.SearchTable {
  border-radius: 4px;
  position: absolute;
  right: 42px;
}

.SearchTable:global(.bng-search.closed) {
  width: 45px;
  right: 84px;
}

.SearchTable:global(.bng-search.open) {
  width: 255px;
}

.SearchTable:global(.bng-search-input.closed) {
  position: fixed;
}

.SearchTable:global(.bng-search-input.open) {
  position: unset;
}

.LogDialogFooter {
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.SaveJsonButton {
  height: 40px;
}

.SelectedSchemaPlaceholder {
  display: flex;
  align-items: center;
  width: 230px;
}

.SelectedSchemaPlaceholder div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.SelectedSchemaPlaceholder > i {
  margin-right: 10px;
}
