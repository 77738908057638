.BodyWrapper {
  height: 450px;
  display: flex;
  flex-flow: row wrap;
}

.Intelligences {
  height: 100%;
  flex-grow: 1;
  width: 50%;
}

.ProjectResource {
  height: 100%;
  flex-grow: 1;
  width: 50%;
}

.Intelligences > legend,
.ProjectResource > legend {
  color: #669fc7;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 30px;
  border-bottom: 1px solid #e5e5e5;
  width: 97%;
}

.Intelligences > div:global(.BngCheckbox) > label > span,
.ProjectResource > div:global(.BngCheckbox) > label > span {
  line-height: 25px !important;
}
