.dash-icon-component {
    top: calc(50% - 316px);
}

.georef-search {
    width: 400px;
    margin-left: 80px;
    margin-top: -24px;
}

.georef-address,
.georef-address label {
    max-width: 60px;
}

.georef-address label {
    margin-bottom: 0;
}

.georef-search-button {
    margin-left: 486px;
    max-width: 25px;
    margin-top: -39px;
    position: absolute;
}

table#geo-table td > input {
    margin: 3px 0;
}

.georef-search-container {
    margin-top: 5px;
    padding-bottom: 10px;
}

.pagination-table-footer .geo-pagination-container {
    width: 100%;
}

.geo-paginate-table-wrapper {
    padding-right: 10px;
}
