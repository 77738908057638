.basic-info-container {}

.basic-info-container label {
    display: inline-block;
    min-width: 120px;
    text-align: right;
    margin-right: 10px;
    font-weight: bold;
    white-space: nowrap;
}

.basic-info-container .description-container {
    max-height: 150px;
    overflow: auto;
    width: calc(100% - 140px);
    display: inline-flex;
}

.basic-info-container .changes-table-container {
    margin-top: 10px;
    height: 200px;
    overflow: auto;
}