.FilterWrapper {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
  height: 50px;
}

.NewConnectionButton {
  display: flex;
  flex-direction: row;
  align-items: center;
}
