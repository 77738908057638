.AddToDashboardDialog .modal-body,
.AddToDashboardDialog .dialog-body {
    overflow: visible;
}

.AddToDashRadioButtons {
    gap: 8px;
}

.AddToDashRadioButtons > * {
    padding: 0 !important;
    margin: 0 !important;
}

.AddToDashRadioButtons .BngRadio {
    padding-bottom: 10px;
}
