.iconDescription .BngDropdown.DashboardItemMenuDropdown {
    opacity: 1;
}

.ContainerDescription .DescriptionObject {
    letter-spacing: 0.5px;
}

.ContainerDescription .DescriptionMenuItem {
    padding: 0;
}

.ContainerDescription button > i {
    font-size: 16px !important;
}

.ContainerDescription button:hover {
    background-color: transparent !important;
}

.container-description-popper {
    min-height: 80px !important;
    zoom: 1 !important;
}

.container-description-popper .DescriptionMenuItem {
    width: auto !important;
}

.container-description-popper .bng-dropdown.container-dash-item-newmenu {
    position: initial !important;
}