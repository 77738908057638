.IpRestrictionBlockAccessDialog {
  border-radius: 8px;
  overflow: hidden;
}

.headerIcon {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  margin: 20px 0;
  width: 100%;
}

.headerIcon i {
  color: #005dff;
  font-size: 42px;
}

.headerIcon .restrictionTitle {
  font-size: 24px;
  font-weight: 600;
}

.restrictionBody {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.restrictionBody span {
  color: #555555;
  text-align: center;
  max-width: 390px;
}

.actionsWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}

.titleSpan {
  color: #555555;
  font-weight: 500;
  margin-bottom: 0;
}

.projectField {
  align-items: center;
  display: inline-flex;
  gap: 5px;
}

.projectField :global(.BngField) {
  margin-bottom: 0;
  width: 250px;
}

.projectField :global(.BngField label) {
  margin: 0;
}

.projectField button {
  height: 40px;
}

.projectField :global(.OptionPreview) {
  height: 38px;
}

.logoutBtn {
  background: #e9443b;
}

.projectOption {
  align-items: center;
  display: inline-flex;
  gap: 8px;
}

.projectIcon {
  height: 20px;
  width: 20px;
}
