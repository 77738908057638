.DashboardIconForm {
    width: 810px;
    margin-left: -405px;
}

.dash-icon-component .input-icon-container {
    max-height: 285px;
    max-height: 35vh;
    overflow-y: auto;
}

.dash-icon-component .text-preview-icon {
    height: 110px;
    border: 1px solid #ccc;
}

.row-fluid-link .fill-w-icon-link {
    display: inline;
    width: 300px;
    margin-left: 78px;
    margin-top: -68px;
}

.row-fluid-link .button-icon-link {
    color: #000000;
    width: 70px;
    margin-left: 0px;
    margin-top: -49px;
}

.row-fluid-link .button-icon-object {
    width: 70px;
    height: 30px;
    margin-left: -71px;
    margin-top: 27px;

}

.row-fluid-link .icon-object-name {
    display: inline;
    margin-left: 80px;
    margin-top: -22px;
    position: absolute;
}

.row-fluid-link .label-link-icon {
    width: 60px;
    height: 15px;
    line-height: 20px;
}

.row-fluid .fill-w-icon-label {
    margin-left: 50px;
    width: 200px;
    margin-top: -30px;
}

.row-fluid .position-select-label {
    width: 100px;
    margin-left: 260px;
    margin-top: -40px;
}


.row-fluid .label-color-icon {
    margin-left: 300px;
    width: 20px;
    height: 20px;
    margin-top: -40px;
}

.row-fluid-link {
    margin-top: -5px;
}

.dash-icon-component .IconPreview {
    height: 196px;
}

.dash-icon-component .IconPreview.DarkBackground {
    background-color: #333333 !important;
}

.DashboardIconForm .DialogBody .bng-button {
    margin: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.DashboardIconForm .DialogBody .Preview {
    position: relative;
}

.DashboardIconForm .Filters {
    padding: 20px;
    min-height: 100px;
    max-height: 100px;
    overflow: auto;
}

.DashboardIconForm .Filter {
    border-radius: 4px;
}

.DashboardIconForm .ChangeIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.DashboardIconForm .Preview:hover .ChangeIconBg {
    opacity: 0.6;
}

.DashboardIconForm .Preview:hover .ChangeIconText {
    opacity: 1;
}

.DashboardIconForm .ChangeIconBg {
    cursor: pointer;
    height: 32px;
    width: 60%;
    opacity: 0;
    border-radius: 15px;
    background-color: #000000;
    position: absolute;
    top: 90px;
    transition: all 0.5s;
}

.DashboardIconForm .Image .ChangeIconBg {
    top: 130px
}

.DashboardIconForm .ChangeIconText {
    cursor: pointer;
    opacity: 0;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    top: 97px;
    transition: all 0.5s;
}

.DashboardIconForm .Image .ChangeIconText {
    top: 137px
}

.DashboardIconForm .filter-container {
    box-shadow: unset;
    border: 0;

}

.DashboardIconForm .filter-block {
    margin-bottom: 15px;
    border: 1px solid #D4D8DA;
    border-radius: 4px;
}

.DashboardIconForm .FilterBarSlotButton {
    height: 25px;
    width: 28px;
    text-align: center;
    padding-top: 1px;
    color: #999;
    padding-left: 2px;
    cursor: pointer;
}

.DashboardIconForm .Filters .label {
    padding: 2px 10px 4px 6px;
}

.DashboardIconForm .apply-filters-container .BngField .control-label {
    display: none;
}

.DashboardIconForm .apply-filters-container .BngCheckbox .lbl {
    font-weight: bold;
}

.DashboardIconForm .open-object-container {
    display: inline-flex;
    width: 737px;
}

.DashboardIconForm .apply-filters-container {
    margin-left: 15px;
    width: 322px;
    display: inline-flex;
    align-items: baseline;
}

.DashboardIconForm .BngDropdown {
    display: contents !important;
}

.IconFilterDropdownPopper {
    z-index: 321051 !important;
}

.BngIconButton.ButtonIconFilters.enabled {
    color: #005dff;
}

.BngIconButton.ButtonIconFilters.disabled {
    color: #7B7B7B;
}

.DashboardIconForm .apply-filters-container .fa.fa-info-circle {
    margin-left: 10px;
    cursor: pointer;
}

.DashboardIconForm .DialogBody .span12 {
    height: 60px;
}

.DashboardIconForm .textLength {
    position: relative;
}

.DashboardIconForm .textLength .textLengthSpan {
    position: absolute;
    right: 8px;
    top: 7px;
}


.IconAddFilterDropdownOverlay {
    z-index: 321050;
}

.IconAddFilterDropdown {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.195822);
    overflow: hidden;
    padding: 10px 15px 0 15px;
    width: 250px;
    z-index: 321050 !important
}

.filterDropdownList {
    margin-top: 5px;
    margin-left: 0;
    max-height: 210px;
    overflow-x: hidden;
    overflow-y: scroll;
    list-style: none;
}

.filterDropdownItem {
    align-items: center;
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    max-width: 230px;
    min-height: 1.2em;
    padding: 6px 8px;
    white-space: pre;
    width: 100%;
}

.filterDropdownItem:hover {
    background-color: #fafafa;
    color: #005DFF;
    cursor: pointer;
}

.IconAddFilterDropdown .bng-search.SimpleBngSearch {
    border: 1px solid #EFEFEF;
    border-radius: 4px;
}

.filterBarOptionsWrapper {
    display: flex;
    left: 23px;
    position: absolute;
    z-index: 11;
    background: white;
    padding-left: 20px;
    padding-right: 15px;
    height: 30px;
    gap: 15px;
}

.DashboardIconForm .dashboard-filter-box.on-publisher {
    position: relative;
}

.DashboardIconForm .dashboard-filter-box.on-publisher .filter-container.filter-expand {
    padding: 0;
    box-shadow: none;
    overflow: visible;
}

.DashboardIconForm .dashboard-filter-box.on-publisher .filterItemsHolder {
    margin-left: 100px;
    gap: 15px;
}