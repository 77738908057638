.breadcrumb-project-logo {
    height: 20px;
}

.breadcrumb li {
    margin-right: 6px;
    line-height: 36px;
}

.breadcrumbs.from-cockpit {
    margin-left: 0;
}


.breadcrumbs {
    height: 36px;
    background: #ffffff;
}

.breadcrumbs:before {
    content: "";
    position: absolute;
    margin-top: 36px;
    height: 4px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .08), rgba(0, 0, 0, 0));
    z-index: 100;
}

.breadcrumbs .breadcrumb {
    background-color: transparent;
    display: inline-block;
    padding: 0;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.75);
    vertical-align: top;
}

.breadcrumb > li,
.breadcrumb > li.active {
    color: #6e6e6e;
    font-weight: 500;
    letter-spacing: .2px;
}

.breadcrumb > li:first-child {
    color: #6e6e6e !important;
}

.breadcrumb > li:last-child {
    color: #6e6e6e;
}

.breadcrumb-logo {
    height: 36px;
    width: 42px;
    display: inline-block;
}

.breadcrumb-logo i {
    margin: 8px 0px 0px 14px;
    color: #6e6e6e;
    font-size: 20px;
}

.breadcrumb-logo i.oldIconBreadcrumbCompatibility::before {
    margin-top: 11px;
}

.breadcrumb-logo i.oldIconBreadcrumbCompatibility {
    font-size: 16px;
}

.renderedPages .breadcrumb-is-object {
    color: #4272FE !important;
    vertical-align: bottom !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.breadcrumb-is-folder {
    color: #6e6e6e !important;
}

.breadcrumb {
    overflow-x: scroll;
    width: calc(92vw - 393px);
}