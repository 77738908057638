.switchField {
  overflow: hidden;
  user-select: none;
  height: 38px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
}

.switchField input[type='radio']:checked + label::before {
  content: none !important;
}

.switchField input[type='radio'] + label {
  margin-left: 0 !important;
}

.switchField input[type='checkbox'] + label::before {
  display: none !important;
}

.btnWrapper {
  height: 100%;
}

.btnWrapper .labelInput.disabledBtn {
  background-color: #f6f6f6;
  color: #d1d1d1;
  cursor: not-allowed !important;
}

.btnInput {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  border: 0;
  overflow: hidden;
}

.btnWrapper .labelInput {
  background-color: #fff;
  color: #333;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  border: 1px solid #d1d1d1;
  transition: all 0.2s ease;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  gap: 6px;
  height: calc(100% - 2px);
  padding: 0 16px;
}

.btnInput:checked + .labelInput {
  color: #005dff;
  border: 1px solid #005dff;
  text-shadow: 0 0 1px #005dff;
}

.labelIcon {
  font-size: 16px !important;
}

:global(.control-group.error) .labelInput {
  border-color: #f09784;
}
