.BngAda.BngMobileDrawerHelp .BngAdaBackground{
    z-index: 1007;
    position: fixed;
    background: rgba(0,0,0,0.5);
}

.BngAda.BngMobileDrawerHelp img.AdaOpenHandImg{
    z-index: 1007;
}

.BngAda.BngMobileDrawerHelp.IntroOpen .disableBackground{
    position: fixed;
    z-index: 1006;
    background-color: rgba(0,0,0,0.5);
}