.AdvancedConfigurationWrapper {
  margin: 35px;
}

.AdvancedConfigurationTabDiv > div {
  margin-bottom: 8px !important;
}

.AdvancedConfigurationWrapper > h6 {
  width: fit-content;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.AdvancedConfigurationWrapper label {
  font-size: 12px;
  font-weight: 400;
}
