.BngNewAnalysisWrapper {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
}

.AssistedObjectPageContainer {
  padding: 30px;
}

.BngNewAnalysis .Header {
  background-image: url('assets/headerBackground.png');
}