.cockpit-dropdown-popper .cockpit-export-popper-items .cockpit-export-item {
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    padding: 12px 10px;
    font-size: 13px;
    cursor: pointer;
}

.cockpit-dropdown-popper .cockpit-export-popper-items .cockpit-export-item.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.cockpit-dropdown-popper .cockpit-export-popper-items .cockpit-export-item span {
    margin-left: 10px;
}

.cockpit-dropdown-popper .cockpit-export-popper-items .cockpit-export-item i {
    font-size: 18px;
}

.cockpit-dropdown-popper .cockpit-export-popper-items .cockpit-export-item:last-child {
    border-bottom: 0;
}

.cockpit-dropdown-popper .cockpit-export-item.link {
    padding: 0 !important;
}

.cockpit-dropdown-popper .cockpit-export-item.link > a {
    padding: 12px 10px;
    width: 100%;
    display: flex;
    font-weight: 400;
}

.cockpit-dropdown-popper .cockpit-export-item.link:not(:hover) > a {
    color: #333;
}

.cockpit-dropdown-popper .cockpit-export-item.link:hover > a {
    color: #4272FE !important;
}