@keyframes pulsate {
    0% {
        transform: scale(.1);
        opacity: 0.0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}

.loading-pulse-box {
    position: absolute;
    width: 0;
    height: 0;
    border: none;
    font-size: .5em;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    z-index: 30000;
}

.loading-pulse {
    border: 5px solid #333;
    border-radius: 30px;
    height: 30px;
    opacity: 0;
    width: 30px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
}

body > div:last-child {
    cursor: inherit !important;
}

.loading-pulse-box.Type-circle {
    left: calc(50% - 67px);
    top: calc(50% - 67px);
}