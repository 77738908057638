/* Added margins to fix content behind save button on dashboard. See: https://github.com/sol7/bi-machine/issues/3043 */
.right-menu-open .FilterBarWrapper.WithSaveBtn .filter-top-fixed .dashboard-filter-container-persistence,
.right-menu-open .FilterBarWrapper.WithSaveBtn .filter-bottom-fixed .dashboard-filter-container-persistence {
  margin-right: 78px;
}

.FilterBarWrapper.WithSaveBtn .filter-top-fixed .dashboard-filter-container-persistence,
.FilterBarWrapper.WithSaveBtn .filter-bottom-fixed .dashboard-filter-container-persistence {
  margin-right: 35px;
}

.FilterBarWrapper.WithSaveBtn .dashboard-filter-box.filter-bottom-right .dashboard-filter-container-persistence {
  margin-right: 60px;
}

.dashboard-filter-box.filter-top-right,
.dashboard-filter-box.filter-top-left,
.dashboard-filter-box.filter-bottom-left,
.dashboard-filter-box.filter-bottom-right {
  position: fixed;
  max-width: calc(100% - 150px);
}

.dashboard-filter-box.filter-top-right,
.dashboard-filter-box.filter-top-left {
  top: auto;
  z-index: 9;
}

.dashboard-filter-box.filter-top-right,
.dashboard-filter-box.filter-bottom-right.cockpitEnabled {
  right: 30px;
}

.dashboard-filter-box.filter-bottom-left.cockpitEnabled {
  left: 30px;
}

.filterItemsHolder {
  display: flex;
}

.FilterBarWrapper .OverflownFiltersDropdown,
.filterItemsHolder .OverflownFiltersDropdown button,
.FilterBarWrapper .OverflownFiltersDropdown button {
  align-items: center;
  border-radius: 100%;
  display: inline-flex;
  height: 28px;
  justify-content: center;
  padding: 0;
  width: 28px;
}

.bng-dropdown-parent.OverflownFiltersDropdownPopper {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  left: -38px !important;
  max-width: 270px;
  top: 8px !important;
  transition: top, max-height 0.17s ease 0s;
  width: unset;
}

.bng-dropdown-parent.OverflownDropdownPosition-filter-bottom-right,
.bng-dropdown-parent.OverflownDropdownPosition-filter-bottom-left,
.bng-dropdown-parent.OverflownDropdownPosition-filter-bottom-fixed,
.bng-dropdown-parent.OverflownDropdownPosition-bottom {
  top: -10px !important;
}

.OverflownFiltersDropdown .OverflownFiltersDropdownButton {
  transform: rotate(90deg);
}

.OverflownFiltersDropdown .OverflownDropdownFiltered {
  background: #005dff;
  border-radius: 100%;
  padding: 4px;
  position: absolute;
  top: 2px;
  right: 0;
}

.OverflownFiltersDropdownPopper .BngDropdownSeparator {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  margin: 5px 16px !important;
  padding: 0 !important;
  width: 85%;
}

.OverflownFiltersDropdownPopper .OverflownFiltersList {
  background: #fff;
  border-radius: 8px;
  list-style-type: none;
  margin: 0;
  width: 100%;
}

.OverflownFiltersDropdownPopper .dashboard-filter-information {
  background: #fff;
  display: flex;
  left: auto !important;
  margin: 5px 16px;
  top: auto !important;
}

.OverflownFiltersDropdownPopper .dashboard-filter-information:last-child {
  margin-bottom: 10px;
}

.OverflownFiltersDropdownPopper .dashboard-filter-information a:not(.user-filter-config-icon) {
  background: #fff;
  color: #555555 !important;
  text-shadow: none;
}

.OverflownFiltersDropdownPopper .dashboard-filter-information a.user-filter-config-icon {
  display: flex;
  align-items: center;
  top: 0;
}

.OverflownFiltersDropdownPopper .dashboard-filter-information.filtered a:not(.user-filter-config-icon) {
  color: #fff !important;
}

.OverflownFiltersDropdownPopper .OverflowingFilterPopperPosition-bottom {
  top: -8px !important;
}

.bng-dropdown-parent.OverflownDropdownPosition-bottom.growForPlaceholder {
  top: -32px !important;
}

.FilterBarWrapper .filter-container-position-right .growForPlaceholder .filterItemsHolder {
  float: right;
}

#filter-bar-container .filterItemsHolder,
.filter-container.filter-expand.undefined.filter-container-position-fixed
  .dashboard-filter-container-persistence.dashboard-filter-container-persistence-save-enable
  .filterItemsHolder {
  margin-left: unset;
}

.dashboard-filter-information.filter-block.DashboardFilter {
  display: flex;
  align-items: center;
}

.dashboard-filter-information.filter-block.DashboardFilter .user-filter-config-icon {
  top: unset;
}

.ContentContainer.Filters {
  height: 30px !important;
  min-height: unset !important;
  max-height: unset !important;
  overflow-x: auto !important;
  overflow-y: clip !important;
  padding-bottom: 20px !important;
}

#filter-bar-container .filterItemsHolder,
.ContentContainer.Filters .filterItemsHolder {
  display: flex;
  margin-left: 100px;
}

.AllContentWrapper.on-ios.on-mobile .on-mobile.on-ios .filter-container.filter-expand{
  z-index: 1;
}
