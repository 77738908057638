.FindTraceabilityDialog {
  width: 848px !important;
  height: 492px;
  border-radius: 8px;
  left: 45%;
}

.dialogDivisionTop {
  border-top: #e2e2e2 1px solid;
}

.TraceabilitySenderInfo {
  padding-left: 12px;
  border-left: 3px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
}

.blueBall {
  background: #669eff;
  width: 8px !important;
  height: 8px !important;
  border-radius: 4px;
  margin: 3px 6px 0 0;
}

.objectListItem,
.receiverListItem {
  display: flex;
  margin: 4px 0 6px 0;
  box-shadow: 0 1.5px 0 #e4e4e4;
  align-items: center;
  padding: 0 0 2px 0;
  height: 26px;
  width: 235px;
  gap: 5px;
}

.objectListItem:nth-child(even),
.receiverListItem:nth-child(even) {
  margin-bottom: 0;
}

.objectListItem:nth-child(odd),
.receiverListItem:nth-child(odd) {
  background: #f6f6f6;
  margin-top: 2px;
}

.listWrapper,
.receiverWrapper {
  overflow: scroll;
  height: 150px;
}

.objectListCount,
.receiverListCount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #005dff;
  border-radius: 15px;
  color: white;
  width: auto;
  padding: 0 10px 0 10px;
  align-items: center;
  margin: 0 10px 0 0;
}

.objectListTitle,
.receiverListTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.objectListIcon {
  font-size: 20px !important;
  line-height: 18px !important;

  display: flex !important;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #555555;
  height: 34px;
}

.objectListIcon:first-child {
  margin-left: 6px;
}

.objectListName,
.receiverListName {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;

  color: #555555 !important;

  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.objectReceiverListWrapper {
  display: flex;
  margin: 0 0 0 20px;
}

.receiverList,
.objectList {
}

.receiverListTitleWrapper,
.objectListTitleWrapper {
  display: flex;
  padding-bottom: 15px;
  box-shadow: 0 1.5px 0 #e4e4e4;
}

.sendingDataWrapper {
  margin-top: 24px;
}

.sendingData {
  display: flex;
  align-items: center;
  gap: 3px;
}

.sendingData:first-child b {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 175px;
}

.sendingData:last-child {
  position: absolute;
}

.findTraceabilitySection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 445px;
}

.findTraceabilityBodyFooter {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.findTraceabilityBodyFooter :global(.bng-button.save) {
  margin-right: 24px;
  width: 96px;
  bottom: 12px;
  top: auto;
}

.findTraceabilityImage {
  width: 193px;
  height: 187px;
  margin: 24px 58px;
}

.findTraceabilityImage :global(img) {
  width: 368px;
  z-index: 1;
}

.findTraceabilityResult {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  background: #ffffff;
}

.findTraceabilityDetailsEmptyImage {
  width: 453px;
  height: 313px;
}

.findTraceabilityDetailsText {
  position: absolute;
  width: 453px;
  top: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.findTraceabilityDetailsEmptyIcon {
  color: #337dff;
  margin-bottom: 8px;
}

.findTraceabilityDetailsEmptyText {
  max-width: 237px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
}

.findTraceabilityDetails {
  height: 100%;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 11px;
  height: 100%;
}

.gridItem {
  margin: 0;
}

.gridItem:nth-child(1) {
  margin: 42px 0 0 16px;
}

.gridItem:nth-child(2) {
  margin: 42px 16px 0 0;
}

.gridItem:nth-child(3) {
  margin: 0 0 42px 16px;
}

.gridItem:nth-child(4) {
  margin: 0 16px 42px 0;
}

.sendingUserDateDetails {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-bottom: 24px;
  justify-content: center;
}

.senderRectangle {
  position: absolute;
  width: 3px;
  height: 40px;
  background: #d1d1d1;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 24px;
}

.userForm {
  text-align: center;
}

.userForm :global(.DialogBody),
.FindTraceabilityDialog :global(.widget-main) {
  background: #f6f6f6;
  padding-bottom: 0;
}

.fieldWrapper :global(.BngField) {
  width: 100%;
}

.fieldWrapper :global(.BngField .flex-center-items) {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #333333;
  text-shadow: 0 0 0;
}

.fieldWrapper :global(.controls input),
.fieldWrapper :global(.controls input:hover),
.fieldWrapper :global(.controls input:focus) {
  -webkit-box-shadow: 0 0 0 1px rgb(220 220 220 / 30%);
  -moz-box-shadow: 0 0 0 0;
  box-shadow: 0 0 0 0;
  z-index: 1 !important;
}

.fieldWrapper.incorrect :global(.controls input),
.fieldWrapper.incorrect :global(.controls input:focus) {
  -webkit-box-shadow: 0 0 0 1px rgb(217 32 32 / 30%) !important;
}

.fieldWrapper.incorrect :global(.BngField .flex-center-items) {
  color: red;
}

.trackingCodeField {
  min-height: 40px;
}

.iconFieldWrapper {
  position: absolute;
  left: 255px;
  bottom: 135px;
  z-index: 1;
}

.iconFieldWrapper.correct {
  color: #00a900;
}

.iconFieldWrapper.incorrect {
  color: red;
  text-shadow: 0 0 0;
}

.fieldWrapper input::-webkit-outer-spin-button,
.fieldWrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fieldWrapper input[type='number'] {
  -moz-appearance: textfield;
  padding-right: 35px;
}

.fieldWrapper :global(.controls) {
  display: flex;
  background: white;
  border: 1px solid #e4e4e4;
  border-radius: 4px !important;
}

.userForm :global(.content-full-width .bng-button) {
  margin: 20px 24px 0 0;
}

.fieldWrapper :global(.BngInput) {
  border: none;
  padding-left: 8px !important;
}
