.KpiMultiStepInfo {
  height: 500px;
  background: linear-gradient(120deg, rgba(35, 41, 214, 1) 0%, rgba(0, 172, 248, 1) 100%);
  position: fixed;
  z-index: 1008;
}

.KpiMultiStepInfo .PurpleBackground {
  background-color: #494b81;
}

.KpiMultiStepInfo.BngMultiStep .Step .Subtitle {
  color: #fff;
}

.KpiMultiStepInfo.BngMultiStep .Step .Title {
  color: #fff;
}

.KpiMultiStepInfo .widget-footer-pg-button {
  background-color: #70acf5;
}
.KpiMultiStepInfo .widget-footer-pg-button.active {
  background-color: #fff;
}

.KpiMultiStepInfo .bng-button.cancel {
  color: white !important;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.KpiMultiStepInfo .bng-button.save {
  color: white !important;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.KpiMultiStepInfo .bng-button.cancel.enable:hover {
  background-color: transparent !important;
  border: none !important;
}

.KpiMultiStepInfo .bng-button.save.enable:hover {
  background-color: transparent !important;
  border: none !important;
}

.KpiMultiStepInfo a {
  color: white;
  text-decoration: underline !important;
}
