.search-menu-button-container-enable {
  display: none;
}

.search-menu-button-container.open .search-menu-button-container-enable {
  display: block;
}

input.search-menu-button-input {
  width: 190px;
  border: none;
  margin-top: -7px;
}

.search-menu-button-container {
  position: absolute;
  top: 10px;
  right: 90px;
  background: #fff;
  height: 20px;
}

.search-menu-button-container i.search-menu-button-icon.material-icons,
.search-menu-button-container i.search-menu-button-icon-close.material-icons {
  cursor: pointer;
}

.bng-dropdown-parent .dropdown-menu {
  display: block;
  position: relative;
  float: none;
  border: none !important;
}

.MenuButtonDropdownPopper {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 8, 16, .15);
  border-radius: 4px;
  box-shadow: 0 4px 14px -2px rgba(0, 8, 16, .08);
  color: #333;
  width: fit-content;
  z-index: 101;
}

.MenuButtonDropdown .tippy-content {
  padding: 0;
}

.AlertContainer.error {
    position: absolute;
    bottom: 3px;
    right: -8px;
    z-index: 1;
    background-color: transparent;
    box-shadow: none;
    text-shadow: none;
    font-size: 16px;
    -webkit-text-stroke: 1px white;
}