.card {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 120px;
}

.title {
  font-size: 32px;
  font-weight: 550;
  line-height: 50px;
  text-align: left;
}

.subTitle {
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
}

.text {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  text-align: left;
  margin-bottom: 5px;
}

.buttonPrimary {
  width: 275px;
  height: 64px;
  font-size: 20px;
  padding: 18px 24px;
  border-radius: 12px;
  background-color: #337dff;
  color: #fff;
  border: none;
}

.buttonPrimary:focus {
  outline: none;
}

.buttonPrimary:hover {
  opacity: 0.8;
}