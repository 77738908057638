.backdrop {
  background-color: rgb(0, 0, 0, 0.8);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10002;
}

.main {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(229, 229, 229);
  padding: 24px 32px;
  z-index: 10003;
}

.textVertical {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
}

.continueCommon {
  height: 14px;
  width: 306px;
  border-radius: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: center;
  color: rgba(136, 136, 136, 1);
}

.continueOne {
  color: rgba(136, 136, 136, 1);
}

.continueTwo {
  color: rgba(77, 142, 255, 1);
}

.href {
  display: none;
}
