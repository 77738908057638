.main {
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.separator {
  color: #888888;
  font-size: 18px;
}
