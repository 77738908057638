.DescriptionMenuItem{
    width: 340px !important;
    max-width: 340px !important;
    padding-bottom: 0 !important;
    border-radius: 0px !important;
}

ul.bng-dropdown.container-dash-item-newmenu.prevent-children-overflow ul.DescriptionMenuItem {
    left: calc(-100% - 95px) !important;
}

ul.bng-dropdown.container-dash-item-newmenu.prevent-children-overflow-top ul.DescriptionMenuItem {
    top: unset;
    bottom: 0;
}

ul.DescriptionMenuItem li {
    cursor: default !important;
}

.DescriptionMenuItem .TitleObject{
    display: flex;
    align-items: center;
    box-shadow: inset 0 -1px 0 0 #E7E8EB;
    text-align: left;
}

.DescriptionMenuItem .TitleContainer{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.DescriptionMenuItem .TitleContainer > span{
    font-size: 14px !important;
}

.DescriptionMenuItem .TitleContainer > i{
    font-size: 15px !important;
}

.DescriptionMenuItem > li{
    display: block !important;
    padding: 0 !important;
}
.DescriptionMenuItem .DescriptionObject{
    background-color: #f7f8fa;
    box-shadow: inset 0 -1px 0 0 #E7E8EB;
    height: 100%;
    max-height: 250px;
    overflow: auto;
}

.DescriptionMenuItem .TitleContainer,
.DescriptionMenuItem .DescriptionObject > span {
    padding: 12px 17px !important;
    display: inline-block;
}