.dropDownButton {
  height: 40px;
  border-radius: 8px !important;
  display: inline-flex;
}

.dropDownIconButton {
  width: 23px;
  margin-right: 10px;
  border-radius: 15px;
  color: #ffffff;
  background: #005dff;
}

.dropDownLabelButton {
  font-weight: 500;
  color: #005dff;
  margin-top: 2px;
}

.cockpitName {
  width: 357px;
}

.cockpitNameLabel {
  margin-bottom: -1px !important;
}

.cockpitNameLabel :global(.control-label) {
  font-weight: unset !important;
}

.cockpitNameLabel :global(.controls) {
  display: grid !important;
}

.actionsButtons {
  position: absolute;
  top: 0;
  margin-top: 500px;
  margin-left: 550px;
}

.addObjectsButton {
  margin-left: 75%;
  position: absolute !important;
  z-index: 24999 !important;
  width: 160px;
}

.selectIconButton {
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 34px;
  width: 34px;
  border-radius: 4px;
  margin-top: 8px;
  text-align: center;
}

.selectIconButton:hover {
  background-color: rgba(0, 93, 255, 0.1);
  cursor: pointer;
}

.iconButton {
  font-size: 25px;
  display: block;
  position: relative;
  margin-top: 5px;
  color: #005dff;
}

.selectIcon {
  margin-right: 20px;
}

.iconLabel {
  font-size: 13px !important;
  font-weight: unset !important;
}

.checkBoxAlign {
  margin-top: 30px;
  margin-left: 15px;
}

.checkBoxLabel:global(.BngCheckbox .lbl) {
  font-size: 12px;
}

.listIcons {
  font-size: 22px !important;
  color: #005dff;
  padding-left: 5px;
}

.listIcons:hover {
  background-color: rgba(0, 93, 255, 0.1);
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
}

.iconWrapper {
  width: 45px;
}

.iconWrapper:hover {
  margin-left: 0;
}

.draggableIcon {
  width: 0 !important;
  min-width: 25px;
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.draggableIcon :first-child {
  font-size: 20px;
}

.iconCol {
  padding-right: 0 !important;
}

.nameCol {
  padding-left: 0 !important;
}

.nameRow {
  padding-left: 0 !important;
  width: 100% !important;
}

.nameRow :global(.div-information-object) {
  padding-left: 0 !important;
  opacity: 1 !important;
}

.options {
  text-align: end !important;
}

.tableList {
  width: 784px;
  max-height: 310px;
  height: 73%;
  overflow: auto;
  min-height: 285px;
  background-color: #f6f6f6;
}

.modalBackdrop {
  z-index: 24999 !important;
}

.CockpitSettings {
  z-index: 24999 !important;
  width: 830px;
  max-height: 550px;
  left: 43%;
}

.CockpitSettings:global(.CockpitSettings .modal-dialog .modal-content .modal-body) {
  height: 58vh;
  max-height: 650px;
  min-height: 570px;
  overflow: hidden;
}

.CockpitSettings:global(.CockpitSettings .widget-body) {
  height: 375px;
  border-bottom: 1px solid #e2e2e2;
}

.CockpitSettings:global(.CockpitSettings .widget-box) {
  border-bottom: 1px solid #e2e2e2;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.CockpitSettings:global(.CockpitSettings .widget-box .widget-body .widget-main) {
  height: 100%;
}

.CockpitSettings:global(.CockpitSettings .widget-box .widget-body .widget-main .dialog-body) {
  overflow: hidden;
  height: 35vh;
  max-height: 430px;
  min-height: 370px;
}

.CockpitSettings:global(.CockpitSettings .widget-box .widget-body .widget-main .dialog-body .BngForm) {
  overflow: hidden;
  height: 100%;
}

.objectOptionsOverlay {
  z-index: 24999 !important;
  background-color: transparent;
}

.selectedRow {
  background-color: rgba(220, 233, 248, 1) !important;
}

.selectedRow :global(.BngTableTd) {
  background-color: rgba(220, 233, 248, 1) !important;
}

.selectedRow :global(:nth-child(even) .BngTableTd) {
  background-color: rgba(220, 233, 248, 1) !important;
}

.selectedRow :global(:hover .BngTableTd) {
  background-color: rgba(220, 233, 248, 1) !important;
}
.rowActions {
  width: 0 !important;
}

.iconRow {
  padding-left: 30px !important;
  color: #373737 !important;
  width: 25px !important;
  padding-right: 0 !important;
}

.wrapperActionsButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.objectNameEditing {
  margin-bottom: 0 !important;
}

.nameWrapper {
  display: inline-flex;
  align-items: center;
}
