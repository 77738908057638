.ActionsDialog {}

.ActionsDialog.BngClickOutsideOverlay {
    z-index: 321051;
}

.ActionsDialog.bng-dropdown-parent {
    z-index: 321052;
}

.ActionsDialog .icon-dropdown {
    cursor: pointer;
    border-radius: 100%;
    padding: 2px;
    transition: all 0.2s ease-in;
}

.ActionsDialog .icon-dropdown:hover,
.ActionsDialog .icon-dropdown.open {
    background-color: lightgrey;
}

.ActionsDialog .NewActionBtn {
    margin: 0;
    width: auto;
    white-space: nowrap;
}

.ActionsDialog .NewActionBtn .material-icons {
    vertical-align: bottom;
}

.ActionsDialog .NewActionBtn.open {
    background: #efefef !important;
}

.ActionsDialog .ScrollContainer {
    height: 300px;
    max-height: 300px;
}