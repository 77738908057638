.menu-home {
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 60;
    font-weight: 500;
}

.menu-home.active,
.menu-home:hover {
    background: #f7f8fa;
}

.menu-home i.material-icons {
    line-height: 48px;
    height: 48px;
    width: 35px;
    color: #6e6e6e;
    font-size: 22px;
}

td.menutd.menutd-text.menutd-text-home {
    color: #6e6e6e;
}

.menutd {
    vertical-align: middle;
}

.menutd i.material-icons {
    font-size: 22px;
}

.menutd:not(:first-child) {
    padding-right: 10px;
}

.menu-text-span {
    line-height: 15px;
    margin-left: 0;
    font-weight: 500;
}

.nav-list > li .submenu > li > a {
    padding: 0 0 0 15px;
    height: 38px;
}

.menu-min .nav-list > li .submenu > li > a {
    padding: 7px 0 7px 24px;
}

.nav-list > li .submenu > li > * {
    padding: 0;
}

.nav-list > li > .submenu li > .submenu > li a {
    margin-right: 0;
}

.nav-list > li .submenu > li > a:hover {
    background: #efefef;
}

.submenu-item {
    text-align: left;
    padding: 10px 8px 10px 44px;
    text-overflow: ellipsis !important;
    white-space: nowrap;
    width: 210px;
    overflow: hidden;
}

.nav-list li .submenu {
    overflow: hidden;
    border-bottom: 1px solid #e2e2e2;
    border-left: 1px solid #e2e2e2;
}

.sidebar .nav > li > a:hover, .nav > li > a:focus {
    color: #6e6e6e;
}

.sidebar .nav-list > li > a:focus {
    color: #6e6e6e;
}

.nav-list > li > .submenu li.open > a:hover,
.nav-list > li .submenu > li > a:hover,
.open td.menutd-icon,
.sidebar .nav > li > a:hover,
.sidebar .nav > li > a:focus,
.nav-list > li.active {
    color: #575bd8;
}

i.material-icons.submenu-item-chevron {
    position: absolute;
    left: 14px;
    top: 11px;
    font-size: 16px;
    opacity: .5;
}

#sidebarContainerList i.submenu-icon {
    position: absolute;
    width: 14px;
    left: 16px;
    top: 11px;
    font-size: 18px;
}

/* Fix bug on menu items on /spr/bng url */
.BngApp .nav td.menutd.menutd-text {
    line-height: 0;
}