.filter-selection-dialog.FilterType-TIME.modal {
    width: 575px;
    margin-left: -275px;
}

.filter-selection-dialog.FilterType-TIME .DialogContent {
    padding: 0;
}

.TimeFilterView {
    height: 330px;
    overflow: hidden;
}

.TimeFilterView .select-items-component-items-container {
    width: 100%;
}

.TimeFilterView > div {
    height: 100%;
}

.TimeFilterView .Content {
    padding-top: 10px;
    margin: 0;
    width: 67%;
}

.TimeFilterView .BngCalendar {
    width: 100%;
}

.TimeFilterView .Menu {
    width: 33%;
    font-weight: 500;
    border-right: 1px solid #dedfe1;
}

.TimeFilterView .Menu .Header {
    border-top: 1px solid #e8e9eb;
    border-bottom: 1px solid #e8e9eb;
    height: 35px;
    line-height: 35px;
    padding-left: 20px;
    margin-bottom: 10px;
}

.TimeFilterView .Menu .Header:first-child {
    border-top: none;
}

.TimeFilterView .Menu li {
    margin-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.TimeFilterView .Menu li .material-icons {
    line-height: 19px;
}

.TimeFilterView .Menu a {
    color: #6C6C6C;
}

.TimeFilterView .Menu a.disabled {
    color: #bcbcbc;
}

.TimeFilterView .Menu li.selected a {
    color: #005DFF;
}

.TimeFilterView .BngCalendar {
    background-color: #F7F8FA;
}

.TimeFilterView .select-items-one .left-panel > fieldset > hr,
.TimeFilterView .select-items-one .left-panel > fieldset > .row-fluid:last-child {
    display: none;
}

.TimeFilterView .select-items-component-items-container {
    height: 265px;
}

.TimeFilterViewMaxDetailDropOverlay {
    z-index: 999998 !important;
}

.TimeFilterViewMaxDetailDrop {
    z-index: 999999 !important;
}

.TimeFilterView .PeriodView > .Inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 20px;
}

.TimeFilterView .PeriodView.year .PeriodField {
    width: 62px;
}

.TimeFilterView .PeriodView.decade .PeriodField {
    width: 42px;
}

.TimeFilterView .PeriodView .PeriodField {
    background-color: transparent;
    border-color: transparent;
    border-radius: 0;
    border-bottom-color: #005DFF;
    color: #292C31;
    width: 82px;
    line-height: 1em;
    margin-bottom: 0;
    font-weight: bold;
    padding: 6px 2px 0;
}

.mobileIos-dropdown input[type=radio]:after {
    margin: -2px !important;
}

.mobileIos-dropdown input[type=radio]:checked:before {
    margin: 0.5px !important;
}

.TimeFilterView .select-items-component-items-container {
    overflow-x: hidden;
}

@media only screen and (max-device-width: 829px), screen and (max-device-height: 560px) {
    .TimeFilterView .PeriodView > .Inputs {
        justify-content: start !important;
        padding-left: 10px !important;
        width: 76%;
        margin-top: -10px;
        margin-bottom: 3px !important;
    }

    .TimeFilterView .PeriodView > .Inputs .BngDropdown button {
        width: 67px;
    }

    .TimeFilterView .Content {
        padding-top: 5px;
        width: 75% !important;
    }

    .TimeFilterView .Menu {
        width: 25% !important;
    }

    .TimeFilterView .select-items-component-items-container {
        height: 280px;
    }

    .TimeFilterView .PeriodView .PeriodField {
        width: 90px;
    }
}