.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrapper :global(span) {
  line-height: 17px;
}

.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 254px;
  max-height: 254px;
}

.imgContainer :global(img) {
  border-radius: 10px;
  max-height: inherit;
}

.textContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
}

.title {
  color: var(--dark-gray-title);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.desc {
  color: var(--gray-description);
  text-align: center;
  font-size: 14px;
}

.childrenContainer {
  margin-top: 8px;
}

.video {
  width: 255px;
  height: 255px;
}
