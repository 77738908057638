.tableScroll {
  height: calc(100vh - 230px);
  overflow: auto;
  position: relative;
  transition: height 0.8s ease-in-out, top 0.8s ease-out;
}

.tableScroll :global(.BngTableTh:first-child),
.tableScroll :global(.BngTableTd:first-child) {
  padding-left: 32px;
}

.tableScroll :global(.BngTableTh) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tableScroll.closed {
  height: 0;
  opacity: 0;
}
