.DimensionMembersHeader {
  display: flex;
  justify-content: space-between;
}

.DimensionMembersList {
  background-color: white;
  border: solid 1px #efefef;
  border-radius: 8px;
}

.MemberItemContainer {
  display: flex;
  margin: 7px 15px;
  border-bottom: solid 1px #efefef;
  justify-content: space-between;
}

.MemberItemContainer:last-child {
  border-bottom: none;
}

.MemberItemContainer > div > span {
  font-size: 12px;
  padding-left: 10px;
}

.MemberItem {
  display: flex;
  align-items: center;
}

.EditButtonContainer {
  display: flex;
  align-items: flex-end;
  margin-top: -8px;
}

.EditButtonContainer > button {
  margin-right: -5px;
}

.EditButtonContainer > button:hover {
  background-color: transparent !important;
}

.MemberItemContainer > button i,
.EditButtonContainer > button i {
  font-size: 20px !important;
}

.EditButtonContainer > label {
  cursor: pointer !important;
}

.IconDragIndicator {
  padding: 5px !important;
  color: #6e6e6e;
}
