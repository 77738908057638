.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  color: #333333;
}

.LogPreviewWrapper .errorDescription {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #333333;
}

.AdaAiButton {
  position: relative;
  display: flex;
  align-items: flex-end;
}

.AdaAiButtonImg {
  width: 60px;
}

.AdaAiButton :global(.ada-ai) {
  background-color: #005DFF;
  padding: 2px 8px 2px 8px;
  border-radius: 8px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: center;
  height: fit-content;
}

.AdaAiButton :global(.ada-ai):hover {
  background-color: rgb(71, 130, 248);
}

.AdaAiButton :global(.Icon) {
  margin-left: 5px;
  display: flex;
}
