.bng-button-group {
    display: inline-block;
    margin-bottom: 18px;
}

.bng-button-group .bng-button-group-item {
    display: inline-block;
    padding: 3px 3px;
    background-color: #fff;
    color: #000 !important;
    cursor: pointer;
}

.bng-button-group-item i {
    font-size: 24px;
}

.bng-button-group-item:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.bng-button-group-item:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.bng-button-group .bng-button-group-item:hover {
    background-color: #ccc;
}

.bng-button-group .bng-button-group-item.active {
    background-color: #0679FF;
    color: #fff !important;
}

.bng-button-group .bng-button-group-item.disabled {
    background-color: #ddd;
    color: #888 !important;
    cursor: not-allowed !important;
}

.bng-button-group .bng-button-group-item.active:hover {
    background: #506dea !important;
}