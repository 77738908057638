.CopyClipboardComponent {
    width: 100%;
    display: flex;
    align-items: center;
}

.CopyClipboardComponent .AuxCopyInput {
    position: absolute;
    opacity: 0;
    z-index: -1;
}