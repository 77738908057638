.AccessTimeRestrictionTab {
  margin: 35px;
}

.warningBadge {
  display: flex;
  align-items: center;
  background-color: #fcf8e3;
  border: 1px solid #fcf1de;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
}

.warningIcon {
  color: #f98900;
  margin-right: 8px;
}

.containerRuleLog {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  border: 1px solid #e4e4e4;
  padding: 5px;
}

.buttonNewRule {
  color: #005dff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  margin-left: 10px;
}

.buttonLog {
  color: #333;
  margin-right: 15px;
}

.containerTable {
  border: 1px solid #e4e4e4;
  border-top: none;
}

.userAvatar {
  width: auto;
  height: 34px;
  border-radius: 100%;
  background-size: cover !important;
}

.userAvatarOuter {
  background: #ffffff;
  border-radius: 100%;
  width: 34px;
  height: 34px;
}

.groupIcon {
  height: 34px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: #7f7f7f;
}

.groupWeekDays {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.day {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
}

.releasedDays {
  background-color: #005dff;
  color: #fff;
}

.restrictionDays {
  background-color: #d1d1d1;
  color: #fff;
}

.lockIcons {
  color: #005dff;
}

.days :global(.div-information .item-slide-name) > div {
  display: flex;
  align-items: center;
}

.userAndGroups :global(.div-information-user .user-item-slide-name) > div {
  display: flex;
  align-items: center;
}
