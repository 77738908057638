.ExpertFormulaDialog {
    top: 5px;
}

.ExpertFormulaDialog .form-container {
    height: 500px;
}

.ExpertFormulaDialog .inner-tabs .tab-content {
    height: 280px;
}

.ExpertFormulaDialog .tab-content {
    height: 350px;
}

.ExpertFormulaDialog .CodeMirror {
    height: 315px;
    width: 435px;
}

.ExpertFormulaDialog .has-error .CodeMirror {
    height: 290px;
}

.ExpertFormulaDialog .free-member-box {
    height: 138px;
    overflow: auto;
}

@media screen and (min-height: 700px) {

    .ExpertFormulaDialog .form-container {
        height: 620px;
    }

    .ExpertFormulaDialog .inner-tabs .tab-content {
        height: 400px;
    }

    .ExpertFormulaDialog .tab-content {
        height: 470px;
    }

    .ExpertFormulaDialog .CodeMirror {
        height: 435px;
        width: 435px;
    }

    .ExpertFormulaDialog .has-error .CodeMirror {
        height: 410px;
    }

    .ExpertFormulaDialog .free-member-box {
        height: 198px;
        overflow: auto;
    }

}

.ExpertFormulaDialog .name-input {
    height: 62px;
}

.ExpertFormulaDialog .name-input .help-block {
    margin-left: 50px;
}

.ExpertFormulaDialog .name-input label {
    display: inline-block;
    vertical-align: middle;
}

.ExpertFormulaDialog .name-input input[type='text'] {
    width: calc(100% - 53px);
    margin-left: 10px;
}

.ExpertFormulaDialog .member-btn {
    text-align: left !important;
    padding-left: 10px;
}

.ExpertFormulaDialog .operation-btn {
    background-color: #f3f3f3 !important;
    color: #333 !important;
    font-size: 20px;
}

.ExpertFormulaDialog .droppable.drag-active {
    background-color: #CCC;
    min-width: 100px;
    min-height: 30px;
}

.ExpertFormulaDialog .mdx-table.initial {
    margin: 0 auto;
}

.ExpertFormulaDialog .preview-backdrop {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #333;
    opacity: 0.25;
}

.ExpertFormulaDialog .preview-box .preview-box-inner .mdx-box {
    width: 600px;
    height: 400px;
    overflow: auto;
}

.ExpertFormulaDialog .preview-box .preview-box-inner {
    cursor: default;
    background-color: white;
    padding: 10px;
    border: 1px solid #CCC;
    border-radius: 2px;
    z-index: 2;
}

.ExpertFormulaDialog .preview-box {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ExpertFormulaDialog.preview-on form {
    filter: blur(5px);
}

.ExpertFormulaDialog legend {
    color: #333;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
}

.FormatComponent {
    width: 620px;
}

.FormatComponent .checkbox {
    padding-left: 0
}

.FormatComponent .element-box {
    width: 55px;
    height: 35px;
    line-height: 35px;
    display: inline-block;
    text-align: center;
    border: 1px solid white;
    border-radius: 3px;
}

.FormatComponent .colorhextxt {
    width: 220px;
}

.FormatComponent .IconSelector[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

.FormatComponent .IconSelector[disabled] .element-box:hover {
    background-color: white;
}

.FormatComponent .element-box:hover {
    background-color: #eeeeee;
}

.FormatComponent .element-box.selected {
    background-color: #f8f8f8;
    border-color: #333;
}

.FormatComponent .element-box .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.FormatComponent .element-box img {
    max-width: 50px;
    max-height: 30px;
    vertical-align: middle;
}

.BandsConfiguration input,
.BandsConfiguration select {
    margin-bottom: 0;
}

.BandsConfiguration .FormatRender {
    min-width: 29px;
    height: 29px;
    line-height: 29px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 0 3px;
    cursor: pointer;
    white-space: nowrap;
    border: 1px solid #ccc;
}

.BandsConfiguration .table td:nth-child(1) {
    width: 90px;
}

.BandsConfiguration .table td:nth-child(2) {
    width: 80px;
}

.BandsConfiguration .table td:nth-child(3) {
    width: 130px;
}

.BandsConfiguration .table td:nth-child(5) {
    width: 30px;
    text-align: center;
}

.FormulaFormat .customize-checkbox {
    position: absolute;
    right: 0;
}