.BngMention {
    overflow-x: hidden;
    width: 100%;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
}

.BngMention .BngMention__control {
    width: 100%;
}

.BngMention .BngMention__highlighter {
    padding: 4px 6px;
}

.BngMention input.BngMention__input {
    border: none;
    margin: 0;
}

div.BngMention__suggestions {
    z-index: 105 !important;
}

.BngMention__suggestions__list {
    max-height: 200px;
    overflow-y: auto;
}

.BngMentionText strong {
    text-shadow: 0 0 0 black;
}