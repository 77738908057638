.disabledButton {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.userNameWrapper {
  align-items: center;
  display: inline-flex;
  gap: 5px;
  width: 285px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userAvatar {
  background-size: cover !important;
  border-radius: 100%;
  height: 38px;
  min-width: 38px;
}

.projectsWrapper {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 210px;
}

.colEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}

.UsersTabActions {
  width: 280px !important;
}

.UsersTabActions li i {
  font-size: 20px !important;
}

.UsersTabActions li span {
  font-size: 14px;
}

.AccUsersTab :global(.BngTableTh):first-child {
  padding-left: 25px;
}

.AccUsersTab :global(.BngTableTd):first-child {
  padding-left: 40px;
}

.userCount {
  color: #333333;
  font-weight: 500;
  margin-right: 10px;
}
