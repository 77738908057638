.AddonAcceptPage {
  align-items: center;
  background: #f6f6f6;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.AddonAcceptContent {
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  max-width: 485px;
  padding: 24px;
}
