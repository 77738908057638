.loadingFrame {
  width: 100vw;
  height: calc(100vh - 50px);
}

.universityBlocker {
  z-index: 100;
}

.universityFrame {
  width: 100vw;
  height: calc(100vh - 50px);
  z-index: 101;
  position: absolute;
  border: none;
}
