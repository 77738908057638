.AccAdaAi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.AccAdaAi :global(.BngForm) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AccAdaAi h1 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.AccAdaAi h1 img {
  width: 30px;
}

.AccAdaAi span {
  font-weight: bold;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputWrapper {
  width: 423px;
  height: 87px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
}

.inputWrapper :global(.BngField.control-group) {
  margin-bottom: 0;
  width: 400px;
  flex: 92%;
}

.inputWrapperIcon {
  flex: 8%;
}

.inputWrapperErrorMsg {
  flex: 100%;
  display: flex;
  justify-content: center;
}

.errorMsg {
  color: red;
  margin-top: 5px;
}

.input.invalid {
  border-color: red;
}

.input {
  flex: 1;
  width: 440px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.iconDone:hover {
  background-color: #ff5d5d;
  transition: background-color 0.3s ease;
}

.iconDone {
  margin: 30px 0 10px 10px;
  color: white;
  background-color: #005dff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px !important;
}

.saveButton {
  padding: 10px 20px;
  margin-top: 10px;
}
