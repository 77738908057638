.MemberFilterList {
}

.MemberFilterListItemPopper .FilterMembers {
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 6px;
}

.MemberFilterListItemPopper .FilterMembers .MembersContainer {
  position: relative;
  overflow: auto;
  padding: 0 4px;
}

.MemberFilterListItemPopper .FilterMembers .bng-search {
  height: 30px;
}

.MemberFilterList .MemberInfo .BngInput {
  background-color: white !important;
}

.MemberFilterList .AddButton {
  width: 100% !important;
}

.MemberFilterListItemPopper .FilterMembers .MembersContainer .lbl {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.BngSelectDropdownPopper.DimensionListFilterPopper {
  z-index: 1000002 !important;
}

.MemberFilterList .Action {
  padding: 8px;
}