.BngCodeMirror {
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    position: relative;
}

.BngCodeMirror .CodeMirror.cm-s-default {
    border: none;
    border-bottom: 2px solid #f7f7f7;
}

.BngCodeMirror .BngCodeMirrorVars {
    display: flex;
    padding: 10px;
}

.BngCodeMirror .BngCodeMirrorVar {
    background-color: #6c6c6c;
    transition: 0.2s ease-in;
    font-weight: bold;
    padding: 4px 8px;
    color: white;
    border-radius: 2px;
    cursor: pointer;
    margin-right: 15px;
}

.BngCodeMirror .BngCodeMirrorVar:last-child {
    margin-right: 0;
}

.BngCodeMirror .BngCodeMirrorVar:hover {
    background-color: #3558ef;
}

.BngCodeMirror .DisabledOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left:0;
    right: 0;
    background-color: grey;
    opacity: 0.2;
    cursor: not-allowed;
    z-index: 3;
}