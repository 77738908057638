.BngVideoModal {
    border-radius: 6px;
    background: linear-gradient(180deg, #00ACF8 10%, #2329D6 115%);
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
    width: 550px;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.BngVideoModal .Body {
    flex: 1;
}

.BngVideoModal .Step {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.BngVideoModal .Step .Children {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.BngVideoModal .Step .Image {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    height: 309px;
    width: 100%;
}

.BngVideoModal .Step .Image.FullHeight {
    height: 100%;
}

.BngVideoModal .Step .Title {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: normal;
    margin: 20px 20px;
}

.BngVideoModal .Step .Subtitle {
    color: #fff;
    font-size: 15px;
    letter-spacing: 0.09px;
    line-height: 19px;
    margin: 0 20px;
    max-height: 70px;
    overflow-y: auto;
}

.BngVideoModal .Step .Footer button {
    margin: 0;
    border: unset;
}

.BngVideoModal .Footer {
    display: flex;
    flex: 0 1 60px;
    margin: 0 20px;
    align-items: center;
}

.BngVideoModal .Footer .Dots {
    display: flex;
    flex: 1;
}

.BngVideoModal .Footer .Buttons {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.BngVideoModal .bng-button.cancel {
    color: white !important;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
}

.BngVideoModal .bng-button.save {
    color: white !important;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    font-size: 16px;
}

.BngVideoModal .bng-button.cancel.enable:hover {
    background-color: transparent !important;
    border: none !important;
}

.BngVideoModal .bng-button.save.enable:hover {
    background-color: transparent !important;
    border: none !important;
}

.BngVideoModal a {
    color: white;
    text-decoration: underline !important;
}