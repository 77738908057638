.HttpActionDialog {
}

.HttpActionDialog .navigation-tabs {
    padding: 0;
}

.HttpActionDialog .widget-main {
    padding-top: 0
}

.HttpActionDialog .BodyEditorContainer {
    position: relative;
    height: 275px;
}

.HttpActionDialog .BodyEditorContainer .BodyOpts {
    display: flex;
    align-items: baseline;
}

.HttpActionDialog .BodyEditorContainer .BodyOpts .BngRadio {
    margin-right: 15px;
}

.HttpActionDialog .KvTable .BngField {
    margin: 0;
}

.HttpActionDialog .KvTable .control-label {
    display: none;
}

.HttpActionDialog .tab-content {
    height: 325px;
}

.HttpActionDialog .ProxyAddButton {
    position: absolute;
    top: 3px;
    right: 0;
}

.HttpActionDialog .tab-content .AddButton {
    display: none;
}

.HttpActionDialog .BodyType {
    margin-bottom: 0;
}

.HttpActionDialog .BodyType .control-label {
    width: 50px;
}

.HttpActionDialog .BodyType .controls {
    width: 100%;
}

.HttpActionDialog .BodyEditorContainer .ScrollContainer {
    height: 255px;
}

.HttpActionDialog .save.TestButton {
    margin-left: 0;
    background-color: #269c59 !important;
    border-color: #269c59 !important;
}

.HttpActionDialog .save.enable.TestButton:hover {
    background-color: #26b471 !important;
    border-color: #26b471 !important;
}

.HttpTestResultDialog pre {
    max-height: 30vh;
    min-height: 30vh;
    overflow: auto;
}