.CreateIpRestrictionDialog :global(.DialogBody) {
  max-height: 450px;
  overflow: auto;
}

.CreateIpRestrictionDialog :global(.DialogBody),
.CreateIpRestrictionDialog :global(.DialogBody) label,
.CreateIpRestrictionDialog :global(.DialogBody) button {
  font-size: 12px;
}

.restrictedIps {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.textField {
  flex: 1;
  margin-bottom: 0 !important;
}

.textField input {
  border: 1px solid #d1d1d1;
  color: #888888;
  font-weight: 500;
  height: 38px !important;
}

.ipRegionWrapper {
  display: inline-flex;
  gap: 20px;
  width: 100%;
}

.restrictionListField {
  width: 50%;
}
