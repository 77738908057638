.main {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.main input,
:global(.DarkMode) .main input {
  color: #333333 !important;
}

.main input {
  width: 40px;
  height: 46px;
  background: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px !important;
  font-weight: 700;
  font-size: 32px !important;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  padding: 8px 0;
}

.main input:not([value='']) {
  background: rgba(255, 255, 255, 0.8) !important;
}

.main input:focus {
  background: rgba(255, 255, 255, 1) !important;
}

.main input.correct {
  border-color: #00a355 !important;
}

:global(.error) input {
  border-color: #e23d3d !important;
}

.main input.warning {
  border-color: #ffb800 !important;
}

.main input:focus {
  background: #ffffff !important;
  border-color: #005dff !important;
}

.main input.incorrect:focus {
  border-color: #e23d3d !important;
}

.main input.warning:focus {
  border-color: #ffb800 !important;
}
