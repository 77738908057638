.ul-dropdown-slideType {
    list-style-type: none;
    padding: 0;
    margin-top: 26px;
    margin-left: 604px;
    background-color: white;
    font-weight: bold;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 50000 !important;
    border-radius: 8px;
    width: 170px;
}

.button-menu-slide-types {
    border-radius: 6px;
    font-size: 28px;
    cursor: pointer;
    margin-top: 9px;
    display: inline-flex;
    position: absolute;
    right: 0;
    padding: 3px;
    margin-right: 17px;
    color: #005DFF;
    font-weight: 500;
}

.button-menu-slide-types:hover {
    background-color: #eee;
}

.li-dropdown:hover {
    background-color: #eee;
}

.button-dropdown-action {
    margin: 0 2px !important;
    background: transparent !important;
    color: #555555 !important;
    padding-top: 5px !important;
    font-size: 14px;
}

.bng-dropdown.slide-type {
    width: 200px;
    margin-right: 9px;
    margin-top: -17px;
}

.btn-only-icon.slide-type {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
}