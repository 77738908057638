.BngTimePicker {
    animation: fade-in 150ms ease-out;
    border-radius: 12px;
    box-shadow: 0 11px 15px #0005;
    overflow: hidden;
}

@keyframes fade-in {
    0% {
        transform: translateY(-150px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.WheelsContainer {
    background-color: #f6f6f6;
    display: flex;
    justify-content: center;
    height: 138px;
    overflow: hidden;
    padding: 20px 0;
    position: relative;
    width: 220px;
}

.BngTimePickerPopup button {
    border: none;
    background: transparent;
    cursor: pointer;
}

.BngTimePickerPopup input {
    border: none;
    background: transparent;
    cursor: pointer;
}

.BngTimePickerPopup input:focus {
    outline: none;
}

input.TimePickerInput {
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    color: #333;
    cursor: text;
    font-size: 14px;
    height: 38px;
    padding: 5px 10px;
}

.BngTimePickerPopup {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    width: 220px;
    z-index: 321050;
}

.react-ios-time-picker-popup-overlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.NumberWheel {
    position: relative;
    width: 50px;
    overflow: hidden;
    z-index: 100;
    margin-left: 5px;
}

.HourWheelFormat,
.MinuteWheelFormat {
    align-items: center;
    display: flex;
    text-align: center;
    transition: all 100ms linear;
    user-select: none;
    width: 100%;
}

.HourWheelFormat,
.HourWheelFormat .cellFormat {
    justify-content: end;
}

.MinuteWheelFormat,
.MinuteWheelFormat .cellFormat {
    justify-content: start;
}

.cellFormat {
    align-items: center;
    border-radius: 7px;
    color: #333333;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 100%;
    transition: all 100ms linear;
    line-height: 35px;
    padding: 0 10px;
    text-align: center;
    width: fit-content;
}

.cellFormat:hover {
    background-color: #005dff;
    color: white;
}

.cellFormat.cellSelected {
    color: #f7f7f7;
    font-size: 16px;
}

.cellFormat.cellHidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
}

.fastScroll {
    transition: transform 700ms cubic-bezier(0.13, 0.67, 0.01, 0.94);
}

.slowScroll {
    transition: transform 600ms cubic-bezier(0.13, 0.67, 0.01, 0.94);
}

.buttonContainer {
    background-color: #fff;
    border-bottom: 1px solid #E4E4E8;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 100;
}

.selectedOverlay {
    position: absolute;
    border-radius: 6px;
    background-color: #005dff;
    pointer-events: none;
    margin: 0 10px;
    left: 0;
    right: 0;
    z-index: 1;
}

.actionButtons {
    padding: 10px 15px;
    font-size: 13px;
    color: #005dff;
    transition: all 150ms linear;
    font-weight: 500;
    z-index: 1;
}

.actionButtons:hover {
    opacity: 0.6;
}

.actionButtons.cancelButton {
    font-size: 12px;
    font-weight: 300;
}

.WheelsSeparator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #f7f7f7;
    position: relative;
    z-index: 100;
    font-weight: 600;
}