.BngAnalystMenu {
}

.BngAnalystMenu .Action {
    padding: 8px;
    width: 100% !important;
}

.BngAnalystMenu .BngSelectSearch {
    box-shadow: none;
    border: 1px solid #EFEFEF;
}

.BngAnalystMenu .BngSelectSearch .OptionPreview {
    padding-top: 1px;
    padding-bottom: 1px;
}

.BngAnalystMenu .BngSelectSearch .OptionPreview label {
    font-size: 12px;
    font-weight: 400;
}