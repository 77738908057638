.UserFilterManagementDialog {
  max-height: 600px;
  width: 860px;
}

.UserFilterManagementDialog :global(.DialogBody) {
  height: 48vh;
  max-height: 450px;
  overflow: hidden;
}

.UserFilterManagementDialog :global(.widget-body),
.UserFilterManagementDialog :global(.widget-main) {
  padding: 0;
}

.UserFilterManagementDialog :global(.DialogFooter) {
  border: 1px solid #e6e7ea;
  padding: 12px 10px;
}

.containerNewUserFilter {
  margin-top: 5px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buttonNewUserFilter {
  color: #005dff !important;
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableContainer {
  height: 43vh;
  overflow: auto;
}

.tableRows {
  max-height: 40px;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.filterUsers :global(.div-information-cockpit .cockpit-item-slide-name) > div {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.filterUsers:global(.div-information-cockpit) {
  justify-content: center;
  opacity: unset;
}

.filterUsers:global(.cockpit-item-slide-name) {
  opacity: unset;
}
