.AddAdditionalDialog {
  background: #fff;
  border-radius: 8px;
  max-width: 500px;
  width: 500px;
}

.AddAdditionalDialogHeader {
  border-radius: 8px;
  height: 150px;
  overflow: hidden;
  width: 100%;
}

.AddAdditionalDialogHeader svg {
  width: 100%;
}

.headerIcon {
  font-size: 82px !important;
  left: 42%;
  position: absolute;
  top: 35%;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 25px;
}

.buttonWrappers {
  align-items: center;
  display: flex;
  gap: 35px;
  justify-content: center;
  margin: 10px 0;
  overflow: hidden;
  width: 100%;
}

.additionalTitle {
  color: #333333;
  font-size: 26px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
}

.additionalDesc,
.additionalPricingInfo,
.additionalPricing {
  color: #555555;
  font-weight: 500;
  text-align: center;
}

.additionalPricing {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.additionalPricingInfo {
  font-size: 12px;
}

.additionalButtons {
  border-radius: 2px;
  font-weight: 700;
  padding: 8px 18px;
}

.currencySymbol,
.pricingCents,
.byMonth {
  font-size: 14px;
}

.pricingCents {
  margin-bottom: -5px;
}

.currencySymbol {
  align-items: end;
  display: flex;
  height: 100%;
}

.fullPricing {
  font-size: 32px;
}

.centsWrapper {
  align-items: start;
  display: flex;
  flex-direction: column;
}

.detailsBtn {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
}

.hiddenTemporaryQuota {
  align-items: center;
  box-sizing: border-box;
  color: #555555;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 0 40px;
  text-align: center;
  width: 100%;
}

.temporaryQuotaWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.freeQuotaButtons {
  display: flex;
  gap: 15px;
  justify-content: center;
  width: 100%;
}
