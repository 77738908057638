.bar-style {
    background: #000000;
    color: #ffffff;
    margin: 0;
    width: 804px;
    height: 70px;
    border: 0px;
}

.bar-style .img {
    margin: 15px;
    height: 45px;
    width: 50px;
}

.bar-style .imggif {
    background: url(./loadbar.gif) repeat-x;
    height: 8px;
    min-height: 8px;
}

.bar-style.spanmesage {
    line-height: 50px;
    margin: 20px 0 0 6px;
    font-weight: bold;
}

.ws-information-container-left {
    float: left;
    margin-left: 20px;
    padding-right: 20px;
    border-right: 3px solid #f1f2f2;
    width: 500px;
    height: 210px;
}

.spansubmit {
    height: 66px;
    width: 804px;
    border-top: 1px solid #CCCCCC;
    margin-left: 0px;
}


.spansubmit .pull-right {
    margin-right: 34px;
    margin-top: 25px;
    float: right;
    border-radius: 0;
    font-weight: bold;
    background-color: #000000 !important;
    padding: 5px 15px;
}


#btn-validade {
    margin-left: 60px;
    border-radius: 0;
    font-weight: bold;
    background-color: #000000 !important;
}

.validate {
    margin-right: 20px;
    margin-top: 25px;
}

.informationtitle {
    font-weight: bold;
}

.information {
    font-weight: bold;
    margin-top: 10px;
}

.information > *:first-child {
    margin-right: 5px;
}

.informationfa.fa-address-card {
    color: #6384B7;
}

.titlebox {
    line-height: 30px;
    margin: 20px 0 0 6px;
    font-weight: bold;
}

.is-connected {
    font-size: 11px;
    display: inline-flex;
    margin-left: 65px;
    margin-top: 5px;
}

.is-connected. fa-circle-o:before {
    margin-right: 5px;
}

#pipe-btn-ok {
    margin-left: 0px;
    height: 28px;
    margin-top: 0px;
}

#circle-validate {
    margin-right: 5px;
    margin-top: 3px;
}

#titlehelp {
    color: #ffffff;
    margin-left: 4px;
    margin-bottom: 50px;
    font-weight: bold;
}

#nameinvalid {
    color: #700;
}

.pipe-help-link-box {
    display: inline-block;
    text-align: right;
    margin-right: 10px;
}

.spansubmit .pull-right {
    margin-right: 0;
}

.spansubmit .pull-right i {
    vertical-align: middle;
    height: 16px;
    line-height: 14px;
}

.ws-information-details {
    padding: 10px 0;
    word-break: break-word;
}

.ws-information-container {
    padding: 25px 0;
}