.BngColorRange__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BngColorRange__ranges {
    background-color: #fff;
    border-radius: 5px;
    padding: 8px 15px 15px;
}

.BngColorRange__range {
    padding: 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.BngColorRange__button {
    color: #666;
    cursor: pointer;
    font-size: 26px;
    font-weight: bold;
}

.BngColorRange__deleteButtonContainer {
    display: flex !important;
    align-items: center;
    justify-content: center;
}