.ConnectionsDialog .ConnectionsList .BngTableTd {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ConnectionsDialog .DialogBody {
    min-height: 350px !important;
    max-height: 350px !important;
    overflow-y: auto;
    background-color: #f6f6f6;
}

.ConnectionsDialog .ConnectionSearch {
    margin: 0 20px;
    width: 800px;
}

.conection-dialog-division.top {
    border-top: #E2E2E2 1px solid;
}

.conection-dialog-division.bottom {
    border-top: #E2E2E2 1px solid;
    margin-bottom: -10px;
}

.BngTable.ConnectionsList th.BngTableTh {
    padding-left: 30px;
    min-width: 15px;
}

.BngTable.ConnectionsList td.BngTableTd {
    padding-left: 30px;
}

.BngTable.ConnectionsList td.BngTableTd .BngDropdown {
    padding-right: 5px;
}

.BngTable.ConnectionsList td.BngTableTd .BngDropdown .BngIconButton .Icon {
    color: #000001c9 !important;
}

.ConnectionsDialog .new-connection-btn {
    border-radius: 6px;
    font-size: 17px;
    cursor: pointer;
    right: 0;
    padding: 3px;
    margin-left: 20px;
    color: #005dff;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.ConnectionsDialog .new-connection-btn:hover {
    background-color: #eee;
}

.ConnectionsDialog .groups-header {
    margin-bottom: 5px !important;
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid #E6E7EA;
}

.ConnectionsDialog .IconConnectionList {
    width: 26px;
    max-height: 30px;
}