.CreateUserDialog {
    width: 420px;
    margin-left: -210px;
}

.CreateUserDialog :global(input) {
    height: 42px;
    border: 1px solid #D5D5D5;
}

.CreateUserDialog :global(label) {
    padding-left: 5px;
}