.button-create-presentation {
    cursor: pointer;
    color: #005DFF;
    font-weight: 500;
    display: inline-flex;
    padding: 3px;
    margin: 7px 14px;
    align-items: center;
    border-radius: 6px;
}

.button-create-presentation:hover {
    background-color: #eee;
}

.Presentation-list-dialog {
    height: 540px;
}

.PresentationListDialog .widget-body {
    border-bottom-color: transparent;
}

.PresentationListDialog {
    height: auto;
}

.PresentationListDialog .widget-body .widget-main {
    padding: 0 !important;
}

.PresentationSearch .search-button {
    padding: 4px !important;
}

.BngSelectSearch.PresentationFilterOption .OptionPreview button {
    height: 22px;
}