.cardWrapper {
  display: inline-flex;
  width: calc(100% - 24px);
  height: fit-content;
  padding: 8px 12px;
  border-radius: 4px;
  background: var(--light-gray-default);
  gap: 8px;
}

.cardsContainer {
  height: 370px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  color: #333;
  font-size: 14px;
  font-weight: 500;
}

.desc {
  color: var(--dark-gray-title);
  font-size: 14px;
}

.button {
  padding: 10px 18px;
  background-color: var(--blue-default);
  color: white;
  border-radius: 8px;
  border: none;
  font-weight: 700;
  margin-left: auto;
  width: 97px;
}

.headerDesc .Description {
  line-height: 18px;
}

.button.disabled {
  filter: opacity(0.5);
  cursor: not-allowed;
}