.select-origin-form {
    position: relative;
}

.select-origin-form .form-group {
    display: inline-block;
}

.row-fluid.field {
    height: 60px;
}

.select-origin-form .select-btn-container {
    padding: 0 125px;
}

.select-origin-form .error-alert {
    margin-left: 10px;
    color: #d15b47;
    cursor: pointer;
}

.select-origin-form .action-button {
    margin-left: 10px;
    display: inline-block;
}

.control-label.bold {
    font-weight: bold;
    padding-right: 10px;
}