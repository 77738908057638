.WhiteLabelWrapper {
  margin: 35px;
}

.IntroductionCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 185px;
  border-radius: 8px;
  border: 1px solid #e4e4e4;
  border-left: 10px solid;
  border-image: 0 0 0 1 linear-gradient(180deg, #d77419 0%, #f6bf1a 100%);
  background: linear-gradient(0deg, #f6f6f6 0%, #f6f6f6 100%), #fff;
}

.IntroductionCard > img {
  height: 130px;
  margin: 0 35px 0 35px;
}

.IntroductionCard > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.IntroductionCard > div > h5 {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.IntroductionCard > div :global(.white-label-description) {
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.IntroductionCard > div :global(.learn-more) > i {
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.IntroductionCard > div :global(.learn-more) {
  display: flex;
  flex-direction: row;
}

.IntroductionCard > div :global(.learn-more) > div {
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.IntroductionCard > div :global(.learn-more) > div > span,
.IntroductionCard > div :global(.learn-more) > div > a {
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.IntroductionCard > div :global(.learn-more) > div > a:hover {
  cursor: pointer;
}

.IntroductionCard > div > button {
  display: flex;
  border-radius: 5px;
  align-items: center;
  flex-direction: row;
  background: linear-gradient(136deg, #d77419 0%, #f6bf1a 100%);
  gap: 4px;
  width: 120px;
}

.IntroductionCard > div > button > i,
.IntroductionCard > div > button > span {
  color: #fffdfd;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
}

.FaviconConfiguration,
.ProjectImageConfiguration,
.ToolbarProjectImageConfiguration,
.BackgroundImageConfiguration,
.BackgroundImageSize,
.LoginBoxAlignment,
.BIMachineServices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 0 40px 0;
}

.FaviconConfiguration > div,
.ProjectImageConfiguration > div,
.ToolbarProjectImageConfiguration > div,
.BackgroundImageConfiguration > div {
  width: 50%;
}

.FaviconConfiguration :first-child :first-child > div,
.ProjectImageConfiguration :first-child :first-child > div,
.ToolbarProjectImageConfiguration :first-child :first-child > div,
.BackgroundImageConfiguration :first-child :first-child > div {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.FaviconConfiguration :last-child,
.ProjectImageConfiguration :last-child,
.ToolbarProjectImageConfiguration :last-child,
.BackgroundImageConfiguration :last-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.FaviconConfiguration > div > img {
  width: 391px;
  height: 165px;
}

.FaviconConfiguration > div > img,
.ProjectImageConfiguration > div > img,
.ToolbarProjectImageConfiguration > div > img,
.BackgroundImageConfiguration > div > img,
.FaviconConfiguration :last-child > button,
.ProjectImageConfiguration :last-child > button,
.ToolbarProjectImageConfiguration :last-child > button,
.BackgroundImageConfiguration :last-child > button {
  border-radius: 8px;
}

.FaviconConfiguration :last-child > button,
.ProjectImageConfiguration :last-child > button,
.ToolbarProjectImageConfiguration :last-child > button,
.BackgroundImageConfiguration :last-child > button {
  width: 165px;
  height: 165px;
  border: 1px solid #e4e4e4;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FaviconConfiguration :last-child > button > i,
.ProjectImageConfiguration :last-child > button > i,
.ToolbarProjectImageConfiguration :last-child > button > i,
.BackgroundImageConfiguration :last-child > button > i {
  color: #888;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.FaviconConfiguration :last-child > button > span,
.ProjectImageConfiguration :last-child > button > span,
.ToolbarProjectImageConfiguration :last-child > button > span,
.BackgroundImageConfiguration :last-child > button > span {
  color: #888;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.FaviconConfiguration :last-child > div,
.ProjectImageConfiguration :last-child > div,
.ToolbarProjectImageConfiguration :last-child > div,
.BackgroundImageConfiguration :last-child > div {
  color: #888;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ProjectImageConfiguration :last-child > button {
  width: 357px;
  height: 86px;
}

.ToolbarProjectImageConfiguration :last-child > button {
  width: 257px;
  height: 86px;
}

.BackgroundImageConfiguration :last-child > button {
  width: 391px;
  height: 220px;
}

.BackgroundImageSize,
.LoginBoxAlignment,
.BIMachineServices {
  display: flex;
  flex-direction: row;
}

.BackgroundImageSize > div,
.LoginBoxAlignment > div,
.BIMachineServices > div {
  width: 50%;
}

.BackgroundImageSize :first-child > h5,
.LoginBoxAlignment :first-child > h5,
.BIMachineServices :first-child > h5 {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0;
}

.BackgroundImageSize :first-child > div,
.LoginBoxAlignment :first-child > div,
.BIMachineServices :first-child > div {
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.BackgroundImageConfiguration :first-child > div:global(.BackgroundImageConfiguration-description) > span,
.LoginBoxAlignment :first-child > div > span,
.BackgroundImageSize :first-child > div > span {
  display: unset;
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BackgroundImageConfiguration :first-child > div:global(.BackgroundImageConfiguration-description) > span {
  font-size: unset;
}

.BIMachineServices :last-child:global(.services-options) {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.BIMachineServices :last-child > div {
  display: flex;
  flex-direction: row;
}

.BIMachineServices :last-child > div > div:global(.BngSwitch) {
  width: 50%;
}

.BIMachineServices :last-child > div > div:global(.BngSwitch) > div {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.LoginBoxPositionStyle {
  height: 100%;
}

.LoginBoxPositionStyle > div {
  margin-right: 0;
}

.LoginBoxPositionStyle > div > label {
  border-radius: 0 !important;
}

.LoginBoxPositionStyle > div > label > i {
  color: #000;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.LoginBoxPositionStyle > :first-child > label {
  border-radius: 5px 0 0 5px !important;
}

.LoginBoxPositionStyle > :last-child > label {
  border-radius: 0 5px 5px 0 !important;
}

.LoginBoxPositionStyle > div > label:global(.selected) {
  background-color: #005dff;
}

.LoginBoxPositionStyle > div > label:global(.selected) > i {
  color: #ffffff;
}

.HideField {
  display: none !important;
}

.ChangeImageIndication:hover {
  cursor: pointer;
  box-shadow: 0 0 10px #005dff;
  border-radius: 8px;
}

.ChangeImageIndication:global(.simuleToolBar) {
  background: #404040;
  padding: 10px;
  border-radius: 6px;
}
