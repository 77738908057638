.MarketplaceGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.groupHeader {
  align-items: center;
  display: inline-flex;
  gap: 20px;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.title {
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

.description {
  color: #888;
  font-size: 12px;
  font-weight: 400;
}

.knowMoreLink {
  cursor: pointer;
  text-decoration-line: underline !important;
  white-space: nowrap;
}

.cardArray {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}

.marketplaceCardContainer {
  width: calc((100% - 56px) / 4);
}

.moreCard {
  align-items: center;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e4e4e4;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 281px;
  justify-content: center;
  min-width: 230px;
  overflow: hidden;
  transition: all ease-in-out 200ms;
  width: calc((100% - 56px) / 4);
}

.moreCard i,
.moreCard strong {
  color: var(--blue-default);
}

.moreCard i {
  font-size: 80px !important;
}

.moreCard strong {
  font-size: 18px;
}

.moreCard:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.24);
}

@media only screen and (max-width: 1270px) {
  .marketplaceCardContainer {
    width: calc((100% - 56px) / 3);
  }
}
