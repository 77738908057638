.AccessTimeRestrictionLogsDialog {
  max-height: 600px;
  width: 500px;
}

.headerButtons :global(.bng-search) {
  height: 40px;
}

.userAvatar {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background-size: cover !important;
  background: #ffffff;
}

.logsSearch {
  width: 100%;
  margin-left: 6px;
}

.avatarAndName {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.dateField {
  height: 38px !important;
  box-shadow: inset 0 0 0 1px #ccc !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  color: #555 !important;
  position: relative !important;
  background-color: white !important;
}
