.personalPageContainer {
  overflow: auto;
  height: calc(100% - 48px);
  padding: 24px;
  box-sizing: border-box;
}

.inlineDivs {
  display: flex;
  gap: 12px;
}

.flexPanel {
  flex: 1;
}
