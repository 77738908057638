.CommonActivationPage {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CommonActivationPage .BngCard {
  width: 830px;
  /*height: 650px;*/
}

.CommonActivationPage .BngCardHeader {
  padding: 30px 30px 20px;
}

.CommonActivationPage .BngCardBody {
  padding: 0 30px;
}

.CommonActivationPage .BngCardFooter {
  padding: 25px 30px;
}
