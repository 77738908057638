.Menu {
}

.Menu.menuItemOpened .nav li,
.Menu.menuItemOpened .nav li.open > a {
  background-color: #dddddd;
}

.Menu.menuItemOpened .nav li a:hover {
  background-color: #eaeaea !important;
  color: #6e6e6e;
}

.Menu.menuItemOpened .nav .lastOpened li,
.Menu.menuItemOpened .nav .lastOpened a {
  background-color: #fafafa !important;
}

.Menu.menuItemOpened .nav .lastOpened > a {
  background-color: #e0e9fb !important;
  color: var(--main-highlight-color);
}

.Menu.menuItemOpened .nav .lastOpened > a:hover {
  background-color: #d0dcf9 !important;
}

.Menu.menuItemOpened .nav .open:not(.lastOpened) > a {
  background-color: #d0d8e7 !important;
  color: var(--main-highlight-color);
}

.Menu.menuItemOpened .open .submenu li:last-child {
  background-color: #dddddd !important;
  padding-bottom: 4px;
}

.Menu.menuItemOpened .open .submenu li:last-child a {
  box-shadow: 1px 2px 3px #c3c3c3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 1px solid #c0c0c0;
}

.Menu #sidebarContainerList .spinner {
  width: 32px;
}

.sidebar.menu-min {
  width: 50px;
}

.sidebar .nav.nav-list li a {
  padding: 0;
}

.sidebar .nav.nav-list li {
  text-align: center;
}

.menutd-icon-more {
  margin: -16px 0px 0px 190px;
  font-size: 16px !important;
  font-weight: normal !important;
  opacity: 0.5;
}

.submenu td.menutd.menutd-icon {
  display: none;
}

.submenu td.menutd.menutd-text {
  width: 175px;
  padding-left: 42px;
}

td.menutd.menutd-icon {
  width: 35px;
  height: 40px;
  text-align: right;
  font-size: 20px;
}

td.menutd.menutd-text {
  width: 206px;
}
