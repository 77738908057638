.circle {
    width: 24px;
    height: 24px;
    display: inline-block;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 100%;
    cursor: pointer;
    box-sizing: border-box;
}

.active {
    border-width: 6px;
    border-color: #0679FF;
}

.active.danger {
    border-color: #E24C38;
}

.active.success {
    border-color: #269C59;
}