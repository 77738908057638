.ProjectInvitePage {
  background: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  margin: 15px;
}

.ProjectInviteWrapper {
  align-items: center;
  display: flex;
  height: 70%;
  width: 80%;
}

.ProjectInviteContainer {
  align-self: center;
  width: 75%;
}

.PasswordInfo label {
  color: #5f5f5f;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  margin-top: -15px;
  opacity: 0.8;
  text-align: center;
}

.AdditionalInfo {
  font-size: 12px;
  text-align: center;
}

.LogoAndAvatarContainer {
  display: flex;
  flex-direction: column;
  height: 38vh;
  justify-content: space-between;
  margin-left: 30px;
  margin-bottom: 120px;
  width: 20vw;
}

.AvatarInput {
  align-self: center;
}

.InfoContainers {
  display: flex;
}

.InfoItens {
  width: 50%;
}

.ProjectInvitePage .SubmitButton {
  border-radius: 8px;
  font-size: 20px;
  height: 48px;
  margin-top: 10px;
  width: 160px;
}

.ProjectInviteContainer div p {
  font-size: 18px;
}
