.itemSelectWrapper {
  height: fit-content;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  transition: border linear 0.2s !important;
  border: 1px #d1d1d1 solid;
}

:global(.error) .itemSelectWrapper {
  border: 1px #f09784 solid;
}

.selectButtonContainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.selectButtonContainer .selectButton {
  justify-content: start;
  background-color: #fff;
  color: #005dff;
  font-size: 12px;
  width: 100%;
  border-radius: 8px;
  transition: unset;
}

:global(.error) .selectButtonContainer .selectButton {
  color: #b94a48;
}

.selectButtonContainer .selectButton:hover {
  background-color: #fff;
}

.tagsContainer {
  position: relative;
  height: fit-content;
  padding: 12px;
  display: inline-flex;
  gap: 10px;
  width: calc(100% - 24px);
  border-top: 1px solid #d4d4d4;
  background: #fff;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}

.readOnly .tagsContainer {
  border-radius: 8px;
  border-top: 0;
}

.tagsContainer.notExpanded {
  flex-wrap: wrap;
}

.tag {
  background-color: #005dff;
  width: fit-content;
  padding: 2px 8px;
  color: #fff;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.customHeaderButtonContainer {
  margin-right: 10px;
}

.customComponentContainer {
  width: 100%;
  position: absolute;
  height: 45px;
}

.fadingArea {
  position: absolute;
  top: 0;
  right: 0;
  height: 48px;
  width: 80px;
  background-image: linear-gradient(90deg, transparent, white);
  border-radius: 0 0 8px 0;
}
