.CodeBlockHeader {
  background: #474747;
  padding: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px 6px 0 0;
  height: 20px;
}

.CodeBlockHeaderSpan {
  color: white;
  margin-left: 10px;
}

.CodeBlockHeaderButton {
  padding: 0;
  cursor: pointer;
  margin-right: 10px;
  background: transparent;
  color: white !important;
  border: none;
  font-size: 13px;
  border-radius: 5px !important;
}

.CodeBlockHeaderButton :global(.Icon) {
  font-size: 13px !important;
  margin-right: 5px;
}

.ReactMarkdownWrapper {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.ReactMarkdown pre {
  background-color: transparent;
  border: none;
}

.ReactMarkdownCode {
  padding: 2px 4px;
  color: #000000;
  white-space: nowrap;
  font-weight: bold;
  background: transparent;
}

.CodeBlockMathJax :global(.MathJax) {
  text-align: left !important;
  margin: 1em !important;
}

.ReactMarkdown p {
  color: black;
  margin: 10px;
  text-align: justify;
  font-size: 13px;
}

.ReactMarkdown h1,
.ReactMarkdown h2,
.ReactMarkdown h3,
.ReactMarkdown h4 {
  color: black;
  margin: 10px 0;
  text-align: left !important;
  font-size: 13px !important;
  margin-bottom: 10px !important;
  font-weight: bold;
}

.ReactMarkdown ol,
.ReactMarkdown li {
  color: black;
  text-align: left;
  font-size: 13px;
}

.ReactMarkdown ul {
  color: black;
  text-align: left;
  padding-left: 20px;
  font-size: 13px;
}
