.main {
  --btn-blue: #0f82f4;
  --link-blue: #3f9bf6;
  background-size: cover;
  height: calc(100vh - 48px);
  height: calc(100dvh - 48px);
}

.loginBox {
  background-color: rgba(239, 239, 239, 0.85);
  width: 670px;
  height: calc(100vh - 48px);
  height: calc(100dvh - 48px);
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
  z-index: 1;
}

:global(.DarkMode) .loginBox {
  background-color: rgba(0, 0, 0, 0.85);
}

.logoImg {
  width: 212px;
}

.loginBoxContentWrapper {
  overflow: auto;
  padding: 0 20px;
  width: 392px;
}

.loginBoxContent {
  min-height: 302px;
}

.main :global(.BngPrimaryButton) {
  background-color: var(--btn-blue) !important;
  padding: 14px 0 14px 0;
  border-radius: 8px;
  transition: filter 200ms ease-in-out;
  width: 100%;
}

.main :global(.BngPrimaryButton):hover {
  background-color: var(--btn-blue) !important;
  filter: brightness(95%);
}

.main :global(.BngCancelButton) {
  padding: 0 !important;
  transition: filter 200ms ease-in-out;
}

.main :global(.BngCancelButton):hover {
  background-color: transparent !important;
  filter: brightness(150%);
}

.main :global(input[type='checkbox']:checked + .lbl::before) {
  background: var(--btn-blue) !important;
  background-color: var(--btn-blue) !important;
}

.newsContainer {
  width: 100%;
  min-height: 115px;
  max-height: 260px;
  height: calc(100vh - 500px);
  height: calc(100dvh - 500px);
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.newsContainer img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.newsContainer p {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #3A4045 !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

:global(.DarkMode) .newsContainer p {
  color: white !important;
}

.newsContainer h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #3A4045;
  margin: 0;
}

:global(.DarkMode) .newsContainer h3 {
  color: white;
}

.newsActionsContainer {
  position: absolute;
  width: 100%;
  bottom: 0;
  box-sizing: border-box;
  padding: 10px 15px 10px 15px;
  box-shadow: 0 0 4px 0 rgba(255, 255, 255, 0.25);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 1));
}

:global(.DarkMode) .newsActionsContainer {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
}

.allNewsLink {
  font-weight: 400;
  font-size: 12px;
  color: #3A4045;
  transition: filter 200ms ease-in-out;
}

.allNewsLink:hover {
  color: #3A4045;
  filter: brightness(90%);
}

:global(.DarkMode) .allNewsLink {
  color: white;
}

:global(.DarkMode) .allNewsLink:hover {
  color: white;
}

.main h1 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.main p {
  font-size: 13px;
  font-weight: 400;
  color: #555555;
  line-height: 16px;
  margin: 0;
}

.main input {
  border-radius: 8px;
}

.dot {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #cccccc;
  transition: filter 200ms ease-in-out;
}

.dot:hover {
  filter: brightness(80%);
}

.dot.selected {
  background-color: var(--btn-blue);
}

.dotsContainer {
  padding-left: 10px;
}

.bottomActionsContainer {
  padding: 0 25px;
}

.appVersion {
  position: absolute;
  bottom: 0;
  left: 10px;
  font-size: 10px;
}

.appVersion.lightMode {
  color: #C9C9C9 !important;
}

.darkModeBtn {
  position: fixed;
  top: 4px;
  right: 10px;
  color: white;
}

@media only screen and (max-device-width: 670px) {
  .loginBox {
    box-shadow: 0 0 4px 0 #00000040;
    height: auto;
    min-height: 500px;
    padding: 25px 0;
  }
}