.typeOptsIcon {
  padding: 5px;
  margin-right: 4px;
  width: 20px;
  color: #005dff;
}

.typeOptsIcon :global(i) {
  font-size: 24px !important;
}

.filterOptsWrapper {
  max-width: 78%;
  display: flex;
  align-items: center;
  gap: 5px;
}

.labelContainer {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.filterCheckbox {
  min-height: 16px;
}