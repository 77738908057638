.addonAlertOverlay {
  background: rgba(0, 0, 0, 0.24);
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 321040 !important;
}

.alertArrow {
  background: #e6efff;
  opacity: 1;
  padding: 7px;
  position: absolute;
  right: 18px;
  top: -7px;
  transform: rotate(-45deg);
}

.alertPopup {
  background: #e6efff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 24px;
  position: fixed;
  width: 435px;
  z-index: 321041;
}

.alertTitle {
  align-items: center;
  color: #005dff !important;
  display: inline-flex;
  gap: 8px;
  font-weight: 700;
}

.alertTitle h5 {
  margin: 0;
}

.alertDescription {
  color: #337dff;
  margin-top: 16px;
}

.buttonsContainer {
  display: inline-flex;
  gap: 32px;
}

.buttonsContainer button {
  border-radius: 29px;
  padding: 10px 18px;
}

.buttonsContainer button:hover {
  transform: scale(1.02);
}

.seeDetailsButton {
  background: #005dff !important;
  color: #fff !important;
}

.seeDetailsButton:hover {
  background: #5e8fef !important;
}

.gotItButton {
  background: #e6efff !important;
  color: #337dff !important;
}

.gotItButton:hover {
  background: #98b6ef !important;
}
