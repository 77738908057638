.dialogWrapper {
  width: 740px !important;
}

.dialogBodyWrapper {
  max-height: 610px;
  min-height: 490px;
  overflow: hidden;
}

.headerWrapper {
  background-color: white;
  padding: 23px 24px 23px 24px;
  display: flex;
  gap: 12px;
}

.headerWrapper .headerButtons :global(.ButtonGroupLabel) {
  padding: 0 9px;
}

.lineSeparator {
  width: 1px;
  height: 64px;
  background-color: var(--light-gray-default);
}

.headerWrapper .headerButtons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 4px;
}

.headerWrapper .headerButtons,
.headerWrapper .headerButtons :global(.BngButtonGroup) {
  height: 32px;
}

.headerWrapper .headerButtons .headerButtonsLabelShareType,
.headerWrapper .headerButtons .headerButtonsLabelMessageType {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}

.tabSetContainer {
  background-color: #fff;
}

.tabsContainer .tabSetContainer :global(li.active) {
  background-color: #f6f6f6;
  border-bottom-color: #f6f6f6;
}

.tabsContainer :global(li:last-child) {
  background-color: #fff;
}

.tabsContainer :global(li:first-child) {
  margin-left: 24px;
}

.tabsContainer .tabSetContainer :global(.tab-content) {
  min-height: 260px;
  max-height: 378px;
  height: 40vh;
  background-color: #f6f6f6;
}

.tabsContainer .tabSetContainer :global(.tab-content > div) {
  padding: 24px;
}

.tabsContainer .tabSetContainer :global(.navigation-tab-link > a) {
  opacity: 1;
  font-weight: 400;
}