.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-gray-title);
}

.desc {
  color: var(--gray-description);
}
