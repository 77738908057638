.BngAssistedAnalysisForm {
  min-height: 130px;
}

.BngAssistedAnalysisForm .MeasureList,
.BngAssistedAnalysisForm .DimensionListWithFilters {
  display: flex;
  align-items: center;
}

.BngAssistedAnalysisForm .MeasureList .BngField,
.BngAssistedAnalysisForm .DimensionListWithFilters .BngField {
  flex-grow: 1;
  margin-right: 10px;
}

.BngAssistedAnalysisForm .Action.AddMeasureButton,
.BngAssistedAnalysisForm .Action.AddButton {
  padding: 8px;
}

.BngAssistedAnalysisForm .RemoveMeasureButton {
  padding-top: 10px;
}

.BngAssistedAnalysisForm .AccordionWrapper {
  background-color: #fff;
}

.BngAssistedAnalysisForm .AccordionSideMenu {
  background-color: #fff;
}

.BngAssistedAnalysisForm .AccordionTitle {
  padding: 10px;
}

.BngAssistedAnalysisForm .ParamType.Rank .BngField {
  margin-bottom: 0;
}
