.filterDropDown {
    z-index: 321100 !important;
    background: rgba(0, 0, 0, 0) !important;
}

.UiBlocker {
    padding: 20px;
}

.filterDropDownOptions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
}

.scrollContainer {
    height: 350px;
}

.mobileContainer .scrollContainer {
    height: calc(100vh - 60px);
}

.titleCol {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    display: -webkit-box;
    overflow: hidden;
    line-height: 37px;
    font-size: 12px;
    color: #373737;
    font-weight: 500;
    opacity: 0.8;
    align-items: center;
}