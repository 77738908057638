.resendBtn:global(.BngCancelButton),
:global(.DarkMode) .resendBtn:global(.btn.BngCancelButton) {
  color: #337dff !important;
  font-weight: 400;
  font-size: 12px;
}

.backBtn:global(.BngCancelButton) {
  color: #5f5f5f !important;
  font-weight: 400;
  font-size: 12px;
}

:global(.DarkMode) .backBtn:global(.BngCancelButton) {
  color: #999999 !important;
}
