.AddItemToDashboard .AccordionContent {
    padding: 10px;
}

.AddItemToDashboard ul.ListItemDashboard {
    margin: 0;
    display: grid;
    grid: auto-flow 84px / repeat(3, 33%);
}

.AddItemToDashboard ul.ListItemDashboard li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #5f5f5f;
    font-weight: 500;
    font-size: 13px;
    padding: 2px;
    cursor: pointer;
    text-align: center;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.AddItemToDashboard ul.ListItemDashboard li:hover {
    background: #ddd;
    border-radius: 4px;
    color: #404040;
}

.AddItemToDashboard li .Icon {
    font-size: 28px;
    padding: 5px 0;
}

.AddItemToDashboard li .Icon.material-icons {
    font-size: 36px;
}

.AddItemToDashboard .ItemDashboardDescription {
    font-size: 13px;
}