.buttonsContainer .saveButton,
.buttonsContainer .cancelButton {
  border-radius: 2px;
}

.buttonsContainer .saveButton {
  font-weight: 500;
}

.formErrorIcon {
  font-size: 16px;
}

.formErrors {
  display: flex;
  align-items: center;
  color: #b94a48;
  gap: 6px;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
}

.ActionsErrors {
  display: grid;
  grid-template-columns: 60% 40%; /* Limiting buttons and errors size */
}
