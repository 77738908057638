.filtered :global(i) {
  color: #005dff;
}

.dropdownBody {
  width: 216px;
  padding: 25px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  border: 1px;
  border-radius: 4px;
  right: 45px;
  position: absolute;
}

.filterDropdownField :global(label) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
