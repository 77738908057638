.ServerErrorPagePopper .AccordionTitle {
  display: none;
}

.ServerErrorPagePopper .VersionContainer {
  width: 350px;
}

.ServerErrorPagePopper .AccordionContent {
  padding: 0;
}

.ServerErrorPagePopper .ActionListItems {
  padding: 10px 5px;
}

.ServerErrorPage.OutOfApp {
  position: fixed;
}
