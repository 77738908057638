.Title {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.TabWrapper {
  display: flex;
  margin: 0 35px 0 35px;
  height: 100%;
  flex-direction: column;
}

.KpisWrapper {
  display: flex;
  flex-direction: row;
}

.KpiWidth {
  width: 100%;
}

.KpiTitle {
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.KpiDescription {
  color: #888;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.DescriptionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-left: 10px;
  width: 100%;
}

.ConsumptionInfo {
  margin: 20px 0 20px 0;
  border-radius: 4px;
  border: 1px solid #ccdfff;
  background: #e6efff;
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
}

.ConsumptionInfo i {
  color: #005dff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.InfoText {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.InfoText a {
  color: #005dff;
  text-decoration-line: underline !important;
}

:global(.PublisherFullTab .PageLayout .UiBlocker) > div {
  height: calc(100vh - 510px) !important;
}

:global(.PublisherFullTab .PageLayout .UiBlocker) :global(thead) tr th {
  width: 75%;
}

.NameWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.NameWrapper div {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.NameWrapper i {
  margin-right: 5px;
  color: #005dff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.NameWrapperChildren {
  margin-left: 10px;
  border-left: 1px dashed #bbbbbb;
}

.PublisherFullDialogTitle {
  color: #333333 !important;
}

.PublisherFullDialogTitle i {
  color: #555555 !important;
}

.AddRemoveIcon {
  color: #555555 !important;
}

.Requests {
  display: flex;
  width: 56px;
  height: 24px;
  background-color: #005dff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Interactions {
  width: 56px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #00a355;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ActionsOptionsIcon {
  color: #333 !important;
  font-style: normal;
  line-height: normal !important;
  width: 255px;
  height: 34px;
  flex-shrink: 0;
}

.ActionsOptionsIcon i {
  color: #333;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 15px !important;
}

.ActionsOptionsIcon span {
  color: #333;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.DetailsDialogConfigWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 20px 0 20px 0;
}

.DetailsDialogConfigWrapper > div {
  width: 100%;
}

.ListWrapper {
  display: flex;
  flex-direction: row;
}

.ColumnWrapper {
  width: 100%;
}

.ComponentWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.ListComponentWrapper {
  height: 200px;
  overflow: auto;
  background-color: #f6f6f6;
  margin-top: 10px;
  border-top: 1px solid #e4e4e4;
}

.ListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  box-shadow: 0 1px 0 0 #e4e4e4;
  justify-content: space-between;
}

.ListItem :first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ListItem.grayscale {
  background-color: gray;
}

.ListItem i {
  margin-right: 5px;
}

.HeaderLeft {
  background-color: #00a355;
}

.ConfigOptionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Message {
  color: #555;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 5px;
}

.BlueBall {
  background: #669eff;
  width: 8px !important;
  height: 8px !important;
  border-radius: 4px;
  margin: 3px 6px 0 0;
}

.ListTitle {
  display: flex;
  flex-direction: row;
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ListQuantity {
  border-radius: 15px;
  display: inline-flex;
  padding: 2px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-right: 5px;
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ListInfo {
  color: #555;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin-left: 5px;
}

.LinksWrapper {
  display: flex;
  flex-direction: row;
}

.LinksWrapper > div {
  display: flex;
  flex-direction: column;
  position: relative;
}

.LinksWrapper :first-child {
  margin-right: 20px;
}

.LinksWrapper > div > span {
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.LinksWrapper > div > input {
  width: 239px;
  height: 30px;
  flex-shrink: 0;
}

.LinksWrapper > div > i {
  color: #005dff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  right: 4px;
  bottom: 16px;
  background-color: #eeeeee;
  box-shadow: rgb(238 238 238) -10px 0 8px 1px;
  cursor: pointer;
}

.ListItemAction {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 330px;
}