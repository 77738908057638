.CreateFilterDialog {
  max-height: 594px;
  width: 862px;
}

.CreateFilterDialog :global(.widget-header) {
  border: 1px solid #e6e7ea;
}

.CreateFilterDialog :global(.DialogBody) {
  background-color: #f6f6f6;
  border-bottom: 1px solid #e6e7ea;
  height: 430px;
  max-height: 430px;
  overflow: hidden;
}

.formFieldsWrapper {
  border-bottom: 1px solid #e6e7ea;
  padding: 12px 33px 12px 33px;
}

.rowWrapper {
  display: inline-flex;
  gap: 18px;
  justify-content: space-between;
  width: 100%;
}

.CreateFilterDialog :global(.widget-body),
.CreateFilterDialog :global(.widget-main) {
  padding: 0;
}

.CreateFilterDialog :global(.DialogFooter) {
  padding: 12px 10px 12px 10px;
}

.smallFormField {
  width: 13rem;
}

.mediumFormField {
  width: 23.3rem !important;
}

.largeFormField {
  width: 27.3rem !important;
}

.CreateFilterDialog :global(.BngInput) {
  height: 38px;
}

.addDimensionButton {
  background: #005dff !important;
  border-radius: 4px !important;
  color: white !important;
  height: 38px;
  margin-top: 28px;
  width: 48px;
}

.optionEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CreateFilterDialog :global(.BngTable .BngTableTd),
.CreateFilterDialog :global(.BngTable .BngTableTr) {
  padding-top: 0;
  padding-bottom: 0;
}

.CreateFilterDialog :global(.BngTable .BngTableTd) {
  height: 5px;
}

.CreateFilterDialog :global(.BngTable .BngTableTd:first-child),
.CreateFilterDialog :global(.BngTable .BngTableTh:first-child) {
  padding-left: 32px;
}

.CreateFilterDialog :global(.BngTable .BngTableTd:last-child),
.CreateFilterDialog :global(.BngTable .BngTableTh:last-child) {
  padding-right: 10px;
}

.dimensionDeleted {
  align-items: center;
  background-color: #d15b47;
  border-radius: 100%;
  color: #fff;
  display: flex !important;
  font-size: 18px !important;
  height: 20px;
  justify-content: center;
  margin-bottom: 4px;
  margin-left: 10px;
  width: 20px;
}

.tableContainer {
  height: 209px;
  overflow: auto;
}