.StructureDetailsDialog {
}

.StructureDetailsDialog .StructureInfo {
  color: rgba(74, 77, 81, 0.8);
  font-weight: 500;
  font-size: 16px;
}

.StructureDetailsDialog .StructureInfo .Icon {
  font-size: 24px;
}

.UpdateInfo {
  padding-left: 12px;
  border-left: 3px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
}

.StructureDetailsDialog h2,
.StructureDetailsDialog h3 {
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 15px;
  line-height: 20px;
}

.StructureDetailsDialog h3 {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
}

.StructureDetailsDialog .MetricsPanel {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
}

.StructureDetailsDialog .MetricsPanel .Icon {
  font-size: 16px;
}

.StructureDetailsDialog .Metric {
  width: calc(50% - 20px);
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.StructureDetailsDialog .Metric:nth-child(odd) {
  margin-right: 20px;
}

.StructureDetailsDialog .Metric:nth-child(even) {
  margin-left: 20px;
}

.StructureDetailsDialog .metric-success .Icon,
.StructureDetailsDialog .metric-success .metric-value {
  color: #00d960;
}

.StructureDetailsDialog .metric-warning .Icon,
.StructureDetailsDialog .metric-warning .metric-value {
  color: #fdb500;
}

.StructureDetailsDialog .metric-danger .Icon,
.StructureDetailsDialog .metric-danger .metric-value {
  color: #e24c38;
}

.StructureDetailsDialog .metric-danger .Icon {
  transform: rotate(-90deg);
}

.StructureDetailsDialog .DialogFooter {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
}

.StructureDetailsDialog .DialogFooter a {
  color: #005dff;
}
