.BngFolderFieldInput {
}

.BngFolderFieldInput .folder-field {
  cursor: pointer;
  flex-grow: 1;
  max-width: calc(100% - 60px);
}

.BngFolderFieldInput .folder-field.disabled {
  cursor: not-allowed;
}

.BngFolderFieldInput .button-create-folder {
  align-items: center;
  background-color: #3558ef !important;
  border-radius: 2px;
  display: flex;
  height: 38px;
  margin-left: 10px;
  padding: 2px 12px;
  width: 50px;
}

.dashboard-folder-input .button-create-folder,
.kpi-wizard-step-container .button-create-folder {
  align-items: center;
  height: 40px;
}

