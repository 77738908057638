.MenuLink {
  position: relative;
}

.MenuLink .LoadingSvg {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 15px;
}

.MenuLink .LoadingSvg.withBeta {
  right: 70px;
}

.MenuLink.Loading .Overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0, 0.05);
  cursor: progress;
}