.WhatsSupportDialog :global(.DialogBody) {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.WhatsSupportDialog h5 {
  margin-top: 10px;
}

.whatsButton {
  background: #25d366 !important;
  border-radius: 8px !important;
  color: #fff !important;
  display: flex;
  font-size: 20px;
  gap: 5px;
  height: fit-content !important;
  padding: 16px 20px;
  width: fit-content !important;
}

.whatsButton:hover {
  background: #4dd582 !important;
}

.phoneText {
  font-size: 22px;
  padding-top: 10px;
}
