.publisherlist-table-td {
    line-height: 37px;
    max-width: 260px;
    max-height: 40px;
}

.publisherlist-table-tr:nth-child(even) {
    background-color: #f6f6f6 !important;
}

.publisherlist-table-tr:nth-child(odd) {
    background-color: #ffffff !important;
}

.div-information-publisher {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    display: -webkit-box;
    width: 100%;
    overflow: hidden;
    line-height: 37px;
    padding-left: 10px;
    font-size: 12px;
    color: #5a5a5a;
}

.button-views {
    height: 24px;
    width: 49px;
    border-radius: 4px;
    background-color: #29C46C;
    text-align: center;
    line-height: 27px;
    color: #ffffff;
    margin-top: 6px;
}

.button-menu-publisher {
    font-weight: 500;
    padding-top: 7px;
    font-size: 28px;
    opacity: 0.8;
    color: #373737 !important;
}

.div-container-pagination-publisher.pagination-table-footer {
    padding-left: 18px;
    width: 380px;
}


.btn-publisher-success {
    background-color: #005DFF !important;
}

.btn-publisher-success:hover {
    background-color: #506dea !important;
}

.btn-publisher-success.Disabled {
    background-color: #8496e0 !important;
    cursor: not-allowed !important;
}