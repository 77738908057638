.publisher-layout-title {
  font-weight: 500;
}

.publisher-layout-title.fixed-size {
  font-weight: 500;
  width: 100px;
}

.ConfigureLayouPublisher .container-fixed-size {
  height: 55px;
}

.publisher-layout-title .width-publisher {
  width: 200px;
}

.publisher-layout-title .height-publisher {
  width: 200px;
  margin-left: 244px;
  margin-top: -50px;
}

.publisher-layout-title.publisher-frame-color {
  margin-bottom: -10px;
}

.publisher-layout-title.analysis-type {
  margin-top: 8px;
  margin-bottom: 8px;
}

.container-fixed-size {
  display: inline-flex;
}

.container-fixed-size .x-title {
  width: 38px;
  text-align: center;
  margin-top: 28px;
}

.publisher-tab-container {
  height: 380px;
}

.publisher-itens-align {
  display: flex;
  align-items: center;
}

.container-enablefixsize {
  padding-left: 10px;
}

.container-enablepublisherframe {
  padding-left: 10px;
}

.container-sharingtable {
  width: 100px;
  margin-left: 98px;
  margin-top: -35px;
}

.publisher-field-size {
  width: 200px;
}

.ConfigureLayouPublisher .publisher-field-size.BngInput {
  width: 70px;
}

.publisher-field-frame {
  width: 800px;
}

.ConfigureLayouPublisher .config-options {
  display: flex;
  flex-direction: column;
}

.ConfigureLayouPublisher .config-options .mt-2:nth-child(2) {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}

.ConfigureLayouPublisher .config-options .BngField {
  margin-bottom: 0;
}

.publisher-tab-container .BngCheckbox label span.lbl {
  display: revert !important;
}

.publisher-tab-container.ConfigureLayouPublisher {
  display: flex;
}

.publisher-tab-container.ConfigureLayouPublisher > .configColumn {
  width: 26%;
}

.ConfigureLayouPublisher .publisher-json-formatter {
  width: 74%;
  display: flex;
  flex-direction: column;
}

.ConfigureLayouPublisher .json-formatter-language {
  display: flex;
  height: fit-content;
  justify-content: center;
}

.ConfigureLayouPublisher .json-formatter-language .BngButton {
  background-color: white;
  color: black;
  height: 35px;
  margin: 5px 10px 10px 0;
  border: solid 1px grey;
  border-radius: 10px;
}

.ConfigureLayouPublisher .json-formatter-language .selected {
  background-color: white !important;
  color: blue;
  font-weight: 500;
  border: solid 2px blue;
}

.ConfigureLayouPublisher .json-formatter-path {
  padding: 0;
  border: 0;
}

.ConfigureLayouPublisher .python-icon,
.ConfigureLayouPublisher .java-icon {
  width: 25px;
}

.ConfigureLayouPublisher .php-icon {
  width: 35px;
}

.ConfigureLayouPublisher .json-formatter-path pre {
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 15px;
  padding: 0;
}
