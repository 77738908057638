.BngImageUpload {
    position: relative;
}

.BngImageUpload .image-upload-hidden {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
}

.BngImageUpload .lbl-image-upload {
    position: relative;
    padding: 1px;
    background-color: #FAFAFA;
    border: 1px solid #D0D0D0;
    border-radius: 4px;
    cursor: pointer;
    color: #969696;
    text-align: center;
}

.BngImageUpload .lbl-image-upload .material-icons {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
}

.BngImageUpload .button-cancel-image {
    height: 20px;
    width: 20px;
    border-radius: 16px;
    border: 0;
    background-color: #E23D3D;
}

.BngImageUpload .label-container-image {
    border: 2px solid #005DFF;
    border-radius: 4px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.BngImageUpload .button-cancel-image {
    position: absolute;
    right: -6px;
    top: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-cancel-image .material-icons {
    color: #FFFFFF;
    font-size: 13px;
}