:global(.DashboardPage) {
  position: relative;
}

:global(.DashboardPage.Editing #body-dashboard-home) {
  width: calc(100% - 53px);
}

:global(.DashboardPage #body-dashboard-home) {
  height: calc(100vh - (var(--navbar-height) + var(--breadcrumb-height)));
  overflow-y: auto;
  overflow-x: hidden;
}

:global(.DashboardPage .dashboard-wrapper-with-filters.filter-top-fixed #body-dashboard-home),
:global(.DashboardPage .dashboard-wrapper-with-filters.filter-bottom-fixed #body-dashboard-home) {
  height: calc(100vh - (var(--navbar-height) + var(--breadcrumb-height) + 43px));
}
