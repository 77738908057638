.event-notification {
  position: fixed;
  height: 27px;
  background-color: #000000bd;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  z-index: 9999;
  display: flex;
  width: 100%;
}

.event-notification.hidden {
  transition: all 0.75s ease-in-out;
  transform: translateY(-150%);
  position: fixed;
  height: 27px;
  background-color: #000000bd;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  z-index: 9999;
  display: flex !important;
  width: 100%;
}

.event-notification-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.event-notification-live-container {
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid;
  width: fit-content;
  justify-content: center;
  margin-right: 10px;
  height: 20px;
}

.event-notification-live-container.glow {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.event-notification-link,
.event-notification-link:focus,
.event-notification-link:hover {
  color: #ffffff;
}

.event-notification-message > a:hover {
  cursor: pointer;
}

.event-notification-live-text {
  padding-left: 8px;
  color: #FFFFFF;
}

.event-notification-close {
  font-size: 26px;
  position: absolute;
  right: 0;
  opacity: 1;
  margin-right: 6px;
}

.event-notification-close-button:hover {
  cursor: pointer;
}

.event-notification-live-icon {
  font-size: 18px !important;
  margin: 4px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 4px 0;
  }
  50% {
    box-shadow: 0 0 4px 2px;
  }
}