.selectedItemsOverlay {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100vw;
}

.selectedItemsWrapper {
  align-items: center;
  color: #fff;
  background: var(--blue-default);
  border-radius: 55px;
  display: inline-flex;
  gap: 5px;
  height: 35px;
  justify-content: center;
  margin-bottom: 15px;
  min-width: 145px;
}

.selectedItemsWrapper i {
  color: #fff;
}

.selectedItemsWrapper.overlayHidden * {
  opacity: 0;
}

.actionWrapper {
  align-items: center;
  border-right: 2px solid #fff;
  display: inline-flex;
  gap: 5px;
  height: 100%;
  padding: 0 10px;
}

.actionButton {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  margin-left: 15px;
  padding: 0 10px;
}

.actionButton:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.selectedItemsWrapper.overlayHidden .actionButton,
.selectedItemsWrapper.overlayHidden :global(.BngIconButton) {
  display: none;
}