.EnabledPill {
  align-items: center;
  border-radius: 12px;
  display: inline-flex;
  gap: 5px;
  margin-left: 10px;
  padding: 0 10px;
  white-space: nowrap;
}

.EnabledPill.enabled {
  background: #e6efff;
  color: #005dff;
}

.EnabledPill.disabled {
  background: #e2e2e2;
  color: #7e7e7e;
}

.pillDot {
  border-radius: 100%;
  width: 5px;
  height: 5px;
}

.EnabledPill.enabled .pillDot {
  background: #005dff;
}

.EnabledPill.disabled .pillDot {
  background: #7e7e7e;
}
