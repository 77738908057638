.starRating {
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 90%;
  margin-left: 6%;
}

.starRating :global(button) {
  width: 100px;
  text-align: start;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.on .star {
  color: #ffda17 !important;
}
.off .star {
  color: #e4e4e4 !important;
}

.star {
  font-size: 50px !important;
}
