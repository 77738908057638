#bigTableContainer {
    height: 100%;
}

.cockpit-header-hideable-breadcrumb #bigTableContainer {
    position: relative;
    top: 36px;
}
.cockpit-header-hideable-breadcrumb #cockpit-item-panel #bigTableContainer {
    top: 0
}

.BngBigTable__App {
    height: 100%;
}

.BngBigTable__App.withFilters {
    height: calc(100% - 42px);
    position: relative;
    top: 42px;
}

.BngBigTable__App.withFilters .BngRightMenu {
    height: 100%;
    top: 0;
}

.BngBigTable__mainContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.BngBigTable__mainPanel {
    width: 100%;
    padding: 12px;
}

.BngBigTable__errorPanel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 12px;
}

.BngBigTable__spacer {
    flex: 0 0 340px;
}

.BngBigTable__rightMenu {
    flex: 0 0 52px;
}

.BngBigTable__columnsForm__grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.w-67 {
    width: 67%;
}

.w-45 {
    width: 45%;
}

.w-32 {
    width: 32%;
}

.w-45.mb-10 {
    margin-bottom: 10px;
}

.ag-react-container {
    width: 100%;
}

.BngBigTable__sourceFieldTitles {
    margin-bottom: 0px;
    font-weight: bold;
}

.ag-cell.ag-cell-value {
    user-select: text;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.BngBigTable__rightPanel .tabbable .tab-content > div {
    padding: 0;
}

.BngBigTable__rightPanel .tabbable {
    height: 100%;
}

.BngBigTable__rightPanel .tab-content {
    border: none;
    background-color: #e7e7e7;
    height: 100%;
}

.BngBigTable__ag-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BngBigTable__columnForm hr {
    margin-top: 0px;
    margin-bottom: 12px;
}

.BngBigTable .bng-snackbar.no-data {
    max-width: fit-content;
}

.p-10 {
    padding: 10px;
}

.BngBigTable__menuTab {
    width: unset !important;
}

.BngBigTable__menuTab .AccordionTitle {
    padding: 10px;
}

.BngBigTable__menuTab .AccordionContent {
    padding: 0;
}

.BigTableColumnsMenuItem .Icon {
    font-size: 22px !important;
}

#BigTableRenderContainer {
    width: 100%;
    height: 100vh;
}

#BigTableRenderContainer .ExportAsync {
    padding: 0 !important;
}

.main-embed-panel-parent #BigTableRenderContainer {
    height: calc(100vh - 50px);
}

.backgroundBlocker {
    width: 100%;
    height: 100%;
    background: #9e9e9e;
    position: fixed;
    z-index: 47;
}