.BngAda.AssistedAnalysisHelp.AdaHidden .wrapperAdaClosed {
     margin-right: 0;
}


.BngAda.AssistedAnalysisHelp.AdaVisible .wrapperAdaOpen img{
    margin-right: 50px;
    z-index: 10001;
}

.BngAda.AssistedAnalysisHelp.AdaVisible .contentContainer {
    margin-right: 200px;
    z-index: 10004;
}

.BngAda.AssistedAnalysisHelp .disableInfo {
    position: fixed;
    z-index: 10003;
}

.BngAda.AssistedAnalysisHelp.PopUpAdaVisible .contentContainer {
    position: fixed;
    max-width: 400px;
    z-index: 10004;
}

.BngVideoModal.AssistedAnalysisHelpVideoModal {
    background: linear-gradient(120deg, rgba(35,41,214,1) 0%, rgba(0,172,248,1) 100%);
    z-index: 10004;
}