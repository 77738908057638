.fieldLabel {
  font-size: 12px;
  font-weight: 500;
  color: var(--dark-gray-title);
}

.phoneInput {
  border: 1px solid #e4e4e4;
  width: calc(100% - 10px);
  border-radius: 4px;
  padding-left: 8px;
}

.phoneInput :global(input) {
  border: none;
}

.buttons {
  margin-top: 76px;
}

.nameFieldContainer {
  margin-top: 24px;
}
