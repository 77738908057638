.colorPickerContainerWrapper {
  background-color: white;
  border-radius: 10px;
  position: absolute;
  border: solid 1px #e3e1e1;
  user-select: none;
}

.colorPickerContainerHeader {
  display: flex;
}

.paletteTab,
.solidTab,
.gradientTab {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
  font-weight: 500;
  user-select: none;
}

.colorPickerContainerBottomBar {
  width: 100%;
  height: 2px;
  background-color: #f6f6f6;
  position: relative;
}

.colorPickerContainerBlueBar {
  height: 2px;
  background-color: var(--blue-default);
  position: absolute;
}

.selectedTab {
  color: var(--blue-default);
}

.paletteTab:hover,
.solidTab:hover,
.gradientTab:hover {
  color: #52a3ff;
}
