.GitHubForm .row-fluid.painel{
    width: 383px;
    margin-top: 95px;
    border-right: 3px solid #f1f2f2;
    height: 189px;
}
.git-hub-button-container .row-fluid{
    width: 100%;
}

.GitHubForm .BngField .control-label{
    display: none;
}
.GitHubForm .git-hub-field-container{
    height: 50px;
    width: 80%;
}
.GitHubForm .git-hub-validate-params{
    float: right;
    display: inline-grid;
    margin-top: -195px;
    margin-right: 50px;
}

