.SelectIconDialog {
    top: calc(50% - 250px);
}

.SelectIconDialog .select-icon-dialog-body {
    background-color: #f6f6f6;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    text-align: right;
}

.SelectIconDialog .select-icon-dialog-body .BngSelect {
    width: 140px;
    margin-bottom: 0 !important;
    margin-top: 8px;
}

.SelectIconDialog .SearchContainer {
    display: flex;
    justify-content: end;
    padding: 10px 30px 10px 30px;
    margin: 0 2px;
}

.SelectIconDialog .SearchContainer .bng-search {
    background-color: #f6f6f6 !important;
    margin-right: 10px;
}

.SelectIconDialog .Icons {
    height: 320px;
    overflow: auto;
    text-align: left;
    padding: 0 30px;
    margin: 0 2px;
}

.SelectIconDialog .Icons .Icon {
    padding: 8px;
    margin: 5px;
    border-radius: 100%;
    cursor: pointer;
    width: 24px;
    font-size: 24px;
}

.SelectIconDialog .Icons .Icon:hover {
    background: #ccc;
}

.SelectIconDialog .Icons .Icon.selected {
    color: #FFF;
    background: #3558ef;
}

.SelectIconDialog .IconsBox {
    display: block;
    margin-top: 20px;
}

.SelectIconDialog .IconsBox:first-child {
    margin-top: 0;
}

.SelectIconDialog .IconsTitle {
    display: flex;
    align-items: center;
    min-height: 32px;
}

.SelectIconDialog .IconsTitle span {
    color: #000;
    font-size: 16px;
}

.SelectIconDialog .IconsList {
    display: block;
    overflow: hidden;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 10px 0;
    padding: 13px;
}