.WizardDialog {
}

.WizardSteps {
    display: flex;
    margin-top: 8px;
    cursor: default;
}

.WizardStep {
    flex: 1;
    text-align: center;
    position: relative;
    height: 6px;
    background-color: #E2E2E2;
}

.WizardStep .material-icons {
    font-size: 12px;
    margin: 2px;
}

.WizardStep.Active {
    background-color: #3558EF;
}

.WizardStep--step {
    box-sizing: border-box;
    height: 26px;
    width: 26px;
    border-radius: 12px;
    line-height: 24px;
    margin: -10px auto 0;
    font-size: 14px;
    font-weight: 500;
    background-color: #E2E2E2;
    border: 2px solid #FFFFFF;
}

.WizardStep.Active .WizardStep--step {
    background-color: #3558EF;
    color: white;

}

.WizardStep.Done .WizardStep--step {
    height: 16px;
    width: 16px;
    background-color: #859af5;
    border: none;
    margin-top: -5px;
    margin-bottom: 15px;
    line-height: 20px;
    color: white;
}

.WizardStep--title {
    display: block;
    height: 14px;
    opacity: 0.8;
    color: #292C31;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 14px;
    margin-top: 10px;
}

.WizardStepsContainer {
    width: calc(100% - 22px);
}

.WizardStepsContainer .WizardSteps {
    margin-top: 40px;
    margin-bottom: 40px;
}