#publisher-not-found-container {
    height: 100%;
}

#publisher-not-found-container > .ObjectNotPublished {
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ObjectNotPublished-Body {
    display: grid;
    text-align: center;
    min-height: 30vh;
}

@media only screen and (max-device-width: 800px) {
    .ObjectNotPublished-Body {
        min-height: 50vh;
    }
}

.ObjectNotPublished-Body > * {
    padding: 10px 0;
}

.ObjectNotPublished-Body a {
    color: #599eda;
}

.ObjectNotPublished-Body .Icon {
    font-size: 120px;
    color: #599eda;
}

.ObjectNotPublished-Body .ObjectNotPublished-Message {
    font-size: 20px;
    line-height: 20px;
}

.ObjectNotPublished-Body .ObjectNotPublished-Links > * {
    display: block;
    padding-bottom: 10px;
}