.EvaluationPeriodDropdownPopper {
    z-index: 100;
}

.EvaluationPeriodDropdown {
    width: 680px;
    height: 347px;
    background-color: #fff;
    border-radius: 6px;
    padding: 25px 25px 0;
    overflow: hidden;
    position: relative;
    display: flex;
}

.evaluationPeriodOverlay {
    z-index: 60;
    background: black;
    opacity: 0.25;
}

.evaluationTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
}

.evaluationMessage {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.messageIcon {
    color: #005DFF;
}

.messageList {
    padding-top: 28px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    margin-left: 0;
}

.messageList :global(li) {
    list-style-type: none;
    display: flex;
    gap: 10px;
}

.evaluationText {
    width: 60%;
}

.activateButton {
    background: #005DFF !important;
    border-radius: 100px;
    color: white;
    padding: 10px 20px;
}

.evaluationGraph {
    min-width: 290px;
    max-width: 290px;
}

.backCircle {
    stroke: #DFDFDF;
}

.coloredCircle {
    stroke: #E92A2A;
}

.smallTextUnderGraph {
    font-size: 10px;
    text-align: center;
    display: block;
}

.daysLeftWrapper {
    position: relative;
    top: -63%;
    display: flex;
    align-items: center;
    flex-direction: column;
}