.DimensionGrouping {

}

.DimensionGrouping .MemberTree .members-container > ul {
    margin: 0 0 0 2px;
}

.DimensionGrouping .MemberTree .member > a:first-child {
    margin-right: 5px;
}

.DimensionGrouping .MemberTree .member > label {
    display: inline;
}

.DimensionGrouping .MemberTree .member.leaf {
    margin-left: 15px;
}

.DimensionGrouping .MemberTree,
.DimensionGrouping .MemberTree ul {
    list-style: none;
}

.DimensionGrouping .cm-container {
    width: 100%;
    height: 250px;
    overflow: auto;
}

.DimensionGrouping .cm-container label {
    margin-bottom: 0;
}

.DimensionGrouping .members-container {
    width: 100%;
    height: 210px;
    overflow: auto;
}

@media screen and (min-height: 700px) {
    .DimensionGrouping .members-container {
        width: 100%;
        height: 310px;
        overflow: auto;
    }

    .DimensionGrouping .cm-container {
        width: 100%;
        height: 340px;
        overflow: auto;
    }
}

.DimensionGroupingMembersWrapper {
    width: 100% !important;
}