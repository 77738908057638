.IpRestrictionTab {
  padding: 35px;
}

.ipRestrictionWarn {
  align-items: center;
  background: #fcf8e3;
  border: 1px solid #fcf1de;
  border-radius: 4px;
  display: inline-flex;
  gap: 8px;
  padding: 5px;
  width: 100%;
}


.ipRestrictionWarn span {
  font-size: 14px;
  font-weight: 500;
}

.warnIcon {
  color: #f98900;
}

.sectionTitle {
  color: #333333;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-top: 24px;
  margin-bottom: 16px;
}

.ipRestrictionSection {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.ipRestrictionTable {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  min-height: 400px;
  position: relative;
}

.createButton {
  color: #005dff;
  font-size: 15px;
  font-weight: 700;
  gap: 5px !important;
  margin-left: 10px;
  padding: 8px;
  transition: background-color 250ms linear, color 250ms linear, filter 250ms linear;
}

.createButton:hover {
  background-color: rgba(0, 0, 0, 0.08);
  text-shadow: none;
}

.createButton i {
  font-size: 24px !important;
}

.logsButton {
  color: #333;
  margin-right: 15px;
}

.tableHeader {
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
}

.activeTag {
  background: #00a355;
  width: fit-content;
}

.inactiveTag {
  background: #f98900;
  width: fit-content;
}

.userAvatarOuter {
  background: #ffffff;
  border-radius: 100%;
  height: 35px;
  width: 35px;
}

.groupIcon {
  align-content: center;
  align-items: center;
  color: #7f7f7f;
  display: flex;
  height: 34px;
  justify-content: center;
}

.userAvatar {
  background-size: cover !important;
  border-radius: 100%;
  height: 35px;
  width: 35px;
}

.actionsDropdown li {
  box-shadow: none !important;
  padding: 5px 15px;
}

.userCol :global(.LinkedObjects) {
  align-items: center;
  display: inline-flex;
  gap: 5px;
  width: 100%;
}

.ipList {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
