.ul-dropdown-publiclink {
    list-style-type: none;
    padding: 0;
    margin-top: -14px;
    margin-left: -157px;
    background-color: white;
    font-weight: bold;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 50000 !important;
    border-radius: 8px;
}

.button-menu-publisher {
    color: black;
    padding-top: 7px;
    font-size: 28px;
    cursor: pointer;
}

.li-dropdown:hover {
    background-color: #eee;
}

.button-dropdown-action {
    margin: 0 2px !important;
    background: transparent !important;
    color: #555555 !important;
    padding-top: 5px !important;
    font-size: 14px;
}


.li-dropdown .presentation-public-link {
    width: 105px !important;
}

.hidden-public-link-input {
    opacity: 0;
    position: absolute;
    width: 147px;
}

.bng-dropdown .presentation-public-link {
    text-align: left;
}

.button-menu-publisher.presentation-public-link {
    font-weight: 500;
    opacity: 0.8;
    text-align: right;
    padding-top: 0 !important;
    margin-right: 20px;
}