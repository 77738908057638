.StructuresPage .Header .bng-search.closed {
  width: 82px;
}

.FloatActionButton .BngIconButton {
  transition: transform 250ms linear, filter 250ms linear;
}

.FloatActionButton .BngIconButton.Open {
  transform: rotate(45deg);
}

.StructuresPage .StructureIcon {
  font-size: 24px;
  color: #005dff;
}

.StructuresPage .IconContainer {
  display: inline-block;
  position: relative;
}

.LoadStatusBadge {
  padding: 2px;
  border-radius: 100%;
  border: 2px solid white;
  text-align: center;
}

.LoadStatusBadge .Icon {
  font-size: 12px !important;
  width: 100%;
}

.StructuresPage .IconContainer .LoadStatusBadge {
  position: absolute;
  right: 6px;
  bottom: -6px;
}

.StructuresPage .TimeIcon {
  font-size: 21px;
}

.StructureMenuPopper {
  width: 280px;
  z-index: 100;
}

.StructureMenuPopper .LoadStatusBadge {
  width: 12px;
  border: none;
}

.SchedulingCounter,
.SchedulingCounterEmpty {
  display: table;
  margin: 0 auto;
}

.SchedulingCounter {
  background-color: #095dff !important;
}

.SchedulingCounterEmpty {
  background-color: #f89406 !important;
}

.StructuresPage > .BngDropdown .BngIconButton,
.FloatActionButtonDropdown .BngIconButton {
  padding: 12px;
}

.FloatActionButtonDropdown {
  width: 250px;
}

.FloatActionButtonDropdown .ButtonsContainer {
  transition: max-height 500ms linear, opacity 300ms linear;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}

.FloatActionButtonDropdown .ButtonsContainer.Show {
  max-height: 100vh;
  opacity: 1;
}

.FloatActionButtonDropdown .ButtonsContainer > div {
  position: relative;
  margin-top: 10px;
}

.FloatActionButtonDropdown .ButtonsContainer > div label,
.FloatActionButtonDropdown .ButtonsContainer > div span {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  top: 10px;
  left: 60px;
  white-space: nowrap;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer !important;
}

.FloatActionButtonDropdown .ButtonsContainer > div label:after {
  position: absolute;
  top: 8px;
  left: -6px;
  content: '';
  width: 0;
  height: 0;

  border-right: solid 6px rgba(0, 0, 0, 0.8);
  border-bottom: solid 6px transparent;
  border-top: solid 6px transparent;
}

.StructureFilterDropdown.Filtered .Icon {
  color: #005dff;
}

.StructureFilterDropdownPopper {
  width: 280px;
  z-index: 100;
}

.StructureFilterDropdownPopper .bng-dropdown {
  width: 224px;
  padding: 28px;
}

.StructuresPage .QuotaViewerContainer {
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}

.StructuresPage .QuotaViewerContainer.Fade {
  opacity: 0;
  visibility: collapse;
}

.StructuresPage .SearchVisible {
  width: 370px;
}

.StructuresPage .Header .bng-search .search-button {
  color: rgba(0, 0, 0, 0.54);
  transition: ease-in-out 250ms;
  transition-property: background-color, color;
}

.StructuresPage .Header .bng-search .search-button:not(:disabled):hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.StructuresPage .Header .bng-search .search-button:not(:disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
}

.StructuresPage .Header .bng-search .search-field {
  width: calc(100% - 40px);
}

.StructureMenuPopper .TagsDropdownGroupOption {
  padding: 0;
}

.StructureMenuPopper .BngDropdownTags {
  width: 100%;
  height: 100%;
}

.TypeOption {
  line-height: 20px;
}

.TypeOption.Type-ANALYTICAL {
  align-items: flex-end;
}

.LimitAlertBadge {
  width: 13px !important;
  height: 13px !important;
  line-height: 13px;
  text-align: center;
}

.StructureMenuPopper .LimitAlertBadge {
  padding: 3px;
  margin-left: auto;
}

.StructureAdvancedMenuDropOverlay {
  z-index: 25051;
}

.StructureAdvancedMenuDrop {
  z-index: 25052;
  width: 335px;
}

.StructureAdvancedSubmenuDropOverlay {
  z-index: 25053;
}

.StructureAdvancedSubmenuDrop {
  z-index: 25054;
}

.StructuresPageBimQueryButton img {
  height: 25px;
  filter: brightness(0) invert(1);
  padding: 3px;
}

.StructuresPage .customHeaderAlerts {
  align-items: center;
  display: inline-flex;
  gap: 5px;
  margin-left: 10px;
}

.StructuresPage .customHeaderAlerts .BngTag {
  border-radius: 14px;
  cursor: pointer;
  padding: 4px 4px;
}

.StructuresPage .customHeaderAlerts .warningTag {
  background: #e23d3d;
}
