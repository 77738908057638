.BngAda.NewAnalysisHelp.AdaHidden .wrapperAdaClosed {
    margin-right: 0;
}


.BngAda.NewAnalysisHelp.AdaVisible .wrapperAdaOpen img{
   margin-right: 50px;
   z-index: 1004;
}

.BngAda.NewAnalysisHelp.AdaVisible .contentContainer {
    max-width: 350px;
    margin-right: 200px;
    z-index: 1004;
}

.BngAda.NewAnalysisHelp .disableInfo,
.BngAda.NewAnalysisHelp .disableBackground {
   position: fixed;
   z-index: 1003;
   background-color: rgba(0,0,0,0.5);
}