.PulsateLoading {
    z-index: 30000;
    border: 5px solid #333;
    border-radius: 30px;
    height: 30px;
    right: 50px;
    margin: -15px 0 0 -15px;
    position: fixed;
    bottom: 50px;
    width: 30px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
}

@keyframes pulsate {
    0% {
        transform: scale(.1);
        opacity: 0.0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}
