.labelWrapper {
    width: fit-content;
    border-radius: 16px;
    background-color: #E6EFFF;
    border: 1px solid #CCDFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
}

.releaseLabel {
    color: #005DFF;
    font-weight: 700;
    font-size: 12px;
    margin: 0;
}

.labelWrapper .releaseIcon {
    font-size: 16px;
    margin-left: 5px;
    color: #005dff;
}

