.BngMobileDrawerMultiStepInfo {
    height: 500px;
    background: linear-gradient(120deg, rgba(35,41,214,1) 0%, rgba(0,172,248,1) 100%);
    position: fixed;
    z-index: 1008;
}

.BngMobileDrawerMultiStepInfo.BngMultiStep {
    position: fixed;
    right: 0;
    bottom: 0;
}

.BngMobileDrawerMultiStepInfo .PurpleBackground {
    background-color: #494B81;
}

.BngMobileDrawerMultiStepInfo.BngMultiStep .Step .Subtitle {
    color: #fff;
}

.BngMobileDrawerMultiStepInfo.BngMultiStep .Step .Title {
    color: #fff;
}

.BngMobileDrawerMultiStepInfo .widget-footer-pg-button {
    background-color: #70acf5;
}
.BngMobileDrawerMultiStepInfo .widget-footer-pg-button.active {
    background-color: #fff;
}

.BngMobileDrawerMultiStepInfo .bng-button.cancel {
    color: white !important;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
}

.BngMobileDrawerMultiStepInfo .bng-button.save {
    color: white !important;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
}

.BngMobileDrawerMultiStepInfo .bng-button.cancel.enable:hover {
    background-color: transparent !important;
    border: none !important;
}

.BngMobileDrawerMultiStepInfo .bng-button.save.enable:hover {
    background-color: transparent !important;
    border: none !important;
}

.BngMobileDrawerMultiStepInfo a {
    color: white;
    text-decoration: underline !important;
}