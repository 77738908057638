.BngBigTable__ColumnConfigurationDialog {
  background: #efefef;
  height: calc(100% - 84px);
  position: fixed;
  z-index: 49;
  top: 82px;
  right: 53px;
  max-width: 322px;
}

.BngBigTable__ColumnConfigurationDialogForm {
  overflow-y: scroll;
  height: calc(100% - 45px);
}

.BngBigTable__ColumnConfigurationDialogHeader {
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BngBigTable__ColumnConfigurationDialogHeaderTitle {
  color: #005dff;
  font-weight: bold;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.BngBigTable__ColumnConfigurationDialogHeaderName {
  color: #000;
  font-size: 15px;
}

.BngBigTable__ColumnConfigurationDialogHeaderBack {
  color: #005dff;
  cursor: pointer;
}

.BngBigTable__ColumnConfigurationDialogHeaderLink {
  color: #005dff;
  font-weight: bold;
}

.BngBigTable__ColumnConfigurationPinned .bng-button-group-item .material-icons {
  transform: rotate(90deg);
}

.BngBigTable__ColumnConfigurationDialog .AccordionDescription {
  flex: 1;
}

.BngBigTable__columnsForm__titleDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BngBigTable__columnsForm__titleDiv p {
  padding: 10px;
  margin-bottom: 0;
  font-weight: bold;
}

.BngBigTable__columnsForm__titleDiv span {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
}

.BngBigTable__columnsForm__help {
  font-size: 18px !important;
  cursor: pointer;
}

.BngBigTable__ColumnConfigurationDialog.BngBigTable__menuTab {
  width: 100% !important;
}

.BigTable__ImageUrlAccordionConfiguration {
  padding: 10px;
}

.BigTable__BorderConfiguration div span {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
}

.Bigtable__ClickActions {
  padding: 10px;
}

.Bigtable__ClickActions span {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
}

.Bigtable__ClickActions div {
  margin-bottom: 12px;
}

.ColumnConfigurationDialog-BngDropDown-filter-selection-button {
  width: 100%;
}

.ColumnConfigurationDialog-BngDropDown-filter-selection-button button {
  gap: 10px;
  border-radius: 8px;
  width: 100%;
}

.ColumnConfigurationDialog-filter-selection-button-popper {
  width: 300px;
  z-index: 49 !important;
}

.ColumnConfigurationDialog-filter-selection-button-popper {
  height: 230px;
  background-color: #ffffff;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 #00000033;
  right: 71px;
}

.ColumnConfigurationDialog-filter-selection-button-popper.filtered {
  left: -72px !important;
}

.ColumnConfigurationDialog-filter-selection-button-popper div {
  display: grid;
  justify-content: center;
  justify-items: center;
}

.ColumnConfigurationDialog-filter-selection-button-popper div :first-child {
  line-height: 40px;
}

.ColumnConfigurationDialog-filter-selection-button-popper div .filter-box-list-selector {
  width: 255px;
  height: 135px;
  border: 1px solid #efefef;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: flex-start;
}

.ColumnConfigurationDialog-filter-selection-button-popper div :last-child {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
}

.ColumnConfigurationDialog-filter-selection-button-popperOverlay {
  z-index: 49 !important;
}

.ColumnConfigurationDialog-filter-selection-button-popper div .filter-box-list-selector .BngRadio.BngCheckbox.radio {
  margin-left: 15px;
}

.ColumnConfigurationDialog-filter-selection-button-popper
  div
  .filter-box-list-selector
  .BngRadio.BngCheckbox.radio
  label {
  display: flex;
  align-items: center;
}

.ColumnConfigurationDialog-filter-selection-button-popper
  div
  .filter-box-list-selector
  .BngRadio.BngCheckbox.radio
  label
  span {
  margin-left: 10px !important;
}

.ColumnConfigurationDialog-BngDropDown-filter-selection-button div.selectedFilterNameFilterChangeButton input {
  width: 87%;
}

.ColumnConfigurationDialog-BngDropDown-filter-selection-button div.selectedFilterNameFilterChangeButton button {
  width: unset;
}

.Bigtable__ClickActions .BngSwitch {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 12px;
  justify-content: space-between;
}

.Bigtable__ClickActions .BngSwitch .BngSwitchLabel {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  margin-top: 12px;
  margin-right: 10px;
}
