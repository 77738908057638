.my-accounts-dialog-body {
    background-color: #F6F6F6;
    padding: 10px 10px;
    margin-top: 10px;
}

.my-accounts-dialog-body .my-accounts-remove-projects-flag {
    float: right;
    margin-right: 18px;
}

.my-accounts-configuration {
    display: inline-flex;
    width: 100%;
}

.my-accounts-configuration > div {
    width: 50%;
}

.bng-button.BngAccountButton {
    margin: 0 8px 0 0 !important;
}

.MyAccountsDialog .bng-button.BngAccountButton {
    border-radius: 4px;
    border: 1px solid #999;
    background-color: transparent !important;
    color: #888 !important;
    font-weight: 500;
    height: 32px;
    width: 117px;
}

.MyAccountsDialog .bng-button.BngAccountButton:focus {
    outline: 0;
}

.MyAccountsDialog .bng-button.BngAccountButton.active-plan {
    border-color: #3558EF;
    background-color: #3558EF !important;
    color: #fff !important;
}

.MyAccountsDialog .application-keys-tab,
.MyAccountsDialog .consumption-board,
.MyAccountsDialog .features-tab,
.MyAccountsDialog .projects-tab {
    min-height: 360px;
    max-height: 360px;
    overflow-y: auto;
}

.MyAccountsDialog .features-tab {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

/*consumption tab*/
.MyAccountsDialog {
    top: calc(50% - 320px);
}

.MyAccountsDialog .consumption-board {
    display: block;
}

.MyAccountsDialog .consumption-board .consumption-item {
    display: inline-flex;
    width: 50%;
    padding: 5px 0;
    margin: 10px 0;
    align-items: center;
}

.MyAccountsDialog .consumption-board .consumption-illustration {
    width: 100px;
    text-align: center;
}

.MyAccountsDialog .consumption-board .consumption-illustration > img {
    height: 60px;
}


.MyAccountsDialog .consumption-board .consumption-description {
    width: 280px;
    padding: inherit;
}

.MyAccountsDialog .consumption-board .consumption-description > * {
    display: block;
}

.MyAccountsDialog .consumption-board .consumption-description .consumption-quantity {
    font-weight: 600;
    color: #000;
}

.MyAccountsDialog .consumption-board .consumption-description .consumption-capacity {
    color: #888;
    font-size: 13px;
}

.MyAccountsDialog .consumption-board .consumption-additional {
    text-align: right;
    padding-right: 30px;
}

.MyAccountsDialog .consumption-board .consumption-additional input {
    width: 65px;
    margin-left: calc(100% - 65px);
    display: block;
}

.MyAccountsDialog .consumption-illustration .percentage {
    vertical-align: middle;
    height: 64px;
    width: 64px;
    line-height: 64px;
}

.MyAccountsDialog .consumption-illustration .percent {
    font-size: 14px;
    color: #269C59;
}

.MyAccountsDialog .consumption-illustration .infobox-circle {
    border-radius: 100%;
    height: 64px;
    width: 64px;
    margin: auto;
    background: #F5B650;
    text-shadow: none;
}

.MyAccountsDialog .consumption-illustration .infobox-circle .infobox-iconcircle {
    margin: auto;
    padding: 5px 0;
    display: block;
}

/*end consumption tab*/

/*feature tab*/

.MyAccountsDialog .features-tab .feature-item {
    display: inline-flex;
    padding: 5px 0;
    margin: 10px 0;
    align-items: center;
}

.MyAccountsDialog .features-tab .feature-item:nth-child(odd) {
    margin-left: 17px;
}

.MyAccountsDialog .features-tab .feature-item .feature-icon {
    padding: 15px;
    border-radius: 100%;
    background-color: #419441;
    color: #fff;
    font-size: 32px;
    margin: 0 22px;
}

.MyAccountsDialog .features-tab .feature-item .feature-details {
    margin-right: 40px;
    display: inline-grid;
}

.MyAccountsDialog .features-tab .feature-item .feature-title {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.MyAccountsDialog .features-tab .feature-item .feature-description {
    color: #888;
    font-size: 13px;
}

/*end feature tab*/

/*application key tab*/

.MyAccountsDialog .application-key-domains {
    width: 320px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.MyAccountsDialog .application-keys-actions {
    text-align: right;
    display: inline-flex;
    float: right;
}

.MyAccountsDialog .application-keys-actions .application-keys-action {
    padding: 5px;
    border-radius: 100%;
    cursor: pointer;
}

.MyAccountsDialog .application-keys-actions .application-keys-action:hover {
    background-color: #e6e6e6;
}

.MyAccountsDialog .application-keys-tab tr.iceDatTblRow.crud-tableRow.crud-grid-formRow :nth-child(3) {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*end application key tab*/

/*projects tab*/

.MyAccountsDialog .projects-tab .badge {
    padding: 3px 9px;
    font-size: 12px;
    text-shadow: none;
    letter-spacing: 0.3px;
}

/*end projects tab*/

/*addon tab*/

.addons-icon {
    width: 30px;
    margin-right: 8px;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex !important;
    font-size: 28px !important;
}

.addons-tab {
    min-height: 360px;
    max-height: 360px;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.addons-component {
    display: block;
    padding: 10px 0;
    align-items: flex-start;
    margin-left: 10px;
}

.addons-component-full-width .addons-details,
.addons-component-full-width .addons-btn-container {
    margin-right: 10px;
}

.addons-component-full-width .addons-details {
    display: flex;
    flex-direction: column;
}

.limited-addons-component-width .addons-details {
    width: 410px;
}

.limited-addons-component-width .addons-btn-container {
    width: 420px;
}

.addons-details, .addons-btn-container {
    display: inline-grid;
    border: #dbdbdb 1px solid;
}

.addons-details {
    padding: 15px;
    height: 140px;
    border-radius: 4px 4px 0px 0px;
}

.addons-btn-container {
    border-top: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px 0px 4px 4px;
}

.addons-title-container {
    align-items: center;
    display: flex;
    margin-bottom: 10px;
}

.learn-more-label {
    font-size: 14px;
    font-weight: 500;
    margin-left: 5px;
}

.learn-more-label-container {
    color: #4086FF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.learn-more-label-container:hover {
    color: #2e72e6 !important;
}

.learn-more-label-icon {
    font-size: 16px !important;
    margin-left: 5px;
}

.bng-switch-addon {
    width: 36px;
    display: flex;
    margin-right: 10px;
}

.addons-title {
    color: #555555;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
}

.addons-component .activation-request-info {
    color: #F98900 !important;
    margin-left: 10px;
}

.addons-component .activation-request-info i {
    font-size: 18px;
}

.addons-description {
    color: #888;
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 80px;
}

.addons-btn-manage {
    width: 136px !important;
    height: 34px !important;
    font-size: 15px !important;
    justify-content: center !important;
    background-color: #fff !important;
    border: 1px solid #6C6C6C !important;
    color: #000 !important;
    overflow: hidden;
}

.addons-btn-manage:hover {
    background-color: #EEEEEE !important;
    border: 0px none !important;
}

/*end addon tab*/