.cockpit-action-buttons:before {
    content: "";
    position: absolute;
    height: 36px;
    top: 0;
    width: 40px;
    background-image: linear-gradient(to right, rgba(239, 239, 239, 0), rgba(239, 239, 239, 1));
    left: -40px;
    pointer-events: none;
    z-index: 3;
}

.BngIconButton.cockpit-nav-btn {
    padding: 2px;
}

.cockpit-action-buttons {
    position: relative;
    display: inline-flex;
    background: #fff;
    border-left: 1px solid #e6e6e6;
}

.cockpit-dropdown-popper {
    background: #fff;
    box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.07) !important;
    border: 1px solid #efefef !important;
    border-radius: 4px;
}

.cockpit-action-buttons .cockpit-nav-btn {
    padding: 10px;
    cursor: pointer;
}

.cockpit-action-buttons .cockpit-nav-btn.share {
    padding: 8px;
}

.cockpit-action-buttons .cockpit-nav-btn.share .share-button {
    background-color: #E6EFFF;
    color: #005DFF;
    height: 22px;
    border-radius: 14px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.cockpit-action-buttons .cockpit-nav-btn.share .share-button .selected i {
    font-size: 18px;
}

.cockpit-action-buttons .cockpit-nav-btn.disabled {
    opacity: 0.5;
}

.cockpit-action-buttons .cockpit-nav-btn a {
    display: block;
    color: rgba(0, 0, 0, .75);
}

.cockpit-dropdown-popper .cockpit-export-popper-items .cockpit-export-item:hover,
.cockpit-action-buttons .cockpit-nav-btn:hover {
    color: #4272FE;
}

.cockpit-action-buttons .cockpit-button-group {
    border-right: 1px solid #e6e6e6;
}

.cockpit-book-dropdown-popper,
.cockpit-filter-popper,
.cockpit-book-popper,
.cockpit-export-popper {
    z-index: 10;
    margin-top: 0;
}

.cockpit-book-popper,
.cockpit-filter-popper {
    right: 0;
    width: 434px;
}

.cockpit-book-dropdown-popper .bng-dropdown .li-dropdown.link {
    padding: 0;
}

.cockpit-book-dropdown-popper .bng-dropdown .li-dropdown.link > a {
    color: #6e6e6e;
    display: block;
    padding: 10px 15px;
}

.cockpit-dropdown-popper .cockpit-dropdown-popper-action,
.cockpit-dropdown-popper .cockpit-dropdown-popper-header {
    display: flex;
    align-items: center;
    background: #fff !important;
    padding: 14px;
    letter-spacing: .5px;
    text-transform: uppercase;
    white-space: nowrap;
    color: #555;
}

.cockpit-dropdown-popper .cockpit-dropdown-popper-header {
    box-shadow: inset 0 -1px 1px #e6e6e6;
}

.cockpit-dropdown-popper .cockpit-dropdown-popper-action {
    box-shadow: inset 0 1px 1px #e6e6e6;
    cursor: pointer;
}

.cockpit-dropdown-popper .cockpit-dropdown-popper-header span {
    font-size: 13px;
    font-weight: bold;
}

.cockpit-dropdown-popper-action .cockpit-generate-book-button {
    font-size: 12px;
    color: #4272FE;
    font-weight: 500;
    text-align: center;
    width: 100%;
}

.cockpit-filter-popper-list .cockpit-dropdown-popper-header.inactive {
    border-top: 1px solid #e6e6e6;
}

.cockpit-dropdown-popper .cockpit-book-popper-items,
.cockpit-dropdown-popper .cockpit-export-popper-items,
.cockpit-dropdown-popper .cockpit-filter-popper-items {
    background: #fafafa;
    margin: 0;
    list-style: none;
}

.cockpit-dropdown-popper .cockpit-filter-popper-items {
    padding: 0 5px;
}

.cockpit-dropdown-popper .cockpit-dropdown-popper-header .cockpit-filter-type {
    margin-left: 8px;
}

.cockpit-dropdown-popper .cockpit-dropdown-popper-header .cockpit-filter-type-icon {
    font-size: 16px;
}

.cockpit-dropdown-popper .cockpit-book-popper-items {
    max-height: 300px;
    overflow: auto;
}

.cockpit-dropdown-popper .cockpit-book-popper-items .empty-list-books {
    padding: 30px 10px;
    text-align: center;
}

.CockpitHideHeaderButton {
    padding: 10px;
}