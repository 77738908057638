.project-expired-form {
    padding: 20px 10px 0;
    text-align: center;
    word-break: normal;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
}


.project-expired-form p {
    font-size: 1.2rem;
}

.project-expired-form select,
.project-expired-form button {
    font-family: inherit !important;
    margin: 0;
    border-radius: 0;
}

.project-expired-information-link {
    display: block;
    margin: 20px 0;
}