#FilterListAccordion .FilterList .ActionListItems {
    max-height: 260px;
}

.FilterAccordion .HideDraggable .ActionListItemDescription {
    opacity: 0.7;
}

.FilterAccordion button[class*='CheckboxFilter'] .Icon {
    font-size: 20px;
}

.FilterAccordion button.CheckboxFilter-Selected .Icon {
    color: #0679FF !important;
}

.FilterAccordion button.CheckboxFilter-Selected:hover {
    background-color: none;
}

.FilterAccordion .FilterList .ActionListItem {
    width: calc(100% - 36px);
}

.FilterAccordion .FilterList .ActionListItemDescription,
.FilterAccordion .CustomFiltersAccordion .ActionTagItemDescription {
    overflow: hidden;
    text-overflow: ellipsis;
}

.FilterAccordion .CustomFiltersTag {
    max-width: calc(100% - 10px);
}