.BngApp {
  height: 100vh;
}

.BngAppContent {
  height: calc(100vh - var(--navbar-height)); /*-48px from navbar*/
}

.RouterPage {
  height: calc(100% - var(--breadcrumb-height)); /* Size of .BngAppContent - 36px from breadcrumb */
}

.cockpit-header-hidden .BngAppContent {
  height: 100vh;
}

.BngAppContent.GreyBg {
  background-color: #f6f6f6;
}

.BngAppContent.Discover:not(.ExternalPage) {
  margin-left: 52px;
}

.BngAppContent.Discover .page-content {
  margin-left: 0;
  margin-right: 0;
}

/* Imported from empty-template.xhtml */
a {
  color: #2876fd;
}

a:hover,
a:focus {
  outline: none;
}

hr {
  border-top: 1px solid #e6e6e6;
}

html {
  overflow: hidden;
}

body {
  font-family: -apple-system, 'San Francisco', 'Helvetica Neue', 'Roboto', 'Lato', 'Open Sans', Helvetica, Arial,
    sans-serif !important;
}

.DarkMode {
  --default-text-color: #efefef;
  color: var(--default-text-color, #efefef);
}
