.BngAda.NewKpiHelp.AdaHidden .wrapperAdaClosed {
  margin-right: 0;
}

.BngAda.NewKpiHelp.AdaVisible .wrapperAdaOpen img {
  margin-right: 50px;
  z-index: 1004;
}

.BngAda.NewKpiHelp.AdaVisible .contentContainer {
  max-width: 350px;
  margin-right: 200px;
  z-index: 1004;
}

.BngAda.NewKpiHelp .disableInfo,
.BngAda.NewKpiHelp .disableBackground {
  position: fixed;
  z-index: 1003;
  background-color: rgba(0, 0, 0, 0.5);
}
