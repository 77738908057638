#assistedObjectPageContainer {
  padding: 30px;
  height: calc(100% - 75px);
}

.AssistedObjectPage.onTypeSelection {
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  width: 100%;
  min-height: 100%;
  background-color: white;
  max-width: 1320px;
  margin: 0 auto;
}

/* Header */
.AssistedObjectPage .Header {
  background-color: #494b81;
  padding: 24px 48px;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.AssistedObjectPage .Header .Title {
  color: #ffffff;
  font-size: 36px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}

.AssistedObjectPage .Header .Title .Icon {
  color: #ffffff;
  font-size: 66px;
  margin-right: 16px;
}

.AssistedObjectPage .Header .TitleMessage {
  margin-top: 20px;
  margin-left: 8px;
  opacity: 0.75;
  color: #ffffff;
  font-size: 18px;
  font-weight: lighter;
  line-height: 23px;
}

.AssistedObjectPage .Header .TextContainer {
  width: calc(100% - 325px);
}

.AssistedObjectPage .Header a {
  color: #ffffff;
  font-weight: lighter;
  text-decoration: underline !important;
}

/* Content */
.AssistedObjectPage .Content {
  box-sizing: border-box;
  padding: 48px 60px;
}

.AssistedObjectPage .Content .SearchContainer {
  width: 300px;
  position: relative;
  margin-bottom: 38px;
}

/* BngSearch style adjusts */

.AssistedObjectPage .Content .SearchContainer .bng-search-input .search-field {
  font-size: 16px;
  border-bottom: none;
}

.AssistedObjectPage .Content .SearchContainer .bng-search-input {
  border-bottom: 1px solid #979797;
  padding-bottom: 5px;
}

.AssistedObjectPage .Content .SearchContainer .bng-search-input .Icon {
  order: 1;
  font-size: 32px;
  opacity: 0.5 !important;
  margin-right: 0;
}

.AssistedObjectPage .Content .SearchContainer .search-button {
  display: none;
}

/* END */

.AssistedObjectPage .Content .CardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 25px;
}

.AssistedObjectPage .Content .BngHorizontalCard {
  width: 100%;
  height: 120px;
  max-height: 120px;
}
