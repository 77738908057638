.RelevanceTitle,
.LinkTitle,
.DescriptionTitle,
.RegularFunctionalityTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #333333;
}

.RelevanceDescription,
.LinkDescription,
.DescriptionText,
.RegularFunctionalityDescription,
.Star > i {
  font-size: 12px !important;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.OptionWrapper,
.PrimaryFunctionalityOptionWrapper {
  margin-right: 16px;
}

.RelevanceOptionsWrapper,
.OptionWrapper,
.RegularPrimaryFunctionsOptionsWrapper,
.RelevanceOptionsRadioButton,
.RelevanceOptionsRadioButton > label,
.RelevanceOptionsRadioButton > label > input {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

.RelevanceOptionsRadioButton > label {
  margin-left: 4px !important;
}

.RelevanceOptionsRadioButton > label > input,
.RelevanceOptionsRadioButton > label > input::after {
  height: 10px !important;
  width: 10px !important;
}

.RelevanceOptionsRadioButton > label > span {
  margin-left: 5px !important;
}

.RelevanceOptionsLabel {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.LinkWrapper,
.DescriptionWrapper,
.RegularFunctionalityDescription {
  margin-top: 16px;
}

.RegularPrimaryFunctionsOptionsWrapper {
  margin-top: 11px;
}

.PrimaryFunctionalityOptionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.PrimaryFunctionalityOptionWrapper:global(.function-not-selected) {
  margin-right: 32px !important;
}

.PrimaryFunctionalityOption > label > span::before {
  margin-right: 4px !important;
}

.Star {
  padding: 0 0 0 4px;
  background-color: unset !important;
}

.Star:global(.primary) > i {
  color: #337dff;
}

.Dialog {
  height: 415px;
}

.Dialog :global(.modal-dialog),
.Dialog :global(.modal-dialog .modal-content),
.Dialog :global(.modal-dialog .modal-content .modal-body),
.Dialog :global(.modal-dialog .modal-content .modal-body .UiBlocker),
.Dialog :global(.modal-dialog .modal-content .modal-body .UiBlocker .widget-box) {
  height: 414px;
}

.DescriptionTextArea {
  height: 135px;
}

.Dialog :global(.modal-dialog .modal-content .modal-body .UiBlocker .widget-box .widget-body) {
  height: 337px;
}

.Dialog :global(.modal-dialog .modal-content .modal-body .UiBlocker .widget-box .widget-body .widget-main) {
  height: 96%;
}

.Dialog
  :global(.modal-dialog .modal-content .modal-body .UiBlocker .widget-box .widget-body .widget-main .dialog-body) {
  height: 83%;
}

.DialogBody {
  height: 269px;
}

.DialogFooter {
  position: absolute;
  height: 50px;
  width: 91%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.CancelButton {
  color: black;
  background-color: white;
}

.ErrorMessage {
  font-size: smaller;
  color: red;
}
