.ul-dropdown-presentation {
    list-style-type: none;
    padding: 0;
    width: 180px;
    height: 200px;
    margin-top: -14px;
    margin-left: -181px;
    background-color: white;
    font-weight: bold;
    position: absolute;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 50000 !important;
    border-radius: 8px;
}

.li-dropdown:hover {
    background-color: #eee;
}

.button-dropdown-action {
    margin: 0 2px !important;
    background: transparent !important;
    color: #555555 !important;
    padding-top: 5px !important;
    font-size: 14px;
}

.button-edit-page-presentation .visualization-label {
    margin-left: 7px;
}

.bng-dropdown.select-visualization {
    width: 205px !important;
    right: -3px;
    top: -6px;
}

.btn-only-icon.select-visualization {
    width: 100%;
    text-align: left;
}



