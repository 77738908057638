.dropdown-menu-tst {
   /* position: absolute;
    display: inline-block;*/
}
.ul-dropdown {
    list-style-type: none;
    padding: 0;
    width: 180px;
    height: 72px;
    margin-top: -14px;
    margin-left: -181px;
    background-color: white;
    font-weight: bold;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 50000 !important;
    border-radius: 8px;
}
.button-menu-publisher.table-list{
    font-weight: 500;
    writing-mode: vertical-lr;
    padding-top: 7px;
    font-size: 28px;
    font-weight: 500;
    opacity: 0.8;
    color: #373737 !important;
}

.li-dropdown:hover{
    background-color: #eee;
}


.button-dropdown-action{
    margin: 0 2px !important;
    background: transparent !important;
    color: #555555 !important;
    padding-top: 5px !important;
    font-size: 14px;

}
.bng-dropdown .table-list{
    width: 205px;
    margin-right: 5px;
    margin-top: 0px;
}
.btn-only-icon.table-list{
    width: 140px;
    text-align: left;
    padding-top: 5px !important;
}
