.NavRoundButton {
    cursor: pointer;
    margin-left: 25px;
    padding: 4px 12px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.5px;
    border: 1px solid #fff;
    border-radius: 30px;
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 5px;
}

.noClick {
    cursor: unset !important;
}

.skin-1 :global(.NavRoundButton),
.skin-3 :global(.NavRoundButton),
.skin-6 :global(.NavRoundButton) {
    background-color: #005dff2e;
    border-color: #005dff60;
}


.NavRoundButton :global(a) {
    color: white;
    text-decoration: underline !important;
}

@media screen and (max-width: 1415px) {
    .labelSpan {
        display: none;
    }
}

@media screen and (min-width: 1416px) {
    .activateNowSpan {
        display: none;
    }
}

@media screen and (max-width: 1400px) {
    .NavRoundButton {
        display: none;
    }
}