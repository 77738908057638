.AccountsPage {
  left: 0;
  min-height: calc(100vh - 84px);
  position: absolute;
  width: 100%;
}

.AccountsPage .accountsBlocker {
  min-height: calc(100vh - 84px);
  height: 100%;
}

.navPanelWrapper {
  display: inline-flex;
  height: calc(100vh - 230px);
  width: 100%;
}

.AccountsPageAda :global(.wrapperAdaClosed),
.AccountsPageAda :global(.AdaOpenHandImg) {
  margin-right: 0 !important;
}

.AccountsPageAda :global(.contentContainer) {
  margin-bottom: 185px !important;
  margin-right: 157px !important;
}

.tutorialStepTitle {
  align-items: center;
  display: inline-flex;
  gap: 5px;
}

.AccountsPageMultiStepInfo {
  background: linear-gradient(90deg, #1e3ada 0%, #049cf4 100%);
  border-radius: 8px;
  height: 450px;
  overflow: hidden;
}

.AccountsPageMultiStepInfo :global(.Title),
.AccountsPageMultiStepInfo :global(.Subtitle) {
  color: #fff !important;
}

.AccountsPageMultiStepInfo :global(.BngTag.betaTag) {
  border-color: #fff;
  color: #fff;
}

.AccountsPageMultiStepInfo :global(.widget-footer-pg-button.active) {
  background-color: #fff;
}

.AccountsPageMultiStepInfo :global(.widget-footer-pg-button) {
  background-color: #005dff;
}

.AccountsPageMultiStepInfo :global(.Buttons .bng-button.enable) {
  background: transparent !important;
  border-radius: 8px;
  color: #fff !important;
}

.AccountsPageMultiStepInfo :global(.Buttons .bng-button.enable):hover {
  background-color: rgba(255, 255, 255, 15%) !important;
}

.AccountsPageMultiStepInfo :global(.bng-button.save) {
  font-weight: bold;
}

.tutorialLink {
  color: #fff;
  font-weight: bold;
  text-decoration: underline !important;
}

.tutorialLink:hover,
.tutorialLink:focus {
  color: #fff;
}
