.dialog {
  height: 440px;
}

.tabSet {
  height: 400px;
}

.tabSet :global(.tab-content) {
  height: 90%;
}

.switchesContainer {
  display: flex;
  justify-content: space-between;
  width: 35%;
  margin-top: 5%;
}
