.bannerWrapper {
  aspect-ratio: 110/23;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.bannerWrapper.emptyBanner {
  display: none;
}

.carouselWrapper {
  display: inline-flex;
  height: 100%;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.bannerImage {
  flex: none;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
  width: 100%;
}

.bannerImage > div {
  height: 100%;
}

.addonImage {
  height: 85%;
  width: auto !important;
}

.controlsWrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  position: absolute;
  top: calc(50% - 18px);
  width: 100%;
}

.navigationButton {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  padding: 6px;
  position: absolute;
  top: calc(50% - 18px);
}

.btnPrevBannerImg {
  left: 15px;
}

.btnNextBannerImg {
  right: 15px;
}

.navigationButton:hover {
  background: rgba(0, 0, 0, 0.56) !important;
}
