.BngLineColorPicker {
    width: 100%;
    margin: 12px 0;
}

.BngLineColorPicker__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.DashboardLayoutAccordion .HighlightColor .BngLineColorPicker .BngColorPickerDropdown,
.BngLineColorPicker__itemContainer,
.BngLineColorPicker__container .BngColorPickerDropdown{
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BngLineColorPicker__item {
    height: 24px;
    flex: 0 0 24px;
    border-radius: 24px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.275104);
    transition: all ease-in-out 200ms;
    cursor: pointer;
}

.BngLineColorPicker__item:hover {
    height: 28px;
    flex: 0 0 28px;
    border-radius: 28px;
}

.BngLineColorPicker__item--active {
    height: 32px !important;
    flex: 0 0 32px !important;
    border-radius: 32px !important;
}

.BngLineColorPicker__customPicker .ColorCircle {
    height: 24px !important;
    width: 24px !important;
    border-radius: 24px !important;
    border: 1px solid #005DFF !important;
}

.BngLineColorPicker__customPicker--active .ColorCircle {
    height: 32px !important;
    width: 32px !important;
    border-radius: 32px !important;
    /*border: none !important;*/
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.275104);
}

.BngLineColorPicker__customPicker .ColorCircle .Icon.material-icons{
    color: #005DFF;
    font-weight: 500;
}

.BngLineColorPicker__customPicker .ColorCircle:hover {
    height: 28px;
    width: 28px;
    border-radius: 28px;
}