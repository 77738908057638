.delete_forever_icon {
  margin: 6px 0 6px 0;
}

.buttonContainer {
  display: flex;
}

.captionContainer {
  display: flex;
  align-items: center;
}

.captionIcon {
  margin-right: 20px;
  transform: scale(1.6);
  color: #005dff;
}

.dumpPage :global(.bng-search.closed) {
  width: 82px;
  padding: 0 10px 0 5px;
}

.dumpPage :global(.search-button) {
  color: rgba(0, 0, 0, 0.54);
  transition: ease-in-out 250ms;
  transition-property: background-color, color;
}

.dumpPage :global(.bng-search.closed .bng-search-input) {
  width: 0px;
}

.dumpPage :global(.bng-search.closed) {
  width: 30px;
}

.filterDropdownField :global(label) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.typeOptsIcon {
  padding: 5px;
  margin-right: 4px;
  width: 20px;
  color: #005dff;
}

.dateField {
  height: 38px !important;
  box-shadow: inset 0 0 0 1px #ccc !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  color: #555 !important;
  position: relative !important;
  background-color: white !important;
}

.restoreVersionDropdownBody {
  width: 230px;
  padding: 15px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  border: 1px;
  border-radius: 10px;
  right: 45px;
  position: absolute;
}

.restoreVersionDropdownTitle {
  font-weight: 500;
}

.restoreVersionDropdownItem {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 2px;
}

.restoreVersionDropdownItem :global(i) {
  color: #307bff;
  margin-right: 10px;
}

.restoreVersionDropdownItem:hover {
  background-color: #e6efff !important;
  color: #6b87f1 !important;
  cursor: pointer;
  user-select: none;
}

.restoreVersionDropdownWrapper {
  margin-bottom: 12px;
}

.restoreWrapper > .restoreVersionDropdownBody {
  position: relative;
}

.DumpFilterDropdown.fill :global(button) {
  color: #4272fe;
}

.SpinElement {
  animation-name: utils-spin-animation;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  cursor: not-allowed;
}

.removedByFilterItem {
  max-width: 156px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.removalHelper :global(i) {
  font-size: 18px;
  color: #555;
}

.removalHelper {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin: 15px 0 15px 30px;
  background-color: #ebebeb;
  height: 40px;
  padding: 0 30px 0 30px;
  border-radius: 10px;
  line-height: 20px;
}

.captionIcon .dumpTableCaptionIcon {
  font-size: 18px;
}
