.labsWrapper {
  padding: 10px 18px 10px 18px;
  margin-top: 8px;
}

.labsFeaturesWrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  z-index: 3;
  position: relative;
}

.labsHeaderBetaFrag {
  display: flex;
  user-select: none;
}

.labsHeaderBetaFlag {
  border: 1px solid #e23d3d;
  border-radius: 10px;
  color: #e23d3d !important;
  padding: 3px 8px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
}

.labsHeaderBetaSpan,
.labsHeaderBetaFlag {
  color: #555555;
  font-size: 12px;
  font-weight: 400;
}

.featuresFeedbackWarningContainer {
  width: inherit;
  height: 110px;
  padding: 20px;
  background-color: #e4e4e4;
  margin-bottom: 20px;
  border-radius: 14px;
  display: grid;
  gap: 5px;
}

.featuresFeedbackWarningTitle {
  font-weight: 500;
}

.featuresFeedbackWarningDesc {
  font-size: 12px;
  font-weight: 400;
}

.feedbackLink {
  display: flex;
  gap: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  margin-left: auto;
}

.feedbackLink :global(i) {
  position: relative;
  font-size: 16px;
  top: 2px;
}

.adaPublicLabs :global(.wrapperAdaOpen img) {
  margin-right: 50px !important;
  z-index: 10001 !important;
}

.adaPublicLabs :global(.contentContainer) {
  margin-right: 200px !important;
  z-index: 10004 !important;
}

.adaPublicLabs :global(.contentContainer) {
  position: fixed !important;
  z-index: 10004 !important;
}

.adaPublicLabs :global(.disableInfo) {
  z-index: 10000;
  background: rgba(0, 0, 0, 0.45) !important;
}
