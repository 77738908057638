:global(.BngColorPalette) {
  height: 380px;
  overflow-y: auto;
  padding: 15px 15px 0 15px;
  box-sizing: border-box;
}

.colorPaletteWrapper {
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
}
