.AggregationPopper {
    z-index: 22;
}

.Aggregation {
    width: 180px;
    display: block;
    padding-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    margin: 3px 0 0;
    background-color: #fff;
}

.Aggregation li {
    padding: 2px 15px;
    display: block;
}

.Aggregation .AggregationTitle {
    letter-spacing: 0.5px;
    font-weight: 500;
    padding-top: 12px;
    padding-bottom: 12px;
}

.Aggregation .AggregationTitle i {
    padding-right: 10px;
}

.Aggregation .AggregationTitle.Rows i:before {
    transform: rotate(270deg);
}

.Aggregation .AggregationTitle:not(.Columns) {
    border-top: 1px solid #f1f1f1;
    margin-top: 10px;
}

.Aggregation .AggregationInput .checkbox {
    padding-left: 0;
}

.Aggregation .AggregationInput label,
.Aggregation .AggregationInput span.lbl {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 146px;
}
