.cockpit-panel-nav-tab {
    display: none;
    grid-template-columns: repeat(auto-fill, minmax(154px, 1fr));
    grid-gap: 1rem;
}

.cockpit-panel-nav-tab.active {
    display: grid;
}

.cockpit-panel-itens-list {
    height: 270px;
    background: #fafafa;
    padding: 0 10px;
}

a.cockpit-panel-item-nav-button:hover, a.cockpit-panel-item-nav-button:focus, a.cockpit-panel-item-nav-button:active {
    background: #ffffff !important;
}

.cockpit-panel-list-item i {
    margin-right: 6px;
    font-size: 15px;
}

.cockpit-panel-popper {
    right: 0;
    z-index: 10;
    width: 745px;
    margin-top: 7px;
}

input.cockpit-panel-item-search-input {
    border: 0;
    width: 690px;
    margin-bottom: 0;
}

.cockpit-panel-itens-search-container {
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    padding: 10px;
}

.cockpit-panel-itens-search-container-icon {
    color: #6e6e6e;
}

.cockpit-panel-list-item {
    padding: 10px;
    display: block;
    font-size: 13px;
    letter-spacing: .4px;
}

.cockpit-panel-list-item.current-panel a,
.cockpit-panel-list-item a:hover {
    color: #005dff !important;
}

.cockpit-header-controls-container a:hover {
    color: #4272FE;
}

.cockpit-panel-list-item a {
    font-weight: 400;
    color: #696969;
    display: flex;
    align-items: center;
}