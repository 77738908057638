.RemoveUserDialog {
  height: 545px;
}

.RemoveUserDialog :global(.DialogBody) {
  background: #f6f6f6;
  height: 348px;
  overflow: scroll;
}

.RemoveUserDialog :global(.widget-header) {
  border-bottom: 1px solid #e4e4e4;
}

.headerWrapper {
  align-items: start !important;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.removeMsg {
  background: #fcf8e3;
  border: 1px solid #fbeed5;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 12px;
  line-height: 16px;
  padding: 14px 14px 14px 22px;
  width: 97%;
}

.RemoveUserDialog .checkboxColumn {
  margin-left: 15px;
}

.roleWrapper {
  align-items: center;
  color: #555555 !important;
  display: flex;
  gap: 8px;
}

.projectTypeBadge {
  border-radius: 4px;
  color: #fff;
  display: flex;
  height: 18px;
  justify-content: center;
  width: 18px;
}

.projectTypeWrapper {
  align-items: center;
  display: inline-flex;
  gap: 5px;
}

.dialogFooter {
  border-top: 1px solid #e4e4e4;
}

.RemoveUserDialog .dialogFooter .removeButton {
  background: #e23d3d !important;
  border: none;
}

.RemoveUserDialog .dialogFooter :global(.btn-primary).removeButton:hover {
  background: #e55e5e !important;
  border: none;
}
