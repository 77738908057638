.AddonInfoDialog {
  height: 85vh;
  left: 34vw;
  overflow: hidden;
  top: 5vh;
  width: 80vw !important;
}

.AddonInfoDialog .modal-dialog,
.AddonInfoDialog .modal-content,
.AddonInfoDialog .UiBlocker,
.AddonInfoDialog .bs-dialog.modal-body,
.AddonInfoDialog .widget-box,
.AddonInfoDialog .modal-body {
  height: 100%;
}

.AddonInfoDialog .modal-body {
  overflow: clip;
}

.AddonInfoDialog .widget-body.content-full-width {
  height: calc(100% - 52px);
  overflow: scroll;
}
