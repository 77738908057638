.form-group.inline-checkbox .control-label {
    display: inline-block;
}

.form-group.inline-checkbox .checkbox {
    display: inline-block;
    float: left;
    padding: 0;
}

.ReduxFormCheckbox.checkbox.form-control {
    display: flex;
    align-items: center;
}

.ReduxFormCheckbox.checkbox.form-control label {
    margin-bottom: 0;
}