.size_SMALL {
  height: 22px !important;
}

.size_SMALL:global(.container-dropdown-menu .BngIconButton) {
  padding: 2px !important;
}

.size_DEFAULT {
  height: 32px !important;
}

.size_LARGE {
  height: 38px !important;
}

.size_LARGE:global(.container-dropdown-menu .BngIconButton) {
  padding: 10px !important;
}

.headerStyle_ROUNDED {
  box-shadow: none !important;
  border-radius: 30px;
  padding: 2px 8px !important;
  margin: 4px 4px 0 4px;
  width: calc(100% - 24px) !important;
}

.headerStyle_LEFT_ROUNDED {
  box-shadow: none !important;
  border-radius: 30px 0 0 30px;
  padding: 2px 8px !important;
  margin: 4px 0 0 4px;
  width: calc(100% - 20px) !important;
}

.headerStyle_RIGHT_ROUNDED {
  box-shadow: none !important;
  border-radius: 0 30px 30px 0;
  padding: 2px 8px !important;
  margin: 4px 4px 0 0;
  width: calc(100% - 20px) !important;
}

.headerStyle_UPPER_ROUNDED {
  box-shadow: none !important;
  border-radius: 30px 30px 0 0;
  padding: 2px 12px !important;
  margin: 4px 6px 0 6px;
  width: calc(100% - 36px) !important;
}

.headerStyle_ROUNDED_BOTTOM {
  box-shadow: none !important;
  border-radius: 0 0 30px 30px;
  padding: 4px 12px !important;
  margin: 0;
  width: calc(100% - 24px) !important;
}

.headerStyle_OPPOSITE_ROUNDED {
  box-shadow: none !important;
  border-radius: 0 30px 0 30px;
  padding: 0 12px !important;
  margin: 6px 6px 0 6px;
  width: calc(100% - 36px) !important;
}

.headerStyle_KNIFE {
  box-shadow: none !important;
  clip-path: polygon(24px 0%, 100% 0%, calc(100% - 24px) 100%, 0 100%);
  -webkit-clip-path: polygon(24px 0%, 100% 0%, calc(100% - 24px) 100%, 0 100%);
  padding: 1px 18px !important;
  position: relative;
  margin: 6px 6px 0 6px;
  width: calc(100% - 48px) !important;
}

.customContainerHeaderColor:global(.container-dropdown-menu .BngDropdown .BngIconButton),
.customContainerHeaderColor:global(.container-dropdown-menu .BngDropdown .BngIconButton):hover,
.customContainerHeaderColor:global(.container-dropdown-menu .BngDropdown .BngIconButton):focus {
  background: inherit !important;
}

.customContainerHeaderColor :global(.BngIconButton) i {
  color: inherit !important;
}

.ContainerTitle_UNDERLINE :global(.container-title span) {
  text-decoration: underline;
}

.ContainerTitle_ITALIC :global(.container-title span) {
  font-style: italic;
  padding-right: 3px;
}

.ContainerTitle_BOLD :global(.container-title) {
  font-weight: bolder;
}

.ContainerTextAlign_RIGHT {
  flex-direction: row-reverse;
}

.ContainerTextAlign_RIGHT :global(.container-title) {
  justify-content: end;
}

.ContainerTextAlign_CENTER :global(.container-title) {
  justify-content: center;
}

.containerLightBg :global(table.mdx-table th),
.containerLightBg
  :global(table.mdx-table
    td:not([style*='background']):not([class*='green']):not([class*='yellow']):not([class*='red'])) {
  filter: none !important;
}

.containerDarkBg :global(table.mdx-table th),
.containerLightBg
  :global(table.mdx-table
    td:not([style*='background']):not([class*='green']):not([class*='yellow']):not([class*='red'])) {
  filter: invert(90%) hue-rotate(180deg);
}

:global(.DashGrid) .containerLightBg :global(.kpititle),
:global(.DashGrid) .containerLightBg :global(.KpiTarget),
:global(.DashGrid) .containerLightBg :global(.KpiInternalBody div[id*='percent']),
:global(.DashGrid) .containerLightBg :global(.KpiDescriptionSpan),
:global(.DashGrid) .containerLightBg :global(.icon-chevron-left),
:global(.DashGrid) .containerLightBg :global(.KpiBandsValue),
:global(.DashGrid) .containerLightBg :global(.kpi-percent),
:global(.DashGrid) .containerLightBg :global(.kpidescription),
:global(.DashGrid) .containerLightBg :global(.KpiGrowth) {
  color: #333333;
}

:global(.DashGrid) .containerDarkBg :global(.kpititle),
:global(.DashGrid) .containerDarkBg :global(.KpiTarget),
:global(.DashGrid) .containerDarkBg :global(.KpiInternalBody div[id*='percent']),
:global(.DashGrid) .containerDarkBg :global(.KpiDescriptionSpan),
:global(.DashGrid) .containerDarkBg :global(.icon-chevron-left),
:global(.DashGrid) .containerDarkBg :global(.KpiBandsValue),
:global(.DashGrid) .containerDarkBg :global(.kpi-percent),
:global(.DashGrid) .containerDarkBg :global(.kpidescription),
:global(.DashGrid) .containerDarkBg :global(.KpiGrowth) {
  color: #ececec;
}

.containerCustomBackground table th,
.containerCustomBackground table td {
  filter: none !important;
}

.iconLeft {
  display: inline-flex;
}

.iconRight {
  display: inline-flex;
  flex-direction: row-reverse;
}

.iconLeft :global(.Icon) {
  padding-right: 10px;
}

.iconRight :global(.Icon) {
  padding-left: 10px;
}
