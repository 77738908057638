.ApplicationKeyDialog {
    top: calc(50% - 200px);
}

.ApplicationKeyDialog .application-key-dialog-body {
    padding: 20px 25px 0;
}

.ApplicationKeyDialog .token-row {
    display: flex;
    position: relative;
}

.ApplicationKeyDialog .token-row > *:first-child {
    width: 100%;
}

.ApplicationKeyDialog .generate-token-button {
    display: block;
    margin: auto;
    padding: 1px 6px;
    border-radius: 4px;
    background: #FCBA27;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 7px;
    font-size: 12px;
    top: calc(50% - 3px);
    letter-spacing: 0.3px;
}

.ApplicationKeyDialog .generate-token-button:hover {
    background: #E6AA27;
}

.ApplicationKeyDialog .block-by-domain-row {
    display: flex;
}

.ApplicationKeyDialog .block-by-domain-row .BngSwitch {
    width: 100%;
}

.ApplicationKeyDialog .block-by-domain-row .bng-add-button  {
    text-align: right;
    padding: 10px 0;
    min-width: fit-content;
    min-width: -moz-fit-content;
}

.ApplicationKeyDialog .remove-domain-button {
    margin-bottom: 10px;
    padding: 5px 0;
    text-align: center;
    background: #EE5825;
    color: white;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
}