.tfa-form-header {
  display: flex;
  margin-bottom: 22px;
}

.tfa-form-header-fields {
  display: flex;
  margin-left: 160px;
  gap: 20px;
  align-items: center;
}

.tfa-form-header-fields .tfa-checkbox-field {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 12px;
}

.tfa-form-header-fields .tfa-checkbox-label {
  margin-bottom: 0;
}

.scroll-bar-tfa-table-wrapper {
  overflow-y: scroll;
  height: 450px;
}

.tfa-bng-panel {
  width: 100%;
}

.Panel.Header.tfa-bng-panel-header {
  padding: 16px;
}

.tfa-title-text {
  margin: 0;
}