.tabWrapper {
  width: 682px;
  height: 300px;
  background-color: #f6f6f6;
}

.recurrenceContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.diarySendTime {
  display: flex;
  margin-top: 36px;
  align-items: center;
  justify-content: space-between;
}

.diarySendTime :global(.controls .BngDropdown) {
  margin-left: auto;
}

.fieldDefault,
.fieldDefault :global(.controls) {
  width: 100% !important;
}

.fieldSelectDefault,
.fieldDefault,
.fieldDefault :global(.controls) {
  display: flex;
  height: 40px;
  margin-bottom: 0 !important;
}

.fieldSelectDefault:last-child {
  margin-left: auto;
}

.monthlyDaysContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.labelDefaultStrong {
  font-weight: 500 !important;
}

.labelDefault,
.labelDefaultStrong {
  font-size: 14px !important;
  color: #333333 !important;
  text-shadow: none !important  ;
}

.labelDefault {
  font-weight: 400 !important;
}

.inlineTimingContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.fieldSelectDefault .monthlyDaysComboBox {
  width: 254px;
  height: 40px;
  border-radius: 8px;
  margin-bottom: 0;
  font-size: 14px;
  border: 1px solid #ccc;
}

.fieldSelectDefault .monthlyDaysComboBox:focus {
  border-color: #9d9d9d;
}

.intervalBottomContainer {
  margin-top: 24px;
}

.intervalTime {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 8px;
}

.tabWrapper .intervalMinutes {
  width: 156px;
  height: 40px;
  border-radius: 8px;
  margin-bottom: 0;
  border: 1px solid #d1d1d1;
}

.intervalBetween {
  display: flex;
  align-items: center;
}

.intervalLabelSize,
.intervalFirstGapLabel {
  font-size: 12px;
  margin-bottom: 0 !important;
  font-weight: 400 !important;
}

.intervalLabelSize {
  margin-left: 14px;
}

.intervalFirstGapLabel {
  width: 168px;
}

.scheduleTypeButtonGroup {
  height: 44px;
}

.dayButtons {
  height: 44px;
}

.tabWrapper .schedulingIdentifier {
  height: 38px !important;
  color: #888888;
  font-weight: 500;
  border: 1px solid #d1d1d1;
}

.diarySendTimeInputIcon {
  margin-left: auto;
  width: 287px;
}

.intervalSendTimeInputIcon {
  width: 156px;
}

.monthlyInputIcon {
  width: 274px;
}
