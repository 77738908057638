.LastLoginsTab {
  overflow: auto;
  height: 358px;
  background-color: #f6f6f6;
  border: 1px solid #e4e4e4;
}

.LastLoginsTab .BngTableTd span {
  color: #555555;
}

.LastLoginsTab .BngTableTd .Icon {
  font-size: 18px !important;
  color: #1a6dff;
}