.CreatePublicLink .widget-header > * {
    text-transform: uppercase;
    font-size: 14px !important;
    font-weight: bold;
}

.CreatePublicLink .content-full-width .bng-button {
    margin-top: 10px;
}

.CreatePublicLink .public-link-form-container {
    padding: 23px;
    background-color: #F6F6F6;
    border: 1px solid #E7E8EB;
    border-left: 0;
    border-right: 0;
}

.CreatePublicLink .public-link-field-container {
    margin-bottom: 19px;
}

.CreatePublicLink .public-link-field-container:last-child {
    margin-bottom: 0;
}

.CreatePublicLink .PermanentSwitch .BngField {
    margin-bottom: 0;
    display: inline-flex !important;
    align-items: center;
}

.CreatePublicLink .PermanentSwitch .BngField .control-label {
    margin-right: 15px;
    margin-bottom: 0;
}

.CreatePublicLink .ShortLinkSwitch .BngField {
    margin-bottom: 0;
    display: inline-flex !important;
    align-items: center;
}

.CreatePublicLink .ShortLinkSwitch .BngField .control-label {
    margin-right: 15px;
    margin-bottom: 0;
}

.CreatePublicLink .public-link-form-container .BngField .control-label {
    font-weight: bold;
}