.MktDefaultTab {
  background: #f6f6f6;
  display: flex;
  justify-content: center;
}

.defaultHeader {
  background: #f6f6f6;
}

.MktDefaultTabWrapper {
  background: #f6f6f6;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin: 25px;
  max-width: 1100px;
  width: 100%;
}

.searchWrapper {
  padding: 20px 0 0 0;
}

.customSearch {
  align-items: center;
  align-self: stretch;
  background: #fff;
  border: 2px solid #e4e4e4;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  color: #555;
  display: inline-flex;
  gap: 8px;
  padding: 10px 14px;
  width: 100%;
}

.placeholder {
  color: #888;
  opacity: 0.75;
}

.marketplaceSearchField {
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
}

.marketplaceItemsShowcase {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.groupsWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.clearSearch {
  padding: 0;
}

.itemBanner {
  border-radius: 8px;
  display: inline-flex;
  height: 230px;
  overflow: hidden;
  width: 100%;
}

.itemBannerInfoWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 24px 24px 24px 42px;
  width: 65%;
}

.itemBannerImage {
  background: #fff;
  position: relative;
  width: 35%;
}

.itemBannerImage img {
  height: 100%;
  object-fit: cover;
}

.itemBannerIcon {
  font-size: 102px !important;
  left: 40%;
  position: absolute;
  top: 38%;
}

.bannerTitle {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.bannerDescription {
  color: #fff;
}

.bannerBtn {
  margin-top: 10px;
}

.emptyIndicator {
  position: relative;
  transform: translateX(0px);
}
