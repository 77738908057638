.SaveAsDialog .folder-description {
    margin: 0;
}

.SaveAsDialog .folder-text {
    height: 40px;
}

.SaveAsDialog .FolderContainer {
    display: flex;
}

.SaveAsDialog .FolderContainer > div:first-child {
    flex-grow: 1;
}

.swal2-container {
    z-index: 999999 !important;
}