.ContainerCreatorParent {
    position: fixed;
    bottom: 50px;
    opacity: 1;
    z-index: 350;
    transform: translate(-50%);
    left: 50%;
    border-radius: 100px;
    animation: softComing 0.5s ease-out;
}

@keyframes softComing {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.ContainerCreatorParent .bng-snackbar .cancel-container-creation {
    cursor: pointer;
    width: 35px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.free-style-marker-class.OnContainerCreation .DashGrid .DashGridItem.select-item-for-container {
    box-sizing: border-box;
    border: 2px dashed #005dff !important;
    opacity: 1 !important;
}

.DashGrid .DashGridItem.conflict-item-for-container {
    box-sizing: border-box;
    border: 2px dashed #e23d3d !important;
}

.DashGrid .DashGridItem.conflict-item-for-container .CustomResizeHandle circle {
    stroke: #e63d3d !important;
}

.select-item-for-container .grid-stack-item-content,
.select-item-for-container .widget-box,
.select-item-for-container .widget-body {
    background-color: white;
}

.ContainerCreatorParent .ConflictHelp {
    color: white;
    margin-bottom: 2px;
    cursor: pointer;
    width: 35px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ContainerCreatorOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333;
    opacity: 0.3;
    z-index: 250;
}

.free-style-marker-class.OnContainerCreation {
    z-index: 300 !important;
}

.free-style-marker-class.OnContainerCreation .item-content-container {
    background-color: transparent;
}

.free-style-marker-class.OnContainerCreation .DashGridItem.Container {
    opacity: 0.3;
}

.free-style-marker-class.OnContainerCreation .DashGrid .DashGridItem.react-resizable:not(.Container) {
    border: 2px dashed #828282;
    opacity: 0.8;
}

.free-style-marker-class.OnContainerCreation
.DashGrid
.DashGridItem.react-resizable:not(.Container)
.widget-box
.widget-body {
    border-color: transparent;
}

.free-style-marker-class.OnContainerCreation .container-dropdown-menu .drop-button {
    display: none;
}

.free-style-marker-class .dashboard-page-break-filler {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
}

.free-style-marker-class.OnContainerCreation .dashboard-page-break-filler {
    display: block;
}

.containerCreatorSnackbarClose {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
