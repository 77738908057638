.BngCalendar {
    position: relative;
    word-break: normal;
}

.BngCalendar * {
    font-weight: 500;
}

.BngCalendar.react-calendar {
    border: none;
}

.BngCalendar .react-calendar__navigation {
    border-top: 1px solid #dedfe1;
    margin: 0;
    padding-left: 25px;
    padding-right: 25px;
/*    position: absolute;
    width: 100%;
    top: 28px;*/
}

.BngCalendar .react-calendar__month-view__days {
    margin-top: 5px;
    padding-left: 25px;
    padding-right: 25px;
    /*margin-top: 65px;*/
}

.BngCalendar .react-calendar__month-view__weekdays {
    padding-left: 25px;
    padding-right: 25px;
    border-top: 1px solid #dedfe1;
    border-bottom: 1px solid #dedfe1;
    /*position: absolute;
    width: 100%;
    top: 0;*/
}

.BngCalendar .react-calendar__tile {
    padding: 4px;
    margin-bottom: 5px;
    font-weight: bold;
}

.BngCalendar .react-calendar__tile--rangeStart {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}

.BngCalendar .react-calendar__tile--rangeEnd {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

.BngCalendar .react-calendar__navigation__label {
    text-transform: capitalize;
}

.BngCalendar .react-calendar__year-view .react-calendar__tile,
.BngCalendar .react-calendar__decade-view .react-calendar__tile,
.BngCalendar .react-calendar__century-view .react-calendar__tile {
    text-transform: capitalize;
    padding: 8px;
}

.BngCalendar .react-calendar__year-view,
.BngCalendar .react-calendar__decade-view,
.BngCalendar .react-calendar__century-view {
    padding: 0 15px;
}

.BngCalendar .react-calendar__tile {
    color: #6C6C6C;
}

/*.BngCalendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--active,
.BngCalendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth.react-calendar__tile--active,
.BngCalendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.react-calendar__tile--active,
.BngCalendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--rangeEnd,
.BngCalendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--rangeStart,
.BngCalendar .react-calendar__tile .react-calendar__tile--hasActive .react-calendar__tile--rangeStart .react-calendar__year-view__months__month,
.BngCalendar .react-calendar__tile .react-calendar__tile--hasActive .react-calendar__tile--rangeEnd .react-calendar__year-view__months__month
{
    color: white;
}*/

.BngCalendar .react-calendar__tile.react-calendar__month-view__days__day--weekend {
    color: #9b9b9b;
}

.BngCalendar .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
    color: #cdcdcd;
}



.BngCalendar .react-calendar__month-view__weekdays__weekday,
.BngCalendar .react-calendar__month-view__weekdays__weekday abbr {
    border: none;
    text-decoration: none;
}

.BngCalendar .react-calendar__tile:disabled,
.BngCalendar .react-calendar__navigation button[disabled] {
    background-color: unset;
}


.BngCalendar .react-calendar__tile.react-calendar__tile--active,
.BngCalendar .react-calendar__tile.react-calendar__tile--hasActive {
    background-color: #005DFF;
    color: white;
}

.BngCalendar button[disabled] {
    color: #bcbcbc;
    cursor: not-allowed;
}

@media only screen and (max-device-width: 829px), screen and (max-device-height: 560px) {
    .BngCalendar.react-calendar {
        width: 100%;
        font-size: 18px;
    }

    .BngCalendar .react-calendar__navigation {
        height: 46px;
    }

    .BngCalendar .react-calendar__month-view__days {
        margin-top: 10px;
        height: 208px;
    }

    .BngCalendar .react-calendar__month-view__weekdays {
        font-size: 16px;
    }

    .BngCalendar label,
    .BngCalendar input,
    .BngCalendar button,
    .BngCalendar select,
    .BngCalendar textarea {
        font-size: 18px;
    }
}