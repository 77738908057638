.BngAnalysisDrillDownContainer {
  position: relative;
}

.BngAnalysisDrillDownBar.DrillMembers {
  position: absolute;
  top: 12px;
  left: 0;
  max-width: calc(100% - 100px);
}

.BngAnalysisDrillDownBar .Members {
  background-color: white;
  box-shadow: 0 0 3px rgb(0 0 0 / 25%);
  border-radius: 17px;
  font-size: 11px;
  padding: 0 1px 0 8px;
  color: #757575;
  font-weight: 500;
  line-height: 13px;
  flex-wrap: wrap;
}

.BngAnalysisDrillDownBar .Members .BngIconButton {
  margin-left: 3px;
}

.BngAnalysisDrillDownBar .DrillMember {
  display: flex;
  align-items: center;
  gap: 3px;
}

.BngAnalysisDrillDownBar .DrillMember > span {
  white-space: nowrap;
}

.BngAnalysisDrillDownBar .DrillMember .Sep {
  margin-right: 3px;
}

.BngAnalysisDrillDownBar.DrillButtons {
  position: absolute;
  top: 10px;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity ease-in-out 150ms;
}

.withDrillButtons .DrillButtonsContainer .DrillButtons {
  border-right: 2px solid #e9e9e9;
}

.chart-container-marker-class {
  position: relative;
}

/* On Cockpit */
.cockpit-item-panel .BngAnalysisDrillDownBar.DrillMembers {
  left: 12px;
}

/* only on analysis directly */
.cockpit-item-panel #drillDownOperationsContainer .BngAnalysisDrillDownBar.DrillButtons {
  right: 12px;
}

/* On Dashboard */
.RenderablePreload .BngAnalysisDrillDownBar.DrillButtons,
.cockpit-item-panel .RenderablePreload .BngAnalysisDrillDownBar.DrillButtons {
  right: 12px;
}

.RenderablePreload .BngAnalysisDrillDownBar.DrillMembers,
.cockpit-item-panel .RenderablePreload .BngAnalysisDrillDownBar.DrillMembers {
  left: 12px;
  max-width: calc(100% - 150px);
}

.BngAnalysisDrillDownContainer:hover .DrillButtons {
  visibility: visible;
  opacity: 1;
}

/* on Dashboard menu DashboardItemMenu/DashboardItemMenuExplorerMobile */
.withDrillButtons .BngIconButton {
  box-shadow: none !important;
}

.withDrillButtons .DrillButtonsContainer {
  display: inline-block;
  border-radius: 0;
}

.withDrillButtons .DrillButtons {
  position: relative;
  top: unset;
  right: unset;
  opacity: 1;
  visibility: visible;
}

.BngAnalysisDrillDownContainer rect[onclick],
.BngAnalysisDrillDownContainer path[onclick] {
  transition: opacity ease-in-out 150ms;
}

.BngAnalysisDrillDownContainer rect[onclick]:hover,
.BngAnalysisDrillDownContainer path[onclick]:hover,
.BngAnalysisDrillDownContainer g.rect-hover:hover path {
  opacity: 0.8 !important;
}

.OnContainerCreation .BngAnalysisDrillDownContainer rect[onclick]:hover,
.OnGridCreation .BngAnalysisDrillDownContainer rect[onclick]:hover,
.OnContainerCreation .BngAnalysisDrillDownContainer path[onclick]:hover,
.OnGridCreation .BngAnalysisDrillDownContainer path[onclick]:hover {
  opacity: 1 !important;
}

.BngAnalysisDrillDownBar.DrillButtons.analystMenuOpen {
  right: 335px;
}

svg [onclick] {
  cursor: pointer;
}

.drillDownOperationsLoaderContainer {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(100, 100, 100, 0.15);
}

.BngAnalysisDrillDownContainer.onLastLevel rect[onclick],
.BngAnalysisDrillDownContainer.onLastLevel path[onclick] {
  opacity: 0.6;
}

.BngAnalysisDrillDownContainer.onLastLevel rect[onclick].SelectedMember,
.BngAnalysisDrillDownContainer.onLastLevel path[onclick].SelectedMember {
  opacity: 1;
}

.OnContainerCreation .BngAnalysisDrillDownContainer *[onclick],
.OnGridCreation .BngAnalysisDrillDownContainer *[onclick] {
  cursor: default;
}
