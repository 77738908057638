.text {
  font-size: 12px;
}

.buttonAction {
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  font-weight: 500;
  background-color: #005dff !important;
}

.buttonAction:hover {
  background-color: #0050db !important;
}
