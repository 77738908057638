.NewFolderDialog {
    width: 420px;
    margin-left: -210px;
}

.NewFolderDialog .BngForm input {
    height: 42px;
    border: 1px solid #D5D5D5;
    max-width: 350px;
}

.NewFolderDialog .FieldAndIconContainer {
    display: flex;
}

.NewFolderDialog .FieldAndIconContainer > div:first-child {
    flex-grow: 1;
}

.NewFolderDialog .FieldAndIconContainer > div:last-child {
    margin-left: 10px;
    min-width: 45px;
    width: 45px;
}